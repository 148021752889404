const messages = {
    country: "País",
    priceToSend: "Precio de envío",
    priceToReceive: "Precio de recepción",
    seeSMSListPrices: "Ver precios SMS",
    countryCL: "Chile",
    countryES: "España",
    countryCO: "Colombia",
    countryPE: "Perú",
    countryAR: "Argentina",
    countryEC: "Ecuador",
    countryVE: "Venezuela",
    countryBO: "Bolivia",
    countryMX: "México",
    countryPA: "Panamá",
    countryUS: "Estados Unidos",
    countryCA: "Canadá",
    countryBR: "Brasil",
    countryPY: "Paraguay",
    countryUR: "Uruguay",
}

export default messages
