const messages = {
    quickAnswers: "Respuestas rápidas",
    quickAnswersDesc1:
        "Define mensajes que puedas enviar con un solo click. Útiles para mensajes estandarizados o envíados con frecuencia.",
    quickAnswersDesc2: "Las respuestas rápidas no se pueden usar para iniciar conversaciones.",
    quickAnswerText: "Texto de respuesta rápida",
    createNewQuickTextDesc1:
        "Puedes incluir alguna de las siguientes variables predefinidas (deben ir escritas con doble llave):",
    createNewQuickTextDesc2:
        "También puedes agregar tus propias variables personalizadas usando doble llave de la misma forma: ",
    createNewQuickTextDesc3:
        ". Si usas variables personalizadas, se te pedirá que declares su valor cuando envíes la respuesta rápida.",
    createQuickText: "Crear respuesta rápida",
    updateQuickText: "Actualizar respuesta rápida",
    editQuickText: "Editar respuesta rápida",
    newQuickText: "Nueva respuesta rápida",
    createQuickTextAndAnother: "Crear respuesta rápida y otra",

    youCanOnlyHaveUpTo80QuickTexts: "Solo puedes tener hasta 80 respuestas rápidas.",
    pleaseDeleteSomeQuickTexts: "Deberás eliminar algunas para poder crear una nueva.",
}

export default messages
