import { useState, useEffect } from "react"

function getBrowserVisibilityProp() {
    if (typeof document.hidden !== "undefined") {
        return "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitvisibilitychange"
    }
}

function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== "undefined") {
        return "hidden"
    } else if (typeof document.msHidden !== "undefined") {
        return "msHidden"
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitHidden"
    }
}

function getIsDocumentHidden() {
    return !document[getBrowserDocumentHiddenProp()]
}

// Retorna si la página es visible en este momento para el cliente
function usePageVisibility() {
    const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())
    useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp()
        document.addEventListener(visibilityChange, onVisibilityChange, false)
        return () => {
            document.removeEventListener(visibilityChange, onVisibilityChange)
        }
    })
    return isVisible
}

export default usePageVisibility
