const messages = {
    didYouKnow: "Você sabia...?",
    cheetahFact1: "Uma chita pode correr até 120 km/h",
    cheetahFact2: "Chitas caçam durante o dia",
    cheetahFact3: "Apenas 9.000-12.000 chitas permanecem na África",
    cheetahFact4: "A chita geralmente dá à luz de 2 a 8 filhotes por vez",
    cheetahFact5: "Uma chita pode cuidar de si mesma aos 16-24 meses de idade",
    cheetahFact6: "As chitas geralmente perseguem coelhos, javalis, gazelas e pássaros",
    cheetahFact7: "A chita é o animal terrestre mais rápido",
    cheetahFact8: "As chitas não retraem as garras quando correm",
    cheetahFact9: "Ao correr, as chitas usam a cauda para dirigir",
    cheetahFact10: "As lágrimas pretas na chita protegem seus olhos do sol durante a perseguição",
    cheetahFact11: "Uma chita pode correr 100 metros em 6 segundos",
    cheetahFact12: "Uma chita atinge 100 km/h em apenas três segundos",
    cheetahFact13: "Depois de uma perseguição, uma chita precisa de 30 minutos para recuperar o fôlego antes de comer",
    cheetahFact14: "As chitas não podem rugir, elas miam e ronronam",
    cheetahFact15: "As chitas só bebem água a cada três ou quatro dias",
    cheetahFact16: "Uma chita corre cerca de 50% mais rápido que Usain Bolt",
    cheetahFact17: "A plena velocidade, os guepardos cobrem cerca de 21 pés com cada passada",
    cheetahFact18: "Um guepardo pode enxergar até 3 milhas de distância",
    cheetahFact19: "Um guepardo tem entre 2000 e 3000 pintas",
    cheetahFact20: "Guepardos eram mantidos como animais de estimação e utilizados para caça no Antigo Egito",
    cheetahFact21: "Chita tem origens na língua Hindi e significa adornado ou pintado",
    cheetahFact22: "Você pode escrever em letras em negrito no chat usando * assim: ",
    cheetahFact23: "Você pode escrever letras em itálico no chat usando _ assim: ",
    cheetahFact24:
        "Conectar o Cheetrack ao Shopify permite segmentar clientes e enviar mensagens personalizadas a eles",
    cheetahFact25: "Uma pessoa precisa ver seu produto 5 vezes antes de comprá-lo",
    cheetahFact26: "Conectar o Cheetrack ao Shopify permite recuperar carrinhos abandonados por meio do WhatsApp",
    cheetahFact27: "Você pode usar emojis em suas mensagens no Cheetrack",
    cheetahFact28: "Você pode criar painéis de vendas usando etiquetas",
    cheetahFact29: "Você pode conectar o Cheetrack às suas contas do Facebook e Instagram",
    cheetahFact30: "Você pode usar suas próprias variáveis personalizadas em suas mensagens",
    cheetahFact31: "O Cheetrack pode atribuir automaticamente mensagens recebidas aos seus agentes",
    cheetahFact32: "Você pode limitar seus agentes a verem apenas as conversas atribuídas a eles",
    cheetahFact33:
        "O WhatsApp impõe um limite diário para suas mensagens; quanto mais mensagens você enviar, maior será o limite",
}

export default messages
