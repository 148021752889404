const messages = {
    boardName: "Nome do Painel",
    boards: "Painéis",
    boardsDesc: "Crie painéis para organizar seus clientes.",
    boardsDesc2: "Os painéis utilizam etiquetas para organizar as conversas com seus clientes em colunas.",
    createNewBoard: "Configurar um novo painel",
    addTagToBoard: "Adicionar etiqueta ao painel",
    boardsSelectTag: "Selecionar etiqueta",
    selectTagsForYourBoard: "Selecionar etiquetas para adicionar a este painel",
    saveBoardChanges: "Salvar painel",
    deleteBoard: "Excluir painel",
    thisTagBelongsToABoard: "Esta etiqueta pertence a um painel.",
    seeBoard: "Ir para o painel",
    advanceToNextTagInBoard: "Avançar para a próxima etiqueta no painel",
    backToPreviousTagInBoard: "Voltar para a etiqueta anterior no painel",
    goToConversation: "Ir para esta conversa",
    goToConversationsWithTag: "Ver conversas com esta etiqueta",
    boardSuccessPercentage: "Taxa de sucesso do painel",
    seeBoardsList: "Ver lista de painéis",
    nameYourBoard: "Nomeie seu painel",
    gettingConversationsWithThisTag: "Obtendo clientes com esta etiqueta",
    boardStepSucess: "sucesso",
    boardStepSucessDesc: "Porcentagem de clientes que avançaram dos passos anteriores para este passo ou além",
    finalBoardStep: "Passo final",
    savingTag: "Salvando etiqueta",
    editBoard: "Editar painel",
    thisTagIsPartOfABoard: "Esta etiqueta faz parte de um painel:",
    thisTagActivatesAChase: "Esta etiqueta ativa uma procura:",
    thisTagIsStepOnBoard: "Etapa {step} sobre {board}.",
    thisConversationIsOnABoard: "Esta conversa aparece nos seguintes painéis:",
    clickOnOneOfTheStepToChangeTag:
        "Clique em uma das etapas para mover esta conversa para dentro do painel e alterar sua etiqueta conforme necessário",
    youDontHaveAnyBoardsYet: "Você ainda não tem nenhum painel",
    createYourFirstBoardClickingTheTopBtn: "Crie seu primeiro painel clicando no botão no topo",
    youDontHaveThePermissionsToCreateBoards:
        "Você não tem as permissões necessárias para criar ou editar painéis. Solicite a um administrador se precisar que um seja criado ou alterado.",
    boardTags: "Etiquetas do painel",
    filterBoardClients: "Filtrar clientes do painel",
    aDayAgo: "Há um dia",
    threeDaysAgo: "Três dias atrás",
    aWeekAgo: "Uma semana atrás",
    aMonthAgo: "Um mês atrás",
    aYearAgo: "Um ano atrás",
    clientCreationDate: "Data de início",
    successPercentageInfo:
        "A taxa de sucesso por etapa indica a proporção de clientes que passaram por aquela etapa, incluindo tanto aqueles que estão atualmente lá quanto aqueles em etapas posteriores.",

    xTotalClientsForThisBoard: "{totalClients} Clientes totais para este painel.",
    xTotalClientsForThisBoardWithFilters: "{totalClients} Clientes totais para este painel com {filters} filtros.",
    youCanOnlyHaveUpToXTags: "Você só pode ter até {x} etiquetas.",
    youWillNeedToDeleteSomeTagsToCreateANewOne: "Exclua uma para criar uma nova.",
    youCanOnlyHaveUpToXBoards: "Você só pode ter até {x} painéis.",
    youWillNeedToDeleteSomeBoardsToCreateANewOne: "Você precisará excluir alguns painéis para criar um novo.",
}
export default messages
