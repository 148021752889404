const messages = {
    telephone: "Telefone",
    roleInCheetrack: "Função no Cheetrack",
    activeConversation: "Conversas ativas",
    workTeam: "Equipe de trabalho",
    invite: "Convidar",
    userDescription:
        "O plano <account_plan_name> inclui <included_seats_plan> assentos, expansível até <max_seats_plan> assentos.",
    active: "Ativo",
    inactive: "Inativo",
    manager: "Gerente",
    executive: "Executivo",
    activeDialogTitle: "Ativar usuários",
    activeDialogDesc1: "Seu plano inclui <included_seats_plan> assentos na mensalidade.",
    activeDialogDesc2:
        "Ao ativar este usuário, carregaremos <amount_current_month> pelo restante deste mês e um valor adicional de <amount_others_month> para os meses seguintes em que você tiver mais de <included_seats_plan> usuários ativos.",
    activeDialogDesc3:
        "Lembramos que seu plano é <account_plan_name> e permite <total_seats_plan> assentos no total. Para melhorar seu plano e ver detalhes de faturamento, visite o módulo de Faturamento",
    activeDialogButton: "Eu entendo, ativar usuário",
    inviteUserDialogTitle: "Invitar usuários",
    editUserDialogTitle: "Editar usuários",
    inviteUserDialogDesc: "Escreva o e-mail e selecione a função dos usuários que você convidará para o Cheetrack.",
    inviteuserDialogButton: "Enviar Convite",
    selectRole: "Selecione uma função",
    planLimitDialogTitle: "Seu plano atingiu o número máximo de assentos",
    planLimitDialogDesc1: "Seu plano permite um máximo de <total_seats_plan> assentos.",
    planLimitDialogDesc2: "Para ativar este usuário, você deve melhorar seu plano.",
    planLimitDialogButton: "Melhorar plano",
    deleteUserConfirmMsg1: "Esta ação removerá o usuário ",
    deleteUserConfirmMsg2: " e todos os registros associados a esta conta.",
    deleteUserConfirmMsg3: "Você tem certeza de que deseja excluir este usuário?",
    buttonUserDelete: "Ok, usuário excluído",
    buttonUpdateUser: "Atualizar usuário",
    inviteMyTeam: "Convide minha equipe",
    blankTipDescription1: '"O talento vence jogos, mas o trabalho em equipe vence campeonatos"',
    blankTipDescription2: "Convide sua equipe para o Cheetrack e impulsione suas vendas.",
    inviteUser: "Convide usuário",
    improvePlan: "Melhore o plano",
    usedSeats: "Assentos ocupados",
    maxUsersReached: "Você atingiu o número máximo de usuários. Se precisar de mais, atualize seu plano!",
    inviteOtherUser: "Convidar outro usuário",
    userGroup: "Grupo",
    userGroupHelp:
        "Você pode atribuir este usuário a um grupo que o associe a outros usuários. Por exemplo: vendas ou marketing.",
    recommendedGroups: "Grupos recomendados",
    createGroup: "Criar grupo",
    allowExecutivesToSeeAllConversations: "Executivos podem ver todas as conversas.",
    executivesWillOnlySeeTheirConversations: "Executivos só veem as suas conversas.",
    anErrorOcurredObtainingUserData: "Ocorreu um erro ao obter dados dos usuários",
    thatEmailIsAlreadyInUse: "Este e-mail já está em uso",
    deactivateUser: "Desativar usuário",
    deactivateUserDesc: "Desativar este usuário impedirá que ele faça login na plataforma.",
    errorSavingUser: "Erro ao salvar o usuário",
    eachAdditionalUserCosts19USD: "Cada usuário adicional custa 19 USD por mês.",

    yourPlanIncludesXSeats: "Seu plano inclui {x} assentos.",
    ifYouNeedMoreUsersClickHere: "Se precisar de mais usuários, clique aqui.",
    notifications: "Notificações",
    feedbackNotifications: "Notificações de Feedback",
    userXAlreadyHasAnAccount: "O usuário {x} já possui uma conta, por favor, tente com outro e-mail.",
}

export default messages
