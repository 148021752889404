import { useCallback, useRef } from "react"

// Hace debounce de una función para que se ejecute después de un tiempo
function useDebounceCallback(method, delay) {
    const argsRef = useRef()
    const timeout = useRef()

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
    }

    const debouncedCallback = useCallback(
        (...args) => {
            // capture latest args
            argsRef.current = args

            // clear debounce timer
            cleanup()

            // start waiting again
            timeout.current = setTimeout(() => {
                if (argsRef.current) {
                    method(...argsRef.current)
                }
            }, delay)
        },
        [delay, method]
    )

    return debouncedCallback
}

export default useDebounceCallback
