import { templateTimeLimits } from "../constants"

// Ajusta las plantillas de persecución a los límites de tiempo
// Esto para que obedezcan a las reglas definidas en templateTimeLimits

function adjustChaseTemplatesToTimeLimits(templates, extraRules) {
    const updateTemplates = JSON.parse(JSON.stringify(templates))

    let cumulativeDays = updateTemplates.length - 2,
        rules = { ...templateTimeLimits }

    if (extraRules) {
        rules = {
            ...rules,
            ...extraRules,
        }
    }

    updateTemplates.forEach((template, index) => {
        try {
            var time = Math.trunc(template.time)
            template.time = time
            if (template.time_unit === "days") {
                const maxDays = rules[template.time_unit].max - cumulativeDays
                cumulativeDays += template.time
                if (template.time > maxDays) template.time = maxDays
            }
            if (template.time_unit === "hours" && template.time >= 24) {
                if (index !== 0 && template.time < 24) template.time = 24
                const maxHours = (rules.days.max - cumulativeDays) * 24
                cumulativeDays += template.time / 24
                if (template.time > maxHours) template.time = maxHours
            }

            if (rules[template.time_unit]) {
                if (template.time % rules[template.time_unit].step !== 0) {
                    template.time =
                        Math.round(template.time / rules[template.time_unit].step) * rules[template.time_unit].step
                }
                if (template.time < rules[template.time_unit].min) template.time = rules[template.time_unit].min
            }
        } catch (error) {
            console.error(error)
        }
    })

    return updateTemplates
}

export default adjustChaseTemplatesToTimeLimits
