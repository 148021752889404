const messages = {
    chases: "Chases",
    chasesSubtitle: "Chase your clients as a Cheetah does and don't let them go.",
    chasesTrigger: "Trigger",
    chasesTriggerDesc: "The event that starts the chase",
    chasesAppEvent: "Event from another App",
    chasesAppEventDesc: "Stars the chase when something happens in another App",
    chasesSchedule: "Schedule",
    chasesScheduleDesc: "Starts the chase every so often",
    chasesTag: "Tag",
    chasesTagDesc: "Starts the chase after certain tag has been assigned to a conversation",
    chasesSelectTriggerEvent: "Select the triggering event",
    chasesConnectYourAccount: "Connect your account",
    chasesTestTrigger: "Test trigger",
    chasesChangeAccount: "Change account",
    chasesTemplateSequence: "Message sequence",
    chasesTemplateSequenceDesc: "Messages will be sent automatically to the client until a response is obtained",
    chasesSelectTemplate: "Select a start message to send",
    chasesWait: "Wait ",
    chasesBeforeSendingTemplate: " before sending.",
    chasesSend: "Send",
    chasesTriggerEventOcurred: " after trigger event occurred.",
    chasesPreviousTemplateWasSent: " after previous message was sent and no answer was received",
    chasesSendCheetabotAfter: "Activate Cheetabot",
    chasesSendCheetabotAfterDesc: "If a response is obtained, Cheetabot will be activated for this conversation",
    createChase: "Create a new chase",
    chasesCancel: "Cancel",
    chasesSelectTag: "Select tag to act as trigger",
    chasesSelectApp: "Select App to connect to",
    chasesConnectAccount: "Connect your account",
    chasesApiKey: "Enter the necessary API Key",
    chasesSelectAppEvent: "Select the App event",
    chasesSelectATimeFrequency: "Select a time frequency to trigger this chase",
    chasesOnceAMonth: "Once a month",
    chasesSelectDayOfTheMonth: "Select day of the month",
    onceAWeek: "Once a week",
    selectDayOfTheWeek: "Select day of the week",
    everyDay: "Every day",
    selectTimeOfTheDay: "Select a time of the day",
    chasesMessageTemplate: "Start message",
    chasesAddMessageTemplateToSequence: "Add another message to the sequence",
    chasesActivateBotToRespond: "Activate Cheetabot when a message from the sequence gets a response.",
    publishChase: "Publish this chase",
    unpublishChase: "Cancel this chase",
    chaseName: "Name this chase",
    chasesSavingError: "An error ocurred when saving this chase, please try again later",
    thereCanBeADifferenceInActualTimes:
        "The time between messages cannot be less than 1 day. There may be a 30-60 minute delay when messages are sent.",
    chasesPreviousTagConversationsMsg:
        "The chase will NOT apply to conversations in which the trigger was executed prior to posting the chase.",
    chasesPreviousTagConversationsMsg2:
        "If you want conversations with the trigger tag to be chased, you'll have to assign them that tag again.",
    chasesNoteChaseEndedAndConversationsArchived: "If the client does not respond, the conversation will be archived.",
    activateChaseInTaggedConversations: "Activate this chase on {taggedConversations} previously tagged conversations.",
    chasesDeleteDialogTitle: "Are you sure you want to eliminate the persecution {{chase_name}}?",
    chasesChangeToPublishedDialogTitle: "Post chase",
    chasesChangeToDraftDialogTitle: "Cancel chase and go to draft",
    chasesDeleteDialogDescription:
        "Removing this chase will stop and lose all scheduled messages and actions. Which will affect {{number_of_client_with_active_chase}} clients who have this chase active.",
    chasesChangeToDraftDialogDescription:
        "Accepting this chase will cancel it, removing all ongoing chases. While in this state you will be able to edit the chase. Do you want to continue?",
    chasesChangeToPublishedDialogDescription:
        "By accepting this chase it will be published and the messages will be sent according to the rules you configured. Do you want to continue?",
    chasesDialogConfirmButton: "Confirm",
    chasesUnavailableVariableWarning:
        "If the start message has a variable it will only be sent to clients that have said variable (I.e. if you use {{customer_company_name}} it will only be sent to clients with a defined Company).",
    youCanSendUpToChaseMessages: "You can send up to {number} chase messages a day.",
    chasesGraphExplanation:
        "On the graph below you can see how many messages your chases will send the following 2 weeks.",
    chasesCreationGraphExplanation: "On the graph you can see how many messages your new chase would send and when.",
    chaseHardLimitCreationDesc:
        "You cannot create this chase because the 600 daily chase message limit would be surpassed!",
    thisChase: "This chase",
    overDailyLimitOn: "Over daily limit on:",
    yourChasesCanOnlyTemplates: "A chase cannot have more than {templates} messages.",
    chaseDeletingError: "An error ocurred deleting this chase, try again later.",
    chaseStatusDraft: "Draft",
    chaseStatusPublished: "Published",
    chaseMsgNoteHeaderChangeToDraftForEdit: "You must change this chase's status to draft before you can edit it",
    chaseTooltipSwitch: "It is not possible to change the state of this chase because the information is incomplete",
    chaseNameIsRequired: "You need to name your chase",
    chaseThatTriggerEventRequiresATemplateVariable:
        "That trigger event requires that the message has the variable: {variable}.",
    chaseThatTriggerEventHasMaximunTemplates: "That trigger event can't have more than {x} messages.",
    chasePleaseRemoveXTemplates: "Please remove {x} messages.",
    chaseTimelimit: "Your chase cannot last more than {limit} days.",
    sendMessagesThrough: "Send this chase messages through:",
    SMSSegments: " SMS segments",
    clientSegments: "Client segments",
    chooseASegment: "Choose a client segment",
    onceAWeekSendAMessageToClientsMeetingCriteria: "Once a week, send a message to clients that meet some criteria.",
    equalTo: "equal to",
    equalOrGreaterThan: "equal or greater than",
    equalOrLessThan: "equal or less than",
    greaterThan: "greater than",
    lessThan: "less than",
    segmentCriteria: "Segmentation criteria",
    operator: "Operator",
    segmentCustomersWhoHavePurchasedTheProducts:
        "Segment customers who have purchased the products: {products} in the last {days} days",
    segmentCustomersWhoHavePurchasedTheProductsBeetwen:
        "Segment customers who have purchased the products: {products} within the last {daysAgo} days, and prior to {days} days.",
    value: "Value",
    until: "until",
    lastOrderDate: "Last purchase date",
    averagePurchase: "Average purchase",
    customerAddedDate: "Customer registration date",
    numberOfOrders: "Number of orders",
    productsPurchased: "products purchased",
    createSegment: "Create new segment",
    editSegment: "Edit segment",
    deleteSegment: "Delete segment",
    productName: "Purchased: [product name]",
    saveSegment: "Save segment",
    addCriteria: "Add another segmentation criteria",
    segmentName: "Segment name",
    otherCriteria: "other criteria",
    selectADayOfTheWeekToSendMessages: "Select a day of the week to start the chase",
    segmentChaseDescription1: "Each ",
    segmentChaseDescription2: "Trigger this chase for clients belonging to the segment: ",
    youNeedToCreateASegmentFirst: "You need to create a segment before setting up this chase.",
    segmentChaseCaption1:
        "To avoid spam, this chase will not be sent to clients that have already received it in the last 30 days.",
    segmentChaseCaption2: "Due to WhatsApp's policies, this chase will only begin for up to 500 clients each day.",
    tagChaseDescription: "This chase will begin for clients that get assigned the tag: ",
    appChaseDescription1: "This chase will begin when in the app ",
    appChaseDescription2: " the following event occurs ",
    daysAgo: "days ago",
    thisMessageWillBeSentEach: "This message will be sent each ",
    atTime: " at ",
    onTheChosenDayTheSegmentWillBeUpdated: "On the chosen day, the segment will be updated before messages are sent.",
    clientsWontReceiveThisMessageMoreThanOnce: "Clients won't receive this messages more than once.",
    anErrorOcurredSavingYourSegment: "An error ocurred trying to save your segment.",
    anErrorOcurredGettingSegments: "An error ocurred trying to get your segments.",
    loadingSegments: "Loading segments",
    dueToWhatsAppLimitsWellOnlySendXMessages:
        "Due to WhatsApp's API limits, we'll only be able up to send 500 messages at once.",
    clientsSegment: "Clients segment",
    inTheLast: "in the last",
    beforeThan: "before than",
    period: "Period",
    thisChaseWillRunOnXDays: "This chase will run on {x} days.",
    thisChaseWillRunTomorrow: "This chase will run tomorrow.",
    giveThisChasePriority: "Give this chase priority number ",
    overOtherChases: " compared to other chases.",
    priorityExplanation:
        "This will help decide which messages to send when reaching WhatsApp's daily message rate limit.",
    priority: "Priority",

    runThisChaseOnce: "Run this chase once",
    thisMessageWillBeSentAt: "This message will be sent on ",
    allClients: "All clients (no segmentation) ",

    chasesList: "Chases list",
    chaseLogs: "Chase logs",
    withYourCurrentLimitItWillBeSentOnXDays:
        "With your current daily message limit of {x}, it will be sent on {y} days.",
    youNeedToKeepSendingMessageToUpYourLimit: "You need to keep sending messages to increase your limit.",
    clickHereIfYouPreferNotToSendOverSeveralDays:
        "Click here if you prefer not to send over several days. This will only send the first {x} messages.",
    sendThisChaseTo: "Send this chase to ",
    yourCurrentDailyLimitIs: "Your current daily limit is {x} messages.",
    thisWillTakeXDays: "This will take {x} days to send.",

    youCanOnlyHaveOneChaseWithThisTrigger: "You can only have one active chase with this trigger.",
    confirmedPurchaseOrder: "Purchase order paid and confirmed",
    unpaidPurchaseOrder: "Purchase order unpaid",

    sendStartMessage: "Send start message",
    startCheetabot: "Start Cheetabot",
    chasesSelectCheetabot: "Select Cheetabot to start",
    chasesStart: "Start",
    afterPreviousBotCloses: "after previous Cheetabot closes.",
    addAnotherMessageOrCheetabot: "Add another message or Cheetabot",
    botNeedsToStartWithAStartMessageForWaitsOver24Hours:
        "Cheetabot needs to start with a Start Message for waits over 24 hours.",
    doesntStartWithAStartMessage: "Doesn't start with a Start Message.",
    botIsNotReady: "This Cheetabot is not ready. Please wait a few minutes and refresh the list.",
    refreshBotList: "Refresh Cheetabot list",

    youCannotHaveThatTriggerWithACheetabot: "You cannot have that trigger activate a Cheetabot.",
    inUseByAnotherChase: "In use by another chase",
    botDisabled: "Cheetabot inactive",
    setUpATimeFrameForMessages: "Set up a time frame for messages",
    youCanOnlyHaveUpToXChases: "You can only have up to {x} active chases.",
    youWillNeedToDeleteSomeChasesToCreateANewOne: "You will need to delete some chases to create a new one.",
    abandonedCheckoutsOutsideThisTimeFrameWillNotTriggerThisChase:
        "Abandoned checkouts outside this time frame will not trigger this chase.",
    orderUpdatedReadyForPickup: "Order ready for pickup",
    orderUpdatedCanceled: "Order cancelled",
    orderUpdatedFulfilled: "Order fulfillment",
}

export default messages
