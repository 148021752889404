export const message_templates = [
    {
        name: "welcomeMessage",
        id: 1,
    },
    {
        name: "abandonedCheckout",
        id: 2,
        button: {
            type: "url",
            text: "seeYourAbandonedCheckout",
            url: "https://link.cheetrack.com/{{1}}",
            fixedUrl: true,
            variableAlternative: "{{abandoned_checkout_link}}",
        },
        templateType: "abandoned_checkout",
        noButtonAlternative: "abandonedCheckoutNoBtns",
    },
    {
        name: "newProductLaunch",
        id: 3,
        button: {
            type: "url",
            text: "seeOurCatalog",
        },
    },
    {
        name: "reengageCustomer",
        id: 5,
        button: {
            type: "url",
            text: "seeOurCatalog",
        },
    },
    {
        name: "restaurantMenu",
        id: 7,
        templateType: "restaurant_menu",
        buttonRequired: true,
        button: {
            type: "url",
            text: "seeTheMenu",
            url: "https://links.cheetrack.com/{{1}}",
            fixedUrl: true,
        },
    },
]
