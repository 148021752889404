// lee un archivo subido por el usuario para generar previews
function readFileObj(fileObj) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            resolve(e.target.result)
        }
        reader.readAsDataURL(fileObj)
    })
}

export default readFileObj
