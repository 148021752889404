import { getDefaultCheetabotSequence } from "../utils"

const initialState = {
    selectedBot: {
        id: null,
        name: "",
        is_active: false,
        sequence: getDefaultCheetabotSequence(),
        utterances: [],
        type: "welcome",
    },
    loaders: {},
    activeBotConversations: 0,
    errors: {},
    botUpdate: { questionContent: {} },
    bots: [],
    tenant_has_active_bot: false,
}

export default initialState
