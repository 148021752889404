const messages = {
    connectToInstagram: "Conectar com Facebook e Instagram",
    yourInstagramIsConnected: "Suas contas do Facebook e Instagram estão conectadas!",
    yourWhatsAppIsConnected: "Sua conta de WhatsApp está conectada.",
    yourWhatsAppIsNotReady:
        "Sua conta de WhatsApp está em processo. Entraremos em contato quando estiver pronto para uso.",
    yourInstagramHasIssues:
        "Estamos com problemas em sua conta do Facebook ou Instagram. Pode haver falhas no envio ou recebimento de mensagens.",
    yourWhatsAppHasIssues:
        "Estamos com problemas em sua conta do WhatsApp. Pode haver falhas no envio ou recebimento de mensagens.",
    instagramInstructionsTitle: "Conectar Cheetrack com Facebook e Instagram",
    instagramInstructions1: "Para conectar sua conta do Instagram você precisa:",
    instagramInstructions2: "1) Ter uma conta Empresarial/Comercial no Instagram.",
    instagramInstructions3: "2) Ter uma conta comercial do Facebook vinculada a essa conta do Instagram.",
    instagramInstructions4: "3) Pressionar o botão abaixo e conceder à Cheetrack as permissões solicitadas.",
    youAlreadyHaveAnInstagramAccount: "Você já tem uma conta do Facebook conectada a esta conta do Cheetrack.",
    youAlreadyHaveAnInstagramAccount2:
        "Se você pressionar o botão abaixo, poderá substituir a conta do Facebook conectada por outra.",
    connectNewInstagram: "Conecte outra conta do Facebook",
    integrations: "Integrações",

    connectWithShopify: "Conectar com Shopify",
    yourShopifyAccountIsConnected: "Sua conta do Shopify está conectada!",
    shopifyInstrucitionsTitle: "Conectar Cheetrack com Shopify",
    shopifyInstruction1: "Para conectar sua conta de Shopify você precisa:",
    shopifyInstruction2: "1) Vá para 'Aplicativos' em sua conta do Shopify",
    shopifyInstruction3: "2) Clique em 'Desenvolver aplicativos'",
    shopifyInstruction4: "3) Clique em 'Permitir desenvolvimento de aplicativos personalizados'",
    shopifyInstruction5: "4) Clique em 'Criar um aplicativo'",
    shopifyInstruction6:
        "5) Escreva um nome para a sua Aplicação (pode ser Cheetrack), selecione um usuário como desenvolvedor (pode ser você) e clique em 'Criar Aplicação'",
    shopifyInstruction7: "6) Clique em 'Configurar escopos da API do painel de controle'",
    shopifyInstruction8:
        "7) Entre as opções disponíveis, vá para Pedidos/Orders e habilite os seguintes: 'read_orders', 'read_customers', 'write_discounts' e 'read_discounts'. Em seguida, clique em 'Salvar'",
    shopifyInstruction9:
        "8) Quando terminar de salvar, clique em 'Instalar Aplicativo'. Se uma janela de confirmação se abrir, clique em 'Instalar'",
    shopifyInstruction10:
        "9) Após a conclusão da instalação, seu 'Token de acesso da API do painel de controle' será exibido, clique em 'Revelar token uma vez' para visualizá-lo",
    shopifyInstruction11:
        "10) Copie seu Token de acesso da API do painel de controle e cole-o na caixa de texto abaixo",
    shopifyInstruction12:
        "11) Em seguida, escreva o nome da sua loja Shopify na outra caixa de texto. É o endereço URL do painel da sua conta Shopify e deve ser algo como 'cheetrack.myshopify.com'",
    shopifyInstruction13: "12) Por fim, clique em 'Conectar com Shopify'",
    adminApiAccessToken: "Token de acesso da API do painel de controle",
    youAlreadyHaveAnShopifyAccount: "Já há uma conta do Shopify conectada a esta conta da Cheetrack.",
    youAlreadyHaveAnShopifyAccount2:
        "Você pode alterar o Access Token e a URL da loja abaixo e, em seguida, pressionar o botão para substituir sua conexão com o Shopify por uma nova.",
    connectNewShopify: "Substituir conexão com Shopify",
    hereYouCanConnectCheetrackToOtherApps: "Aqui você pode conectar o Cheetrack a outros aplicativos.",
    clickingOnAnAppIconWillTellYouHowToConnectOrShowSettings:
        "Clicar em um ícone de aplicativo mostrará como se conectar ou exibirá informações sobre esse aplicativo.",
    shopifyDescription1: "Ao conectar o Shopify você poderá:",
    shopifyDescription2: "Importar e conectar clientes e histórico de pedidos.",
    shopifyDescription3: "Importar e conectar catálogo de produtos.",
    shopifyDescription4: "Recuperar carrinhos perdidos",
    instagramDescription1: "Conectar o Facebook e o Instagram permitirá que você:",
    instagramDescription2: "Envie e receba mensagens do Facebook e Instagram.",
    lostCart: "Carrinho abandonado",
    customerPhoneNumberRegistration: "Registro do número de telefone do cliente",
    integrationsDescription:
        "Integrar outras Apps permitirá que você receba mensagens, ative perseguições ou vincule uma variedade de funcionalidades à sua conta da Cheetrack.",
    integrationsDescription2:
        "Cada App possui sua própria forma de se integrar ao Cheetrack, que você terá que revisar individualmente para cada uma.",

    connectNewAgendaPro: "Conectar nova conta do Agenda Pro",
    connectWithAgendaPro: "Conectar com o Agenda Pro",
    agendaProInstrucitionsTitle: "Conectar Cheetrack a Agenda Pro",
    yourAgendaProAccountIsConnected: "Sua conta do Agenda Pro está conectada",
    agendaProDescription1: "Conectando sua conta do Agenda Pro, você poderá:",
    agendaProDescription2: "Enviar mensagens com lembretes para seus clientes.",
    agendaProInstruction1: "Para conectar sua conta Agenda Pro você deve:",
    agendaProInstruction2:
        "1) Ir para Integrações/API Pública em sua conta do Agenda Pro, ou clique no link a seguir: ",
    agendaProInstruction3: "2) Ir para API Pública e clique em Criar Webhook",
    agendaProInstruction4: "3) Cole o seguinte texto na caixa de texto: api.cheetrack.com/api/v1/agenda-pro/webhook",
    agendaProInstruction5: "4) Clicar em Ativo e depois em Salvar",
    agendaProInstruction6:
        "5) Copie as informações de Usuário e Senha da sua API pública no Agenda Pro e cole nos campos de texto abaixo",
    agendaProInstruction7: "6) Clicar no botão abaixo que diz 'Conectar com o Agenda Pro'",
    agendaProError: "Ocorreu um erro ao integrar com o Agenda Pro, por favor, tente novamente mais tarde.",
    youAlreadyHaveAnAgendaProAccount: "Você já tem uma conta do Agenda Pro conectada a esta conta da Cheetrack.",
    youAlreadyHaveAnAgendaProAccount2:
        "Pode alterar a conta à qual está conectado inserindo novos dados em API User e API Password e em seguida clicando no botão abaixo.",
    agendaProInstructions1: "1. Visite a página de ",
    agendaProInstructions1b: " nas configurações da sua conta AgendaPro.",
    agendaProInstructions2:
        "2. Na seção <b>API pública</b>, copie os valores de Usuário e Senha, e cole nos campos abaixo deste formulário.",
    agendaProInstructions3: "3. Na AgendaPro, pressione ** + Criar Webhook** e copie a seguinte URL: ",
    agendaProInstructions3b: " Certifique-se de que seja https e que esteja Ativo",
    agendaProInstructions4: "4. Em Cheetrack, pressione Conectar com AgendaPro",
    agendaProInstructions5: "Integrações/API pública",
    instagramError:
        "Ocorreu um erro ao tentar integrar com o Facebook ou Instagram, por favor, tente novamente mais tarde.",
    shopifyError: "Ocorreu um erro ao tentar integrar com o Shopify, por favor, tente novamente mais tarde.",
    connectingToShopify: "Conectando-se ao Shopify",
    toConnectWithShopifyYouMustLogIn: "Para se conectar ao Shopify, você deve primeiro entrar no Cheetrack!",
    finishingYouShopifyConnection: "Dando os passos finais para a sua conexão com o Shopify 😉",
    whatsAppInstructionsTitle: "Conectar à API do WhatsApp",

    whatsAppDescription: "Benefícios de conectar à API do WhatsApp",
    whatsAppDescription1: "Enviar e receber mensagens no WhatsApp.",
    whatsAppDescription2: "Enviar e receber arquivos, imagens e gravações de áudio.",
    whatsAppDescription3: "Receber localizações e mostrá-las em um mapa.",
    whatsAppDescription4: "Programar o Cheetabot para responder aos seus clientes.",
    whatsAppDescription5: "Configurar perseguições para acompanhar e enviar lembretes.",
    youAlreadyHaveAWhatsAppAccount: "Você já possui uma conta de WhatsApp API conectada a esta conta da Cheetrack.",
    youAlreadyHaveAWhatsAppAccount2:
        "Ao pressionar o botão abaixo, você pode substituir a conta da API do WhatsApp por outra.",
    connectNewWhatsApp: "Conectar uma nova conta de WhatsApp API",
    whatsAppError: "Ocorreu um erro ao tentar conectar com a API do WhatsApp, por favor, tente novamente mais tarde.",
    connectToWhatsApp: "Conectar com WhatsApp API",
    whatsAppInstructions1: "Instruções para conectar-se à API do WhatsApp:",
    whatsAppInstructions2:
        "Vá para {link} ➜ Se você não possui um perfil de desenvolvedor: 'Iniciar' ➜ Crie seu perfil",
    whatsAppInstructions3:
        "'Meus Aplicativos' ➜ 'Criar APP' ➜ 'Negócios' ➜ Você adiciona um nome ao APP ➜ Você seleciona uma conta comercial para o seu negócio ➜ 'Criar'",
    whatsAppInstructions4: "Você entra no painel do APP ➜ 'Adicionar produtos' ➜ 'Login com Facebook'",
    whatsAppInstructions5: "Em seguida, no menu esquerdo ‘Login com Facebook’ ➜ ‘Configurações’",
    whatsAppInstructions51: "Faça login com o JavaScript SDK: Sim",
    whatsAppInstructions52: "URIs de redirecionamento OAuth válidos: https://app.cheetrack.com/integrations",
    whatsAppInstructions53:
        "Domínios permitidos para o JavaScript SDK: https://app.cheetrack.com ➜ 'Salvar alterações'",
    whatsAppInstructions6: "No painel esquerdo ➜ 'Webhooks' ➜ 'Configurar'",
    whatsAppInstructions61: "No menu suspenso 'Usuário', selecionamos 'Conta comercial do WhatsApp' ➜ 'Inscrever-se'",
    whatsAppInstructions62: "URL de retorno de chamada: https://api.cheetrack.com/v1/whatsapp/webhook/receive",
    whatsAppInstructions63: "Token de verificação: meatyhamhock",
    whatsAppInstructions64: "Luego, haz clic en 'Suscribir' para todas las opciones.",
    whatsAppInstructions7: "Vamos para o painel superior esquerdo ➜ 'WhatsApp' ➜ 'Configurar'",
    whatsAppInstructions71:
        "En este paso, debes tener tu cuenta de WhatsApp eliminada y el chip del número en un teléfono para recibir un SMS.",
    whatsAppInstructions72: "'Primeiros passos'",
    whatsAppInstructions73: "Vamos para o passo 5: adicionar número de telefone.",
    whatsAppInstructions74: "Complete com as informações solicitadas.",
    whatsAppInstructions741:
        "Se o seu setor for 'beleza, spa', recomendamos não selecionar esta categoria para evitar qualquer tipo de rejeição por parte do WhatsApp.",
    whatsAppInstructions75: "Agregamos o número de telefone e inserimos o código.",
    whatsAppInstructions8:
        "Já foi enviado para verificar seu número de telefone 😉. Você receberá um e-mail 📩 dizendo 'sua conta do WhatsApp já foi aprovada'. Prossiga para a próxima etapa ➡️ uma vez que tenha recebido esse e-mail.",
    whatsAppInstructions9: "Em caso de demora na recepção do e-mail, confira os seguintes vídeos:",
    whatsAppInstructions10: "7) Una vez el número está conectado, debes integrar tu cuenta de WhatsApp a Cheetrack.",
    whatsAppInstructions11: "Pronto! Já está conectado, agora você pode enviar e receber mensagens sem problemas.",
    whatsAppInstructions101: "Ir a [enlace] y seleccionar tu negocio",
    whatsAppInstructions102: "Clique em ‘Configurações’ e depois vá para ‘Configurações comerciais’",
    whatsAppInstructions103: "No menu à esquerda, sob 'Usuários' ➜ 'Usuários do Sistema'",
    whatsAppInstructions104: "Clicar em 'Adicionar' ➜ Adicionar seu nome ➜ Selecionar 'Função de administrador'",
    whatsAppInstructions105:
        "Em seguida, à direita, clique em 'Adicionar Ativos' ➜ À esquerda, em 'Aplicações' ➜ Selecionamos Cheetrack ➜ Em seguida, concedemos todas as permissões para o aplicativo ➜ Salvar alterações",
    whatsAppInstructions106: "Clique em 'Gerar um novo identificador'",
    whatsAppInstructions107:
        "Selecionamos o aplicativo ➜ Devemos permitir dois permissões ➜ 'whatsapp_business_messaging' e 'whatsapp_business_management,'",
    whatsAppInstructions108:
        "Copiamos o 'Identificador de acesso' ➜ Ir para o Cheetrack ➜ Colar o ID no campo 'Token de Acesso'",
    whatsAppInstructions109: "Voltamos ao gerenciador comercial ➜ À esquerda, 'Contas' ➜ 'Contas do WhatsApp'",
    whatsAppInstructions110:
        "Selecionamos a conta ➜ Copiamos o número identificador ➜ Ir para o Cheetrack ➜ Colar o valor em 'Identificador do Whatsapp Business APP",
    whatsAppInstructions111: "Agora, clique em 'Conectar uma nova conta do Whatsapp API'",
    whatsAppStep1: "1. Criar Facebook APP",
    whatsAppStep2: "2. Configurar Facebook LogIn",
    whatsAppStep3: "3. Configurar Webhooks",
    whatsAppStep4: "4. Configurar WhatsApp",
    facebookAppId: "Identificador do App",
    facebookAppSecret: "Chave secreta do aplicativo",
    shopifyInstructionsForConnectWithWhatsapp:
        "Só falta mais uma etapa para que sua conta esteja pronta. Você precisa fazer a integração com o WhatsApp Meta. Para isso, clique no botão abaixo que o levará ao painel de integração com o WhatsApp e outros aplicativos.",
    makeSureYouHaveVerifiedYourFacebookAccount:
        "Certifique-se de ter verificado sua conta de administrador no Facebook.",
    accessToken: "Token de acesso",
    wabaId: "Identificador do aplicativo WhatsApp Business",
    seeInstructions: "Ver instruções",
}

export default messages
