const messages = {
    first: "Primeiro",
    sheFirst: "Primeira",
    second: "Segundo",
    sheSecond: "Segunda",
    third: "Terceiro",
    sheThird: "Terceira",
    fourth: "Quarto",
    sheFourth: "Quarta",
    fifth: "Quinto",
    sheFifth: "Quinta",
    sixth: "Sexto",
    sheSixth: "Sexta",
    seventh: "Sétimo",
    sheSeventh: "Sétima",
    eighth: "Oitavo",
    sheEighth: "Oitava",
    ninth: "Nono",
    sheNinth: "Nona",
    tenth: "Décimo",
    sheTenth: "Décima",
    eleventh: "Décimo primeiro",
    sheEleventh: "Décima primeira",
    twelfth: "Décimo segundo",
    sheTwelfth: "Décima segunda",
    thirteenth: "Décimo terceiro",
    sheThirteenth: "Décima terceira",
    fourteenth: "Décimo quarto",
    sheFourteenth: "Décima quarta",
    fifteenth: "Décimo quinto",
    sheFifteenth: "Décima quinta",
    sixteenth: "Décimo sexto",
    sheSixteenth: "Décima sexta",
    seventeenth: "Décimo sétimo",
    sheSeventeenth: "Décima sétima",
    eighteenth: "Décimo oitavo",
    sheEighteenth: "Décima oitava",
    nineteenth: "Décimo nono",
    sheNineteenth: "Décima nona",
    twentieth: "Vigésimo",
    sheTwentieth: "Vigésima",
}

export default messages
