const messages = {
    country: "Country",
    priceToSend: "Price to send",
    priceToReceive: "Price to receive",
    seeSMSListPrices: "See SMS prices",
    countryCL: "Chile",
    countryES: "Spain",
    countryCO: "Colombia",
    countryPE: "Peru",
    countryAR: "Argentina",
    countryEC: "Ecuador",
    countryVE: "Venezuela",
    countryBO: "Bolivia",
    countryMX: "Mexico",
    countryPA: "Panama",
    countryUS: "United States",
    countryCA: "Canada",
    countryBR: "Brazil",
    countryPY: "Paraguay",
    countryUR: "Uruguay",
}

export default messages
