const messages = {
    country: "Países",
    priceToSend: "Preço para enviar",
    priceToReceive: "Preço para receber",
    seeSMSListPrices: "Ver preços de SMS",
    countryCL: "Chile",
    countryES: "Espanha",
    countryCO: "Colômbia",
    countryPE: "Peru",
    countryAR: "Argentina",
    countryEC: "Equador",
    countryVE: "Venezuela",
    countryBO: "Bolívia",
    countryMX: "México",
    countryPA: "Panamá",
    countryUS: "Estados Unidos",
    countryCA: "Canadá",
    countryBR: "Brasil",
    countryPY: "Paraguai",
    countryUR: "Uruguai",
}

export default messages
