const initialState = {
    clients: [],
    loadingClients: false,
    currClientsParams: { status: "open" },
    clientsError: {},
    clientsPage: 1,
    clientListDone: false,
    checkSelectedAll: false,
    savingClient: false,
    unreadClients: 0,
    multiselectClients: {},
    instagramClients: {},
    facebookClients: {},
    smsClients: {},
    whatsappClients: {},
    selectedTags: undefined,
    selectedSources: undefined,
    selectedReadStatus: undefined,
    selectedAgent: "all",
    filterPending: false,
    selectedStatus: "open",
    searchQuery: "",
    clientsReadStatusFixed: {},
}

export default initialState
