import store from "../store"

// Verifica que esté abierta la ventana de mensajeria de whatsapp
export function timeSinceLastMessage(messages) {
    const userTimeDiff = store.getState().websocket.userTimeDiff * 1000
    let lastMessage
    for (let i = messages.length - 1; i > -1; i--) {
        const message = messages[i]
        if (message.metadata.is_client_message) {
            lastMessage = message
            break
        }
    }

    if (lastMessage) {
        let msgDate = lastMessage.sort_date
        const messageDate = new Date(new Date(msgDate).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
        let totalSeconds = 24 * 60 * 60 - (Date.now() + userTimeDiff - messageDate) / 1000
        let hours = Math.floor(totalSeconds / 3600)
        let hoursInt = hours
        if (hours > 24) hours = 23
        if (hours < 10) hours = "0" + hours

        totalSeconds = totalSeconds %= 3600

        let minutes = Math.floor(totalSeconds / 60)
        let minutesInt = minutes
        if (minutes < 10) minutes = "0" + minutes

        let seconds = Math.floor(totalSeconds % 60)
        let secondsInt = seconds
        if (seconds < 10) seconds = "0" + seconds
        let res = hoursInt <= 0 && secondsInt <= 0 && minutesInt <= 0 ? false : hours + ":" + minutes + ":" + seconds
        return res
    }

    return 0
}

const timeWindow = 24 * 60 * 60 * 1000

export function shouldAllowAgentSendMessage(messages) {
    const userTimeDiff = store.getState().websocket.userTimeDiff * 1000
    let lastMessage

    for (let i = messages.length - 1; i > -1; i--) {
        const message = messages[i]
        if (message.metadata.is_client_message) {
            lastMessage = message
            break
        }
    }

    if (lastMessage) {
        let msgDate = lastMessage.sort_date
        const messageDate = new Date(new Date(msgDate).getTime() - new Date().getTimezoneOffset() * 60 * 1000),
            timeSinceMessage = Date.now() + new Date().getTimezoneOffset() + userTimeDiff - messageDate

        if (timeSinceMessage < 0) return false

        return timeSinceMessage < timeWindow
    }

    return false
}
