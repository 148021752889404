const messages = {
    telephone: "Teléfono",
    roleInCheetrack: "Rol en Cheetrack",
    activeConversation: "Conversaciones activas",
    workTeam: "Equipo de trabajo",
    invite: "Invitar",
    userDescription:
        "El plan <account_plan_name> incluye <included_seats_plan> asientos, ampliable hasta <max_seats_plan> asientos.",
    active: "Activo",
    inactive: "Inactivo",
    manager: "Manager",
    executive: "Ejecutivo",
    activeDialogTitle: "Activar usuario",
    activeDialogDesc1: `Tu plan incluye <included_seats_plan> asientos en el pago mensual.`,
    activeDialogDesc2:
        "Al activar a este usuario cargaremos <amount_current_month> por lo que queda de este mes y un adicional de <amount_others_month> para los meses siguientes en que tengas más de <included_seats_plan> usuarios activos.",
    activeDialogDesc3:
        "Te recordamos que tu plan es <account_plan_name> y permite <total_seats_plan> asientos en total. Para mejorar tu plan y ver detalles de facturación visita el módulo",
    activeDialogButton: "Entiendo, activar usuario",
    inviteUserDialogTitle: "Invitar a usuarios",
    editUserDialogTitle: "Editar usuario",
    inviteUserDialogDesc: "Escribe el correo y selecciona el rol de los usuarios que invitarás a Cheetrack.",
    inviteuserDialogButton: "Enviar invitación",
    selectRole: "Selecciona un rol",
    planLimitDialogTitle: "Tu plan alcanzó el máximo de asientos",
    planLimitDialogDesc1: "Tu plan permite un máximo de <total_seats_plan> asientos.",
    planLimitDialogDesc2: "Para activar este usuario debes mejorar tu plan.",
    planLimitDialogButton: "Mejorar plan",
    deleteUserConfirmMsg1: "Esta acción eliminará al usuario ",
    deleteUserConfirmMsg2: " y todos los registros asociados a esta cuenta.",
    deleteUserConfirmMsg3: "¿Estás seguro que quieres eliminar a este usuario?",
    buttonUserDelete: "Sí, eliminar usuario",
    buttonUpdateUser: "Actualizar usuario",
    inviteMyTeam: "Invitar a mi equipo",
    blankTipDescription1: '"El talento gana partidos pero el trabajo en equipo gana campeonatos"',
    blankTipDescription2: "Invita a tu equipo de trabajo a Cheetrack para potenciar tus ventas.",
    inviteUser: "Invitar usuario",
    improvePlan: "Mejorar plan",
    usedSeats: "Asientos ocupados",
    maxUsersReached: "Has alcanzado tu límite de usuarios, si necesitas más actualiza tu plan!",
    inviteOtherUser: "Invitar otro usuario",
    userGroup: "Grupo",
    userGroupHelp:
        "Puedes asignar este usuario a un grupo que lo asocia a otros usuarios. Por ejemplo: ventas o marketing.",
    recommendedGroups: "Grupos recomendados",
    createGroup: "Crear grupo",
    allowExecutivesToSeeAllConversations: "Los ejecutivos pueden ver todas las conversaciones.",
    executivesWillOnlySeeTheirConversations: "Los ejecutivos sólo pueden ver sus conversaciones.",
    anErrorOcurredObtainingUserData: "Ha ocurrido un error obteniendo datos de los usuarios",
    thatEmailIsAlreadyInUse: "Ese email ya está en uso",
    deactivateUser: "Desactivar usuario",
    deactivateUserDesc: "Si desactivas este usuario no podrá ingresar a la plataforma.",
    errorSavingUser: "Error guardando usuario",
    eachAdditionalUserCosts19USD: "Cada usuario adicional cuesta 19 USD al mes.",

    yourPlanIncludesXSeats: "Tu plan incluye {x} asientos.",
    ifYouNeedMoreUsersClickHere: "Si necesitas más usuarios, haz click aquí.",
    notifications: "Notificaciones",
    feedbackNotifications: "Notificaciones de feedback",
    userXAlreadyHasAnAccount: "El usuario {x} ya tiene una cuenta, por favor intenta con otro email.",
}

export default messages
