const messages = {
    chases: "Persecuciones",
    chasesSubtitle: "Persigue a tus clientes como un Chita y nos los dejes ir.",
    chasesTrigger: "Gatillo",
    chasesTriggerDesc: "El evento que da inicio a la persecución",
    chasesAppEvent: "Evento en otra App",
    chasesAppEventDesc: "Comienza la persecución cuando pase algo en una App",
    chasesSchedule: "Agendar",
    chasesScheduleDesc: "Comienza la persecución cada cierto tiempo",
    chasesTag: "Etiqueta",
    chasesTagDesc: "Comienza la persecución después de agregar una etiqueta a una conversación",
    chasesSelectTriggerEvent: "Selecciona el evento gatillo",
    chasesConnectYourAccount: "Conecta tu cuenta",
    chasesTestTrigger: "Probar gatillo",
    chasesChangeAccount: "Cambiar cuenta",
    chasesTemplateSequence: "Secuencia de mensajes",
    chasesTemplateSequenceDesc: "Se enviará una seguidilla de mensajes hasta que el cliente responda",
    chasesSelectTemplate: "Selecciona un mensaje de inicio a enviar",
    chasesWait: "Esperar ",
    chasesBeforeSendingTemplate: " antes de enviar.",
    chasesSend: "Enviar ",
    chasesTriggerEventOcurred: " después de ocurrido el evento gatillo.",
    chasesPreviousTemplateWasSent: " después del mensaje anterior si no hubo respuesta.",
    chasesSendCheetabotAfter: "Activar Cheetabot",
    chasesSendCheetabotAfterDesc: "Si se obtiene una respuesta, se activará al Cheetabot para esa conversación",
    createChase: "Crear persecución",
    chasesCancel: "Cancelar",
    chasesSelectTag: "Selecciona una etiqueta para que actué como gatillo",
    chasesSelectApp: "Selecciona una App a la cuál conectar",
    chasesConnectAccount: "Conecta tu cuenta",
    chasesApiKey: "Ingresa el API Key necesaria",
    chasesSelectAppEvent: "Selecciona un evento de la App",
    chasesSelectATimeFrequency: "Selecciona una frecuencia para activar esta persecución",
    chasesOnceAMonth: "Una vez al mes",
    chasesSelectDayOfTheMonth: "Selecciona un día del mes",
    onceAWeek: "Una vez por semana",
    selectDayOfTheWeek: "Selecciona un día de la semana",
    everyDay: "Cada día",
    selectTimeOfTheDay: "Selecciona una hora del día",
    chasesMessageTemplate: "Mensaje de inicio",
    chasesAddMessageTemplateToSequence: "Agregar otro mensaje a la secuencia",
    chasesActivateBotToRespond: "Activar Cheetabot cuando un mensaje de la secuencia obtenga una respuesta.",
    publishChase: "Publicar esta persecución",
    unpublishChase: "Despublicar esta persecución",
    chaseName: "Ponle nombre a esta persecución",
    chasesSavingError: "Ocurrió un error guardando esta persecución, por favor vuelve a intentarlo más tarde",
    thereCanBeADifferenceInActualTimes:
        "El tiempo entre mensajes no puede ser inferior a 1 día. Puede haber un retraso de 30-60 minutos cuando se envían los mensajes.",
    chasesPreviousTagConversationsMsg:
        "La persecución NO se aplicará a las conversaciones en las cuales el gatillo fue ejecutado previamente a la publicación de la persecución.",
    chasesNoteChaseEndedAndConversationsArchived: "Si el cliente no responde la conversación será archivada.",
    chasesPreviousTagConversationsMsg2:
        "Si quieres que las conversaciones con la etiqueta gatillo reciban esta persecución, debes volver a asociarles la etiqueta.",
    activateChaseInTaggedConversations:
        "Activar esta persecución en {taggedConversations} conversaciones que ya tenían esta etiqueta.",
    chasesDeleteDialogTitle: "¿Estás seguro que deseas eliminar la persecución {{chase_name}}?",
    chasesChangeToPublishedDialogTitle: " Publicar persecución",
    chasesChangeToDraftDialogTitle: " Cancelar persecución y pasar a borrador",
    chasesDeleteDialogDescription:
        "Al eliminar esta persecución se detendrán y perderán todos los mensajes y acciones programadas. Lo que afectará a {{number_of_client_with_active_chase}} clientes que tienen esta persecución activa.",
    chasesChangeToDraftDialogDescription:
        "Al aceptar esta persecución será cancelada, eliminando todas las persecuciones en curso. Mientras esté en este estado podrás editar la persecución. ¿Deseas continuar?",
    chasesChangeToPublishedDialogDescription:
        "Al aceptar esta persecución será publicada y se enviarán los mensajes según las reglas que configuraste.  ¿Deseas continuar?",
    chasesDialogConfirmButton: "Confirmar",
    chasesUnavailableVariableWarning:
        "Si el mensaje de inicio utiliza una variable sólo será envíado a clientes que la tengan definida (por ej. si utiliza {{customer_company_name}} sólo será enviado a clientes que tengan Compañía).",
    youCanSendUpToChaseMessages: "Puedes enviar hasta {number} mensajes de persecución al día.",
    chasesGraphExplanation:
        "En el gráfico puedes ver cuántos mensajes enviarán tus persecuciones las próximas 2 semanas.",
    chasesCreationGraphExplanation: "En el gráfico puedes ver cuántos mensajes enviará tu nueva persecución y cuándo.",
    chaseHardLimitCreationDesc:
        "No puedes crear esta persecución porque se superaría el límite de 600 mensajes de persecución diarios!",
    thisChase: "Esta persecución",
    overDailyLimitOn: "Sobre el límite diario en:",
    yourChasesCanOnlyTemplates: "Una persecución no puede tener más de {templates} mensajes.",
    chaseDeletingError: "Ocurrió un error borrando esta persecución, inténtalo de nuevo más tarde.",
    chaseStatusDraft: "Borrador",
    chaseStatusPublished: "Publicado",
    chaseMsgNoteHeaderChangeToDraftForEdit: "Debes pasar esta persecución a estado borrador para poder editarla",
    chaseTooltipSwitch: "No es posible cambiar el estado de esta persecución porque la información está incompleta.",
    chaseNameIsRequired: "Tu persecución requiere un nombre",
    chaseThatTriggerEventRequiresATemplateVariable:
        "Ese tipo de evento gatillo requiere que el mensaje tenga por variable: {variable}.",
    chaseThatTriggerEventHasMaximunTemplates: "Ese tipo de evento gatillo no puede tener más de {x} mensajes.",
    chasePleaseRemoveXTemplates: "Por favor quitar {x} mensajes.",
    chaseTimelimit: "Una persecución no puede durar más de {limit} días.",
    sendMessagesThrough: "Enviar los mensajes de esta persecución a través de:",
    SMSSegments: " segmentos SMS",
    clientSegments: "Segmentos de clientes",
    chooseASegment: "Selecciona un segmento de clientes",
    onceAWeekSendAMessageToClientsMeetingCriteria:
        "Una vez por semana, envía mensajes a clientes que se adecúen a cierto criterio.",
    equalTo: "igual a",
    equalOrGreaterThan: "igual o mayor a",
    equalOrLessThan: "igual o menor a",
    greaterThan: "mayor a",
    lessThan: "menor a",
    segmentCriteria: "Criterio de segmentación",
    operator: "Operador",
    segmentCustomersWhoHavePurchasedTheProducts:
        "Segmentar clientes que han comprado los productos: {products} en los últimos {days} días",
    segmentCustomersWhoHavePurchasedTheProductsBeetwen:
        "Segmente a los clientes que han comprado los productos: {products} dentro de los últimos {daysAgo} días y previo a {days} días.",
    value: "Valor",
    until: "Hasta",
    lastOrderDate: "Fecha de última compra",
    averagePurchase: "Compra promedio",
    customerAddedDate: "Fecha de registro del cliente",
    numberOfOrders: "Número de ordenes",
    productsPurchased: "Productos comprados",
    createSegment: "Crear nuevo segmento",
    editSegment: "Editar segmento",
    deleteSegment: "Borrar segmento",
    productName: "Ha comprado: [Nombre de producto]",
    saveSegment: "Guardar segmento",
    addCriteria: "Agregar otro criterio de segmentación",
    segmentName: "Nombre del segmento",
    otherCriteria: "otros criterios",
    selectADayOfTheWeekToSendMessages: "Selecciona un día de la semana para iniciar la persecución",
    segmentChaseDescription1: "Cada ",
    segmentChaseDescription2: "Gatillar persecución a clientes del segmento ",
    youNeedToCreateASegmentFirst: "Necesitas crear un segmento antes de configurar esta persecución.",
    segmentChaseCaption1:
        "Para evitar spam, no se iniciará la persecución en clientes que ya la hayan recibido hace menos de 30 días.",
    segmentChaseCaption2: "Por políticas de WhatsApp, se enviará a un máximo de 500 clientes diario.",
    tagChaseDescription: "Se va a iniciar esta persecución cuando a un cliente se le asigne la etiqueta: ",
    appChaseDescription1: "Se va a iniciar esta persecución cuando en la app ",
    appChaseDescription2: " ocurra el evento ",
    daysAgo: "días atrás",

    thisMessageWillBeSentEach: "Este mensaje será envíado cada ",
    atTime: " a las ",
    onTheChosenDayTheSegmentWillBeUpdated:
        "En el día seleccionado, el segmento será actualizado antes de enviar los mensajes.",
    clientsWontReceiveThisMessageMoreThanOnce:
        "Los clientes que ya hayan recibido este mensaje no volverán a recibirlo.",
    anErrorOcurredSavingYourSegment: "Ocurrió un error intentando guardar tu segmento.",
    anErrorOcurredGettingSegments: "Ocurrió un error intentando obtener tus segmentos.",
    loadingSegments: "Cargando segmentos",
    dueToWhatsAppLimitsWellOnlySendXMessages:
        "Debido a los limites de la API de WhatsApp, sólo enviaremos hasta 500 mensajes por ocasión.",
    clientsSegment: "Segmento de clientes",
    inTheLast: "dentro de los últimos",
    beforeThan: "previo a",
    period: "Período",
    thisChaseWillRunOnXDays: "Esta persecución se va a iniciar en {x} días.",
    thisChaseWillRunTomorrow: "Esta persecución se va a iniciar mañana.",
    giveThisChasePriority: "Darle a esta persecución prioridad ",
    overOtherChases: " frente a otras persecuciones.",
    priorityExplanation:
        "Esto permitirá decidir cuáles mensajes envíar si nos acercamos al límite diario impuesto por WhatsApp.",
    priority: "Prioridad",

    runThisChaseOnce: "Enviar una única vez",
    thisMessageWillBeSentAt: "Enviar este mensaje el día ",
    allClients: "Todos los clientes (sin segmento) ",

    chasesList: "Lista de persecuciones",
    chaseLogs: "Historial de persecuciones",
    withYourCurrentLimitItWillBeSentOnXDays: "Con tu límite actual de {x} mensajes diarios, se enviarán en {y} días.",
    youNeedToKeepSendingMessageToUpYourLimit: "Necesitas seguir enviando mensajes para subir tu límite.",
    clickHereIfYouPreferNotToSendOverSeveralDays:
        "Haz click aquí si prefieres enviarlo en un solo día. Esto sólo enviará los primeros {x} mensajes.",
    sendThisChaseTo: "Enviar esta persecución a ",
    yourCurrentDailyLimitIs: "Tu límite diario actual es de {x} mensajes.",
    thisWillTakeXDays: "Esto tomará {x} días.",

    youCanOnlyHaveOneChaseWithThisTrigger: "No puedes tener más de una persecución activa con este evento gatillo.",
    confirmedPurchaseOrder: "Orden de compra pagada y confirmada",
    unpaidPurchaseOrder: "Orden de compra no pagada",
    sendStartMessage: "Enviar mensaje de inicio",
    startCheetabot: "Iniciar Cheetabot",
    chasesSelectCheetabot: "Selecciona un Cheetabot a iniciar",
    chasesStart: "Iniciar",
    afterPreviousBotCloses: "después de que el Cheetabot anterior se cierre.",
    addAnotherMessageOrCheetabot: "Agregar otro mensaje o Cheetabot",
    botNeedsToStartWithAStartMessageForWaitsOver24Hours:
        "El Cheetabot debe iniciar con un Mensaje de Inicio para esperas de más de 24 horas.",
    doesntStartWithAStartMessage: "No inicia con un Mensaje de Inicio.",
    botIsNotReady: "Este Cheetabot no está listo. Por favor espere unos minutos y actualice el listado.",
    refreshBotList: "Actualizar lista de Cheetabots",

    youCannotHaveThatTriggerWithACheetabot: "No puedes tener ese evento gatillo con un Cheetabot.",
    inUseByAnotherChase: "En uso por otra persecución",
    botDisabled: "Cheetabot no activo",
    setUpATimeFrameForMessages: "Define un rango horario para los mensajes",
    youCanOnlyHaveUpToXChases: "Sólo puedes tener hasta {x} persecuciones.",
    youWillNeedToDeleteSomeChasesToCreateANewOne: "Necesitas borrar algunas persecuciones para crear una nueva.",
    abandonedCheckoutsOutsideThisTimeFrameWillNotTriggerThisChase:
        "Carritos abandonados fuera de este rango horario no gatillarán esta persecución.",
    orderUpdatedReadyForPickup: "Pedido listo para retirar",
    orderUpdatedCanceled: "Pedido cancelado",
    orderUpdatedFulfilled: "Pedido retirado",
}

export default messages
