const messages = {
    templateSubHeader: "WhatsApp te limita a ti, como empresa, a sólo poder enviar mensajes aprobados por ellos.",
    templateSubHeader2: "Aquí puedes crear mensajes de inicio y pedir su aprobación a WhatsApp.",
    templateSubHeader3:
        "Una vez tu mensaje de inicio sea aprobado por WhatsApp puedes enviarlo a tus clientes, y si obtienes una respuesta, se abrirá una ventana de 24 horas que te permite escribirles libremente.",
    templateSubHeader4:
        "Después de que esa ventana de 24 horas cierre, volverás a estar limitado a sólo enviar mensajes de inicio aprobados.",
    templateHeader: "Mensajes de inicio",
    templateInitButton: "Entendido",
    templateInitDesc1:
        "Sólo puedes iniciar conversaciones con clientes usando mensajes de inicio. Aquí puedes crear tus propios mensajes de inicio, ",
    templateInitDesc2: "los cuáles serán revisadas ⌛ por",
    templateInitDesc3:
        "Te proporcionamos un conjunto de mensajes de inicio para que ahora puedas empezar a hacerle seguimientos a tus clientes.",
    createdBy: "Creado por",
    status: "Estado",
    shortName: "Nombre",
    createNewDialogDesc: "Este nombre te ayudará a reconocer el mensaje de inicio más rápidamente.",
    templateMessage: "Mensajes de inicio",
    createNewDialogDesc1: "Puedes incluir algunas de las siguiente variables en tu mensaje: ",
    createNewDialogDesc2:
        "También puedes crear tus propias variables, usando llaves dobles nombre y poniéndoles un nombre, por ejemplo: ",
    createNewDialogDesc3: ". Se te pedirá que las reemplaces al enviar el mensaje.",
    saveDraft: "Guardar borrador",
    sendApproval: "Enviar para aprobación",
    confirmTempDialogTitle: "Mensaje de inicio enviado para aprobación",
    confirmTempDialogDesc:
        "Enviamos su mensaje de inicio a WhatsApp para su aprobación. Esto puede llevar un tiempo ⏳, te avisaremos cuando esté aprobado.",
    getIt: "Lo entiendo",
    createMsgTemplate: "Crear mensaje de inicio",
    editMsgTemplate: "Editar mensaje de inicio",
    deleteConfirmMsg1: "Este acción eliminará el mensaje de inicio",
    deleteConfirmMsg2:
        "Este mensaje ya no estará disponible para iniciar una conversación con tus clientes y en caso que quieras volver a usarlo lo deberás crear y enviar a validar a WhatsApp.",
    deleteConfirmMsg3: "¿Estás seguro que quieres eliminar este mensaje de inicio?",
    deleteConfrimBtn: "Sí, eliminar mensaje de inicio",
    updateDraft: "Actualizar borrador",
    updateTemplate: "Actualizar mensaje de inicio",
    characters: "Carácteres",
    templateShortName: "Nombre",
    templateTooltipShortName: "Este nombre le ayudará a reconocer el mensaje de inicio más rápidamente.",
    templateTemplateMessage: "Mensaje de inicio",
    templateTooltipTemplateMessage: `Con este mensaje iniciará una conversación con sus clientes. \n \nPuede ingresar variables con doble llave `,
    templateCreatedBy: "Creado por",
    templateStatus: "Estado",
    templateTooltipStatus:
        "Los mensajes de inicio deben ser aprobados por WhatsApp. Aquí te mostramos el estado de la aprobación.",
    templateFilteredCharacterMessage:
        "Sólo letras minúsculas (a-z), números (0-9) y guión bajo (_) están permitidos en el nombre del mensaje.",
    templateNameDupe: "No puedes repetir nombres de mensajes de inicio",
    searchTemplate: "Buscar mensaje de inicio",
    hideRejectedTemplates: "Esconder mensajes rechazados",
    creationDate: "Fecha de creación",
    tipsForWhatsAppApproval: "Consejos para aprobación de WhatsApp:",
    checkOurTips: "Revisa nuestros consejos para que WhatsApp apruebe tus mensajes de inicio! >",
    templateTip1: "Trata de incluir al menos 8 palabras.",
    templateTip2: "No debe ser una encuesta.",
    templateTip3: "Redacta claramente el objetivo por el cual envías el mensaje.",
    templateTip4: "Si vas a enviar un mensaje tipo saludo debe tener un objetivo más que solo saludar.",
    templateTip5: "Evita contenido de suscripción.",
    templateTip6: "Si estás vendiendo productos o promocionando una campaña, debes cumplir con la ",
    whatsappCommercePolicy: "Política de comercio WhatsApp",
    replaceModalTitle: "El mensaje tiene variables personalizadas que debes reemplazar",
    replaceModalPreview: "Previsualización del mensaje:",
    replaceModalInstruction: "Reemplaza las siguientes variables:",
    templateContent: "Contenido del mensaje",
    templateWordLenghtWarning: "Tu mensaje de inicio debe contener al menos 7 palabras",
    templateCharLenghtWarning: "Tu mensaje de inicio debe contener al menos 50 carácteres",
    templateSellsWarning: "Si tu mensaje de inicio contiene productos, promociones o campañas debe cumplir con la ",
    templatePollWarning: "Tu mensaje de inicio puede ser rechazado si contiene encuestas",
    templateSubscriptionsWarning: "Tu mensaje de inicio puede ser rechazado si tiene contenido de suscripción",
    templateForbiddenProductWarning:
        "Tu mensaje de inicio va a ser rechazado si ofrece productos o servicios relacionados a ",
    illegalProducts: "productos ilegales",
    drugs: "drogas",
    tabacco: "tabaco",
    alcohol: "alcohol",
    unsafeSuplements: "suplementos inseguros",
    gunsAmmosOrExplosives: "armas, munición o explosivos",
    animals: "ganado, especies protegidas o partes de animales",
    adultProductsOrServices: "productos o servicios para adultos",
    bodyPartsOrFluids: "partes o fluidos corporales",
    healthProducts: "productos médicos o salud",
    productsWithSexualConnotation: "productos con connotación sexual",
    realMoneyBet: "apuestas con dinero real",
    dateServices: "servicios de citas",
    unauthorizedAccesToDigitalMedia: "acceso no autorizado a medios digitales",
    offensiveFraudulentDeceivingOrExploitativeCommercialModels:
        "modelos comerciales ofensivos, fraudulentos, engañosos o explotadores",
    realVirtualOrFakeMoney: "moneda real, virtual o falsa",
    forgeryOrPiracy: "pirateria o falsificación",
    templateCloseVariablesWarning:
        "Tu mensaje de inicio contiene variables muy cerca una de otra, considera unirlas o usarlas de otra manera",
    templatesCannotBeEditedWarning: "Los mensajes de inicio no pueden ser editados una vez envíados a WhatsApp.",
    templatesOpenVariableWarning: "Al parecer tienes una variable que le falta una llave. El formato correcto es: ",
    templatesSingleBracketsVariableWarning:
        "Al parecer tienes una variable con llaves simples en vez de llaves dobles. El formato correcto es: ",
    templatesVarTypoWarning: "Escribiste {customVariable}, ¿quisiste usar la variable por defecto: {defaultVariable}?",
    approvalCanTake2Days: "El proceso de revisión de WhatsApp puede tardar hasta 2 días hábiles.",
    templateType: "Tipo",
    previewMessage: "Ver previsualización del mensaje",
    templateContainsHtmlWarning: "Tu mensaje de inicio no puede contener código HTML.",
    emptyVariablesWarning: "Al parecer tienes una variable vacía, por favor ponle un nombre o número.",
    urlVariablesWarning:
        "Las variables no pueden contener URLs, pon un nombre a la variable y luego puedes reemplazarlo por tu URL al enviar el mensaje a un cliente.",
    variablesWithSpacesWarning: "Las variables no pueden contener espacios, usa - o _ en su lugar.",
    anonymousCheetah: "Chita Anónimo",
    templateCannotStartWithVariableWarning: "Tu mensaje de inicio no puede comenzar con una variable.",
    templateCannotEndWithVariableWarning: "Tu mensaje de inicio no puede terminar con una variable.",
    templateThreatsWarning:
        "Tu mensaje de inicio puede ser rechazado si contiene amenazas de acción legal o humillación pública.",
    templateAsksForIDWarning:
        "Tu mensaje de inicio puede ser rechazado si pide documentos de identificación de un cliente.",
    templateHasTooManyLineSkips: "Tu mensaje de inicio puede ser rechazado si contiene muchos saltos de línea.",
    templateHasTooManyVariables:
        "Tu mensaje de inicio parece tener muchas variables para su contenido, puede ser rechazado si su propósito no es claro.",
    templateNoType: "Regular",
    templateAgendaProType: "Agenda Pro - Agendado",
    templateDiscountCouponType: "Shopify - Cupón de descuento",
    templateAbandonedCheckoutType: "Shopify - Carro abandonado",
    templateSegments: "Segmentos",
    templateTypeNeedsVariables: "Ese tipo de mensaje de inicio requiere las variables: ",
    templateSmsMessagesWillInclude: "Los mensajes SMS incluirán automáticamente: ",
    smsStopMessage: "Responde STOP para no recibir mensajes.",
    smsLengthRecommendation: "Recomendamos que los SMS tengan menos de {x} carácteres.",
    templateSource: "Canal",
    templateCostToSend: "Costo de enviar este mensaje: {x}",
    templateSuggestionStopMessage:
        'Sugerimos incluir la frase: Respone "No más mensajes" para no recibir mensajes. Cheetrack detectará esta respuestas y no les enviará más mensajes automáticos a esos clientes.',
    includeTemplateFile: "Incluir archivo",
    templateFileFormat: "JPG, PNG, MP4 o PDF.",

    addTemplateButton: "Agregar botón",
    templateButton: "Botón",
    templateButtonType: "Tipo",
    templateButtonText: "Texto del botón",
    templateButtonQuickReply: "Respuesta rápida",
    templateButtonUrl: "URL del botón",
    templateButtonName: "Nombre del botón",
    templateFlowsTitle: "Agregar Flows para facilitar la interacción con clientes",
    templateButtonPhoneNumber: "Número de teléfono",
    templateButtons: "Botones",
    templateButtonsDescription:
        "Agrega botones a tu mensaje de inicio, para facilitar respuestas de tus clientes o un llamado a la acción a una URL de tu e-commerce.",
    cantUseVariablesInButtonText: "No puedes usar variables en el texto de un botón.",
    noTemplateButtons: "Sin botones",
    dynamicUrl: "URL dinámica",
    staticURL: "URL estática",
    templateButtonCallToAction: "Llamada a la acción",
    dynamicUrlDescription: "La URL dinámica incluye una variable al final de la URL, por ejemplo: ",

    welcomeMessageTemplateContent:
        "Hola {{client_first_name}}! Ya eres parte de la familia {{my_company}}🌟 \nEsperamos que te gusten nuestros productos, y para que sigamos conociéndonos mejor. \nCualquier duda que tengas, puedes escribirnos por aquí.",
    welcomeMessageTemplateName: "Mensaje de bienvenida",
    abandonedCheckoutTemplateContent:
        "Hola {{client_first_name}} te escribimos desde {{my_company}}🌟 \nNos dimos cuenta que dejaste tus productos preferidos olvidados! ¡Yo que tú voy ahora antes de que sea tarde!",
    abandonedCheckoutTemplateName: "Carro abandonado",
    newProductLaunchTemplateName: "Lanzamiento nuevo producto",
    newProductLaunchTemplateContent:
        "Hola {{client_first_name}} te escribimos desde {{my_company}}🌟 \nTe queremos contar un secreto 🤫 \nEstamos lanzando un nuevo producto con stock reducido, y queremos contarte a ti antes que a los demás 😁 \n👀 Te dejamos el link a nuestro catálogo: ",
    discountCouponTemplateContent:
        "Hola {{client_first_name}}, desde {{my_company}} te queremos hacer un regalito 🎁 para que nos vuelvas a visitar. \nTen un {{discount_amount}}% de descuento en tu próxima compra usando el código {{discount_code}}. \n¡Esperamos verte pronto!",
    discountCouponTemplateName: "Código de descuento",
    reengageCustomerTemplateContent:
        "🤗 {{client_first_name}} En {{my_company}} te extrañamos 🌟 \nHace días que no te vemos por nuestra tienda y esperamos no nos hayas olvidado. Revisa nuestro catálogo para ver todas las ofertas que tenemos para ti!",
    reengageCustomerTemplateName: "Reencuentro con cliente",
    abandonedCheckoutNoBtnsTemplateContent:
        "Hola {{client_first_name}} te escribimos desde {{my_company}}🌟 \nNos dimos cuenta que dejaste tus productos preferidos olvidados! \n\nClickea el enlace para ir a recuperarlos: {{abandoned_checkout_link}}\n\n¡Yo que tú voy ahora antes de que sea tarde!",

    seeYourAbandonedCheckout: "Ir a mi carro abandonado",
    seeOurCatalog: "Ve nuestro catálogo",
    templateTemplate: "Plantilla",
    noTemplate: "Sin plantilla",
    anErrorOcurredCreatingThisTemplate: "Ocurrió un error creando este mensaje de inicio.",
    templateCategory: "Categoría",
    rejectReasonINCORRECT_CATEGORY: "La categoría del mensaje de inicio es incorrecta, por favor selecciona otra.",
    rejectReasonSCAM:
        "El mensaje de inicio fue considerado una potencial estafa, por favor revisa el contenido y vuelve a intentarlo.",
    rejectReasonINVALID_FORMAT:
        "El mensaje de inicio tiene problemas de formato, por favor revisa el contenido y vuelve a intentarlo.",
    rejectReasonABUSIVE_CONTENT:
        "El mensaje de inicio fue marcado como potencial contenido abusivo, por favor revisa el contenido y vuelve a intentarlo.",

    restaurantMenuTemplateName: "Menú de restaurante",
    restaurantMenuTemplateContent:
        "Bienvenido a {{my_company}}! \nPuedes ver nuestra carta haciendo click en el botón: ",
    seeTheMenu: "Ver carta",
    youCanOnlyHaveUpTo80Templates: "Solo puedes tener hasta 80 mensajes de inicio.",
    pleaseDeleteSomeTemplates: "Por favor elimina algunos mensajes de inicio para poder crear nuevos.",
}

export default messages
