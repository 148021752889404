import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    WSData: [],
    agents: [],
    agentsError: false,
    agentsLoader: false,
    clientId: "",
    selectedClient: { metadata: {} },
    multiselectActive: false,
    websocketErrors: 0,
    userTimeDiff: 0,
    agentGroups: [],
}

const websocketSlice = createSlice({
    name: "websocket",
    initialState,
    reducers: {
        storeWSData(state, action) {
            if (action.payload.action === "receiveMessage" || action.payload.action === "sendMessage") {
                const siblingAction = state.WSData.find((d) => d.action === action.payload.action)
                if (siblingAction) siblingAction.message = [...siblingAction.message, ...action.payload.message]
                else state.WSData = [...state.WSData, action.payload]
            } else if (action.payload.action === "updateMessage") {
                const siblingAction = state.WSData.find((d) => d.action === action.payload.action)
                if (siblingAction) {
                    const messageIndex = siblingAction.message.findIndex((c) => c.PK === action.payload.message[0].PK)
                    if (messageIndex !== -1) siblingAction.message[messageIndex] = action.payload.message[0]
                    else siblingAction.message = [...siblingAction.message, ...action.payload.message]
                } else state.WSData = [...state.WSData, action.payload]
            } else if (action.payload.action === "updateClient") {
                const siblingAction = state.WSData.find((d) => d.action === action.payload.action)
                if (siblingAction) {
                    const clientIndex = siblingAction.client.findIndex((c) => c.PK === action.payload.client[0].PK)
                    if (clientIndex !== -1) siblingAction.client[clientIndex] = action.payload.client[0]
                    else siblingAction.client = [...siblingAction.client, ...action.payload.client]
                } else state.WSData = [...state.WSData, action.payload]
            } else state.WSData = [...state.WSData, action.payload]
        },
        refreshWSData(state) {
            state.WSData = []
        },
        setAgents(state, action) {
            const agents = action.payload

            state.agents = agents
            state.agentsLoader = false
            state.agentGroups = [...new Set(agents.map((u) => u.metadata.group_tag).filter((g) => g && g !== ""))]
        },
        setAgentsError(state) {
            state.agentsError = true
            state.agentsLoader = false
        },
        toggleAgentsLoader(state) {
            state.agentsLoader = !state.agentsLoader
        },
        setClientId(state, action) {
            state.clientId = action.payload
        },
        setSelectedClient(state, action) {
            state.selectedClient = action.payload
        },
        toggleMultiselect(state) {
            state.multiselectActive = !state.multiselectActive
        },
        setMultiselectFalse(state) {
            state.multiselectActive = false
        },
        setMultiselectTrue(state) {
            state.multiselectActive = true
        },
        addWebsocketError(state) {
            state.websocketErrors++
        },
        restartWebsocketErrors(state) {
            state.websocketErrors = 0
        },
        setUserTimeDiff(state, action) {
            state.userTimeDiff = action.payload
        },
    },
})

export const websocketActions = websocketSlice.actions
export default websocketSlice
