const messages = {
    boardName: "Nombre de Tablero",
    boards: "Tableros",
    boardsDesc: "Crea tableros para organizar a tus clientes.",
    boardsDesc2: "Los tableros usan etiquetas para ordenar las conversaciones con tus clientes en columnas.",
    createNewBoard: "Crea un nuevo tablero",
    addTagToBoard: "Agregar etiqueta a tablero",
    boardsSelectTag: "Selecciona una etiqueta",
    selectTagsForYourBoard: "Selecciona etiquetas para agregar a tu tablero",
    saveBoardChanges: "Guardar tablero",
    deleteBoard: "Eliminar tablero",
    thisTagBelongsToABoard: "Esta etiqueta pertenece a un tablero.",
    seeBoard: "Ver tablero",
    advanceToNextTagInBoard: "Avanzar a la siguiente etiqueta del tablero",
    backToPreviousTagInBoard: "Retroceder a anterior etiqueta del tablero",
    goToConversation: "Ir a esta conversación",
    goToConversationsWithTag: "Ver conversaciones con esta etiqueta",
    boardSuccessPercentage: "Tasa de éxito del tablero",
    seeBoardsList: "Ver lista de tableros",
    nameYourBoard: "Nombra tu tablero",
    gettingConversationsWithThisTag: "Obteniendo clientes con esta etiqueta",
    boardStepSucess: "éxito",
    boardStepSucessDesc: "% de clientes avanzaron desde pasos anteriores a este paso o más allá",
    finalBoardStep: "Paso final",
    savingTag: "Guardando etiqueta",
    editBoard: "Editar tablero",
    thisTagIsPartOfABoard: "Esta etiqueta es parte de un tablero:",
    thisTagActivatesAChase: "Esta etiqueta activa una persecución:",
    thisTagIsStepOnBoard: "Paso {step} en {board}.",
    thisConversationIsOnABoard: "Esta conversación aparece en los siguientes tableros:",
    clickOnOneOfTheStepToChangeTag:
        "Clickea en uno de los pasos para mover esta conversación en el tablero y cambiar a la etiqueta correspondiente",
    youDontHaveAnyBoardsYet: "No tienes tableros todavía",
    createYourFirstBoardClickingTheTopBtn: "Crea tu primer tablero clickeando en el botón de arriba",
    youDontHaveThePermissionsToCreateBoards:
        "No tienes los permisos necesarios para crear o editar tableros. Pídele a un admin si necesitas que cree o modifique un tablero.",
    boardTags: "Etiquetas del tablero",
    filterBoardClients: "Filtrar clientes del tablero",
    aDayAgo: "Hace un día",
    threeDaysAgo: "Hace tres días",
    aWeekAgo: "Hace una semana",
    aMonthAgo: "Hace un mes",
    aYearAgo: "Hace un año",
    clientCreationDate: "Inicio de conversación",
    successPercentageInfo:
        "El porcentaje de éxito por paso indica la proporción de clientes que han llegado a ese paso, lo que incluye quienes están ahí y quienes han avanzado a los siguientes.",

    xTotalClientsForThisBoard: "{totalClients} clientes en total para este tablero.",
    xTotalClientsForThisBoardWithFilters: "{totalClients} clientes en total para este tablero con {filters} filtros.",
    youCanOnlyHaveUpToXTags: "Sólo puedes tener hasta {x} etiquetas.",
    youWillNeedToDeleteSomeTagsToCreateANewOne: "Elimina una si quieres crear otra.",
    youCanOnlyHaveUpToXBoards: "Sólo puedes tener hasta {x} tableros.",
    youWillNeedToDeleteSomeBoardsToCreateANewOne: "Necesitarás eliminar algunos tableros para crear uno nuevo.",
}
export default messages
