// Retorna si el cliente no tiene usuario asignado
export function isClientUnassigned(client) {
    return (
        !client ||
        !client.user ||
        client.user === "none" ||
        client.user === "None" ||
        client.user === "USER#" ||
        client.user === "USER#None" ||
        client.user === "USER#none" ||
        client.user === "USER#USER#none" ||
        client.user === "USER#USER#None"
    )
}

// Retorna si el cliente está asignado a un email
export function isClientAssignedToUser(client, userEmail) {
    return (
        client &&
        client.PK &&
        (!client.user ||
            client.user === "USER#" ||
            client.user === "USER#" + userEmail ||
            client.user === userEmail ||
            client.user === "none" ||
            client.user === "None" ||
            client.user === "USER#None" ||
            client.user === "USER#none" ||
            client.user === "USER#USER#none" ||
            client.user === "USER#USER#None")
    )
}
