// Variables default de cheetrack

export const defaultMessageVariables = [
    // {
    //     name: "my_name",
    //     bracket_name: "{{my_name}}",
    //     parent: "user",
    //     alternatives: ["{{my_user_name}}", "{{mi_nombre}}"],
    //     combined_props: ["name", "lastname"],
    // },
    // {
    //     name: "my_first_name",
    //     bracket_name: "{{my_first_name}}",
    //     parent: "user",
    //     prop_name: "name",
    //     alternatives: ["{{mi_primer_nombre}}"],
    // },
    // {
    //     name: "my_email",
    //     bracket_name: "{{my_email}}",
    //     parent: "user",
    //     prop_name: "email",
    //     alternatives: ["{{mi_email}}"],
    // },
    // {
    //     name: "my_phone_number",
    //     bracket_name: "{{my_phone_number}}",
    //     parent: "tenant",
    //     prop_name: "phone",
    //     alternatives: ["{{mi_telefono}}", "{{mi_teléfono}}"],
    // },
    // {
    //     name: "my_role",
    //     bracket_name: "{{my_role}}",
    //     parent: "user",
    //     prop_name: "role",
    //     alternatives: ["{{mi_rol}}"],
    // },
    // {
    //     name: "my_company",
    //     bracket_name: "{{my_company}}",
    //     parent: "tenant",
    //     prop_name: "name",
    //     alternatives: ["{{mi_compañia}}"],
    // },
    {
        name: "client_first_name",
        bracket_name: "{{client_first_name}}",
        parent: "client",
        prop_name: "firstname",
        alternatives: ["{{primer_nombre_del_cliente}}", "{{contact_first_name}}"],
    },
    {
        name: "client_name",
        bracket_name: "{{client_name}}",
        parent: "client",
        combined_props: ["firstname", "lastname"],
        alternatives: ["{{nombre_del_cliente}}", "{{contact_name}}"],
    },
    {
        name: "client_company_name",
        bracket_name: "{{client_company_name}}",
        parent: "client",
        prop_name: "company",
        alternatives: ["{{company_name}}", "{{customer_company_name}}", "{{compañia_del_cliente}}"],
    },
    {
        name: "client_email",
        bracket_name: "{{client_email}}",
        parent: "client",
        prop_name: "email",
        alternatives: ["{{client_email}}"],
    },
]
