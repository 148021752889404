const messages = {
    dailyClients: "Daily interactions",
    dailyClientsDesc: "See how many of your interactions have gone through the Cheetrack flow in the last days.",
    clientsInTheLastXDaysCompared: "Interactions from {from} to {to} compared to the previous {days} days",
    thisPeriod: "This period",
    previousPeriod: "Previous period",
    last2Weeks: "Last 2 weeks",
    last4Weeks: "Last 4 weeks",
    seeCompiledData: "See compiled data",
    seeClientsReachedByTemplate: "See interactions reached by start message",
    allDailyClients: "All daily interactions",

    filterClients: "Filter interactions",
}
export default messages
