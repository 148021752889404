const metaErrorMessages = {
    metaErrorCode0:
        "Error de autentificación: La sesión ha expirado, por favor intenta volver a conectar con tu cuenta de Meta/Facebook.",
    metaErrorCode1:
        "API desconocida: El servicio de Facebook/WhatsApp parece no estar disponible, por favor vuelve a intentarlo más tarde.",
    metaErrorCode2:
        "Problema con el servicio API: El servicio de Facebook/WhatsApp parece no estar disponible, por favor vuelve a intentarlo más tarde.",
    metaErrorCode3:
        "Problema con el método API: Un problema ocurrió con los permisos de Meta/Facebook, por favor intenta volver a conectar con tu cuenta de Meta/Facebook y otorgarnos los permisos adecuados.",
    metaErrorCode4: "Demasiadas llamadas a la API: Por favor espera un poco y vuelve a intentar.",
    metaErrorCode10:
        "Acceso a la API denegado: Un permiso no fue otorgado o ha sido modificado, por favor intenta volver a conectar con tu cuenta de Meta/Facebook y otorgarnos los permisos adecuados.",
    metaErrorCode100:
        "Parámetro inválido: Ocurrió un problema con un parámetro del mensaje, por revisa los archivos, variables o contenido interactivo de tu mensaje.",
    metaErrorCode190:
        "Token expirado: Tu token de acceso a Meta/Facebook ha expirado, por favor intenta volver a conectar con tu cuenta de Meta/Facebook.",
    metaErrorCode200:
        "Error de permisos de API: Ocurrió un error con los permisos de Meta/Facebook, por favor intenta volver a conectar con tu cuenta de Meta/Facebook y otorgarnos los permisos adecuados.",
    metaErrorCode2494049:
        "Error de permisos de API: No tienes permiso para registrar este número de teléfono, por favor revisa el número de teléfono o intenta volver a conectar con tu cuenta de Meta/Facebook y otorgarnos los permisos adecuados.",
    metaErrorCode368:
        "Bloqueo temporal: Pareces estar bloqueado de manera temporal, por favor vuelve a intentarlo en unos minutos.",
    metaErrorCode506:
        "Publicación duplicada: No puedes crear publicaciones duplicadas de manera consecutiva, por favor cambia el contenido de tu publicación y vuelve a intentarlo.",
    metaErrorCode80007:
        "Problema con límite de frecuencia: Has alcanzado el límite de frecuencia de la plataforma, por favor contacta un administrador o vuelve a intentarlo más tarde.",
    metaErrorCode131052:
        "Error de descarga de medios: El archivo no pudo ser descargado, puede haber expirado o no estar disponible de parte del remitente.",
    metaErrorCode131042:
        "Problema con método de pago: El mensaje no pudo ser enviado debido a un problema con un método de pago. Por favor asegúrate de que tu cuenta de WhatsApp Business tiene lo siguiente configurado de forma correcta: zona horaria, moneda y método de pago. Luego revisa si peticiones de MessagingFor pendientes o rechazadas. {url}",
    metaErrorCode131043: "Mensaje expirado: El mensaje no pudo ser enviado durante su ciclo de vida.",
    metaErrorCode130429:
        "Límite de frecuencia alcanzado: El mensaje no pudo ser enviado porque se han enviado demasiados mensajes en un corto lapso, por favor vuelve a intentarlo más tarde.",
    metaErrorCode131045:
        "Certificado sin firmar: El mensaje no pudo ser enviado debido a un error de certificación. Por favor contacta un administrador.",
    metaErrorCode131016:
        "Sobrecarga del servicio: Por favor vuelve a intentarlo más tarde. Recomendamos revisar el estado de la plataforma de WhatsApp Business.",
    metaErrorCode131047:
        "Fuera de la ventana de 24h: El mensaje no pudo ser enviado ya que han pasado más de 24 horas desde el último mensaje de tu cliente.",
    metaErrorCode131048:
        "Límite de spam alcanzado: Este número de teléfono ha sido catalogado como spam. Por favor contacta un administrador.",
    metaErrorCode131000: "Error desconocido: El mensaje no pudo ser enviado por un error desconocido.",
    metaErrorCode131001: "Mensaje muy largo: El largo del mensaje no puede ser superior a los 4096 carácteres.",
    metaErrorCode131002: "Receptor inválido: Por favor intenta enviar este mensaje a un individuo y no a un grupo.",
    metaErrorCode131005: "Acceso denegado: Ese número de teléfono ya está registrado en WhatsApp.",
    metaErrorCode131006: "Recurso no encontrado: Aquel recurso o archivo no pudo ser encontrado.",
    metaErrorCode131008:
        "Parámetro faltante: Parece estar faltando un parámetro del mensaje. Por favor revisa si el mensaje lleva variables o elementos interactivos.",
    metaErrorCode131009:
        "Valor inválido en parámetro: Ocurrió un problema con un parámetro del mensaje. Por favor revisa que el número de teléfono sea válido y esté registrado en WhatsApp, y que tu mensaje tenga valores válidos para sus variables o elementos interactivos.",
    metaErrorCode131021: "Usuario incorrecto: Parece que te has envíado un mensaje a ti mismo.",
    metaErrorCode131031: "Cuenta de remitente bloqueada: Tu cuenta ha sido bloqueada y no puedes enviar más mensajes.",
    metaErrorCode131051: "Tipo de mensaje no permitido: Ese tipo de mensaje no está permitido.",
    metaErrorCode131053: "Error en súbida de medios: El archivo no pudo ser subido.",
    metaErrorCode131055: "Método no permitido: El método que estás intentado usar no está permitido.",
    metaErrorCode132000:
        "Parámetros ausentes: La cantidad de parámetros enviados no concuerda con la cantidad de parámetros configurada para este mensaje.",
    metaErrorCode132001:
        "Mensaje de inicio no encontrado: El mensaje de inicio específicado no ha sido aprobado o no existe.",
    metaErrorCode132005:
        "Texto muy largo: El texto parece ser muy largo, por favor revisa el mensaje de inicio en el cuál se basa.",
    metaErrorCode132007: "Política de carácteres infringida: Estás enviando un carácter no permitido por WhatsApp.",
    metaErrorCode132012:
        "Formato de parámetros incorrecto: Tienes parámetros en tu mensaje de inicio que no concuerdan con lo que se intenta enviar. Por favor revisa las variables, archivos o elementos interactivos de tu mensaje de inicio.",
    metaErrorCode133000:
        "Borrado incompleto: Una acción de borrado anterior ha sido interrumpida, por favor intenta borrar nuevamente.",
    metaErrorCode133001:
        "Problema de cifrado: Una copia de respaldo no ha podido ser descifrada debido a un error desconocido.",
    metaErrorCode133002:
        "Problema de cifrado: Una copia de respaldo no ha podido ser descifrada debido a un error en una contraseña o formato.",
    metaErrorCode133003:
        "Problema en recuperación de token: El token de recuperación no pudo ser leído debido a un error en una contraseña o formato.",
    metaErrorCode133004: "Servicio no disponible: El servidor de registro está temporalmente no disponible.",
    metaErrorCode133005:
        "PIN no concuerda: Por favor asegúrate de que estás ingresando el PIN correcto y vuelve a intentar.",
    metaErrorCode133006: "Token de recuperación incorrecto: El token de recuperación puede haber expirado.",
    metaErrorCode133007: "Cuenta bloqueada: La cuenta ha sido bloqueada por el servidor.",
    metaErrorCode133008: "Demasiados intentos: Haz intentado demasiadas veces con un PIN incorrecto.",
    metaErrorCode133009:
        "Intento de PIN demasiado rápido: El PIN fue ingresado demasiado rápido y la petición ha sido bloqueada.",

    metaErrorCode400: "Petición inválida: La petición que estás intentando realizar no es válida.",
    metaErrorCode401:
        "No autorizado: No tienes credenciales autorizadas para esta consulta. Por favor intenta volver a conectar con tu cuenta de Meta/Facebook. {url}",
    metaErrorCode403:
        "Acceso denegado: No tienes permiso para acceder a este recurso. Por favor intenta volver a conectar con tu cuenta de Meta/Facebook.",
    metaErrorCode404:
        "Recurso no encontrado: El recurso que estás intentando acceder no existe. Por favor intenta volver a conectar con tu cuenta de Meta/Facebook.",
    metaErrorCode405:
        "Método no permitido: El método que estás intentando usar no está permitido. Por favor intenta volver a conectar con tu cuenta de Meta/Facebook.",
    metaErrorCode500:
        "Error interno del servidor: Ha ocurrido un error interno en el servidor. Por favor contacta con un Administrador.",
    metaErrorCode503:
        "Servicio no disponible: El servidor está temporalmente no disponible. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode412:
        "Precondición fallida: La petición no pudo ser procesada debido a una condición previa no cumplida. Por favor intenta volver a conectar con tu cuenta de Meta/Facebook.",
    metaErrorCode420:
        "Frecuena limitada: Has enviado demasiadas peticiones en un corto lapso de tiempo. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode402:
        "Pago requerido: Hubo uno o más errores con el método de pago: La cuenta de pago no está asociada a una cuenta de WhatsApp, la línea de crédito superó su límite, no se configuró línea de crédito, la cuenta de WhatsApp Business se eliminó o está suspendida, no se configuró divisa o zona horaria, la solicitud MessagingFor está pendiente o rechazada, o se excedió el límite de conversación. Revisa tu configuración de pagos en el administrador de WhatsApp y vuelve a intentarlo. {url}",
    metaErrorCode408:
        "Tiempo de espera agotado: La petición no pudo ser procesada debido a un tiempo de espera agotado. Por favor intenta vuelve a intentarlo más tarde.",
    metaErrorCode410:
        "Tiempo de espera agotado: La petición no pudo ser procesada debido a un tiempo de espera agotado. Por favor intenta vuelve a intentarlo más tarde.",
    metaErrorCode429:
        "Limite de frecuencia alcanzado: Has enviado demasiadas peticiones en un corto lapso de tiempo. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode430:
        "Error de certificado: El certificado de seguridad no pudo ser validado. Descarga del administrador de WhatsApp un nuevo certificado y vuelve a registrarte.",
    metaErrorCode432:
        "Error de certificado: El certificado de seguridad no pudo ser validado. Descarga del administrador de WhatsApp un nuevo certificado y vuelve a registrarte.",
    metaErrorCode433:
        "Firma de certificado no válida: El certificado de la empresa no está firmado con la clave de identidad del cliente. Por favor vulve a crear el certificado de la empresa.",
    metaErrorCode470:
        "El mensaje no se pudo enviar porque pasaron más de 24 horas desde la última vez que el cliente respondió a este número. Usa un mensajde de inicio para responder.",
    metaErrorCode471:
        "Spam: No se pudo enviar el mensaje, porque hay restricciones relacionadas con la cantidad de mensajes que se pueden enviar desde este número de teléfono. Esto puede deberse a que se bloquearon o se marcaron como spam demasiados mensajes anteriores.",
    metaErrorCode472:
        "El mensaje no se pudo enviar porque el número de teléfono del usuario es parte de un experimento. Omite el envío de mensajes a este usuario.",
    metaErrorCode480:
        "Usuario potencialmente cambiado: No se envió el mensaje porque el usuario al que pertenece este número de teléfono posiblemente cambió.",
    metaErrorCode501:
        "No se admite el tipo de mensajes en estos momentos: No se pudo enviar el mensaje debido a un error desconocido. Vuelve a intentarlo más tarde.",
    metaErrorCode1000: "Error génerico. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode1001: "Mensaje demasiado largo: El mensaje que estás intentando enviar es demasiado largo.",
    metaErrorCode1002: "Destinario inválido: El tipo de destinatario al que estás intentando enviar no es válido.",
    metaErrorCode1004: "Recurso ya existe: La UUID que estás intentando usar ya existe.",
    metaErrorCode1005:
        "Acceso denegado: Ocurrió uno o más errores: El directerio de subida ya no existe, las credenciales son inválidas, hay error de certificado, la aplicación necesita actualizarse, esta cuenta no puede usar mensajes con botón, o el número de WhatsApp ya está registrado.",
    metaErrorCode1006: "No se encontró el recurso o archivo.",
    metaErrorCode1007: "El destinario bloqueó la recepción de mensajes.",
    metaErrorCode1008: "Falta un parámetro necesario.",
    metaErrorCode1009: "Valor de eparámetro no válido.",
    metaErrorCode1010: "El mensaje contiene un parámetro no necesario.",
    metaErrorCode1011: "El servicio no está preparado. Vuelve a intentarlo más tarde.",
    metaErrorCode1013: "El usuario no es válido.",
    metaErrorCode1014:
        "Error interno: El mensaje no pudo ser enviado debido a un error interno. Vuelve a intentarlo más tarde.",
    metaErrorCode1015:
        "Demasiadas solicitudes: Has enviado demasiadas peticiones en un corto lapso de tiempo. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode1016: "Sistema sobrecargado: El sistema está sobrecargado. Vuelve a intentarlo más tarde.",
    metaErrorCode1017:
        "Está solicitud sólo se puede realizar en el nodo maestro. Esto puede ocurrir cuando el cliente de la API de WhatsApp Business no se configura correctamente o se producen errores internos.",
    metaErrorCode1018:
        "Está solicitud sólo se puede realizar en la app principal primaria. Este error se produce cuando se envían solicitudes a un nodo de la app principal que no es el propietario del fragmento o no se encuentra en proceso de convertirse en el propietario del fragmento.",
    metaErrorCode1021:
        "Usuario incorrecto: Puede que estés intentando enviar un mensaje a un usuario que no está registrado en WhatsApp o a ti mismo. Por favor verifica el número de teléfono.",
    metaErrorCode1022:
        "URL de Webhooks no configurada: Este error se produce si no configuraste el formato de los webhooks de la API de REST.",
    metaErrorCode1023: "Se produjo un error en la base de datos. Vuelve a intentarlo más tarde.",
    metaErrorCode1024:
        "Es necesario cambiar la contraseña: La contraseña que estás usando es demasiado débil o ha sido vulnerada. Por favor cambia la contraseña, y luego vuelve a establecer la conexión.",
    metaErrorCode1025:
        "Solicud de conexión no válida: La solicitud de conexión no es válida. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode1026: "Recepción imposible: No se pudo enviar el mensaje, vuelve a intentarlo más tarde.",
    metaErrorCode1028:
        "Enviaste un mensaje a un usuario de WhatsApp que posiblemente cambió, y se te envió una notificación de sistema user_identity_changed que está pendiente de confirmación.",
    metaErrorCode1031:
        "Se bloqueó tu cuenta, y no puedes enviar mensajes debido a una infracción de la política de integridad.",
    metaErrorCode2000: "La cantidad de parámetros proporcionados no coincide con la cantidad de parámetros prevista.",
    metaErrorCode2001:
        "Estás intentando usar un mensaje de inicio inexistente, inactivo o que no está asociado a tu cuenta.",
    metaErrorCode2002: "El receptor no pudo descargar tu mensaje de inicio.",
    metaErrorCode2003: "No existe el mensaje de inicio requerido para el idioma o región.",
    metaErrorCode2004:
        "Longitud excesiva para los parámetros del mensaje. Revisa la longitud en los botones o url del mensaje.",
    metaErrorCode2005: "La traducción del mensaje de inicio es demasiado larga.",
    metaErrorCode2006:
        "Política de espacios blancos infringida: El mensaje de inicio no puede contener esos espacios en blanco.",
    metaErrorCode2007: "Se incumplió la política de carácteres de formato.",
    metaErrorCode2008: "El formato de archivo no es válido.",
    metaErrorCode2009: "Falta un componente obligatorio del mensaje de inicio.",
    metaErrorCode2010: "La URL del botón no es válida.",
    metaErrorCode2011: "El número de teléfono del botón no es válido.",
    metaErrorCode2012: "El formato del parámetro no coincide con el formato del mensaje de inicio creado.",
    metaErrorCode2013: "El receptor no admite los botones de mensaje de inicio.",
    metaErrorCode2014:
        "Espacio de nombre previsto vacío: Este error se produce cuando aún no se ha descargado un paquete de idioma de mensaje de plantilla.",
    metaErrorCode2015:
        "La solicitud de mensaje contiene una cantidad de secciones menor al mínimo o superior al máximo.",
    metaErrorCode2016: "Cantidad de filas no válida.",
    metaErrorCode2017: "Se incumplió con la política de caracteres de formato.",
    metaErrorCode2023:
        "El objeto de sección no tiene productos o el conteo de productos totales excedió el número máximo permitido.",
    metaErrorCode2024:
        "El identificador de catálogo que proporcionaste no existe o no pertenece a tu cuenta de WhatsApp Business (WABA).",
    metaErrorCode2025:
        "El identificador de catálogo que proporcionaste no está conectado al número de teléfono que estás usando para enviar un mensaje.",
    metaErrorCode2026: "Algunos productos proporcionados en tu solicitud no se encontraron en el catálogo.",
    metaErrorCode2027: "No se encontraron productos en el catálogo que proporcionaste.",
    metaErrorCode2028:
        "Mensaje de productos individuales con información faltante o incompleta relativa a los requisitos que se deben cumplir en el comercio electrónico.",
    metaErrorCode2029:
        "Mensaje de productos múltiples con información faltante o incompleta relativa a los requisitos que se deben cumplir en el comercio electrónico.",
    metaErrorCode2030:
        "Mensaje de productos múltiples, en el que se combinan productos no válidos y otros que no incluyen información relativa al cumplimiento de los requisitos.",
    metaErrorCode2036: "Estructura del encabezado no válida.",
    metaErrorCode2050: "Empresa no proporcionó la información relativa al cumplimiento de los requisitos.",

    metaErrorCode131026:
        "El mensaje no pudo ser entregado: Puede que el número de teléfono receptor no esté en WhatsApp, el receptor no ha aceptado las políticas de WhatsApp o su versión de WhatsApp no está actualizada.",
    metaErrorCode133010:
        "Número de teléfono no registrado: El número de teléfono no está registrado en la plataforma de WhatsApp Business.",
    metaErrorCode135000:
        "Error de usuario: El mensaje no se pudo envíar debido a un error desconocido en los parámetros de la solicitud. Por favor vuelve a intentarlo más tarde.",
    metaErrorCode132016:
        "Mensaje de inicio desactivado: Este mensaje de inicio ha sido reportado demasiadas veces y se ha desactivado. Por favor crea un nuevo mensaje de inicio.",
    metaErrorCode132015:
        "Mensaje de inicio en pausa: Este mensaje de inicio ha sido pausado por baja calidad. Por favor, contacta con servicio al cliente para editarlo o crea un nuevo mensaje de inicio.",
    metaErrorCode130472:
        "Error de experimento: Este cliente ha sido seleccionado para un experimento de Meta (un 1% de los números de teléfono son seleccionados al azar para experimentos). No podrás enviar mensajes de inicio de tipo Marketing a no ser que previamente hayan aceptado recibirlos. Por favor, intenta otro tipo de mensaje de inicio.",
    metaErrorCode131056:
        "Limite de frecuencia alcanzado: Se han enviado demasiados mensajes a este número de teléfono. Por favor, intenta más tarde.",
}

export default metaErrorMessages
