const messages = {
    choosePlan: "Choose a plan",
    changePlan: "Change plan",
    youHaveCompletedTheSetUp: "You have completed your SetUp and Demo 🥳!",
    youNeedToWaitForWA: "Now we have to wait for WhatsApp to approve your account 😓.",
    wellLetYouKnowWhenIstReady: "We'll let you know when it's ready 😊!",
    confirmYourSubscription: "Confirm your subscription",
    selectYourSalesRange: "Select your sales range",
    betweenXAndYSales: "Between {x} and {y} USD a month",
    fromXSales: "More than {x} USD a month",
    toXSales: "Less than {x} USD a month",
    forUpToXUsers: "For up to {user_amount} users. Each additional user carries a cost of {user_price} USD/month",
    confirmXPlan: "Confirm subscription to plan {x} 😁",
    youWillBeRedirectToShopify: "You will be redirected to Shopify, please wait 😉.",
    anErrorOcurredWithTeRedirection: "Oops! Something went wrong. Please try again 😅.",

    connectToAPlatform: "Connect to chat platforms",
    welcomeToCheetrack: "Thanks for installing Cheetrack 🥳!",
    welcomeToCheetrackSubtitle:
        "We just need to connect a few things 🔌 to start with your first WhatsApp Marketing campaign.",
    welcomeToCheetrackDesc1: "Our clients multiply their investment in Cheetrack from 10 to 37 times!",
    welcomeToCheetrackDesc2: "To begin, we need you to confirm your subscription and connect us to your social media.",
    whatsAppConnectionOff:
        "You'll be able to chat with your clients on WhatsApp, send and receive messages and files, and set up chases and automatic responses.",
    facebookConnectionOff:
        "Allows you to responde to messages from Facebook and Instagram, as well as respond to comments and mentions on stories.",
    smsConnectionOff:
        "You'll be able to send and receive SMS messaging, and use this channel to chase clients and make campaigns.",
    whatsAppConnectionPending: "We are waiting for WhatsApp to approve your account 😊.",
    smsConnectionPending: "Your SMS number is being created and we're waiting for it's approval 😅.",
    facebookConnectionOn: "You are connected to Facebook e Instagram 😁!",
    whatsAppConnectionOn: "Your WhatsApp has been approved and it's ready to be used in Cheetrack 🤩!",
    smsConnectionOn: "You can send SMS on Cheetrack 😀!",
    clickHereForHelp: "Need help? Click here to talk to us! 🤓",
    clickHereToScheduleAMeeting: "Or click here if you need to schedule a meeting 🕐.",
    theFirst14DaysAreFree: "The first 14 days are free, and you can cancel at any time.",

    basicPlan: "Basic plan",
    advancedPlan: "Advanced plan",
    enterprisePlan: "Enterprise plan",
    premiumPlan: "Premium plan",
    thanksForConfirmingYourPlan: "Thanks for confirming your subscription 😁!",
    freeUsers: "users included.",
    extraUsersForXCost: "users for {x} additional.",
    yourPlan: "Your plan",

    youAreReadyToUseCheetrack: "You are ready to use Cheetrack 🎉!",
    goToTheMainScreen: "Go to the main screen",
    plusXUsdForAutomatizedChaseMessage: "+ {x} USD for automatized message within a chase.",
    onlyAnAdminUserCanMakeChanges: "Only an admin user can make changes!",
    clickAPlatformToConnect: "Click on a platform to connect it to Cheetrack:",

    youAreCloserToYourFirstCampaign: "You are closer to your first campaign 🎉! How do you want to continue?",
    relaxAndChill: "Relax and chill",
    relaxAndChillDesc: "Set a meeting with one of our experts 🤓 and let us help you with everything.",
    missionImpossible: "Mission impossible",
    missionImpossibleDesc: "Do it by yourself using our tutorials 📚.",
    youMustConfirmYourPlan: "You must confirm your plan before continuing.",
}

export default messages
