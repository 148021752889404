const messages = {
    templateSubHeader:
        "WhatsApp limits you, as a company, to only be able to start conversations using messages approved by them.",
    templateSubHeader2: "Here you can create start messages and ask for WhatsApp's approval.",
    templateSubHeader3:
        "Once your start message is approved by WhatsApp you can send them to your clients, and if you get a response, a 24-hour window will open up that'll allow you to chat freely.",
    templateSubHeader4:
        "After that 24-hour window closes, you'll be once again be limited to only sending approved start messages to that client.",
    templateHeader: "Start messages",
    templateInitButton: "I understand",
    templateInitDesc1:
        "You can only start conversations with clients with start message. Here you can create your own start messages, ",
    templateInitDesc2: "which will be reviewed ⌛ by",
    templateInitDesc3: "We give you a set of start messages so that you can now start chasing customers.",
    createdBy: "Created by",
    status: "Status",
    shortName: "Name",
    createNewDialogDesc: "This name will help you to recognize the start message faster.",
    templateMessage: "Start message",
    createNewDialogDesc1: "You can include some of the following variables on your message: ",
    createNewDialogDesc2:
        "You can also create your own variables, using double brackets and giving them a name, for example: ",
    createNewDialogDesc3: ". You'll be asked to replace them when sending the start message.",
    saveDraft: "Save draft",
    sendApproval: "Send for approval",
    confirmTempDialogTitle: "Start message waiting for approval",
    confirmTempDialogDesc:
        "We sent your start message to WhatsApp for approval. This could take a while ⏳, we'll let you know when it's approved.",
    getIt: "I get it",
    createMsgTemplate: "Create start message",
    editMsgTemplate: "Edit start message",
    deleteConfirmMsg1: "This action will remove the start message",
    deleteConfirmMsg2:
        "This start message will no longer be available to start a conversation with your customers and in case you want to use it again you must create it and send it to WhatsApp to validate.",
    deleteConfirmMsg3: "Are you sure you want to delete this start message?",
    deleteConfrimBtn: "Yes, delete start message",
    updateDraft: "Update draft",
    updateTemplate: "Update start message",
    characters: "Characters",
    templateShortName: "Name",
    templateTooltipShortName: "This name will help you to recognize the start message faster.",
    templateTemplateMessage: "Start message",
    templateTooltipTemplateMessage: `With this message you will start a conversation with your clients.\n \nYou can enter variables with a double brace`,
    templateCreatedBy: "Created by",
    templateTooltipCreatedBy: "",
    templateStatus: "Status",
    templateTooltipStatus: "Start messages must be approved by WhatsApp. Here we show you the status of the approval.",
    templateFilteredCharacterMessage:
        "Only lowercase letters(a-z), numbers(0-9) and underscore (_) are allowed on the start message's name.",
    templateNameDupe: "You have already used that start message name",
    searchTemplate: "Search start message",
    hideRejectedTemplates: "Hide rejected messages",
    creationDate: "Created at",
    tipsForWhatsAppApproval: "Tips for WhatsApp's approval:",
    checkOurTips: "Check our tips for WhatsApp's to approve your start messages! >",
    templateTip1: "At least 8 words long.",
    templateTip2: "Must not be a poll.",
    templateTip3: "Clearly state the message's purpose.",
    templateTip4: "A greeting message must have a purpose besides greeting.",
    templateTip5: "Avoid subscription content.",
    templateTip6: "If you are selling products or promoting a campaign, you have to comply with ",
    whatsappCommercePolicy: "WhatsApp Commerce Policy",
    replaceModalTitle: "The message has custom variables you need to replace",
    replaceModalPreview: "Message preview:",
    replaceModalInstruction: "Replace the following variables:",
    templateContent: "Message content",
    templateWordLenghtWarning: "Your start message must contain at least 7 words",
    templateCharLenghtWarning: "Your start message must contain at least 50 characters",
    templateSellsWarning: "If your start message contains products, promotions or campaigns it must comply with ",
    templatePollWarning: "Your start message might be rejected if it contains polls",
    templateSubscriptionsWarning: "Your start message might be rejected if it contains subscription content",
    templateForbiddenProductWarning:
        "Your start message will be rejected if it offers products or services related to ",
    illegalProducts: "illegal products",
    drugs: "drugs",
    tabacco: "tabaco",
    alcohol: "alcohol",
    unsafeSuplements: "unsafe supplements",
    gunsAmmosOrExplosives: "guns, ammunition or explosives",
    animals: "livestock, protected species or animal parts",
    adultProductsOrServices: "adult products or services",
    bodyPartsOrFluids: "body parts or fluids",
    healthProducts: "health and medic products",
    productsWithSexualConnotation: "products with sexual connotation",
    realMoneyBet: "real money betting",
    dateServices: "date services",
    unauthorizedAccesToDigitalMedia: "unauthorized access to digital media",
    offensiveFraudulentDeceivingOrExploitativeCommercialModels:
        "offensive, fraudulent, deceiving or exploitative commercial models",
    realVirtualOrFakeMoney: "real, virtual or fake money",
    forgeryOrPiracy: "forgery or piracy",
    templateCloseVariablesWarning:
        "Your start message has variables too close to each other, please consider unifying them or using them differently",
    templatesCannotBeEditedWarning: "Start messages cannot be edited after being sent to WhatsApp.",
    templatesOpenVariableWarning: "You seem to have a variable lacking a bracket, please use the format: ",
    templatesSingleBracketsVariableWarning:
        "You seem to have a variable written with single brackets instead of double brackets, please use the format: ",
    templatesVarTypoWarning:
        "You wrote {customVariable}, did you meant to use the default variable: {defaultVariable}?",
    approvalCanTake2Days: "WhatsApp reviewing process may take up to 2 business days.",
    templateType: "Type",
    previewMessage: "See message preview",
    templateContainsHtmlWarning: "Your start message cannot contain HTML code.",
    emptyVariablesWarning: "There seems to be an empty variable, please give it a name or number.",
    urlVariablesWarning:
        "Your variables cannot contain URLs, please give the variable a name, which you can later replace it with a URL when sending the message to a client.",
    variablesWithSpacesWarning: "Variables cannot contain spaces, please use - or _ instead.",
    anonymousCheetah: "Anonymous Cheetah",
    templateCannotStartWithVariableWarning: "Your start message cannot start with a variable.",
    templateCannotEndWithVariableWarning: "Your start message cannot end with a variable.",
    templateThreatsWarning:
        "Your start message might be rejected if it contains threats of legal action or public humiliation.",
    templateAsksForIDWarning:
        "Your start message might be rejected if it asks for a client's identification documents.",
    templateHasTooManyLineSkips: "You start message might be rejected if it contains too many line breaks.",
    templateHasTooManyVariables:
        "Your start message may have too many variables for it's content, it might be rejected if it's purpose is unclear.",
    templateNoType: "Regular",
    templateDiscountCouponType: "Shopify - Discount coupon",
    templateAbandonedCheckoutType: "Shopify - Abandoned checkout",
    templateSegments: "Segments",
    templateTypeNeedsVariables: "That type of start message requires the variables: ",
    templateSmsMessagesWillInclude: "SMS messages will also include automatically: ",
    smsStopMessage: "Reply STOP to stop receiving messages.",
    smsLengthRecommendation: "We recommend SMS to be under {x} characters.",
    templateSource: "Channel",
    templateCostToSend: "Cost of sending this message: {x}",
    templateSuggestionStopMessage:
        'We suggest you include the phrase: Reply "no more messages" to not receive messages. Cheetrack wil detect these answers and stop sending automatic messages to those clients.',
    includeTemplateFile: "Include file",
    templateFileFormat: "JPG, PNG, MP4 or PDF.",

    addTemplateButton: "Add button",
    templateButton: "Button",
    templateButtonType: "Type",
    templateButtonText: "Button Text",
    templateButtonQuickReply: "Quick reply",
    templateButtonUrl: "Button URL",
    templateButtonName: "Button Name",
    templateFlowsTitle: "Add Flows to facilitate interaction with customers",
    templateButtonPhoneNumber: "Phone number",
    templateButtons: "Buttons",
    templateButtonsDescription:
        "Add buttons to your start message to allow your clients to answer with a single click or visit a URL.",
    cantUseVariablesInButtonText: "You can't use variables in the button text.",
    noTemplateButtons: "No buttons",
    dynamicUrl: "Dynamic URL",
    staticURL: "Static URL",
    templateButtonCallToAction: "Call to action",
    dynamicUrlDescription: "The URL will include a variable at the end, for example: ",

    welcomeMessageTemplateContent:
        "Hello {{client_first_name}}! You are now parte of the {{my_company}} family🌟 \nWe hope you like our products and that we get to know each other better. \nAnything you need, don't hesitate to text us through here.",
    welcomeMessageTemplateName: "Welcome message",
    abandonedCheckoutTemplateContent:
        "Hi {{client_first_name}} we are texting you from {{my_company}}🌟 \nWe noticed you left your favorite products behind! If i were you I'll go get it!",
    abandonedCheckoutTemplateName: "Abandoned checkout",
    newProductLaunchTemplateName: "New product launch",
    newProductLaunchTemplateContent:
        "Hi {{client_first_name}} we are texting you from {{my_company}}🌟 \nWe would like to tell you a secret 🤫 \nWe are launching a new product with limited stock, and would like to tell you before any others 😁 \n👀 We'll leave a link to our catalog: ",
    discountCouponTemplateContent:
        "Hello {{client_first_name}}, from {{my_company}} we would like to give you a little gift 🎁. \nHere's a {{discount_amount}}% discount on your next purchase using the code {{discount_code}}. \nWe hope to see you again soon!",
    discountCouponTemplateName: "Discount code",
    reengageCustomerTemplateContent:
        "🤗 {{client_first_name}}, on {{my_company}} we miss you 🌟 \nIt's been some time since you last saw our products and we hope you haven't forgotten about us. Check our catalog to see all the offers we have for you!",
    reengageCustomerTemplateName: "Client reengage",
    abandonedCheckoutNoBtnsTemplateContent:
        "Hi {{client_first_name}} we are texting you from {{my_company}}🌟 \nWe noticed you left your favorite products behind! \n\nClick the link to go get them: {{abandoned_checkout_link}}\n\nDo it before it's too late! ",

    seeYourAbandonedCheckout: "Go to my abandoned checkout",
    seeOurCatalog: "Check our catalog",
    templateTemplate: "Template",
    noTemplate: "No template",
    anErrorOcurredCreatingThisTemplate: "An error ocurred creating this template.",
    templateCategory: "Category",
    rejectReasonINCORRECT_CATEGORY: "The chosen category was incorrect, please try again with a different one.",
    rejectReasonSCAM: "The message was flagged as a scam, please change it and try again.",
    rejectReasonINVALID_FORMAT: "The message has format issues, please change it and try again.",
    rejectReasonABUSIVE_CONTENT: "The message was flagged as possibly abusive, please change it and try again.",

    restaurantMenuTemplateName: "Restaurant menu",
    restaurantMenuTemplateContent: "Welcome to {{my_company}}! \nYou can see our menu by clicking the button: ",
    seeTheMenu: "See the menu",
    youCanOnlyHaveUpTo80Templates: "You can only have up to 80 start messages.",
    pleaseDeleteSomeTemplates: "You need to delete some start messages to create a new one.",
}

export default messages
