import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

const initialState = {
    user: {},
    tenant: {},
    role: {},
    sessionId: uuidv4(),
    loadingUser: false,
    loadingTenant: false,
    loadingRole: false,
    userError: false,
    dailyClientCount: 0,
    initializingApp: true,
    tenants: [],
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        startAllLoaders(state) {
            state.loadingRole = true
            state.loadingTenant = true
            state.loadingUser = true
            state.userError = false
        },
        endAllLoaders(state) {
            state.loadingRole = false
            state.loadingTenant = false
            state.loadingUser = false
            state.initializingApp = false
        },
        setUser(state, action) {
            state.user = action.payload
        },
        setTenant(state, action) {
            state.tenant = { ...action.payload, loaded: true }
            state.loadingTenant = false
            if (action.payload.new_users_last_24_hours)
                state.dailyClientCount = parseInt(action.payload.new_users_last_24_hours)
        },
        setRole(state, action) {
            state.role = action.payload
        },
        userError(state) {
            state.userError = true
        },
        loadTenant(state) {
            state.loadingTenant = true
        },
        stopLoadingTenant(state) {
            state.loadingTenant = false
        },
        loadRole(state) {
            state.loadingRole = true
        },
        stopLoadingRole(state) {
            state.loadingRole = false
        },
        setTenants(state, action) {
            state.tenants = action.payload
        },
    },
})

export const userActions = userSlice.actions
export default userSlice
