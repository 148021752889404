// Retorna la diferencia en días entre dos fechas
function getTimeDiffInDays(a, b) {
    const dailyMS = 1000 * 60 * 60 * 24,
        dateA = new Date(a),
        dateb = new Date(b),
        utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate()),
        utc2 = Date.UTC(dateb.getFullYear(), dateb.getMonth(), dateb.getDate())

    return Math.floor((utc2 - utc1) / dailyMS)
}

export default getTimeDiffInDays
