const messages = {
    satisfaction: "Satisfacción",
    goodExperience: "Buena experiencia",
    badExperience: "Mala experiencia",
    doesntKnowDoesntAnswer: "Fuera de alternativas",
    satisfactionDesc:
        "Aquí puedes ver las respuestas de tus clientes a {x} sondeos de satisfacción envíados entre el {from} y el {to}.",
    youDontHaveAnySatisfactionSurveys: "No tienes ningún sondeo de satisfacción",
    noDataFoundForThisDateRange: "No se encontraron datos para este rango de fechas",
    clientAnswers: "Respuestas de clientes",
    answersSummary: "Resumen de respuestas",
    badExperiencesBreakdown: "Desglose de malas experiencias",
    answersByDay: "Respuestas por día",
    obtainingSatisfactionData: "Obteniendo datos de satisfacción...",
    feedback: "Comentarios",
    customerFeedback: "Comentarios de clientes",
    anErrorOcurredObtainingSatisfactionData:
        "Ocurrió un error obteniendo los datos de satisfacción, por favor intenta nuevamente.",
    anErrorOcurredObtainingFeedbackData:
        "Ocurrió un error obteniendo los datos de comentarios, por favor intenta nuevamente.",
    noFeedbackFound: "No se encontraron comentarios para este rango de fechas.",
    dataUpdatedevery3Hours: "Los datos se actualizan cada 3 horas.",
    commentsBeforeThe25OfSeptember2023AreNotAvailable:
        "Los comentarios antes del 26 de Septiembre de 2023 no están disponibles.",
    xClientsDidNotSendFeedbackOrSentIncompleteFeedback:
        "{x} clientes no comentaron o enviaron comentarios de menos de 2 carácteres.",
}

export default messages
