import uuid from "react-uuid"

// Retorna la secuencia de cheetabot por defecto
export function getDefaultCheetabotSequence(includeStartMessage, includeStartMessageFlow = false) {
    const defaultSequence = [
        {
            type: "questionWithMessage",
            isOpen: false,
            prompt: "",
            optionSelected: "create_note",
            childOptions: [],
            id: uuid(),
            required: true,
            childQuestion: [],
            typeInput: "AMAZON.AlphaNumeric",
        },
        {
            type: "questionWithOptions",
            prompt: "",
            stringPrompt: "",
            stringPromptWithOutFormat: "",
            optionSelected: "show_submenu",
            childOptions: [
                { optionSelected: { option: "assign_tag", childSelected: "null" }, prompt: "", id: uuid() },
                { optionSelected: { option: "create_note", childSelected: "null" }, prompt: "", id: uuid() },
            ],
            id: uuid(),
            required: true,
            typeInput: "AMAZON.Number",
            childQuestion: [],
        },
        {
            type: "message",
            options: [],
            prompt: "",
            id: uuid(),
            required: true,
        },
    ]

    if (includeStartMessage)
        defaultSequence.splice(2, 0, {
            type: "template",
            prompt: "",
            optionSelected: "send_template",
            id: uuid(),
            required: true,
            typeInput: "AMAZON.AlphaNumeric",
            template_pk: undefined,
        })

    if (includeStartMessageFlow)
        defaultSequence.splice(2, 0, {
            type: "templateFlow",
            prompt: "",
            optionSelected: "send_template_flow",
            id: uuid(),
            required: true,
            typeInput: "AMAZON.AlphaNumeric",
            template_pk: undefined,
        })

    defaultSequence.forEach((item) => {
        item.name = "question_" + item.id

        if (item.childOptions && item.childOptions.length > 0)
            item.childOptions.forEach((child) => {
                child.name = "question_" + child.id
            })
    })

    return defaultSequence
}

export function getCheetabotSubmenuDefaultOptions() {
    return [
        {
            optionSelected: { option: "assign_tag", childSelected: "null" },
            id: uuid(),
            prompt: "",
            required: true,
        },
        {
            optionSelected: { option: "go_back", childSelected: "go_back" },
            id: uuid(),
            prompt: "Go Back",
            disabled: true,
        },
    ]
}

export function getDefaultCheetabotSequenceFlows(includeStartMessage, includeStartMessageFlow = false) {
    const defaultSequence = [
        {
            type: "templateFlow",
            prompt: "",
            optionSelected: "send_template_flow",
            id: uuid(),
            required: true,
            typeInput: "AMAZON.AlphaNumeric",
            template_pk: undefined,
        },
        {
            type: "questionWithMessage",
            isOpen: false,
            prompt: "",
            optionSelected: "create_note",
            childOptions: [],
            id: uuid(),
            required: true,
            childQuestion: [],
            typeInput: "AMAZON.AlphaNumeric",
            action: "success",
        },
        {
            type: "questionWithMessage",
            isOpen: false,
            prompt: "",
            optionSelected: "create_note",
            childOptions: [],
            id: uuid(),
            required: true,
            childQuestion: [],
            typeInput: "AMAZON.AlphaNumeric",
            action: "error",
        },
        {
            type: "questionWithMessage",
            isOpen: false,
            prompt: "",
            optionSelected: "create_note",
            childOptions: [],
            id: uuid(),
            required: true,
            childQuestion: [],
            typeInput: "AMAZON.AlphaNumeric",
            action: "warning",
        },
        {
            type: "message",
            options: [],
            prompt: "",
            id: uuid(),
            required: true,
        },
    ]
    return defaultSequence
}
