const messages = {
    choosePlan: "Seleccionar plan",
    changePlan: "Cambiar plan",
    youHaveCompletedTheSetUp: "¡Has completado tu SetUp y Demo 🥳!",
    youNeedToWaitForWA: "Ahora queda esperar a que WhatsApp apruebe tu cuenta 😓.",
    wellLetYouKnowWhenIstReady: "¡Te avisaremos apenas esté lista 😊!",
    confirmYourSubscription: "Confirma tu suscripción",
    selectYourSalesRange: "Selecciona tu rango de ventas",
    betweenXAndYSales: "Entre {x} y {y} USD al mes",
    fromXSales: "Más de {x} USD al mes",
    toXSales: "Menos de {x} USD al mes",
    forUpToXUsers: "Hasta {user_amount} usuarios. Cada usuario adicional tiene un costo de {user_price} USD/mes",
    confirmXPlan: "Confirmar suscripción a plan {x} 😁",
    youWillBeRedirectToShopify: "Serás redirigido a Shopify, por favor espera 😉.",
    anErrorOcurredWithTeRedirection: "Ups! Algo salió mal. Por favor vuelve a intentarlo 😅.",

    connectToAPlatform: "Conecta con plataformas de chat",
    welcomeToCheetrack: "¡Gracias por instalar Cheetrack 🥳!",
    welcomeToCheetrackSubtitle:
        "Sólo hace falta conectar un par de cables 🔌 para comenzar tu primera campaña de WhatsApp Marketing.",
    welcomeToCheetrackDesc1: "Nuestros clientes multiplican su inversión en Cheetrack entre 10 y 37 veces!",
    welcomeToCheetrackDesc2:
        "Para empezar, necesitamos que confirmes tu suscripción y que conectes Cheetrack a tus redes sociales.",
    whatsAppConnectionOff:
        "Podrás comunicarte con tus clientes a través de WhatsApp, enviar y recibir mensajes y archivos, y programar persecuciones y mensajería automática.",
    facebookConnectionOff:
        "Te permite responder a mensajes en Facebook e Instagram, así como también responder a comentarios y menciones en stories.",
    smsConnectionOff:
        "Podrás enviar y recibir mensajes de tipo SMS, y usar este medio para hacer persecución o para enviar campañas.",
    whatsAppConnectionPending: "Estamos esperando a recibir la aprobación de tu cuenta de WhatsApp 😊.",
    smsConnectionPending:
        "Tu número de teléfono para SMS está siendo creado y estamos a la espera de su aprobación 😅.",
    facebookConnectionOn: "Ya estás conectado a Facebook e Instagram 😁!",
    whatsAppConnectionOn: "Tu cuenta de WhatsApp ha sido aprobada y está lista para su uso en Cheetrack 🤩!",
    smsConnectionOn: "Ya puedes usar SMS en Cheetrack 😀!",
    clickHereForHelp: "¿Necesitas ayuda? Haz click aquí para hablar con nosotros 🤓",
    clickHereToScheduleAMeeting: "O haz click aquí si necesitas agendar una reunión 🕐.",
    theFirst14DaysAreFree: "Los primeros 14 días son gratis, y puedes cancelar en cualquier momento.",

    basicPlan: "Plan Basic",
    advancedPlan: "Plan Advanced",
    enterprisePlan: "Plan Enterprise",
    premiumPlan: "Plan Premium",
    thanksForConfirmingYourPlan: "Gracias por confirmar tu suscripción 😁!",
    freeUsers: "usuarios incluidos.",
    extraUsersForXCost: "usuarios por {x} adicional.",
    yourPlan: "Tu plan",

    youAreReadyToUseCheetrack: "Estás listo para usar Cheetrack 🎉!",
    goToTheMainScreen: "Ir a la página principal",
    plusXUsdForAutomatizedChaseMessage: "+ {x} USD por mensaje automatizado dentro de una persecución.",
    onlyAnAdminUserCanMakeChanges: "Sólo un usuario admin puede hacer cambios!",
    clickAPlatformToConnect: "Haz click en una plataforma para conectarte:",

    youAreCloserToYourFirstCampaign: "¡Estás más cerca de tu primera campaña 🎉! ¿Cómo quieres continuar?",
    relaxAndChill: "Relájate y disfruta",
    relaxAndChillDesc: "Organiza una reunión con uno de nuestros expertos 🤓 y déjanos configurar todo por ti.",
    missionImpossible: "Misión imposible",
    missionImpossibleDesc: "Hazlo por tu cuenta, guíado por nuestros tutoriales 📚.",
    youMustConfirmYourPlan: "Debes confirmar tu plan antes de continuar.",
}

export default messages
