// Verifica si el bot es publicable
// Retorna un objeto con la información del error/razón por la que el bot no es publicable

export function checkIfBotIsPublishable(bot, questionContent) {
    const { sequence, type, name, utterances } = bot
    const error = {
        text: "",
        questionId: null,
    }

    if (name === "") error.text = "noName"
    else if (type === "keyword" && utterances.length === 0) error.text = "noUtterances"
    else if (sequence.length === 0) error.text = "noQuestions"
    else if (sequence.length === 1 && sequence[0].type === "message") error.text = "onlyClosingMessage"
    else {
        for (let i = 0; i < sequence.length; i++) {
            const question = questionContent[sequence[i].id]

            function checkQuestionChildren(question, questionContent, parentIndex) {
                const checkArr = (arr) =>
                    arr.forEach((child, index) =>
                        checkBotQuestion(child, questionContent, parentIndex + "." + (index + 1))
                    )

                if (question.childOptions) checkArr(question.childOptions)
                if (question.optionSelected?.childOptions) checkArr(question.optionSelected.childOptions)
                if (question.buttons) checkArr(question.buttons)
                if (question.actions)
                    question.actions.forEach((child) => checkTemplateAction(child, questionContent, parentIndex))
            }

            function checkTemplateAction(id, questionContent, index) {
                const a = questionContent[id]

                if (error.text) return

                if (a.type === "send_template" && !a.template_pk) error.text = "noTemplateSelected"
                else if (a.type === "send_link" && !a.text) error.text = "noLink"
                else if (a.type === "end_sequence" && !a.tag_id) {
                    error.text = "noTagSelected"
                } else if (a.type === "end_sequence" && (!a.end_message || a.end_message === "") && !a.template_pk)
                    error.text = "noEndMessage"
                else if (a.type === "feedback" && !a.tag_id) {
                    error.text = "noTagSelected"
                } else if (a.type === "feedback" && (!a.feedback_message || a.feedback_message === "")) {
                    if (!a.optionSelected) {
                        error.text = "noFeedbackMessage"
                    }
                    if (a.optionSelected && a.optionSelected.options && a.optionSelected.options.length > 0) {
                        if (!a.optionSelected.options[0].tag_id || !a.optionSelected.options[0].tag_id === "") {
                            error.text = "noTagSelected"
                        }
                        if (
                            !a.optionSelected.options[0].feedback_message ||
                            !a.optionSelected.options[0].feedback_message === ""
                        ) {
                            error.text = "noFeedbackMessage"
                        }
                    }
                } else if (a.type === "assign_tag" && !a.tag_id) {
                    error.text = "noTagSelected"
                } else if (a.type === "assign" && !a.user_id) error.text = "noAgentSelected"
                else if (a.type === "assign_user_group" && !a.group_tag) error.text = "noAgentGroupSelected"

                if (error.text) {
                    error.questionId = id
                    error.questionIndex = index
                } else checkQuestionChildren(a, questionContent, index)
            }

            function checkBotQuestion(id, questionContent, index) {
                const q = questionContent[id]

                if (error.text || !q) return
                if (q.type === "template" && !q.template_pk) error.text = "noTemplateSelected"
                else if ((q.prompt === "" || !q.prompt) && (q.button_answer === "" || !q.button_answer)) {
                    if (q.type === "message") error.text = "emptyCloseMessage"
                    else if (typeof index === "string" || index instanceof String) error.text = "emptyOption"
                    else error.text = "emptyQuestion"
                } else if (q.prompt && typeof q.prompt === "string" && q.prompt.match(/\{{(.+?)\}}/g)?.length > 0) {
                    error.text = "noVariablesAllowed"
                } else if (
                    q.type === "questionWithMessage" &&
                    (!q.optionSelected || q.optionSelected?.option !== "feedback")
                ) {
                    if (q.optionSelected === "" || !q.optionSelected) error.text = "noActionSelected"
                    if (q.typeInput === "" || q.typeInput == null) error.text = "noExpectedAnswer"
                    if (q.optionSelected === "assign_tag" && (q.tag_id === "" || !q.tag_id))
                        error.text = "noTagSelected"
                } else if (q.type === "questionWithOptions") {
                    if (q.childOptions.length === 0) error.text = "noOptions"
                } else if (q.optionSelected && q.optionSelected.option) {
                    if (q.optionSelected.option === "ShowSubMenu" && q.optionSelected.childOptions.length === 1)
                        error.text = "noOptions"
                    else if (
                        q.optionSelected.option === "CoordinateMeeting" &&
                        (!q.optionSelected.options || !q.optionSelected.options[0]?.link)
                    )
                        error.text = "noLink"
                    else if (
                        q.optionSelected.option === "end_sequence" &&
                        (!q.optionSelected.options ||
                            !q.optionSelected.options[0]?.end_message ||
                            q.optionSelected.options[0]?.end_message === "")
                    )
                        error.text = "noEndMessage"
                    else if (
                        q.optionSelected.option === "feedback" &&
                        (!q.optionSelected.options ||
                            !q.optionSelected.options[0]?.feedback_message ||
                            q.optionSelected.options[0]?.feedback_message === "")
                    )
                        error.text = "noFeedbackMessage"
                    else if (
                        (q.optionSelected.option === "end_sequence" || q.optionSelected.option === "feedback") &&
                        (!q.optionSelected.options || !q.optionSelected.options[0]?.tag_id)
                    )
                        error.text = "noTagSelected"
                    else if (
                        q.optionSelected.option === "assign_tag" &&
                        (q.optionSelected.input === "" || !q.optionSelected.input)
                    )
                        error.text = "noTagSelected"
                    else if (
                        q.optionSelected.option === "assign" &&
                        (q.optionSelected.input === "" || !q.optionSelected.input)
                    )
                        error.text = "noAgentSelected"
                    else if (
                        q.optionSelected.option === "assign_user_group" &&
                        (q.optionSelected.input === "" || !q.optionSelected.input)
                    )
                        error.text = "noAgentGroupSelected"
                    else if (
                        q.optionSelected.options &&
                        (
                            q.optionSelected.options[0]?.feedback_message ||
                            q.optionSelected.options[0]?.end_message ||
                            q.optionSelected.options[0]?.link ||
                            ""
                        ).match(/\{{(.+?)\}}/g)?.length > 0
                    ) {
                        error.text = "noVariablesAllowed"
                    }
                }

                if (error.text) {
                    error.questionId = id
                    error.questionIndex = index
                } else checkQuestionChildren(q, questionContent, index)
            }

            if (question) checkBotQuestion(question.id, questionContent, i + 1)

            if (error.text) break
        }
    }

    return error
}
