const messages = {
    dailyClients: "Interações diárias",
    dailyClientsDesc: "Veja quantas de suas interações passaram pelo fluxo do Cheetrack nos últimos dias.",
    clientsInTheLastXDaysCompared: "Interações de {from} a {to} comparadas com os {days} dias anteriores",
    thisPeriod: "Este período",
    previousPeriod: "Período anterior",
    last2Weeks: "Últimas 2 semanas",
    last4Weeks: "Últimas 4 semanas",
    seeCompiledData: "Ver dados compilados",
    seeClientsReachedByTemplate: "Ver interações alcançadas por mensagem de início",
    allDailyClients: "Todas as interações diárias",

    filterClients: "Filtrar interações",
}
export default messages
