// Retorna el tamaño de un archivo en formato legible
function getFileSize(size) {
    const bytes = Number(size)
    if (bytes > 999999) {
        return `${Math.round(bytes / 1000000)} MB`
    } else {
        return `${Math.round(bytes / 1000)} KB`
    }
}

export default getFileSize
