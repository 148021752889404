const messages = {
    fidelization: "Fidelization",
    fidelizationDesc: "Define a strategy to increase customer loyalty according to their shoping frequency.",
    fidelizationTimeframe: "Fidelization timeframe",
    condition: "Condition",
    reward: "Reward",
    couponCode: "Coupon code",
    couponUrl: "Coupon url",
    welcomeToFidelizationMessage: "Fidelization welcoming message, sent on first step",
    fidelizationDiscountMessage: "Fidelization reward message",
    addFidelizationStep: "Add fidelization step",
    deleteFidelizationStep: "Delete fidelization step",
    fidelizationSale: "Sale",
    fidelizationVisit: "Visit",
    salesDataObtainedThroughShopify: "Sales data obtained through Shopify.",
    visitDataObtainedThroughMenuMessageSent: "Visit data obtained through menu message sent.",
    addReward: "Add reward",
    thisMessageNeedsToBeApprovedBeforeStartingFidelization:
        "This message needs to be approved before activating this fidelization strategy.",
    previewOfFidelizationStartingMessage: "Preview of fidelization starting message",
    previewOfFidelizationRewardMessage: "Preview of fidelization reward message",
    restaurantView: "Restaurant View",
    fidelizationTemplateWaitingForApproval: "Fidelization template is waiting for approval",
    youNeedToWait3MinutesBeforeSavingAgain: "You need to wait 3 minutes before saving again",
    fidelizationTemplateWasRejected: "Fidelization template was rejected",

    obtainingFidelizationData: "Obtaining fidelization data...",
    fidelizationAnswersDesc:
        "If your average ticket per person is {average_ticket} Cheetrack helped you generate an estimated sale of   {total_ticket}",
    anErrorOcurredObtainingFidelizationData: "An error ocurred obtaining fidelization data.",
    couponsUsedByCustomers: "Coupons used by customers",
    couponsSent: "Coupons sent",
    couponsUsed: "Coupons used",
    estimatedSale: "Estimated sale",
    youNeedToSetUpFidelizationToSeeThisData: "You need to set up fidelization to see this data.",
    goToFidelizationSetUp: "Go to fidelization setup",
    dataCanTakeUpToADayToBeUpdated: "Data can take up to a day to be updated",
    clientsWhoExceededMaximumProfitsInThePeriod: "Clients who exceeded maximum profits in the period",
    totalBenefitsDeniedOnTheDay: "Total benefits denied on the day",
    showFirstBenefit: "Show first benefit",
    totalBenefitsUsed: "Total benefits used",
    doYouWantToActivateTheSatisfactionBot: "Do you want to triggear satisfaction bot?",
    benefitProgram: "Benefits program",
    clientList: "Client list",
    benefitsObtained: "Benefits obtained",
    globalVisits: "Global visits",
    showingInformationFromTo: "Showing information from {from} to {to}",
}

export default messages
