// Chequea los filtros de usuario para saber si un usuario debe ser mostrado o no
// Filtra los menajes del websocket de usuarios q no pasen el filtro

export const checkUserFilters = (client, filters) => {
    return (
        (!filters.user || filters.user === "none" || client.user === "USER#" + filters.user) &&
        (!filters.status ||
            filters.status === "all" ||
            (filters.status === "open" &&
                filters.status === client.status &&
                !client.metadata?.has_chase_active &&
                (!client.metadata?.has_chase || client.metadata?.has_chase?.length === 0) &&
                !client.metadata?.shopify?.has_chase_active) ||
            (filters.status === "archived" && filters.status === client.status) ||
            (filters.status === "hasBotActive" && client.metadata?.has_bot_active) ||
            (filters.status === "hasChaseActive" && client.metadata?.has_chase_active) ||
            (filters.status === "hasChaseActive" && client.metadata?.shopify?.has_chase_active)) &&
        (filters.answer === undefined || filters.answer === client.answer) &&
        !filters.query &&
        (!filters.tag || filters.tag === client.metadata?.tag?.name) &&
        (!filters.read || filters.read === client.read) &&
        (!filters.source ||
            filters.source === client.metadata.source ||
            (filters.source === "whatsapp" &&
                (client.metadata.source === "" ||
                    !client.metadata.source ||
                    client.metadata.source === "meta_whatsapp")))
    )
}
