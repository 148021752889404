import locales, { ES, EN } from "../locale"

let locale
const storedLoc = localStorage.getItem("locale"),
    browserLocale = navigator.language || navigator.userLanguage || null

if (storedLoc) locale = storedLoc
else if (browserLocale.startsWith("es")) locale = ES
else locale = EN

const intl = locales[locale],
    notificationAudio = new Audio("./notification.mp3")

// Envía notificación de mensaje del chat

export const sendChatNotification = (client, text) => {
    notificationAudio.play()

    let body = text,
        title = intl.formatMessage({ id: "newClientNotification" })

    if (client && client.metadata && client.metadata.firstname)
        title =
            intl.formatMessage({ id: "newMessageNotification" }) +
            client.metadata.firstname +
            " " +
            client.metadata.lastname

    if (!text) {
        if (client) body = "http://app.cheetrack.com/conversation/" + client.PK.replace("CLIENT#", "")
        else body = "http://app.cheetrack.com"
    }

    const options = {
        body,
        icon: "https://app.cheetrack.com/favicon.svg",
        onclick: (e) => {
            e.preventDefault()
            if (client)
                window.open("http://app.cheetrack.com/conversation/" + client.PK.replace("CLIENT#", ""), "_blank")
            else window.open("http://app.cheetrack.com", "_blank")
        },
    }

    try {
        const n = new Notification(title, options)
        setTimeout(n.close.bind(n), 5000)
        console.log("notification", n)
    } catch (e) {
        if (e.name === "TypeError") {
            try {
                const n = ServiceWorkerRegistration.showNotification(title, options)
                setTimeout(n.close.bind(n), 5000)
                console.log("notification", n)
            } catch (e) {
                console.error(e)
            }
        }
    }
}
