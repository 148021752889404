const messages = {
    visits: "Visits",
    visitsDescription: "Visits description",
    newClients: "New clients",
    existingClients: "Existing clients",
    thisPeriodClients: "This period clients",
    previousPeriodClients: "Previous period clients",
    dailyClientsFromTo: "Daily interactions from {from} to {to}",
    newClientsFromTo: "New unique clients from {from} to {to}",
    recurringClientsLast12Months: "Recurring clients in the last {x} months",
    recurringClientsLast12Weeks: "Recurring clients in the last {x} weeks",
    interactions: "Interactions",
    menusSent: "Menus sent",
    menusSentThisPeriod: "Menus sent this period",
    menusOpensThisPeriod: "Menus sent this period",
    menusSentPreviousPeriod: "Menus sent previous period",
    menusOpensPreviousPeriod: "Menus open previous period",
    menusSentFromTo: "Menus sent from {from} to {to}",
    menusOpensFromTo: "Menus open from {from} to {to}",
    menus: "Menus",
    answerRate: "Answer rate",
    dataUpdatesEveryMondayMorning: "Data updates every Monday morning.",
    onlyWeeksWithDataAvailableAreShown: "Only weeks with data available are shown.",
    xClientsFromWeekYReturnedOnWeekZ: "{x} clients from week {y} returned on week {z}",
    xNewClientsOnWeekY: "{x} new clients on week {y}",
    weeklyRecurrence: "Weekly recurrence",

    anErrorOcurredObtainingData:
        "An error ocurred obtaining some of the data, please change the date range or try again later.",
    dataForMenusBeforeThe13OfJulyIsNotAvailable: "Data for menus sent before July 13th 2023 is not available.",
}

export default messages
