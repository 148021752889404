// Opciones para dropdowns de minutos u horas

export const minutes = [
    {
        value: "0",
        label: "00",
    },
    {
        value: "1",
        label: "01",
    },
    {
        value: "2",
        label: "02",
    },
    {
        value: "3",
        label: "03",
    },
    {
        value: "4",
        label: "04",
    },
    {
        value: "5",
        label: "05",
    },
    {
        value: "6",
        label: "06",
    },
    {
        value: "7",
        label: "07",
    },
    {
        value: "8",
        label: "08",
    },
    {
        value: "9",
        label: "09",
    },
    {
        value: "10",
        label: "10",
    },
    {
        value: "11",
        label: "11",
    },
    {
        value: "12",
        label: "12",
    },
    {
        value: "13",
        label: "13",
    },
    {
        value: "14",
        label: "14",
    },
    {
        value: "15",
        label: "15",
    },
    {
        value: "16",
        label: "16",
    },
    {
        value: "17",
        label: "17",
    },
    {
        value: "18",
        label: "18",
    },
    {
        value: "19",
        label: "19",
    },
    {
        value: "20",
        label: "20",
    },
    {
        value: "21",
        label: "21",
    },
    {
        value: "22",
        label: "22",
    },
    {
        value: "23",
        label: "23",
    },
    {
        value: "24",
        label: "24",
    },
    {
        value: "25",
        label: "25",
    },
    {
        value: "26",
        label: "26",
    },
    {
        value: "27",
        label: "27",
    },
    {
        value: "28",
        label: "28",
    },
    {
        value: "29",
        label: "29",
    },
    {
        value: "30",
        label: "30",
    },
    {
        value: "31",
        label: "31",
    },
    {
        value: "32",
        label: "32",
    },
    {
        value: "33",
        label: "33",
    },
    {
        value: "34",
        label: "34",
    },
    {
        value: "35",
        label: "35",
    },
    {
        value: "36",
        label: "36",
    },
    {
        value: "37",
        label: "37",
    },
    {
        value: "38",
        label: "38",
    },
    {
        value: "39",
        label: "39",
    },
    {
        value: "40",
        label: "40",
    },
    {
        value: "41",
        label: "41",
    },
    {
        value: "42",
        label: "42",
    },
    {
        value: "43",
        label: "43",
    },
    {
        value: "44",
        label: "44",
    },
    {
        value: "45",
        label: "45",
    },
    {
        value: "46",
        label: "46",
    },
    {
        value: "47",
        label: "47",
    },
    {
        value: "48",
        label: "48",
    },
    {
        value: "49",
        label: "49",
    },
    {
        value: "50",
        label: "50",
    },
    {
        value: "51",
        label: "51",
    },
    {
        value: "52",
        label: "52",
    },
    {
        value: "53",
        label: "53",
    },
    {
        value: "54",
        label: "54",
    },
    {
        value: "55",
        label: "55",
    },
    {
        value: "56",
        label: "56",
    },
    {
        value: "57",
        label: "57",
    },
    {
        value: "58",
        label: "58",
    },
    {
        value: "59",
        label: "59",
    },
]
export const hours = [
    {
        value: "0",
        label: "00",
    },
    {
        value: "1",
        label: "01",
    },
    {
        value: "2",
        label: "02",
    },
    {
        value: "3",
        label: "03",
    },
    {
        value: "4",
        label: "04",
    },
    {
        value: "5",
        label: "05",
    },
    {
        value: "6",
        label: "06",
    },
    {
        value: "7",
        label: "07",
    },
    {
        value: "8",
        label: "08",
    },
    {
        value: "9",
        label: "09",
    },
    {
        value: "10",
        label: "10",
    },
    {
        value: "11",
        label: "11",
    },
    {
        value: "12",
        label: "12",
    },
    {
        value: "13",
        label: "13",
    },
    {
        value: "14",
        label: "14",
    },
    {
        value: "15",
        label: "15",
    },
    {
        value: "16",
        label: "16",
    },
    {
        value: "17",
        label: "17",
    },
    {
        value: "18",
        label: "18",
    },
    {
        value: "19",
        label: "19",
    },
    {
        value: "20",
        label: "20",
    },
    {
        value: "21",
        label: "21",
    },
    {
        value: "22",
        label: "22",
    },
    {
        value: "23",
        label: "23",
    },
]

export const TwelveHoursFormattedHours = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "13:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
]

export const TwentyfourHoursFormattedHours = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
]
