import makeStyles from "@mui/styles/makeStyles"
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        height: "100vh",
        flexDirection: "column",
    },
    logo: {
        width: "25vw",
        maxWidth: theme.spacing(66),
        minWidth: 180,
        marginBottom: theme.spacing(6),
    },
    textContainer: {
        paddingTop: theme.spacing(8),
        textAlign: "center",
    },
}))

export default useStyles
