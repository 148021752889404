import axios from "axios"
import { apiURL } from "../src/utils/apiURL"
const ApiClient = axios.create({
    baseURL: apiURL(),
})

ApiClient.interceptors.request.use(
    (req) => {
        if (ApiClient.defaults.headers.common["token"]) return req
        throw new Error("Token not available")
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default ApiClient
//this instance requires a token
