const messages = {
    addClients: "Agregar clientes",
    customerAgenda: "Agenda de clientes",
    addNewClient: "Agregar nuevo cliente",
    filters: "Filtros",
    search: "Buscar",
    newCustomerInformation: "Información del nuevo cliente",
    AddTheDetailsOfTheNewCustomerCheckTheNumbersThatWeSuggestFromYourConversationsWithUnsecuredPhones:
        "Agrega los datos del nuevo cliente. Revisa los número que te sugerimos desde tus conversaciones con teléfonos no guardados.",
    actions: "Acciones",
    thisActionWillRemoveTheClient: "Esta acción archivará la conversación con el cliente ",
    andAllTheRecordsAssociatedWithIt: " y todos los registros asociados a él.",
    areYouSureYouWantToDeleteThisClient: "¿Estás seguro que quiere archivar a este cliente?",
    yesDeleteClient: "Si, archivar cliente",
    editInformationFor: "Editar la información de",
    editAndUpdateTheInformationOfThisClient: "Edita y actualiza la información de este cliente",
    firstname: "Nombre",
    lastname: "Apellido",
    validationPassword: "La contraseña debe contener un carácter especial !#@$%+=._-, letras Aa-zZ y números 0-9",
    addClientDescription1: "Tus clientes son el centro de tu negocio",
    addClientDescription2:
        "Agrega un cliente, guardando su contacto e información y así podrás mantener una comunicación personalizada con cada uno.",
    strengthenTheRelationshipWithYourCustomers: "Potencia la relación con tus clientes",
    youAreJustFewClicksAwayTakeYourSalesOnWhatsAppToAnotherLevel:
        "Estas a pocos clicks llevar tus ventas en WhatsApp a otro nivel",
    deleteClient: "Eliminar cliente",
    addFirstClient: "Agregar mi primer cliente",
    addAnotherClient: "Agregar cliente y luego otro",
    seeArchived: "Ver Archivados",
    assignedTo: "Asignar a ",
    alertAfterCreated:
        "El cliente tardará algunos minutos en aparecer o actualizar en este listado y en la vista Atención clientes",
    accept: "Aceptar",
    created_at: "Fecha de creación",
    autoAssignNewClients: "Asignar nuevos clientes a ejecutivos de forma automática.",
    autoAssignDesc:
        "Activar esto automáticamente asignará los clientes que comienzan conversaciones contigo a uno de tus ejecutivos.",
    autoAssignDesc2:
        "Esta asignación será en tándem, es decir, se asignan los clientes a cada uno de tus ejecutivos hasta que todos sean asignados uno antes de comenzar de nuevo.",
    clientExists: "Ya tienes ese número de teléfono en tu lista de clientes",
    anErrorOcurredCreatingClient:
        "Ocurrió un error creando el cliente, por favor revisa la información o vuelve a intentarlo más tarde",
    anErrorOcurredUpdatingClient:
        "Ocurrió un error actualizando el cliente, por favor revisa la información o vuelve a intentarlo más tarde",
    clientsNotListed: "clientes no listados.",
    clientLimitDesc:
        "Hay un límite a la cantidad de clientes que podemos listar, puedes ver el resto usando el buscador.",
    ifYouNeedToCreateMoreThan50Clients: "Si necesitas crear más de 50 clientes avísanos por el chat de soporte.",
    importClientList: "Importar lista de clientes",
    createClientsOneAtATime: "Crear clientes de uno a la vez",
    importFile: "Seleccionar archivo",
    importFileFormat: "Formato: XLSX, XLS o CSV",
    importFileError: "Ocurrió un problema con el archivo, por favor selecciona otro.",
    reviewTheImportedData: "Revisa la información importada.",
    assignATagToClients: "Asigna una etiqueta a los {n} clientes",
    pairColumnHeadersAndProps:
        "Empareja los cabezales de las columnas de tu archivo con la información de tus clientes.",
    useThisRowAsHeader: "Usar la siguiente fila como cabezal",
    clientInformation: "Dato del cliente",
    columHeader: "Cabezal de columna en tu archivo",
    continueToPreviewData: "Continuar a previsualización",
    saveClientList: "Guardar {n} clientes listados",
    selectTag: "Seleccionar etiqueta",
    youHaveXClientsWithIssues:
        "Tienes {n} clientes con números de teléfono inválidos, si lo guardas como están puede que tengas problemas enviándoles mensajes.",
    weCouldntSaveXClientsWithIssues: "No pudimos guardar {n} clientes, puedes revisarlos y volver a enviarlos.",
    weFoundXDuplicatedClients:
        "No guardamos {n} clientes cuyos números de teléfono estaban duplicados o ya tienes en tu agenda.",
    downloadTheseXClientsAsAnCSV: "Descargar estos {n} clientes como CSV",
    reviewDuplicatedClients: "Revisar los {n} clientes duplicados",
    weCorrectlySavedXClientes:
        "Guardamos con éxito {n} clientes. Los cuales aparecerán en la columna de conversaciones, esto puede tardar un tiempo.",
    hereYouCanUploadAFileWithClients: "Sube aquí un archivo que contenga información de tus clientes.",
    youWillBeAbleToPairTheInfo:
        "Una vez arriba, podrás ajustar las columnas de tu archivo para que coincidan con: nombre, apellido, compañía, teléfono e email de tus clientes.",
    theOnlyRequisiteIsAPhoneNumber:
        "El único dato obligatorio es el número de teléfono, por lo que tu archivo debe tener al menos esa columna.",
    ourBotCanCorrectXNumbers: "Nuestro Cheetabot puede corregir automáticamente {n} números de teléfono",
    clickHereToAllowTheBotToCorrectThem: "Haz click aquí para permitir a Cheetabot hacer correcciones",
    allTheNumbersHaveIssues: "Todos los números de teléfono tienen problemas, ¿elegiste la columna correcta?",
    backToPairingStep: "Volver a emparejar columnas",
    clientsAddedThisWayWillShowUpOnRelaod:
        "Los clientes agregados de esta forma pueden tardar unos minutos en aparecer.",
    clientsAddedMayNeedToBeFound:
        "👀 En el caso que tengas muchas conversaciones el día de hoy, los nuevos clientes no aparecerán en el tope de la columna de conversación. Los debes encontrar utilizando el buscador.",
    clientSource: "Medio",
    myClients: "Mis clientes",
    exportToCSV: "Exportar a CSV",
    firstVisit: "First visit",
    daysWithoutComingToToday: "Días sin venir al día de hoy",
    historicalVisits: "Visitas históricas",
    visitsDuringThePeriod: "Visitas en el periodo",
    validatedCoupons: "Cupones validados",
    goodExperiences: "Buenas experiencias",
    badExperiences: "Malas experiencias",
    numberOfVisits: "Cantidad Visitas",
    experiences: "Experiences",
    relativeTime: "Tiempo relativo",
    visitsFrom: "Visitas desde",
    visitsUntil: "Visitas hasta",
    myClients: "Mis clientes",
    exportToCSV: "Exportar a CSV",
    firstVisit: "Primera visita",
    daysWithoutComingToToday: "Días sin venir al día de hoy",
    historicalVisits: "Visitas históricas",
    visitsDuringThePeriod: "Visitas en el periodo",
    validatedCoupons: "Cupones validados",
    goodExperiences: "Buenas experiencias",
    badExperiences: "Malas experiencias",
    numberOfVisits: "Cantidad Visitas",
    experiences: "Experiencias",
    relativeTime: "Tiempo relativo",
    visitsFrom: "Visitas desde",
    visitsUntil: "Visitas hasta",
    searchClients: "Buscar clientes",
    daysAgoRelative: "Últimos {days} días",
    cantVisits: "Número de visitas",
    validatedCoupons: "Cupones validados",
    totalsClientsFiltered: "{number} clientes filtrados",
    numberClientsWithOneVisit: "{number} con 1 visita",
    numberClientsWithTwoVisit: "{number} con 2 visitas",
    numberClientsWithThreeVisit: "{number} con +3 visitas",
    numberCouponsValidates: "{number} con 3 visitas",
    numberValidatedCoupons: "{number} Cupones validados",
    numberGoodExperiences: "{number} Buenas experiencias",
    numberBadExperiences: "{number} Malas experiencias",
    numberTotalVisitsInRange: "{number}  clientes filtrados",
    descriptionNotFound: "No hemos encontrado resultados para los filtros aplicados, pruebe con otra selección.",
    applyFilters: "Aplicar",
    positive: "Buena",
    negative: "Mala",
    preparingCsv: "Preparando CSV",
}

export default messages
