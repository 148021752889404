import en_US from "./en-US"
import es_CL from "./es-CL"
import pt_BR from "./pt-BR"

export const ES = "es_CL"
export const EN = "en_US"
export const PT = "pt_BR"

const locales = {
    en_US,
    es_CL,
    pt_BR,
}

export default locales
