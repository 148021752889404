const messages = {
    first: "Primero",
    sheFirst: "Primera",
    second: "Segundo",
    sheSecond: "Segunda",
    third: "Tercero",
    sheThird: "Tercera",
    fourth: "Cuarto",
    sheFourth: "Cuarta",
    fifth: "Quinto",
    sheFifth: "Quinta",
    sixth: "Sexto",
    sheSixth: "Sexta",
    seventh: "Séptimo",
    sheSeventh: "Séptima",
    eighth: "Octavo",
    sheEighth: "Octava",
    ninth: "Noveno",
    sheNinth: "Novena",
    tenth: "Décimo",
    sheTenth: "Décima",
    eleventh: "Undécimo",
    sheEleventh: "Undécima",
    twelfth: "Duodécimo",
    sheTwelfth: "Duodécima",
    thirteenth: "Decimotercero",
    sheThirteenth: "Decimotercera",
    fourteenth: "Decimocuarto",
    sheFourteenth: "Decimocuarta",
    fifteenth: "Decimoquinto",
    sheFifteenth: "Decimoquinta",
    sixteenth: "Decimosexto",
    sheSixteenth: "Decimosexta",
    seventeenth: "Decimoséptimo",
    sheSeventeenth: "Decimoséptima",
    eighteenth: "Decimoctavo",
    sheEighteenth: "Decimoctava",
    nineteenth: "Decimonoveno",
    sheNineteenth: "Decimonovena",
    twentieth: "Vigésimo",
    sheTwentieth: "Vigésima",
}

export default messages
