const messages = {
    recommendedForYou: "Recomendado para você",
    welcomeToCheetrack: "Bem-vindo ao Cheetrack!",
    welcomeToCheetrackSubtitle: "Estamos felizes por tê-lo aqui",
    chooseACategoryToStart: "Escolha uma categoria para começar",
    shoppingCart: "Resumo do pedido",
    glutenFree: "Sem glúten",
    vegan: "Vegano",
    spicy: "Apimentado",

    catalogSetUp: "Configuração do catálogo",
    catalogSetUpSubtitle: "Configure seu catálogo para começar a vender",
    createProduct: "Criar produto",
    createCategory: "Criar/editar categorias",
    createSubcategory: "Criar subcategoria",
    productCategories: "Categorias de produtos",
    productSubCategories: "Subcategorias de produtos",
    productImage: "Imagem do produto",
    productName: "Nome do produto",
    productDescription: "Descrição do produto",
    catalog: "Catálogo",
    editProduct: "Editar produto",
    editCategory: "Editar categoria",
    addCategories: "Adicionar categorias",
    productPrice: "Preço do produto",
    productDetails: "Detalhes do produto",
    addCategory: "Adicionar categoria",
    subcategories: "Subcategorias",
    addSubcategory: "Adicionar subcategoria",
    thisCategoryNameAlreadyExists: "Este nome de categoria já existe",
    categoryName: "Nome da categoria",
    selectACategoryToEdit: "Selecione uma categoria para editar",
    categoryDetails: "Detalhes da categoria",
    saveNewCategory: "Salvar nova categoria",
}

export default messages
