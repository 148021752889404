const messages = {
    unassigned: "Não atribuído",
    myAssignments: "Minhas atribuições",
    all: "Todos",
    allFemale: "Todas",
    saveNumber: "Salvar número",
    conversationAssignedTo: "Conversa atribuída a",
    archiveConversation: "Arquivar conversa",
    openConversation: "Abrir conversa",
    chatInputPlaceholder: "Digite algo aqui",
    messageStartedAt: "Esta conversa foi iniciada em {data} às {hora}",
    reply: "Responder",
    resendMessage: "Reenviar a mensagem",
    remindMessage: "Lembrar-me disso mais tarde",
    highlightMessage: "Destacar mensagem",
    deleteMessage: "Excluir mensagem",
    reminderText: "Você me pediu para lembrá-lo de",
    assignPopoverTitle: "Selecione um membro da equipe para atribuir esta conversa",
    selectTagPopoverTitle: "Selecione uma etiqueta para classificar esta venda.",
    takeNotePlaceholder: "Digite uma nota aqui",
    onlyYouCanSeeNote: "Apenas você pode ver isso",
    chatTemplatePlaceHolder: "Selecione uma mensagem inicial para enviar",
    chatCalenderPopoverTitle: "Agendar esta conversa para mais tarde",
    chatCalenderPopoverInputPlaceholder: "Ingresa una nota aquí",
    chatFilterAssignedTo: "Atribuído a",
    chatFilterAssignedToAbbrv: "Atribuído a",
    archiveChatMobileTitle: "Você quer arquivar esta conversa?",
    chatWriteNotesTitle: "Escreva notas para esta conversa",
    chatWriteNote: "Escreva uma nova nota aqui",
    addFile: "Adicionar arquivo",
    fileCommentPlaceholder: "Digite um comentário para acompanhar seu arquivo",
    fileCommentPlaceholderDisabled: "Você não pode enviar arquivos nesta conversa, envie uma mensagem inicial primeiro",
    fileCommentPlaceholderMulticlientDisabled: "Você não pode enviar arquivos para várias conversas simultaneamente",
    noTemplates: "Não há mensagens iniciais para enviar, <strong>crie uma mensagem inicial</strong>",
    imgNotAvailable: "Imagem não disponível",
    fileNotAvailable: "Arquivo não disponível",
    msgNotAvailable: "Mensagem não disponível",
    addToMyClients: "Adicionar aos meus clientes",
    addNewCustomer: "Adicionar novo cliente",
    fileOverSizeError: "O arquivo adicionado é maior que 5 MB",
    fileWrongFormatError:
        "O formato do arquivo adicionado não é permitido. Os formatos de arquivo recomendados são JPG, PNG, AMR, MP4, OGG ou PDF",
    instagramFileWrongFormatError: "Instagram e Facebook só permitem formatos JPG, PNG, BMP ou ICO.",
    removeFileError: "Por favor, exclua o arquivo.",
    fileNotReady: "Arquivo não está pronto, por favor, tente novamente em um momento",

    workingHours: "Horas de trabalho",
    setWorkingHours: "Definir horário de trabalho",
    workingHoursModalDescription:
        "Defina as horas de trabalho para informar aos seus clientes os horários em que você está trabalhando e quando está disponível para responder. Se um cliente enviar uma mensagem fora dessas horas, o Cheetabot responderá com sua mensagem personalizada.",
    useWorkingHours: "Use as horas de trabalho",
    workingHoursSelectTimeZone: "Escolha o fuso horário da sua empresa:",
    workingHoursSelectTimes: "Escolha os horários de trabalho da sua empresa:",
    workingHoursAutoReplyMessage: "Defina sua resposta automática:",
    sameHoursAllWeek: "O horário de trabalho da empresa é o mesmo de segunda a sexta-feira",
    saturday: "Sábado",
    sunday: "Domingo",
    monday: "Segunda-feira",
    tuesday: "Terça-feira",
    wednesday: "Quarta-feira",
    thursday: "Quinta-feira",
    friday: "Sexta-feira",
    dayOff: "Dia de folga",
    copyTimeToAll: "Aplicar horário a todos",

    messageError: "Erro ao enviar mensagem",
    error63016:
        "Esta mensagem foi rejeitada pelo WhatsApp porque você está fora da janela de 24 horas. Por favor, verifique se a última mensagem do seu cliente foi enviada dentro de 24 horas ou se você está usando uma Mensagem Inicial aprovada.",
    addContact: "Adicionar Contato",
    contactAlreadyAdded: "Contato já adicionado",
    anErrorOcurredObtainingContactInfo: "Ocorreu um erro ao obter as informações de contato compartilhado",
    selectedConversation: "Conversa selecionada",
    max80: "(Max 40)",
    theActionsYouTakeWillBeAppliedToAllTheSelectedConversations:
        "As ações que você realizar serão aplicadas a todas as conversas selecionadas",
    messagePopoverTagsTitle: "Escolha uma etiqueta para classificar esta venda.",
    messagePopoverTagsCreateTags:
        "Você não tem nenhuma etiqueta criada, clique no botão para criar sua primeira etiqueta",
    addStage: "Adicionar nova etiqueta",
    assignedToPlural: "Atribuído a ",
    assignedToOthers: "Atribuído a outros",
    changesMayTakeAMomentToShow: "Pode demorar até 20 segundos para as alterações aparecerem ao recarregar",
    shareYourWhatsApp: "Compartilhe seu WhatsApp",
    shareYourWhatsappTitle: "Compartilhe seu número do WhatsApp",
    shareYourWhatsappDescription:
        "Copie e disponibilize este link em suas redes sociais e site para que seus clientes possam entrar em contato com você pelo WhatsApp.",
    buttonShareWhatsapp: "Eu entendi",
    titleCreateTag: "Adicionar etiqueta",
    titleUpdateTag: "Atualizar etiqueta",
    placeholderNameTag: "Nome da etiqueta",
    selectColor: "Selecionar uma cor",
    buttonSave: "Salvar",
    dontForgetToShareYourWhatsAppNumber: "Não se esqueça de compartilhar seu número do WhatsApp!",
    createFastAnswer: "Criar uma resposta rápida",
    newFastAnswer: "Nova resposta rápida",
    notQuickText: "Não há respostas rápidas para enviar",
    chatQuickTextPlaceHolder: "Escolha uma resposta rápida para enviar nesta conversa",
    selectAnAnswer: "Selecione uma resposta",
    seeConversationsByAssigned: "Filtrar conversas pelo usuário designado",
    seeConversationsByAnswer: "Exibir somente conversas pendentes",
    seeConversationsByStatus: "Filtrar conversas por status",
    otherFilters: "Outros filtros de conversa",
    file: "Arquivo",
    camera: "Câmera",
    scheduleTo: "Agendar para ",
    halfAnHour: "30 minutos",
    pickADate: "Selecione uma data",
    schedule: "Agendar",
    youHaveTimeToResponse: "Você tem {tempo} para responder. Digite / para abrir respostas rápidas.",
    conversationInChaseWithQuickReply: "Enviar uma resposta rápida não interromperá essa busca.",
    conversationInChaseWithTemplate: "Enviar uma mensagem inicial não interromperá essa busca.",
    timeWindowExplanation1:
        "O WhatsApp limita você, como empresa, a enviar apenas mensagens iniciais previamente aprovadas.",
    timeWindowExplanation2:
        "Se alguém responde ou envia uma mensagem direta para você, uma janela de 24 horas se abre, permitindo que você envie mensagens livremente para esse número.",
    timeWindowExplanation3: "A janela de tempo está atualmente exibida na entrada de mensagem",
    timeWindowExplanation4:
        "Depois que essa janela de tempo se fechar, você será novamente limitado a enviar mensagens iniciais previamente aprovadas",
    chatBlanktip1: "Envie uma mensagem inicial para começar esta conversa.",
    chatBlanktip2:
        "Se você receber uma mensagem, uma janela de 24 horas se abrirá para que você possa responder livremente.",
    thisConversationHasMovedTo: "Conversa transferida para",
    conversationsBlanktip:
        "Você não tem conversas, pode começar uma criando um novo cliente ou recebendo uma mensagem para o número {phone_number}",
    theAdminHasSetTheFollowingWorkHours: "O administrador definiu os seguintes horários de trabalho:",
    mondayToFriday: "Segunda a sexta-feira",
    outsideWorkingHours: "Você está conectado fora do horário de trabalho",
    inWorkingHours: "{hours} horas de trabalho restantes",
    workingHoursNotSet: "Horário de trabalho não definido",
    assignThisConversation: "Designar conversa",
    scheduleConversation: "Agendar conversa",
    conversationsSearchBlanktip:
        "Você não possui conversas que correspondam aos seus critérios de busca ou filtro. Você pode iniciar novas conversas criando um novo cliente ou recebendo uma mensagem para o número {phone_number}",
    newMessageNotification: "Você recebeu uma mensagem de ",
    newClientNotification: "Você recebeu uma mensagem de um novo cliente",
    conversationsWithBotCantBeMultiselected: "Conversas com um bot ativo não podem ser selecionadas",
    disabledChatMsg:
        "Sua conta foi restrita devido a problemas de pagamento ou porque você ainda não definiu um plano de assinatura.",
    disabledChatMsgPlan: "Você precisa definir um plano de assinatura antes de poder enviar mensagens!",
    disabledChatMsgPlanBtn: "Clique aqui para configurar um plano de assinatura",
    disabledChatMsgPhoneNumber: "É necessário conectar uma plataforma de chat a esta conta",
    disabledChatMsgPhoneNumberBtn: "Clique aqui para conectar-se a uma plataforma de chat",
    activateNotifications: "Ativar notificações",
    disableNotifications: "Desativar notificações",
    soundFileError: "Ocorreu um erro com o arquivo de áudio.",
    anErrorOcurredSelectingFile:
        "Ocorreu um problema, você não selecionou um arquivo ou o arquivo selecionado está com algum problema, por favor, tente outro arquivo.",
    sendingFiles: "Enviando arquivos...",
    anErrorOcurredSendingAFile:
        "Ocorreu um erro ao enviar um dos seus arquivos, por favor, tente novamente ou troque o arquivo.",
    thisConversationsHasBeenAssignedTo: "Esta conversa foi designada para ",
    thisConversationHasCheetabotActive: "Esta conversa está sendo respondida pelo Cheetabot",
    thisConversationHasBlockActive: "Esta conversa será pulada nas buscas de segmento.",
    thisConversationHasAChaseActuve: "Esta conversa possui uma busca ativa.",
    thisConversationHasAShopifyChaseActuve: "Esta conversa está sendo rastreada ativamente no Shopify.",
    automaticAgentAssignmentIsActive: "O sistema automático de atribuição de executivo está ativo.",
    lastAssignedAgentWas: "O último responsável atribuído foi ",
    chatStatusUnreadDesc: "As mensagens do cliente ainda não foram lidas pelo responsável atribuído.",
    chatStatusPendingDesc: "A última mensagem foi enviada pelo cliente.",
    chatStatusAnsweredDesc: "Conversa respondida.",
    whatsAppShareDesc:
        "Você também pode compartilhar seu WhatsApp no Facebook, para fazer isso, siga as instruções na seguinte URL https://www.facebook.com/[nome da sua empresa no Facebook]/settings/?tab=whatsapp_management. Substitua [nome da sua empresa no Facebook] pelo nome da sua empresa conforme escrito no Facebook",
    onlyTheAdminCanSetWorkingHours: "Apenas o administrador pode definir os horários de trabalho desta empresa.",
    dialogConfirmDeleteTagDescription1:
        "Esta etiqueta está associada a {{entidades}}, você deve primeiro remover essa funcionalidade ou desvincular a etiqueta dela antes de poder removê-la",
    dialogConfirmDeleteTagDescription2:
        "Esta etiqueta está atribuída a {{number_clients}} clientes. Quando eliminada, será desvinculada dessas conversas, e essa alteração será irreversível. Tem certeza de que deseja continuar?",
    dialogConfirmDeleteTagTitle: "Tem certeza de que deseja excluir esta etiqueta?",
    hasChaseActive: "Em busca",
    hasBotActive: "Com Cheetabot",
    lookForAStartMessageToBeginThisConversation: "Procure por uma mensagem inicial para iniciar esta conversa.",
    clickOAStartMessageToSelectIt: "Clique em uma mensagem inicial para selecioná-la.",
    sendMessage: "Enviar mensagem",
    noTemplatesForSearch:
        "Você não tem mensagens iniciais que correspondam à sua consulta de pesquisa. <strong>Clique aqui se precisar criar uma nova</strong>.",
    clickOAQuickTextToSelectIt: "Clique em uma resposta rápida para selecioná-la.",
    noQuickTextsForSearch:
        "Você não possui respostas rápidas que correspondam à sua consulta de pesquisa. <strong>Clique aqui se precisar criar uma nova</strong>.",
    noQuickTexts:
        "Você não possui respostas rápidas criadas. <strong>Clique aqui para criar respostas rápidas</strong>.",
    error63018: "Seu número do WhatsApp excedeu o limite de taxa imposto pelo WhatsApp.",
    error63038:
        "Seu número do WhatsApp excedeu a quantidade máxima de mensagens por 24 horas estabelecida pelo WhatsApp.",
    whatsAppLimitLink: "Para obter mais informações, consulte o Limite de Taxa do WhatsApp.",
    messageLimitWarning: "Você está atingindo o limite diário de conversas do WhatsApp!",
    messageLimitReached: "Você atingiu o limite diário de conversas do WhatsApp!",
    messageLimitDesc:
        "O WhatsApp impõe um limite diário de 1000 conversas. Se você atingir esse limite, não poderá iniciar novas conversas até o próximo dia.",
    audioFormatIncompatible: "Este navegador não é compatível com o formato de áudio, por favor, use outro navegador.",
    instagramClient: "Cliente do Instagram",
    conversationSource: "Origem",
    instagramTimeWindowExp1:
        "As APIs do Facebook e do Instagram nos limitam, como provedor de mensagens, a apenas responder a mensagens.",
    instagramTimeWindowExp2:
        "Isso significa que você não pode iniciar uma conversa com uma conta do Facebook ou Instagram. Somente se alguém enviar uma mensagem direta para você, uma janela de 24 horas se abrirá, permitindo que você envie mensagens livremente para essa pessoa.",
    instagramTimeWindowExp3: "A janela de tempo está atualmente exibida na entrada de mensagem",
    instagramTimeWindowExp4:
        "Após o fechamento dessa janela de tempo, você não poderá enviar mensagens para essa conta do Instagram ou Facebook até que eles lhe enviem uma mensagem novamente.",
    youNeedToWaitForThisClientToWriteFirst:
        "Você só pode enviar mensagens até 24 horas depois que este cliente escreveu para você.",
    youCantSendMultiMsgToInstagramClients:
        "Você não pode enviar mensagens para vários clientes do Instagram ou Facebook ao mesmo tempo.",
    instagramStoryMention: "Ver história",
    instagramStoryReply: "Resposta da história",
    storyContentNotAvailable: "Conteúdo da história não está mais disponível.",
    instagramStoryMentionText: " mencionou você em uma história.",
    thisClient: "Este cliente",
    thisConversationIsNotAssigned: "Esta conversa não está atribuída a nenhum usuário.",
    thisConversationIsAssignedTo: "Esta conversa está atribuída a ",
    youWantToSendYourMessageAnyway: "Você deseja enviar a mensagem mesmo assim?",
    assignThisConversationToYou: "Clique aqui para atribuir esta conversa a você.",
    sendMessageAnyway: "Enviar mensagem",
    hasNotReadTheLastMessage: " não leu a última mensagem.",
    markAsRead: "Marcar como lido",
    hasReadAllMessages: " leu todas as mensagens.",
    markAsUnread: "Marcar como não lido",
    youHaveReadAllMessages: "Você leu todas as mensagens.",
    allMessagesHaveBeenRead: "Todas as mensagens foram lidas.",
    youHaveSentTheSameMessageXTimes:
        "Você enviou a mesma mensagem {x} vezes seguidas. Gostaria de criá-la como uma resposta rápida?",
    clickToMarkAsRead: "Clique para marcar como lido",
    clickToMarkAsUnread: "Clique para marcar como não lido",
    pauseChase: "Pausar a busca atual desta conversa",
    resumeChase: "Retomar a busca atual desta conversa",
    thisConversationHasAPausedChase: "Esta conversa tem uma busca pausada",
    pause: "Pausar",
    resume: "Retomar",
    sendYourShopifyProducts: "Envie seus produtos do Shopify para seus clientes",
    chooseAShopifyProductToSend: "Escolha um produto do Shopify para enviar:",
    sendProductAsMsg: "Enviar uma mensagem com este produto",
    sendACheckoutLink: "Enviar como link de checkout",
    textMessage: "Mensagem de texto",
    typeToOpenQuickReplies: "Digite / para abrir respostas rápidas.",
    SMSExplanation1:
        "Um SMS tem um tamanho de conteúdo que depende do seu comprimento e se contém símbolos, URLs, emojis, etc.",
    SMSExplanation2: "Dependendo do tamanho do conteúdo do SMS, ele será dividido em segmentos.",
    SMSExplanation3: "Cada segmento será enviado como uma mensagem individual e cobrado como tal.",
    SMSExplanation4: "Além disso, o SMS como um todo não pode ter mais do que 320 caracteres.",
    thisMessageWillBeSentAsXSegments: "Esta mensagem será enviada em {x} segmentos.",
    error21610: "Este cliente cancelou a assinatura das suas mensagens SMS.",
    youHaveReachedTheBottom:
        "Não foram encontradas mais conversas. Verifique seus filtros ou consulta de pesquisa se precisar ver outras.",

    thisMessageExceedsTheCharacterLimit:
        "Esta mensagem ultrapassa o limite de caracteres. Você deseja enviá-la como várias mensagens?",
    good: "Boa",
    bad: "Ruim",
    newClient: "Novo Cliente",
    lastExpGood: "Últ. Exp.: Boa",
    lastExpBad: "Últ. Exp.: Ruim",
    daysLastVisit: "{days} dias sem vir",
    tableSector: "Mesa-setor",
    numberVisits: "{number_visits} visitas"
}

export default messages
