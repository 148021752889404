// Constantes de uso general

export const ASSIGNED = "assigned"
export const UNASSIGNED = "unassigned"
export const NO_USER = "USER#None"
export const OPEN_CLIENT_STATUS = "open"
export const SCHEDULED_CLIENT_STATUS = "scheduled"
export const ARCHIVED_CLIENT_STATUS = "archived"
export const WEEKDAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
export const ALLOWED_FILES_UPLOAD_TYPES = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "audio/mpeg",
    "audio/amr",
    "audio/mp4",
    "audio/ogg",
    "video/mp4",
    "application/pdf",
]
export const INSTAGRAM_ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/ico", "image/bmp"]
export const META_ALLOWED_FILE_TYPES = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/webp",
    "audio/aac",
    "audio/mpeg",
    "audio/amr",
    "audio/mp4",
    "audio/ogg",
    "video/mp4",
    "video/3gp",
    "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]
export const SMS_ALLOWED_FILE_TYPES = [
    "audio/mpeg",
    "audio/amr",
    "audio/mp4",
    "audio/ogg",
    "audio/basic",
    "audio/L24",
    "audio/vnd.rn-realaudio",
    "audio/vnd.wave",
    "audio/3gpp",
    "audio/3gpp2",
    "audio/ac3",
    "audio/webm",
    "audio/amr-nb",
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/gif",
    "image/bmp",
    "image/tiff",
    "video/mp4",
    "video/3gp",
    "video/quicktime",
    "video/webm",
    "video/3gpp",
    "video/3gpp2",
    "video/3gpp-tt",
    "video/H261",
    "video/H263",
    "video/H263-1998",
    "video/H263-2000",
    "video/H264",
    "application/pdf",
    "text/vcard",
    "text/x-vcard",
    "text/csv",
    "text/rtf",
    "text/richtext",
    "text/calendar",
    "text/directory",
    "application/vcard",
    "application/vnd.apple.pkpass",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "audio/aac",
    "audio/ogg; codecs=opus",
    "image/webp",
]

export const CHASE_DURATION_LIMIT = 15
export const CHASE_TEMPLATES_LIMIT = 3
export const DAILY_CLIENTS_LIMIT = 1000
export const DAILY_CLIENTS_WARNING = 800
