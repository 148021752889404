const metaErrorMessages = {
    metaErrorCode0:
        "Authentication exception: The session has expired, please try re-connecting with your Meta/Facebook account.",
    metaErrorCode1: "Unknown API: Facebook/WhatsApp service seems to be unavailable, please try again later.",
    metaErrorCode2: "API Service issue: Facebook/WhatsApp service seems to be unavailable, please try again later.",
    metaErrorCode3:
        "API Method issue: An issue ocurred with Meta/Facebook permissions, please try re-connecting with your Meta/Facebook account and giving us the proper permissions.",
    metaErrorCode4: "Too many calls to the API: Please wait a bit and try again later.",
    metaErrorCode10:
        "API access denied: A permission was not granted or deleted, please try re-connecting with your Meta/Facebook account and giving us the proper permissions.",
    metaErrorCode100:
        "Invalid parameter: An issue ocurred with a message parameter, please check your message's files, variables or interactive content.",
    metaErrorCode190:
        "Token expired: Your Meta/Facebook access token has expired, please try re-connecting with your Meta/Facebook account.",
    metaErrorCode200:
        "API permission error: An issue ocurred with Meta/Facebook permissions, please try re-connecting with your Meta/Facebook account and giving us the proper permissions.",
    metaErrorCode2494049:
        "API permission error: You don't have permission to register this number, please check the phone number or try re-connecting with your Meta/Facebook account and giving us the proper permissions.",
    metaErrorCode368: "Temporarily blocked: You seem to be temporarily blocked, please try again on a few minutes.",
    metaErrorCode506:
        "Duplicated publication: You can't create duplicated publications consecutively, please change your publication's content and try again.",
    metaErrorCode80007:
        "Rate limit issue: You have reached the platform's rate limit, please contact an admin or try again later.",
    metaErrorCode131052:
        "Media download error: The file couldn't be downloaded, it may have expired or be unavailable on the sender's part.",
    metaErrorCode131042:
        "Payment method issue: The message couldn't be sent due to an error related to a payment method. Please make sure your WhatsApp Business account has the following properly set up: timezone, currency and payment method. Then check if you have MessagingFor requests pending or rejected. {url}",
    metaErrorCode131043: "Message expired: Message couldn't be sent during it's lifespan.",
    metaErrorCode130429:
        "Rate limit reached: Message couldn't be sent because too many messages were sent from this number in a short period, please try again later.",
    metaErrorCode131045:
        "Unsigned certificate: Message couldn't be sent due to an error related with the certificate. Please contact an admin.",
    metaErrorCode131016:
        "Service overload: Please try again later. You may want to check out WhatsApp Business' platform status.",
    metaErrorCode131047:
        "Outside the 24h window: Message couldn't be sent because more than 24 hours have passed since the last message from this client.",
    metaErrorCode131048: "Spam rate reached: This phone number has been flagged for spam. Please contact an admin.",
    metaErrorCode131000: "Unknown error: Message couldn't be sent due to an unknown error.",
    metaErrorCode131001: "Message too long: The length of the message cannot be over 4096 characters.",
    metaErrorCode131002: "Invalid recipient: Please try sending this message to an individual and not a group.",
    metaErrorCode131005: "Access denied: That number is already registered on WhatsApp.",
    metaErrorCode131006: "Resource not found: That resource or file couldn't be found.",
    metaErrorCode131008:
        "Parameter missing: A message parameter seems to be missing. Please check if the message has variables or interactive elements.",
    metaErrorCode131009:
        "Parameter value invalid: There is an issue with a message parameter. Please check the phone number is valid and registered for WhatsApp, and that your message has valid values for variables or interactive elements.",
    metaErrorCode131021: "Incorrect user: You seem to have sent a message to yourself.",
    metaErrorCode131031: "Sender account blocked: Your account has been blocked and you cannot send more messages.",
    metaErrorCode131051: "Message type not allowed: That type of message is not allowed.",
    metaErrorCode131053: "Media upload error: Media content on the message could not be uploaded.",
    metaErrorCode131055: "Method not allowed: The method you are trying to use is not allowed.",
    metaErrorCode132000:
        "Parameters missing: The amount of parameters in this message does not match the amount set for that start message.",
    metaErrorCode132001:
        "Start message not found: The specified start message has not been approved or does not exist.",
    metaErrorCode132005: "Text too long: The message is too long, please check the start message it is based on.",
    metaErrorCode132007: "Character's policy infringement: You are trying to send a character not allowed by WhatsApp.",
    metaErrorCode132012:
        "Parameter format does not match: You have a parameter in your start message that does not match what you are trying to send. Please check the start message's files, variables and interactive elements.",
    metaErrorCode133000: "Incomplete deletion: A previous delete action failed, please try deleting again.",
    metaErrorCode133001: "Deciphering issue: A backup copy could not be deciphered due to an unknown issue.",
    metaErrorCode133002: "Deciphering issue: A backup copy could not be deciphered due to a password or format error.",
    metaErrorCode133003:
        "Recovery token issue: The recovery token could not be read due to a password or format error.",
    metaErrorCode133004: "Service unavailable: The registering server is temporarily unavailable.",
    metaErrorCode133005: "PIN doesn't match: Please make sure you are entering the correct PIN and try again.",
    metaErrorCode133006: "Recovery token incorrect: The recovery token may have expired.",
    metaErrorCode133007: "Account blocked: The account has been blocked by the server.",
    metaErrorCode133008: "Yoo many attempts: You tried too many times with an incorrect PIN.",
    metaErrorCode133009: "PIN attempt too fast: The PIN was entered too fast and the request has been blocked.",

    metaErrorCode400: "Invalid request: The request you are trying to make is not valid.",
    metaErrorCode401:
        "Unauthorized: You do not have authorized credentials for this query. Please try reconnecting with your Meta/Facebook account. {url}",
    metaErrorCode403:
        "Access denied: You do not have permission to access this resource. Please try reconnecting with your Meta/Facebook account.",
    metaErrorCode404:
        "Resource not found: The resource you are trying to access does not exist. Please try reconnecting with your Meta/Facebook account.",
    metaErrorCode405:
        "Method not allowed: The method you are trying to use is not allowed. Please try reconnecting with your Meta/Facebook account.",
    metaErrorCode500:
        "Internal server error: An internal error has occurred on the server. Please contact an administrator.",
    metaErrorCode503: "Service unavailable: The server is temporarily unavailable. Please try again later.",
    metaErrorCode412:
        "Precondition failed: The request could not be processed due to an unfulfilled precondition. Please try reconnecting with your Meta/Facebook account.",
    metaErrorCode420:
        "Frequency limited: You have sent too many requests in a short period of time. Please try again later.",
    metaErrorCode402:
        "Payment required: There were one or more errors with the payment method: The payment account is not associated with a WhatsApp account, the credit line exceeded its limit, no credit line was set up, the WhatsApp Business account was deleted or suspended, no currency or time zone was set up, the MessagingFor request is pending or rejected, or the conversation limit was exceeded. Check your payment settings in the WhatsApp Manager and try again. {url}",
    metaErrorCode408: "Request timeout: The request could not be processed due to a timeout. Please try again later.",
    metaErrorCode410: "Request timeout: The request could not be processed due to a timeout. Please try again later.",
    metaErrorCode429:
        "Frequency limit reached: You have sent too many requests in a short period of time. Please try again later.",
    metaErrorCode430:
        "Certificate error: The security certificate could not be validated. Download a new certificate from the WhatsApp Manager and register again.",
    metaErrorCode432:
        "Certificate error: The security certificate could not be validated. Download a new certificate from the WhatsApp Manager and register again.",
    metaErrorCode433:
        "Invalid certificate signature: The company's certificate is not signed with the client's identity key. Please recreate the company certificate.",
    metaErrorCode470:
        "The message could not be sent because more than 24 hours have passed since the last time the customer responded to this number. Use a starter message to respond.",
    metaErrorCode471:
        "Spam: The message could not be sent because there are restrictions related to the number of messages that can be sent from this phone number. This may be because too many previous messages were blocked or marked as spam.",
    metaErrorCode472:
        "The message could not be sent because the user's phone number is part of an experiment. Skip sending messages to this user.",
    metaErrorCode480:
        "User potentially changed: The message was not sent because the user to whom this phone number belongs may have changed.",
    metaErrorCode501:
        "Message type not supported at this time: The message could not be sent due to an unknown error. Please try again later.",
    metaErrorCode1000: "Generic error. Please try again later.",
    metaErrorCode1001: "Message too long: The message you are trying to send is too long.",
    metaErrorCode1002: "Invalid recipient: The type of recipient you are trying to send to is not valid.",
    metaErrorCode1004: "Resource already exists: The UUID you are trying to use already exists.",
    metaErrorCode1005:
        "Access denied: One or more errors occurred: The upload directory no longer exists, the credentials are invalid, there is a certificate error, the application needs to be updated, this account cannot use messages with buttons, or the WhatsApp number is already registered.",
    metaErrorCode1006: "Resource or file not found.",
    metaErrorCode1007: "The recipient has blocked message reception.",
    metaErrorCode1008: "A necessary parameter is missing.",
    metaErrorCode1009: "Invalid parameter value.",
    metaErrorCode1010: "The message contains an unnecessary parameter.",
    metaErrorCode1011: "The service is not ready. Please try again later.",
    metaErrorCode1013: "The user is not valid.",
    metaErrorCode1014:
        "Internal error: The message could not be sent due to an internal error. Please try again later.",
    metaErrorCode1015:
        "Too many requests: You have sent too many requests in a short period of time. Please try again later.",
    metaErrorCode1016: "System overloaded: The system is overloaded. Please try again later.",
    metaErrorCode1017:
        "This request can only be made on the master node. This can occur when the WhatsApp Business API client is not configured correctly or internal errors occur.",
    metaErrorCode1018:
        "This request can only be made on the primary main app. This error occurs when requests are sent to a main app node that is not the owner of the fragment or is not in the process of becoming the owner of the fragment.",
    metaErrorCode1021:
        "Incorrect user: You may be trying to send a message to a user who is not registered on WhatsApp or to yourself. Please verify the phone number.",
    metaErrorCode1022:
        "Webhook URL not configured: This error occurs if you have not configured the format of REST API webhooks.",
    metaErrorCode1023: "An error occurred in the database. Please try again later.",
    metaErrorCode1024:
        "Password change required: The password you are using is too weak or has been compromised. Please change the password, and then reset the connection.",
    metaErrorCode1025: "Invalid connection request: The connection request is not valid. Please try again later.",
    metaErrorCode1026: "Reception impossible: The message could not be sent, please try again later.",
    metaErrorCode1028:
        "You sent a message to a WhatsApp user who may have changed, and you received a pending system notification user_identity_changed.",
    metaErrorCode1031:
        "Your account has been blocked, and you cannot send messages due to a violation of the integrity policy.",
    metaErrorCode2000: "The number of provided parameters does not match the expected number of parameters.",
    metaErrorCode2001:
        "You are trying to use a non-existent, inactive or unassociated start message with your account.",
    metaErrorCode2002: "The recipient could not download your start message.",
    metaErrorCode2003: "The required start message for the language or region does not exist.",
    metaErrorCode2004: "Excessive length for message parameters. Check the length of buttons or URLs in the message.",
    metaErrorCode2005: "The start message translation is too long.",
    metaErrorCode2006: "Whitespace policy violated: Start message cannot contain those whitespaces.",
    metaErrorCode2007: "Format character policy violated.",
    metaErrorCode2008: "File format is invalid.",
    metaErrorCode2009: "A mandatory component of the start message is missing.",
    metaErrorCode2010: "Button URL is invalid.",
    metaErrorCode2011: "Button phone number is invalid.",
    metaErrorCode2012: "Parameter format does not match the format of the created start message.",
    metaErrorCode2013: "Recipient does not support start message buttons.",
    metaErrorCode2014:
        "Expected empty namespace: This error occurs when a template message language package has not yet been downloaded.",
    metaErrorCode2015:
        "The message request contains a section count that is less than the minimum or greater than the maximum.",
    metaErrorCode2016: "Invalid row count.",
    metaErrorCode2017: "Format character policy violated.",
    metaErrorCode2023:
        "The section object has no products or the total product count exceeded the maximum allowed number.",
    metaErrorCode2024:
        "The catalog identifier you provided does not exist or does not belong to your WhatsApp Business Account (WABA).",
    metaErrorCode2025:
        "The catalog identifier you provided is not connected to the phone number you are using to send a message.",
    metaErrorCode2026: "Some products provided in your request were not found in the catalog.",
    metaErrorCode2027: "No products were found in the catalog you provided.",
    metaErrorCode2028:
        "Individual product message with missing or incomplete information regarding e-commerce requirements.",
    metaErrorCode2029:
        "Multiple product message with missing or incomplete information regarding e-commerce requirements.",
    metaErrorCode2030:
        "Multiple product message that combines invalid products and others that do not include information regarding compliance with requirements.",
    metaErrorCode2036: "Invalid header structure.",
    metaErrorCode2050: "Company did not provide information regarding compliance with requirements.",

    metaErrorCode131026:
        "Message could not be delivered: Either the recipient's phone number is not a WhatsApp phone number, the recipient has not accepted WhatsApp's service policies or the recipient has an old version of WhatsApp.",
    metaErrorCode133010: "Phone number non registered: The phone number is not registered on WhatsApp Business.",
    metaErrorCode135000:
        "User error: The message could not be sent due to an unknown error with the request's parameters.",
    metaErrorCode132016:
        "Start message disabled: The start message has been reported too many times and has been disabled. Please create a new start message.",
    metaErrorCode132015:
        "Start message paused: The start message has been paused due to poor quality. Please contact costumer service to modify it or create a new start message.",
    metaErrorCode130472:
        "Experiment error: This client has been selected by Meta to be part of an experiment (roughly 1% of phone numbers are selected). They cannot receive Marketing type start messages, unless they previously agreed to receive them. Please try another type of message.",
    metaErrorCode131056:
        "Frequency limit exceeded: Too many messages sent in a short period of time. Please try again later.",
}

export default metaErrorMessages
