import { TENANT_IDS } from "../constants"

function isCheetrackTenant(tenant_id) {
    return (
        tenant_id === TENANT_IDS.QA_STAGING ||
        tenant_id === TENANT_IDS.QA_STAGING2 ||
        tenant_id === TENANT_IDS.QA_STAGING3 ||
        tenant_id === TENANT_IDS.QA_PRODUCCION ||
        tenant_id === TENANT_IDS.QA_STAGING4 ||
        tenant_id === TENANT_IDS.QA_STAGING_SMS ||
        tenant_id === TENANT_IDS.PROD_SMS ||
        tenant_id === TENANT_IDS.COMERCIAL ||
        tenant_id === TENANT_IDS.STAGING_META_WHATSAPP ||
        tenant_id === TENANT_IDS.PROD_WHATSAPP_META ||
        tenant_id === TENANT_IDS.DEV_META_WHATSAPP ||
        tenant_id === TENANT_IDS.PRODUCTION_WA2
    )
}

export default isCheetrackTenant
