// Constantes para cheetabots

export const cheetabotOptions = [
    { key: 1, value: "create_note", name: "sequenceOption1" },
    { key: 2, value: "archive", name: "sequenceOption2" },
    { key: 3, value: "assign_tag", name: "sequenceOption3" },
    { key: 4, value: "ShowSubMenu", name: "sequenceOption4" },
    { key: 5, value: "CoordinateMeeting", name: "sequenceOption5" },
    { key: 6, value: "assign", name: "sequenceOption6" },
    { key: 8, value: "assign_user_group", name: "sequenceOption8" },
    { key: 9, value: "end_sequence", prompt: "", name: "sequenceOption9" },
    { key: 10, value: "feedback", prompt: "", name: "sequenceOption14" },
]

export const cheetabotTemplateOptions = [
    { key: 1, value: "create_note", name: "sequenceOption1" },
    { key: 2, value: "archive", name: "sequenceOption2" },
    { key: 3, value: "assign_tag", name: "sequenceOption3" },
    { key: 4, value: "send_template", name: "sendTemplateWithButtons" },
    { key: 6, value: "assign", name: "sequenceOption6" },
    { key: 8, value: "assign_user_group", name: "sequenceOption8" },
    { key: 9, value: "end_sequence", prompt: "", name: "endSequenceAndSendMessage" },
    { key: 11, value: "feedback", prompt: "", name: "sequenceOption14", limited: true, tenants: ["HOSTAL_MORENO"] },
]

export const cheetabotTemplateActionsOrder = [
    "assign_tag",
    "assign",
    "assign_user_group",
    "send_template",
    "archive",
    "end_sequence",
]

export const cheetabotExpectedResponseTypes = [
    { name: "sequenceMessagePhoneNumber", value: "AMAZON.PhoneNumber" },
    { name: "sequenceMessageCountry", value: "AMAZON.Country" },
    { name: "sequenceMessageNumber", value: "AMAZON.Number" },
    { name: "sequenceMessageDate", value: "AMAZON.Date" },
    { name: "sequenceMessageAlphaNumeric", value: "AMAZON.AlphaNumeric" },
    { name: "sequenceMessageFirstName", value: "AMAZON.AlphaNumeric_as_name" },
    { name: "sequenceMessageEmailAddress", value: "AMAZON.EmailAddress" },
    { name: "sequenceMessageTime", value: "AMAZON.Time" },
]

export const cheetabotQuestionWithMessageOptions = [
    { name: "sequenceOption1", value: "create_note", amazonValue: "AMAZON.AlphaNumeric" },
    { name: "sequenceOption3", value: "assign_tag", amazonValue: "AMAZON.AlphaNumeric" },
    { name: "sequenceOption10", value: "save_as_customer_name", amazonValue: "AMAZON.AlphaNumeric_as_name" },
    { name: "sequenceOption11", value: "save_customer_email", amazonValue: "AMAZON.EmailAddress" },
    { name: "sequenceOption12", value: "save_as_company_name", amazonValue: "AMAZON.AlphaNumeric" },
]

export const cheetabotMeetingOptions = [{ value: "send_link", prompt: "", name: "sequenceOptionChild1" }]

export const cheetabotAnswersTimings = [
    {
        from: "05:00",
        to: "12:59",
        name: "morning",
    },
    {
        from: "13:00",
        to: "19:59",
        name: "afternoon",
    },
    {
        from: "20:00",
        to: "23:59",
        nextDateTo: "04:59",
        name: "evening",
    },
]
