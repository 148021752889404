const messages = {
    welcomeToChiTrack: "Bem-vindo ao Cheetrack",
    email: "Endereço de e-mail",
    password: "Senha",
    login: "Entrar",
    loginWithGoogle: "Entrar com o Google",
    createAccount: "Criar uma conta",
}

export default messages
