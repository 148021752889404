import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    tags: [],
    tagLoaders: {},
    tagErrors: {},
    boards: [],
    chases: [],
    clientsForTag: {},
    botChases: {},
}

const tagsSlice = createSlice({
    name: "tags",
    initialState,
    reducers: {
        getLoadersStart(state) {
            state.tagLoaders.getTags = true
            state.tagErrors.getTags = false
        },
        setTags(state, action) {
            state.tags = action.payload.tags
            state.boards = action.payload.boards
            state.botChases = {}

            const chases = action.payload.chases

            chases.forEach((chase) => {
                chase.metadata.template_sequence.forEach((template) => {
                    if (template.time_unit === "minutes" && template.time > 59 && template.time % 60 === 0) {
                        template.time_unit = "hours"
                        template.time = template.time / 60
                    }

                    if (template.type === "cheetabot" && template.cheetabot_id) {
                        const botId = template.cheetabot_id.replace("INTENT#", "")
                        if (!state.botChases[botId]) state.botChases[botId] = []
                        state.botChases[botId].push({
                            chaseId: chase.PK.replace("CHASE#", ""),
                            chaseName: chase.metadata.name,
                            chaseStatus: chase.status,
                        })
                    }
                })
            })
            state.chases = chases

            state.tagLoaders.getTags = false
        },
        getTagsError(state) {
            state.tagLoaders.getTags = false
            state.tagErrors.getTags = true
        },
        createTagStart(state) {
            state.tagLoaders.createTag = true
            state.tagErrors.createTag = false
        },
        createTagError(state) {
            state.tagLoaders.createTag = false
            state.tagErrors.createTag = true
        },
        createTag(state, action) {
            const newTag = {
                    PK: "TAG#" + action.payload.id,
                    SK: "TAG_DATA",
                    active: true,
                    metadata: {
                        color: action.payload.color,
                        name: action.payload.name,
                        created_at: new Date(),
                        updated_at: new Date(),
                    },
                },
                tagBoards = state.boards.filter((board) => board.metadata.tags.some((t) => t.id === newTag.PK)),
                tagChases = state.chases.filter(
                    ({ active, metadata }) =>
                        metadata.trigger &&
                        metadata.trigger.type === "tags" &&
                        metadata.trigger.sub_type === newTag.PK &&
                        active
                )

            if (tagBoards && (!newTag.boards || tagBoards.length !== newTag.boards.length)) newTag.boards = tagBoards

            if (tagChases && (!newTag.chases || tagChases.length !== newTag.chases.length)) newTag.chases = tagChases

            state.tags = [...state.tags, newTag]
            state.tagLoaders.createTag = false
        },
        updateTagStart(state) {
            state.tagLoaders.updateTag = true
            state.tagErrors.updateTag = false
        },
        updateTagError(state) {
            state.tagLoaders.updateTag = false
            state.tagErrors.updateTag = true
        },
        updateTag(state, action) {
            const tagIndex = state.tags.findIndex((t) => t.PK === action.payload.PK),
                tagsArr = [...state.tags],
                tagToUpdate = { ...tagsArr[tagIndex] },
                tagBoards = state.boards.filter((board) => board.metadata.tags.some((t) => t.id === tagToUpdate.PK)),
                tagChases = state.chases.filter(
                    ({ active, metadata }) =>
                        metadata.trigger &&
                        metadata.trigger.type === "tags" &&
                        metadata.trigger.sub_type === tagToUpdate.PK &&
                        active
                )

            if (tagBoards && (!tagToUpdate.boards || tagBoards.length !== tagToUpdate.boards.length))
                tagToUpdate.boards = tagBoards

            if (tagChases && (!tagToUpdate.chases || tagChases.length !== tagToUpdate.chases.length))
                tagToUpdate.chases = tagChases

            tagToUpdate.metadata.color = action.payload.color
            tagToUpdate.metadata.name = action.payload.name
            tagsArr[tagIndex] = tagToUpdate

            state.tags = tagsArr
            state.tagLoaders.updateTag = false
        },
        deleteTagStart(state) {
            state.tagLoaders.deleteTag = true
            state.tagErrors.deleteTag = false
        },
        deleteTagError(state) {
            state.tagLoaders.deleteTag = false
            state.tagErrors.deleteTag = true
        },
        deleteTag(state, action) {
            state.tags = state.tags.filter((t) => t.PK !== action.payload)
        },
        resetTagLoaders(state) {
            state.tagLoaders = {}
        },
        resetTagErrors(state) {
            state.tagErrors = {}
        },
        setClientsForTag(state, action) {
            state.clientsForTag[action.payload.tagId] = action.payload.clients
        },
    },
})

export const tagsActions = tagsSlice.actions
export default tagsSlice
