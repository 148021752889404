import { getTimeDiffInDays, isObjectEmpty } from "../utils"

const today = new Date()

// Reduce la cantidad de datos de la entidad mensaje para que ocupen menos memoria en front

export const reduceMessageProps = (msg) => {
    let sortDate
    if (!msg.sort_date && msg.SK_DATE) {
        const date = msg.SK_DATE.replace("MESSAGE_DATA#", "")
        if (date.includes(".")) {
            const splitOnMils = date.split("."),
                dateObj = new Date(splitOnMils[0]).setMilliseconds(parseInt(splitOnMils[1]))
            sortDate = new Date(dateObj)
        } else sortDate = new Date(date)
    } else sortDate = new Date(msg.sort_date)

    const messageDate = new Date(new Date(sortDate).getTime() - new Date().getTimezoneOffset() * 60 * 1000),
        dateDiff = getTimeDiffInDays(messageDate, today)

    let formattedMsgDate

    if (dateDiff === 0) formattedMsgDate = "today"
    else if (dateDiff === 1) formattedMsgDate = "yesterday"
    else if (dateDiff > 1 && dateDiff < 7) formattedMsgDate = messageDate.toLocaleDateString([], { weekday: "long" })
    else formattedMsgDate = messageDate.toLocaleDateString()

    const msgObj = {
        PK: msg.PK,
        user: msg.user,
        sort_date: sortDate,
        formattedMsgDate,
    }

    if (msg.metadata) {
        msgObj.metadata = {
            created_at: msg.metadata.created_at,
            body: msg.metadata.body,
            is_client_message: msg.metadata.is_client_message,
            receiver: msg.metadata.receiver,
            sender: msg.metadata.sender,
            source: msg.metadata.source,
            status: msg.metadata.status,
        }

        if (msg.metadata.error && msg.metadata.error.code) msgObj.metadata.error = msg.metadata.error
        if (msg.metadata.ig_story_mention && !isObjectEmpty(msg.metadata.ig_story_mention))
            msgObj.metadata.ig_story_mention = msg.metadata.ig_story_mention
        if (msg.metadata.ig_story_reply && !isObjectEmpty(msg.metadata.ig_story_reply))
            msgObj.metadata.ig_story_reply = msg.metadata.ig_story_reply
        if (msg.metadata.location && !isObjectEmpty(msg.metadata.location))
            msgObj.metadata.location = msg.metadata.location
        if (msg.metadata.media && msg.metadata.media.length > 0) msgObj.metadata.media = msg.metadata.media
        if (msg.metadata.template_pk && msg.metadata.template_pk.length > 0)
            msgObj.metadata.template_pk = msg.metadata.template_pk
        if (msg.metadata.components && msg.metadata.components.length > 0)
            msgObj.metadata.components = msg.metadata.components
    }

    return msgObj
}
