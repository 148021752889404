const messages = {
    quickAnswers: "Respostas rápidas",
    quickAnswersDesc1:
        "Defina mensagens que você pode enviar com apenas um clique. Útil para mensagens frequentemente usadas ou padronizadas.",
    quickAnswersDesc2: "As respostas rápidas não podem ser usadas para iniciar conversas.",
    quickAnswerText: "Texto de resposta rápida",
    createNewQuickTextDesc1:
        "Você pode incluir algumas das variáveis predefinidas que configuramos (elas devem ser escritas entre colchetes duplos):",
    createNewQuickTextDesc2: "Você também pode criar suas próprias variáveis usando colchetes da mesma forma: ",
    createNewQuickTextDesc3:
        ". Se você usar variáveis personalizadas, será solicitado que informe o valor delas ao enviar a resposta rápida.",
    createQuickText: "Criar respostas rápidas",
    updateQuickText: "Atualizar resposta rápida",
    editQuickText: "Editar respostas rápidas",
    newQuickText: "Nova resposta rápida",
    createQuickTextAndAnother: "Criar respostas rápidas e outras",

    youCanOnlyHaveUpTo80QuickTexts: "Você só pode ter até 80 respostas rápidas.",
    pleaseDeleteSomeQuickTexts: "Você precisará excluir algumas para criar uma nova.",
}

export default messages
