const messages = {
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Email",
    password: "Contraseña",
    phone: "Teléfono",
    register: "Registrarme",
    Iaccept: "Acepto los ",
    TermsOfService: " Términos de servicio",
    privacyPolicy: " y Política de privacidad",
    alreadyAUser: "¿Ya eres usuario? Inicia sesión aquí!",
    logIn: "Iniciar sesión",
    signUp: "Registrarme",
    cancel: "Cancelar",
    logout: "Salir",
    passwordStrength: "Fuerza de tu contraseña",
    weak: "Débil",
    acceptable: "Aceptable",
    strong: "Fuerte",
    nameRequired: "Por favor escribe tu nombre",
    lastNameRequired: "Por favor escribe tu apellido",
    emailRequired: "Por favor escribe una dirección de email",
    phoneRequired: "Por favor escribe tu número de teléfono",
    emailInvalid: "La dirección de email no es válida",
    showPassword: "Mostrar contraseña",
    hidePassword: "Esconder contraseña",
    yourPasswordMustBeAcceptable:
        "Tu contraseña es muy débil, considera combinar símbolos, números y letras mayúsculas y minúsculas",
    passwordMustBeAtLeast6Chars: "Tu contraseña debe tener al menos 6 carácteres",
    anErrorOcurredOnYourRegister:
        "Ha ocurrido un error en tu registro, por favor revisa tu información e intenta nuevamente",
    youMustAgreeToTerms: "Debes aceptar nuestros términos de servicio y política de privacidad",
    companyNameRequired: "Por favor escribe el nombre de tu compañía",
    passwordRecommendation:
        "Recomendamos que tu contraseña contenga al menos 8 carácteres, mayúsculas, minúsculas, y símbolos.",
    shopifyParamsMissingError:
        "Datos de Shopify requeridos no disponibles. Puede que tengas que volver a instalar la app de Cheetrack en tu cuenta de Shopify.",
    emailInUseError: "Ese correo electrónico ya está registrado, click aquí para iniciar sesión.",
    tenantAlreadyExists: "Esa compañía ya está registrada, por favor usa otro nombre.",
    createNewTenant: "Crear nueva compañía",
    selectTenant: "Seleccionar compañía",
    continueWithTenant: "Continuar con {tenant}",
    companyName: "Nombre de la compañía",
    anErrorOcurredSelectingThisTenant:
        "Ocurrió un error seleccionando esta compañía, por favor vuelve a intentarlo más tarde.",
    anErrorOcurredCreatingTenant:
        "Ocurrió un error creando una nueva compañía, por favor vuelve a intentarlo más tarde.",
    notAnUserYet: "¿No eres un usuario aún? Regístrate aquí!",
    youAreEnteringAs: "Estás ingresando con la tienda de Shopify: {name}",

    selectBranch: "Selecciona sucursal",
}

export default messages
