import ApiClient from "../axios"
import Axios from "axios"

// Sube un archivo al back para poder agregarlo a un mensaje
async function uploadChatFile(file, client, tenant, preview, source) {
    try {
        const fileMetaData = {
            content_type: file.type,
            object_name: file.name || "file." + file.type.split("/")[1],
            size: file.size.toString(),
        }

        if (source) fileMetaData.source = source

        const signedUrlData = await ApiClient.post("media", {
            ...fileMetaData,
            tenant,
            client,
        })

        if (signedUrlData) {
            const formData = new FormData(),
                data = signedUrlData.data.data

            formData.append("key", data.fields["key"])
            formData.append("AWSAccessKeyId", data.fields["AWSAccessKeyId"])
            formData.append("x-amz-security-token", data.fields["x-amz-security-token"])
            formData.append("policy", data.fields["policy"])
            formData.append("signature", data.fields["signature"])
            formData.append("Content-Type", file.type)
            formData.append("file", file)

            const uploadedFile = await Axios.post(data.url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })

            if (uploadedFile) {
                return {
                    ...fileMetaData,
                    preview: preview,
                    key: data.fields["key"],
                }
            } else return false
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export default uploadChatFile
