/**
 * Uses [[getEnv]] to return the API URL based on the current environment.
 *
 * @returns The API URL for the current environment based on the REACT_APP_ENV environment variable.
 *
 */

export const apiURL = () => {
    switch (process.env.REACT_APP_ENV) {
        case "production":
            return "https://api.cheetrack.com/v1/"
        case "staging":
            return "https://api-qa.cheetrack.com/v1/"
        case "dev":
            return "https://api-dev.cheetrack.com/v1/"
        default:
            return "https://api-dev.cheetrack.com/v1/"
    }
}

export const websocketURL = () => {
    switch (process.env.REACT_APP_ENV) {
        case "production":
            return "wss://websockets.cheetrack.com/"
        case "staging":
            return "wss://websockets-qa.cheetrack.com/"
        case "dev":
            return "wss://websockets-dev.cheetrack.com/"
        default:
            return "wss://websockets-dev.cheetrack.com/"
    }
}
