import React, { createContext, useMemo, useState } from "react"

import { BrowserRouter as Router } from "react-router-dom"
import { LightThemeProvider } from "./theme-context"
import { DarkThemeProvider } from "./dark-theme-context"
import { ThemeProvider } from "@mui/material/styles"
import { LocaleProvider } from "./locale-context"
import { Auth0Provider } from "@auth0/auth0-react"
import { StyledEngineProvider } from "@mui/material/styles"
import { Provider } from "react-redux"

import store from "../store/index"
// import { useMediaQuery } from "@mui/material"

const current_host = window.location.origin

const ColorModeContext = createContext()

//Proveedor de modo oscuro/claro
function ColorModeProvider({ children }) {
    const storedColor = localStorage.getItem("colorMode"),
        // prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)"),
        darkTheme = useMemo(() => DarkThemeProvider(), []),
        lightTheme = useMemo(() => LightThemeProvider(), []),
        [colorMode, setColorMode] = useState(storedColor || "light")

    const value = {
        mode: colorMode,
        handleModeChange: (mode) => {
            localStorage.setItem("colorMode", mode)
            setColorMode(mode)
        },
    }

    return (
        <ColorModeContext.Provider value={value}>
            <ThemeProvider theme={colorMode === "dark" ? darkTheme : lightTheme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export { ColorModeContext }

//Configuración de proveedores del sitio
function AppProviders({ children }) {
    return (
        <ColorModeProvider>
            <LocaleProvider>
                <Auth0Provider
                    domain={process.env.REACT_APP_DOMAIN}
                    clientId={process.env.REACT_APP_CLIENTID}
                    redirectUri={current_host + "/"}
                    skipRedirectCallback={window.location.pathname === "/shopify/install"}
                >
                    <Provider store={store}>
                        <StyledEngineProvider injectFirst>
                            <Router>{children}</Router>
                        </StyledEngineProvider>
                    </Provider>
                </Auth0Provider>
            </LocaleProvider>
        </ColorModeProvider>
    )
}
export default AppProviders
