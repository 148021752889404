const messages = {
    first: "First",
    sheFirst: "First",
    second: "Second",
    sheSecond: "Second",
    third: "Third",
    sheThird: "Third",
    fourth: "Fourth",
    sheFourth: "Fourth",
    fifth: "Fifth",
    sheFifth: "Fifth",
    sixth: "Sixth",
    sheSixth: "Sixth",
    seventh: "Seventh",
    sheSeventh: "Seventh",
    eighth: "Eighth",
    sheEighth: "Eighth",
    ninth: "Ninth",
    sheNinth: "Ninth",
    tenth: "Tenth",
    sheTenth: "Tenth",
    eleventh: "Eleventh",
    sheEleventh: "Eleventh",
    twelfth: "Twelfth",
    sheTwelfth: "Twelfth",
    thirteenth: "Thirteenth",
    sheThirteenth: "Thirteenth",
    fourteenth: "Fourteenth",
    sheFourteenth: "Fourteenth",
    fifteenth: "Fifteenth",
    sheFifteenth: "Fifteenth",
    sixteenth: "Sixteenth",
    sheSixteenth: "Sixteenth",
    seventeenth: "Seventeenth",
    sheSeventeenth: "Seventeenth",
    eighteenth: "Eighteenth",
    sheEighteenth: "Eighteenth",
    nineteenth: "Nineteenth",
    sheNineteenth: "Nineteenth",
    twentieth: "Twentieth",
    sheTwentieth: "Twentieth",
}

export default messages
