const messages = {
    quickAnswers: "Quick replies",
    quickAnswersDesc1:
        "Define messages that you can send with just a click. Useful for frequently used or standardized messages.",
    quickAnswersDesc2: "Quick replies cannot be used to start conversations.",
    quickAnswerText: "Quick reply text",
    createNewQuickTextDesc1:
        "You can include some of the predefined variables we have set up (they must be written with double brackets):",
    createNewQuickTextDesc2: "You can also create your own variables by using brackets in the same way: ",
    createNewQuickTextDesc3:
        ". If you use custom variables, you'll be asked to state their value when sending the quick reply.",
    createQuickText: "Create quick reply",
    updateQuickText: "Update quick reply",
    editQuickText: "Edit quick reply",
    newQuickText: "New quick reply",
    createQuickTextAndAnother: "Create quick reply and another",

    youCanOnlyHaveUpTo80QuickTexts: "You can only have up to 80 quick replies.",
    pleaseDeleteSomeQuickTexts: "You will need to delete some to create a new one.",
}

export default messages
