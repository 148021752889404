import ScreenMessages from "./screen-messages"
import loadingMessages from "./loading-messages"
import verticals from "./whatsapp-verticals"
import metaErrorMessages from "./meta-error-messages"
import countryMessages from "./countries.messages"
import countingMessages from "./counting.messages"

const messages = {
    ...ScreenMessages,
    ...loadingMessages,
    ...verticals,
    ...metaErrorMessages,
    ...countryMessages,
    ...countingMessages,
    confirmPassword: "Confirmar contraseña",
    rememberPassword: "Recordar contraseña",
    didYouforgetPassword: "¿Olvidaste tu contraseña?",
    forgotPasswordMessage:
        "Ingresa tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña",
    recoveryPassword: "Recuperar contraseña",
    costumerService: "Chat clientes",
    clientsList: "Mis clientes",
    clients: "Clientes",
    noClients: "Sin clientes",
    whatsappConfig: "Ajustes",
    billing: "Facturación",
    name: "Nombre",
    phone: "Teléfono",
    assignedExecutive: "Ejecutivo asignado",
    inquiries: "Cotizaciones",
    company: "Empresa",
    search: "Buscar",
    open: "Abiertas",
    scheduled: "Agendadas",
    archived: "Archivadas",
    pending: "Pendiente",
    pendingPlural: "Pendientes",
    unread: "Sin leer",
    filters: "Filtros",
    hasBeenSavedSuccessfully: "Se ha guardado exitosamente",
    initialSetup: "SetUp Inicial",
    this: "esta",
    postpone: "Posponer",
    logout: "Cerrar sesión",
    myProfile: "Mi perfil",
    users: "Usuarios",
    cold: "Cold",
    warm: "Warm",
    hot: "Hot",
    note: "Nota",
    notes: "Notas",
    tour: "Tour",
    saveChanges: "Guardar cambios",
    discardChanges: "Descartar cambios",
    template: "Mensaje de inicio",
    help: "Ayuda",
    resetFilters: "Reiniciar filtros",
    selected: "Seleccionado",
    tag: "Etiqueta",
    cancel: "Cancelar",
    close: "Cerrar",
    preview: "Previsualización",
    basic: "Básico",
    standard: "Estándar",
    pro: "Pro",
    included: "Incluidos",
    max: "Máximo",
    download: "Descargar",
    approved: "Aprobado",
    rejected: "Rechazado",
    role: "Rol",
    retry: "Reintentar",
    send: "Enviar",
    item: "Ítem",
    date: "Fecha",
    detail: "Detalle",
    price: "Precio",
    save: "Guardar",
    to: "a",
    openInGoogleMaps: "Abrir en Google Maps",
    archivedSingular: "Archivado",
    yesterday: "ayer",
    today: "hoy",
    multiSelect: "Selección Múltiple",
    selectedAll: "Seleccionar conversaciones visibles",
    unselectAll: "Deseleccionar todas las conversaciones",
    none: "Ninguno",
    connectionError: "Ups! Parece que ocurrió un problema con tu conexión",
    reloadPage: "Recargar página",
    status: "Estado",
    month: "Mes",
    week: "Semana",
    months: "Meses",
    weeks: "Semanas",
    hours: "Horas",
    hour: "Hora",
    tomorrow: "Mañana",
    report: "Reporte",
    sequenceMessage: "Cheetabot",
    templateMessageToInitiateConversations: "Mensaje de inicio para comenzar conversaciones",
    messagesSetup: "Configuración de Mensajes",
    delete: "Eliminar",
    duplicate: "Duplicar",
    message: "Mensaje",
    activeBot: "Activar Bot",
    inactiveBot: "Desactivar Bot",
    lockToSegment: "Ignorar esta conversación en persecuciones de segmentos.",
    unlocktoSegment: "Permitir esta conversación en persecuciones de segmentos.",
    goBack: "Volver",
    minutes: "Minutos",
    days: "Días",
    seeMore: "Ver más",
    minimize: "Minimizar",
    here: "aquí",
    seeTable: "Ver tabla",
    seeChart: "Ver gráfico",
    and: "y",
    uploadFile: "Subir Archivo",
    uploadFiles: "Subir Archivos",
    uploadImage: "Subir Imagen",
    uploadImages: "Subir Imágenes",
    dropHere: "Arrastra aquí 😉",
    fileLimitIs: "El límite de archivos es {limit}",
    fileMustBeAnImage: "El archivo debe ser una imagen",
    yourImage: "Tu imagen",
    continue: "Continuar",
    darkMode: "Modo Oscuro",
    lightMode: "Modo Claro",
    yourDocument: "Tu documento",
    yourVideo: "Tu video",
    messages: "Mensajes",
    morning: "Mañana",
    afternoon: "Tarde",
    evening: "Noche",
    dashboard: "Panel",
    others: "Otros",
    from: "Desde",
    until: "hasta",
    andXMore: "y {x} más",
    in: "en",
    add: "Agregar",
}

export default messages
