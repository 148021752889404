import { cheetabotAnswersTimings, tagColors } from "../constants"
import locales, { ES, EN } from "../locale"
import dayjs from "dayjs"
import { neutralGrey } from "../context/theme-context"

//Invocar el locale fuera de un componente
let locale
const storedLoc = localStorage.getItem("locale"),
    browserLocale = navigator.language || navigator.userLanguage || null

if (storedLoc) locale = storedLoc
else if (browserLocale.startsWith("es")) locale = ES
else locale = EN

const intl = locales[locale]

const answerCharLimit = 40

//Esta función procesa y ordena los datos de las respuestas del cheetabot para graficarlos

export const processCheetabotAnswers = (data, duration, fromDate, mapping, answersMapping, categories, typeModule) => {
    const otherText = intl.formatMessage({ id: "nonAlternativeAnswers" }),
        exampleText = intl.formatMessage({ id: "clientAnswerExamples" }),
        emptyText = intl.formatMessage({ id: "emptyAnswer" }),
        dataAmount = duration === 0 ? 24 : duration <= 7 ? duration * 3 : duration + 1,
        baseArray = Array.from({ length: dataAmount }, (x, i) => {
            return {
                y: 0,
                name: categories[i],
            }
        }),
        otherGroup = {
            name: otherText,
            key: "other",
            type: "column",
            data: JSON.parse(JSON.stringify(baseArray)),
            color: neutralGrey,
            tooltip: {
                headerFormat: `<span style="color: {series.color}; text-transform: capitalize"><b>{series.name}</b></span><br/>`,
                pointFormat: `<span>{point.name}: <b>{point.y}</b></span><br/><span>${exampleText}: {point.answers}</span>`,
            },
            dataLabels: { enabled: false },
            answers: [], // Array de respuestas que se muestran en el tooltip
        }

    let graphDataSeries = mapping.map((questionOption, i) => ({
        name: questionOption, // Nombre de la respuesta
        key: i + 1 + "", // Key de la respuesta, se usa para contrastar con el mapeo del backend
        cleanName: questionOption.replace(/[^a-zA-Z0-9]/g, ""), // Nombre de la respuesta sin caracteres especiales
        type: "column", // Tipo de gráfico
        data: JSON.parse(JSON.stringify(baseArray)), // Datos para gráfico
        color: tagColors[1 + i * 2]?.color, // Color de la respuesta
        dataLabels: { enabled: false }, // Habilita los labels de los datos en el gráfico
    }))

    // Mapping corresponde a las preguntas del cheetabot, por lo q arma un set de datos para cada respuesta
    // y uno extra para los no sabe no responde: otherGroup.

    // Abajo itera sobre los datos que generalmente vienen en 3 formatos distintos:
    // 1. Si duration es 0, los datos vienen en formato {date: {hour: {clientAnswer: amount}}}
    // 2. Si duration es menor o igual a 7, los datos vienen en formato {date: {hour: {clientAnswer: amount}}}
    // 3. Si duration es mayor a 7, los datos vienen en formato {date: {clientAnswer: amount}}

    // Para cada dato, ve si hay un dataGroup que corresponda con el mapeo de la pregunta
    // Si lo hay, busca el index para ese dato y lo suma
    // Si no lo hay, lo suma al grupo de otherGroup

    Object.keys(data).forEach((day) => {
        // Itera sobre los días
        if (duration === 0) {
            // Si la duración es 0, los datos vienen en formato {date: {hour: {clientAnswer: amount}}}
            Object.keys(data[day]).forEach((hour) => {
                // Itera sobre las horas
                Object.keys(data[day][hour]).forEach((clientAnswer) => {
                    // Itera sobre las respuestas de los clientes
                    const dataGroup = graphDataSeries.find(
                            // Busca si hay un dataGroup que corresponda con el mapeo de la pregunta
                            (g) =>
                                g.key === answersMapping[clientAnswer] || g.cleanName === answersMapping[clientAnswer]
                        ),
                        hourIndex = categories.findIndex((category) => category === hour) // Busca el indice dentro de las categorías del eje x

                    if (dataGroup) {
                        // Si hay un dataGroup que corresponda con el mapeo de la pregunta
                        dataGroup.data[hourIndex] = {
                            y: parseInt(data[day][hour][clientAnswer]),
                            name: hour,
                        }
                    } else {
                        // Si no lo hay, lo suma al grupo de otherGroup
                        let answer = answersMapping[clientAnswer] || emptyText
                        if (answer.length === 0) answer = emptyText
                        if (answer.length > answerCharLimit) answer = answer.substring(0, answerCharLimit) + "..."
                        if (
                            (otherGroup.data[hourIndex] && answersMapping[clientAnswer]) ||
                            (typeModule == "loyalty" && otherGroup.data[hourIndex]) ||
                            (typeModule == "cheetabotanswer" && otherGroup.data[hourIndex])
                        ) {
                            otherGroup.data[hourIndex].y += parseInt(data[day][hour][clientAnswer])
                            if (
                                otherGroup.data[hourIndex].answers?.length < answerCharLimit &&
                                !otherGroup.data[hourIndex].answers?.includes(answer)
                            ) {
                                otherGroup.data[hourIndex].answers += ", " + answer
                            }
                        }
                    }
                })
            })
        } else if (duration <= 7) {
            // Si la duración es menor o igual a 7, los datos vienen en formato {date: {hour: {clientAnswer: amount}}}
            const date = dayjs(day, "YYYY-MM-DD").hour(12), // Crea un objeto de fecha con el día
                dayIndex = Math.round(date.diff(fromDate, "day", true)) // Calcula el indice base del día

            Object.keys(data[day]).forEach((hour) => {
                // Itera sobre las horas
                const isPrevNight = parseInt(hour) < 5, // Si la hora es menor a 5, es de la noche anterior
                    time = cheetabotAnswersTimings.find(
                        // Busca si la hora corresponde con alguno de los rangos de horas
                        (t) =>
                            (parseInt(t.from) <= parseInt(hour) && parseInt(t.to) >= parseInt(hour)) ||
                            (isPrevNight && parseInt(t.nextDateTo) >= parseInt(hour))
                    )
                if (time && time.name) {
                    // Si la hora corresponde con alguno de los rangos de horas
                    Object.keys(data[day][hour]).forEach((clientAnswer) => {
                        // Itera sobre las respuestas de los clientes
                        const dataGroup = graphDataSeries.find(
                                // Busca si hay un dataGroup que corresponda con el mapeo de la pregunta
                                (g) =>
                                    g.key === answersMapping[clientAnswer] ||
                                    g.cleanName === answersMapping[clientAnswer]
                            ),
                            index = // Calcula el indice del día dentro de las categorías del eje x, tomando el índice base del día y entre 0 y 3 dependiendo de la hora
                                dayIndex * cheetabotAnswersTimings.length +
                                cheetabotAnswersTimings.findIndex((t) => t.name === time.name) -
                                (isPrevNight ? 3 : 0)
                        // console.log(index, dayIndex,time.name,day,hour,clientAnswer,data[day][hour][clientAnswer])
                        if (dataGroup) {
                            // Si hay un dataGroup que corresponda con el mapeo de la pregunta
                            if (dataGroup.data[index]) {
                                dataGroup.data[index].y += parseInt(data[day][hour][clientAnswer]) // Lo suma al dataGroup
                            }
                        } else {
                            // Si no lo hay, lo suma al grupo de otherGroup
                            let answer = answersMapping[clientAnswer] || emptyText
                            if (answer.length === 0) answer = emptyText
                            if (answer.length > answerCharLimit) answer = answer.substring(0, answerCharLimit) + "..."
                            if (
                                (otherGroup.data[index] && answersMapping[clientAnswer]) ||
                                (typeModule == "loyalty" && otherGroup.data[index]) ||
                                (typeModule == "cheetabotanswer" && otherGroup.data[index])
                            ) {
                                otherGroup.data[index].y += parseInt(data[day][hour][clientAnswer])
                                if (
                                    otherGroup.data[index].answers?.length < answerCharLimit &&
                                    !otherGroup.data[index].answers?.includes(answer)
                                ) {
                                    otherGroup.data[index].answers += ", " + answer
                                }
                            }
                        }
                    })
                }
            })
        } else {
            // Si la duración es mayor a 7, los datos vienen en formato {date: {clientAnswer: amount}}
            const date = dayjs(day, "YYYY-MM-DD").hour(12), // Crea un objeto de fecha con el día
                dayIndex = Math.round(date.diff(fromDate, "day", true)) // Calcula el indice del día dentro de las categorías del eje x

            if (dayIndex <= duration && dayIndex >= 0) {
                // Si el día está dentro del rango de fechas
                Object.keys(data[day]).forEach((clientAnswer) => {
                    // Itera sobre las respuestas de los clientes
                    const dataGroup = graphDataSeries.find(
                        // Busca si hay un dataGroup que corresponda con el mapeo de la pregunta
                        (g) => g.key === answersMapping[clientAnswer] || g.cleanName === answersMapping[clientAnswer]
                    )
                    // console.log(dayIndex, day, clientAnswer, data[day][clientAnswer], answersMapping[clientAnswer])
                    if (dataGroup) {
                        // Si hay un dataGroup que corresponda con el mapeo de la pregunta
                        if (dataGroup.data[dayIndex]) {
                            // Lo suma al dataGroup
                            dataGroup.data[dayIndex].y += parseInt(data[day][clientAnswer])
                        }
                    } else {
                        // Si no lo hay, lo suma al grupo de otherGroup
                        let answer = answersMapping[clientAnswer] || emptyText
                        if (answer.length === 0) answer = emptyText
                        if (answer.length > answerCharLimit) answer = answer.substring(0, answerCharLimit) + "..."
                        if (
                            (otherGroup.data[dayIndex] && answersMapping[clientAnswer]) ||
                            (typeModule == "loyalty" &&
                                otherGroup.data[
                                    dayIndex || (typeModule == "cheetabotanswer" && otherGroup.data[dayIndex])
                                ])
                        ) {
                            otherGroup.data[dayIndex].y += parseInt(data[day][clientAnswer])
                            if (
                                otherGroup.data[dayIndex].answers?.length < answerCharLimit &&
                                !otherGroup.data[dayIndex].answers?.includes(answer)
                            ) {
                                otherGroup.data[dayIndex].answers += ", " + answer
                            }
                        }
                    }
                })
            }
        }
    })
    // Si hay datos en el grupo de otherGroup, se agrega al array de dataGroups
    if (otherGroup.data.length > 0) graphDataSeries.push(otherGroup)

    // Aquí se crea un gráfico de tarta con el total de respuestas
    // No todos los gráficos usan el gráfico de torta, pero sirve para tener un dato sumado de todas las respuestas
    // Por ejemplo, en satisfacción estos datos se usan para las pastillas de colores
    const pieGraph = {
        name: "Total",
        type: "pie",
        data: graphDataSeries.map((series) => {
            const total = series.data.reduce((a, b) => a + (b?.y || 0), 0)
            return {
                name: series.name,
                y: total,
                color: series.color,
            }
        }),
        center: ["75%", 20], // Posición del gráfico de torta
        size: 140, // Tamaño del gráfico de torta
        innerSize: "60%", // Tamaño del agujero del gráfico de torta
        dataLabels: {
            enabled: true,
            format: "{y} ({point.percentage:.1f}%)",
        },
    }

    graphDataSeries.push(pieGraph)

    // console.log("Answers Raw data", data, mapping, answersMapping)
    // console.log("Cheetabot answers view data:", graphDataSeries)

    return graphDataSeries
}

export const getItemWithKeyOther = (series) => {
    // Find the item with key "other" in the list
    const itemWithKeyOther = series.find((item) => item.key === "other")

    // Return the found item or null if not found
    return itemWithKeyOther
}

export const replaceItemDataInOtherKey = (series, data) => {
    let total = 0
    const updatedSeries = series.map((value) => {
        if (value.key === "other") {
            total = data.reduce((a, b) => a + (b?.y || 0), 0)
            return { ...value, data: data, y: total } // Update the 'y' property as well if needed
        }
        return value
    })

    // Function to update data[2].y when type is 'pie'
    function updateYForPie(dataArray, typeToMatch, newYValue) {
        for (let i = 0; i < dataArray.length; i++) {
            const item = dataArray[i]
            if (item.type === typeToMatch) {
                dataArray[i].data[2].y = newYValue
                break // Assuming you only want to update the first matching item
            }
        }
    }

    // Call the function to update the 'pie' type with a new y value
    updateYForPie(updatedSeries, "pie", total) // Replace 42 with the desired new value

    return updatedSeries
}
