import React from "react"
import { RawIntlProvider } from "react-intl"
import locales, { ES, EN, PT } from "../locale"
import Highcharts from "highcharts"

//The context will have an array value of 2 items
//The first items is a locale that exists in our locles, so keyof LocalesType
//The second is the type of setLocale fnction: React.Dispatch<React.SetStateAction<keyof LocalesType>>
const LocaleContext = React.createContext(undefined)

function LocaleProvider({ children }) {
    const browserLocale = navigator.language || navigator.userLanguage || null
    const [locale, setLocaleState] = React.useState(ES)

    const setLocale = (loc) => {
        localStorage.setItem("locale", loc)
        setLocaleState(loc)

        Highcharts.setOptions({
            lang: {
                loading: locales[loc].messages.loadingData,
                noData: locales[loc].messages.noDataToDisplay,
            },
        })
    }

    React.useEffect(() => {
        const storedLoc = localStorage.getItem("locale")
        console.log(`storedLoc ${storedLoc} `)
        if (storedLoc) setLocale(storedLoc)
        else if (browserLocale.startsWith("es")) setLocale(ES)
        else setLocale(EN)
    }, [browserLocale])

    //Our provider will have a value of [current locale, setLocale function to change locale];
    return (
        <LocaleContext.Provider value={[locale, setLocale]}>
            <RawIntlProvider value={locales[locale]}>{children}</RawIntlProvider>
        </LocaleContext.Provider>
    )
}

//Using this function we can get the current local or changing the locale
//const [locale, setLocale] = useLocale();
//I also export useLocale in context/index.tsx in order to easily import it like so:
//import { useLocale } from "context";
function useLocale() {
    const context = React.useContext(LocaleContext)
    if (context === undefined) {
        throw new Error(`useLocale must be used within a LocaleProvider`)
    }
    return context
}

export { LocaleProvider, useLocale }
