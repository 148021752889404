// Retorna un arreglo con los días entre 2 fechas en formato corto: 01/01
export function getDaysArray(dateFrom, dateTo) {
    const days = []

    for (let i = dateFrom; i <= dateTo; i.setDate(i.getDate() + 1)) {
        days.push(new Date(i).toLocaleDateString(undefined, { month: "numeric", day: "numeric" }))
    }

    return days
}

// Retorna un arreglo con los días entre 2 fechas en formato largo: Mar. 01 Oct
export function getDaysArrayLongFormat(dateFrom, dateTo) {
    const days = []

    for (let i = dateFrom; i <= dateTo; i.setDate(i.getDate() + 1)) {
        days.push(new Date(i).toLocaleDateString(undefined, { month: "short", day: "numeric", weekday: "short" }))
    }

    return days
}
