import uploadChatFile from "./upload-chat-file"

// Sube archivos al chat y envía el mensaje
async function sendMediaMessages(
    files,
    sendMessage,
    clientId,
    tenantId,
    phone,
    setFileError,
    facebookId,
    waId,
    source
) {
    try {
        for (const file of files) {
            const media = await uploadChatFile(file.file, clientId, tenantId, file.filePreview, source)
            if (media) {
                await sendMessage(
                    source === "meta_whatsapp" ? file.message : "",
                    [media],
                    false,
                    clientId,
                    phone,
                    facebookId,
                    waId,
                    source
                )
                if (file.message && !source)
                    await sendMessage(file.message, undefined, false, clientId, phone, facebookId, waId, source)
            } else setFileError()
        }
    } catch (err) {
        console.error(err)
        setFileError()
    }
}

export default sendMediaMessages
