// Mensajes de fidelización base

export const fidelizationShopifyStartTemplate = {
    text:
        "Hola! Bienvenido al plan de beneficios de {{tenant}}\n\n" +
        "Si realizas nuevas compras dentro los próximos {{period}} días, tendrás los siguientes beneficios:" +
        "\n{{conditions_list}}\n\n" +
        "Recibirás un mensaje indicando tus beneficios disponibles con cada nueva compra." +
        "\n\n*Los descuentos NO son acumulables*." +
        "\n\nPara empezar, utiliza {{code_1}} en tu próxima compra." +
        "\n\nSi no quieres recibir más mensajes, escribe 'No más mensajes'.",
    singleText:
        "Hola! Bienvenido al plan de beneficios de {{tenant}}\n\n" +
        "Si compras de nuevo dentro los próximos {{period}} días, tendrás el siguiente beneficio:" +
        "\n{{conditions_list}}\n\n" +
        "\n\nUtiliza {{code_1}} en tu próxima compra." +
        "\n\nSi no quieres recibir más mensajes, escribe 'No más mensajes'.",
    buttons: [
        {
            text: "Voy por mi descuento!",
            type: "url",
            url: "https://link.cheetrack.com/{{1}}",
        },
    ],
}

export const fidelizationShopifyRewardTemplate = {
    text:
        "Hola! Gracias por preferir nuevamente a {{tenant}}\n\n" +
        "Te recordamos que tu próximo beneficio disponible es {{name}}\n\n" +
        "Tienes hasta el {{end_period_date}} para poder utilizarlo.\n\n" +
        "Recibirás un mensaje indicando tus beneficios disponibles con cada nueva compra." +
        "\n\n*Los descuentos NO son acumulables*" +
        "\n\nUtiliza {{code}} en tu próxima compra." +
        "\n\nSi no quieres recibir más mensajes, escribe 'No más mensajes'.",
    buttons: [
        {
            text: "Voy por mi descuento!",
            type: "url",
            url: "https://link.cheetrack.com/{{1}}",
        },
    ],
}

export const fidelizationRestaurantStartTemplate = {
    text:
        "Hola! Bienvenido al plan de beneficios de {{tenant}}\n\n" +
        "Si realizas nuevas visitas dentro los próximos {{period}} días, tendrás los siguientes beneficios:\n" +
        "{{conditions_list}} \n\nRecibirás un mensaje indicando tus beneficios disponibles cada vez que nos visites." +
        "\n\n*Los descuentos NO son acumulables y sólo puedes validar uno por día*." +
        "\n\n¡Nos vemos!",
    singleText:
        "Hola! Bienvenido al plan de beneficios de {{tenant}}\n\n" +
        "Si nos visitas de nuevo dentro los próximos {{period}} días, tendrás el siguiente beneficio:\n" +
        "{{conditions_list}}" +
        "\n\n¡Nos vemos!",
}

export const fidelizationRestaurantRewardTemplate = {
    text:
        "Hola! Gracias por preferir nuevamente {{tenant}}\n\n" +
        "Te recordamos que tienes este beneficio  disponible: {{name}}" +
        "\n\n*Los descuentos NO son acumulables y sólo puedes validar uno por día*." +
        "\n\nResponde con el número de la alternativa:",
    options: [
        {
            prompt: "Si quiero utilizarlo",
            next: "Has validado el beneficio {{name}} para {{today_date}}.\n\nMuestra este mensaje al garzón que te está atendiendo para cobrar el beneficio.\n\n*Este beneficio es válido solo para la persona quién porta este teléfono*.",
        },
        {
            prompt: "No quiero utilizarlo",
            next: "Ok, no hay problema.",
        },
    ],
}

export const fidelizationRestaurantStartTemplateWithFirstBenefit = {
    text:
        "Hola! Bienvenido al plan de beneficios de {{tenant}}\n\n" +
        "Si realizas nuevas visitas dentro los próximos {{period}} días, tendrás los siguientes beneficios:\n" +
        "{{conditions_list}} \n\nRecibirás un mensaje indicando tus beneficios disponibles cada vez que nos visites." +
        "\n\n*Los descuentos NO son acumulables y sólo puedes validar uno por día*.",
    singleText:
        "Hola! Bienvenido al plan de beneficios de {{tenant}}\n\n" +
        "Si nos visitas de nuevo dentro los próximos {{period}} días, tendrás el siguiente beneficio:\n" +
        "{{conditions_list}}" +
        "\n\n¡Nos vemos!",
}
export const fidelizationRestaurantRewardFirstTemplate = {
    text:
        "⚡️Ahora tienes disponible ⚡️ :  {{name}}\n\n" +
        "¿Quieres utilizarlo? Responde con el número de la alternativa: \n",
    options: [
        {
            prompt: "Si quiero utilizarlo",
            next: "Has validado el beneficio {{name}} para {{today_date}}.\n\nMuestra este mensaje al garzón que te está atendiendo para cobrar el beneficio.\n\n*Este beneficio es válido solo para la persona quién porta este teléfono*.",
        },
        {
            prompt: "No quiero utilizarlo",
            next: "Ok, no hay problema.",
        },
    ],
}

export const fidelizationRestaurantRewardError1Template = {
    text: "Hola ...firstname...! en estos momentos *no tienes descuentos disponibles*, te recordamos que es máximo un descuento diario y recuerda que en tu próxima visita tienes ...visits+1...",
}
export const fidelizationRestaurantRewardError2Template = {
    text: "Hola ...firstname...! en estos momentos *no tienes descuentos disponibles*, te recordamos que haz alcanzado el  máximo número de beneficios.\n\n Sigue visitándonos para obtener nuevos beneficios en un nuevo período.😜",
}
