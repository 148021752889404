// Retorna la fecha y hora d eun mensaje en formato legible
function getMessageDateAndTime(dateString) {
    const date = new Date(new Date(dateString).getTime() - new Date().getTimezoneOffset() * 60 * 1000),
        day = date.getDate(),
        year = date.getFullYear(),
        month = date.toLocaleDateString("default", { month: "numeric" })

    return {
        date: `${day}/${month}/${year}`,
        time: date.toLocaleTimeString([], { hour: "numeric", minute: "numeric" }),
    }
}

export default getMessageDateAndTime
