import ApiClient from "../axios"

// Va a buscar la url de un archivo
async function updateFileUrl(key, messageId) {
    if (key) {
        const urlData = await ApiClient.get(`media?external_url=${key}&message_id=${messageId}`).catch((err) => {
            console.error(err)
            return "error"
        })
        if (urlData && urlData.data) {
            return urlData.data.data.url
        } else {
            return "error"
        }
    } else {
        return "error"
    }
}

export default updateFileUrl
