// Planes disponibles en Cheetrack

export const plans = [
    {
        name: "Basic",
        cost: 49,
        from: 0,
        to: 5000,
        users: 2,
        extra_user_cost: 19,
        message_cost: 0.024,
    },
    {
        name: "Advanced",
        cost: 99,
        from: 5001,
        to: 10000,
        users: 2,
        extra_user_cost: 19,
        message_cost: 0.02,
    },
    {
        name: "Enterprise",
        cost: 199,
        from: 10001,
        to: 50000,
        users: 2,
        extra_user_cost: 19,
        message_cost: 0.016,
    },
    {
        name: "Premium",
        cost: 399,
        from: 50000,
        to: 0,
        users: 2,
        extra_user_cost: 19,
        message_cost: 0.01,
    },
]
