// Colores disponibles para las etiquetas

export const tagColors = [
    {
        color: "#F5DEB3",
        name: "Wheat",
        contrast: "#0b0c0e",
    },
    {
        color: "#FFD700",
        name: "Gold",
        contrast: "#0b0c0e",
    },
    {
        color: "#fca63e",
        name: "Orange",
        contrast: "#0b0c0e",
    },
    {
        color: "#FF4500",
        name: "OrangeRed",
        contrast: "white",
    },
    {
        color: "#B22222",
        name: "FireBrick",
        contrast: "white",
    },
    {
        color: "#8B4513",
        name: "SaddleBrown",
        contrast: "white",
    },
    {
        color: "#ff78cb",
        name: "Pink",
        contrast: "#0b0c0e",
    },
    {
        color: "#c377e0",
        name: "LightPurple",
        contrast: "#0b0c0e",
    },
    {
        color: "#800080",
        name: "Purple",
        contrast: "white",
    },
    {
        color: "#191970",
        name: "MidnightBlue",
        contrast: "white",
    },
    {
        color: "#1E90FF",
        name: "DodgerBlue",
        contrast: "white",
    },
    {
        color: "#AFEEEE",
        name: "PaleTurquoise",
        contrast: "#0b0c0e",
    },
    {
        color: "#40e0d0",
        name: "Turquoise",
        contrast: "#0b0c0e",
    },
    {
        color: "#32CD32",
        name: "LimeGreen",
        contrast: "white",
    },
    {
        color: "#9ACD32",
        name: "YellowGreen",
        contrast: "#0b0c0e",
    },
    {
        color: "#216e2e",
        name: "DarkGreen",
        contrast: "white",
    },
    {
        color: "#1e283b",
        name: "DarkBlue",
        contrast: "white",
    },
    {
        color: "#808080",
        name: "Gray",
        contrast: "white",
    },
    {
        color: "#b3bac5",
        name: "LightGray",
        contrast: "#0b0c0e",
    },
    {
        color: "#DCDCDC",
        name: "Gainsboro",
        contrast: "#0b0c0e",
    },
]
