import dayjs from "dayjs"
const fillAndCheckDailyArr = (arr, dateFrom, duration) => {
    let updateArr = [...arr]

    const date = new Date(dateFrom.format("YYYY/MM/DD"))

    for (let i = 0; i < duration; i++) {
        if (!updateArr[i] || updateArr[i] === undefined) {
            const name = new Date(date).toLocaleDateString(undefined, {
                month: "long",
                day: "numeric",
                year: "numeric",
            })

            updateArr[i] = {
                name,
                y: 0,
            }
        }

        date.setUTCDate(date.getUTCDate() + 1)
    }
    return updateArr
}

// Procesa los datos de saily sales

export const processShopifySales = (sales, dateFrom, duration) => {
    let updateDailySales = [],
        updateDailyCheetrackSales = [],
        updateDailyCheetrackOrders = [],
        updateDailyAbandonedCheckout = []

    const pastPeriodDate = dayjs(dateFrom).subtract(duration, "days")

    sales.forEach((sale) => {
        const saleDate = dayjs(sale.date, "YYYY-MM-DD").hour(12),
            dateName = new Date(saleDate.format("YYYY/MM/DD")).toLocaleDateString(undefined, {
                month: "long",
                day: "numeric",
                year: "numeric",
            }),
            dayIndex = Math.floor(saleDate.diff(pastPeriodDate, "day", true)) + 1

        if (dayIndex <= duration * 2 && dayIndex >= 0) {
            updateDailySales[dayIndex] = {
                y: sale.total_ecommerce,
                name: dateName,
            }
            updateDailyCheetrackSales[dayIndex] = {
                y: sale.total_cheetrack,
                name: dateName,
            }
            updateDailyCheetrackOrders[dayIndex] = {
                y: sale.total_q_cheetrack,
                name: dateName,
            }
            updateDailyAbandonedCheckout[dayIndex] = {
                y: sale.total_q_abandoned_checkout,
                name: dateName,
            }
        }
    })

    updateDailySales = fillAndCheckDailyArr(updateDailySales, pastPeriodDate, duration)
    updateDailyCheetrackSales = fillAndCheckDailyArr(updateDailyCheetrackSales, pastPeriodDate, duration)
    updateDailyCheetrackOrders = fillAndCheckDailyArr(updateDailyCheetrackOrders, pastPeriodDate, duration)
    updateDailyAbandonedCheckout = fillAndCheckDailyArr(updateDailyAbandonedCheckout, pastPeriodDate, duration)

    const processedSalesData = {
        updateDailySales,
        updateDailyCheetrackSales,
        updateDailyCheetrackOrders,
        updateDailyAbandonedCheckout,
    }

    console.log("Sales view data:", processedSalesData)

    return processedSalesData
}

// Procesa los datos de efectividad de templates

export const processTemplatesSales = (templates, dateFrom, duration) => {
    let salesByTemplate = {},
        totalSalesByTemplate = {},
        clientsReachedByTemplate = {},
        ordersByTemplate = {},
        dailyTemplateSalesTotal = [],
        dailyClientsReachedTotal = [],
        dailyOrdersTotal = []

    templates.forEach((t) => {
        totalSalesByTemplate[t.PK] = 0

        salesByTemplate[t.PK] = []
        clientsReachedByTemplate[t.PK] = []
        ordersByTemplate[t.PK] = []

        if (t.metadata.effectiveness) {
            t.metadata.effectiveness.forEach((e) => {
                const saleDate = dayjs(e.date, "YYYY/MM/DD").hour(12),
                    dateName = new Date(saleDate.format("YYYY/MM/DD")).toLocaleDateString(undefined, {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                    }),
                    dayIndex = Math.floor(saleDate.diff(dateFrom, "day", true)) + 1

                if (dayIndex <= duration && dayIndex >= 0) {
                    clientsReachedByTemplate[t.PK][dayIndex] = {
                        name: dateName,
                        y: e.reached_clients,
                    }
                    ordersByTemplate[t.PK][dayIndex] = {
                        name: dateName,
                        y: e.triggered_orders,
                    }

                    if (!dailyClientsReachedTotal[dayIndex]) {
                        dailyClientsReachedTotal[dayIndex] = {
                            name: dateName,
                            y: e.reached_clients,
                        }
                        dailyOrdersTotal[dayIndex] = {
                            name: dateName,
                            y: e.triggered_orders,
                        }
                    } else {
                        dailyClientsReachedTotal[dayIndex].y += e.reached_clients
                        dailyOrdersTotal[dayIndex].y += e.triggered_orders
                    }

                    e.sales.forEach((s) => {
                        const saleForDate = salesByTemplate[t.PK][dayIndex]
                        if (!saleForDate) {
                            salesByTemplate[t.PK][dayIndex] = {
                                name: dateName,
                                y: s.total,
                            }
                        } else saleForDate.y += s.total

                        totalSalesByTemplate[t.PK] += s.total

                        const dailyTemplateSaleTotal = dailyTemplateSalesTotal[dayIndex]
                        if (!dailyTemplateSaleTotal) {
                            dailyTemplateSalesTotal[dayIndex] = {
                                name: dateName,
                                y: s.total,
                            }
                        } else dailyTemplateSaleTotal.y += s.total
                    })
                }
            })
        }

        salesByTemplate[t.PK] = fillAndCheckDailyArr(salesByTemplate[t.PK], dateFrom, duration)
        clientsReachedByTemplate[t.PK] = fillAndCheckDailyArr(clientsReachedByTemplate[t.PK], dateFrom, duration)
        ordersByTemplate[t.PK] = fillAndCheckDailyArr(ordersByTemplate[t.PK], dateFrom, duration)
    })

    dailyTemplateSalesTotal = fillAndCheckDailyArr(dailyTemplateSalesTotal, dateFrom, duration)
    dailyClientsReachedTotal = fillAndCheckDailyArr(dailyClientsReachedTotal, dateFrom, duration)
    dailyOrdersTotal = fillAndCheckDailyArr(dailyOrdersTotal, dateFrom, duration)

    const data = {
        salesByTemplate,
        totalSalesByTemplate,
        clientsReachedByTemplate,
        ordersByTemplate,
        dailyTemplateSalesTotal,
        dailyClientsReachedTotal,
        dailyOrdersTotal,
    }

    console.log("Templates sales data:", data)

    return data
}
