const messages = {
    connectToInstagram: "Connect with Facebook & Instagram",
    yourInstagramIsConnected: "Your Facebook & Instagram accounts are connected!",
    yourWhatsAppIsConnected: "Your WhatsApp account is connected.",
    yourWhatsAppIsNotReady: "Your WhatsApp account is yet pending, we'll contact you when it's ready.",
    yourInstagramHasIssues: "We are having issues with your Instagram account, messages may fail.",
    yourWhatsAppHasIssues: "We are having issues with your WhatsApp account, messages may fail.",
    instagramInstructionsTitle: "Connect Cheetrack to Facebook & Instagram",
    instagramInstructions1: "To connect your Facebook & Instagram accounts you need to:",
    instagramInstructions2: "1) Have an Instagram Business account.",
    instagramInstructions3: "2) Have a Facebook business account connected to that Instagram account.",
    instagramInstructions4: "3) Press the button below and give Cheetrack the asked permissions.",
    youAlreadyHaveAnInstagramAccount: "You already have a Facebook account connected to this Cheetrack account.",
    youAlreadyHaveAnInstagramAccount2:
        "By pressing the button below, you can replace the currently connected Facebook account with another.",
    connectNewInstagram: "Connect to another Facebook account",

    integrations: "Integrations",
    connectWithShopify: "Connect with Shopify",
    yourShopifyAccountIsConnected: "Your Shopify account is connected!",
    shopifyInstrucitionsTitle: "Connect Cheetrack to Shopify",
    shopifyInstruction1: "To connect your Shopify account you need to:",
    shopifyInstruction2: "1) Go to Apps inside your Shopify account",
    shopifyInstruction3: "2) Click on 'Develop apps for your store'",
    shopifyInstruction4: "3) Click on 'Allow development of custom Apps'",
    shopifyInstruction5: "4) Click on 'Create an App'",
    shopifyInstruction6:
        "5) Write a name for your App (it can be Cheetrack), select an user as developer (it may be you) and click on 'Create App'",
    shopifyInstruction7: "6) Click on 'Configure Admin API scopes'",
    shopifyInstruction8:
        "7) Among the available scopes, go to the Orders section and check the following: 'read_orders', 'read_customers', 'write_discounts', and 'read_discounts'. Then click on 'Save'",
    shopifyInstruction9:
        "8) After saving is done, click on 'Install App', if a confirmation window pops up, click on 'Install'",
    shopifyInstruction10:
        "9) After installation is done, your Admin API Access Token will show up, click on 'Reveal token once' to see it",
    shopifyInstruction11: "10) Copy your Admin API Access Token and paste it on the input below",
    shopifyInstruction12:
        "11) Then, write down your shop url from Shopify on the other input, it is the page url address to your shopify account and should be something like: 'cheetrack.myshopify.com'",
    shopifyInstruction13: "12) Finally, click on 'Connect with Shopify'",
    adminApiAccessToken: "Admin API Access Token",
    youAlreadyHaveAnShopifyAccount: "You already have a Shopify account connected to this Cheetrack account.",
    youAlreadyHaveAnShopifyAccount2:
        "You can change your connection's Access Token and Shop URL and then press the button to replace the current connection with a new one.",
    connectNewShopify: "Replace Shopify connection",
    hereYouCanConnectCheetrackToOtherApps: "Here you can connect Cheetrack to other Apps.",
    clickingOnAnAppIconWillTellYouHowToConnectOrShowSettings:
        "Clicking on an App icon will show you how to connect Cheetrack to it, or display information about that App.",
    shopifyDescription1: "By connecting to Shopify you'll be able to:",
    shopifyDescription2: "Import and connect your clients and their orders.",
    shopifyDescription3: "Import and connect your product's catalog.",
    shopifyDescription4: "Rescue abandoned shopping carts",
    instagramDescription1: "By connecting to Facebook & Instagram you'll be able to::",
    instagramDescription2: "Send and receive messages from Facebook & Instagram.",
    lostCart: "Abandoned shopping cart",
    customerPhoneNumberRegistration: "Customer phone number registration",
    integrationsDescription:
        "Connecting to other Apps will allow you to receive messages, trigger chases or to link a variety of systems and functionalities with your Cheetrack account.",
    integrationsDescription2:
        "Each App has it's own way of integrating to Cheetrack that you'll need to check out one a at a time.",

    connectNewAgendaPro: "Connect new Agenda Pro account",
    connectWithAgendaPro: "Connect with Agenda Pro",
    agendaProInstrucitionsTitle: "Connect Cheetrack to Agenda Pro",
    yourAgendaProAccountIsConnected: "Your Agenda Pro account is connected",
    agendaProDescription1: "By connecting your Agenda Pro you'll be able to:",
    agendaProDescription2: "Send messages reminders to your clients.",
    agendaProInstruction1: "To connect your Agenda Pro account you need to:",
    agendaProInstruction2: "1) Go to Integrations / Public API on Agenda Pro, or click here: ",
    agendaProInstruction3: "2) Go to Public API and click on Create Webhook",
    agendaProInstruction4: "3) Paste the following on the text input: api.cheetrack.com/api/v1/agenda-pro/webhook",
    agendaProInstruction5: "4) Click active and then click Save",
    agendaProInstruction6: "5) Copy the information on User and Password for your public API on the text inputs below",
    agendaProInstruction7: "6) Click the 'Connect with Agenda Pro' button below",
    agendaProError: "An error ocurred when attempting to connect with Agenda Pro, please try again later.",
    youAlreadyHaveAnAgendaProAccount: "You already have an Agenda Pro account connected to this Cheetrack account.",
    youAlreadyHaveAnAgendaProAccount2:
        "You can change the account you are connected to by writing a different API User and API Password and clicking the button below.",
    agendaProInstructions1: "1. Visit the ",
    agendaProInstructions1b: " page in your AgendaPro account settings.",
    agendaProInstructions2:
        "2. In the Public API section, copy the User and Password values, and paste them into the fields below this form.",
    agendaProInstructions3: "3. In AgendaPro, press ** + Create Webhook** and copy the following URL: ",
    agendaProInstructions3b: "  Make sure it is https and is Active",
    agendaProInstructions4: "4. In Cheetrack, press Connect with AgendaPro",
    agendaProInstructions5: "Integrations / Public API",

    instagramError: "An error ocurred when attempting to integrate with Facebook & Instagram, please try again later.",
    shopifyError: "An error ocurred when attempting to integrate with Shopify, please try again later.",
    connectingToShopify: "Connecting to Shopify",
    toConnectWithShopifyYouMustLogIn: "To connect with Shopify you must log in to Cheetrack first!",
    finishingYouShopifyConnection: "Setting up the last steps of your Shopify connection 😉",

    whatsAppInstructionsTitle: "Connect to WhatsApp API",
    whatsAppDescription: "Connecting to WhatsApp will allow you to:",
    whatsAppDescription1: "Send and receive WhatsApp text messages.",
    whatsAppDescription2: "Send and receive images, files and audio recordings.",
    whatsAppDescription3: "Receive and render map locations.",
    whatsAppDescription4: "Program a Cheetabot to handle new clients.",
    whatsAppDescription5: "Set up chases to handle client follow up and reminders.",
    youAlreadyHaveAWhatsAppAccount: "You already have a WhatsApp API account connected to this Cheetrack account.",
    youAlreadyHaveAWhatsAppAccount2:
        "By pressing the button below, you can replace the currently connected WhatsApp API account with another.",
    connectNewWhatsApp: "Connect new WhatsApp API account",
    whatsAppError: "An error ocurred when attempting to connect to WhatsApp API, please try again later.",
    connectToWhatsApp: "Connect to WhatsApp API",
    whatsAppInstructions1: "How to connect to WhatsApp API:",
    whatsAppInstructions2: "Go to {link} ➜ If you don't have a developer profile: 'Get Started' ➜ Create your profile.",
    whatsAppInstructions3:
        "'My Apps' ➜ 'Create APP' ➜ 'Business' ➜ Name the APP ➜ Select your business account ➜ 'Create'",
    whatsAppInstructions4: "Enter the APP's panel ➜ 'Add products' ➜ 'Facebook LogIn'",
    whatsAppInstructions5: "Then on the left menu 'Facebook LogIn' ➜ 'Settings'",
    whatsAppInstructions51: "Login with the JavaScript SDK: Yes",
    whatsAppInstructions52: "Valid OAuth Redirect URIs: https://app.cheetrack.com/integrations",
    whatsAppInstructions53: "Allowed Domains for the JavaScript SDK: https://app.cheetrack.com ➜ 'Save Changes'",
    whatsAppInstructions6: "On the left panel ➜ 'Webhooks' ➜ 'Settings'",
    whatsAppInstructions61: "On the 'User' dropdown we select 'WhatsApp Business Account' ➜ 'Suscribe'",
    whatsAppInstructions62: "Callback URL: https://api.cheetrack.com/v1/whatsapp/webhook/receive",
    whatsAppInstructions63: "Verification Token: meatyhamhock",
    whatsAppInstructions64: "Then click on suscribe for all options.",
    whatsAppInstructions7: "On the left panel ➜ 'WhatsApp' ➜ 'Settings'",
    whatsAppInstructions71:
        "On this step you should've deleted your WhatsApp account and have your SIM card on a phone to receive an SMS.",
    whatsAppInstructions72: "'First steps'",
    whatsAppInstructions73: "We go to step 5 and add a phone number.",
    whatsAppInstructions74: "Fill the required information.",
    whatsAppInstructions741:
        "If your business vertical is 'beauty, spa' we recommend you do not pick that option, because it may trigger a rejection from WhatsApp.",
    whatsAppInstructions75: "We add our phone number and send the code.",
    whatsAppInstructions8:
        "Your phone number has been sent for verification 😉, you'll receive an email 📩 stating 'your WhatsApp account has been approved'. Continue to next step ➡️ once you have received the email.",
    whatsAppInstructions9: "In case it takes a while for you to receive the email, please check the following videos:",
    whatsAppInstructions10: "7) Once your number is verified, you need to connect WhatsApp to Cheetrack.",
    whatsAppInstructions11:
        "We are ready! You are connected, now you can send and receive WhatsApp messages on Cheetrack.",
    whatsAppInstructions101: "Go to {link} and select your business",
    whatsAppInstructions102: "Click on 'Settings' and then 'Business Settings'",
    whatsAppInstructions103: "On the left side menu, under 'Users' ➜ 'System Users'",
    whatsAppInstructions104: "Click on 'Add' ➜ Add your name ➜ Select Admin role",
    whatsAppInstructions105:
        "On the right side, click on 'Add assets' ➜ On the left 'Apps' ➜ Select Cheetrack ➜ Grant all permissions ➜ 'Save Changes'",
    whatsAppInstructions106: "Click on 'Generate new ID'",
    whatsAppInstructions107:
        "Select the App ➜ Grant the following permissions ➜ 'whatsapp_business_messaging' and 'whatsapp_business_management'",
    whatsAppInstructions108: "Copy the Access Token ➜ Go to Cheetrack ➜ Paste the Access Token on the box below",
    whatsAppInstructions109: "Go back to the business manager ➜ On the left, 'Accounts' ➜ 'WhatsApp Accounts'",
    whatsAppInstructions110:
        "Select the WhatsApp account ➜ Copy the ID ➜ Go to Cheetrack ➜ Paste in WhatsApp Business ID",
    whatsAppInstructions111: "Click on 'Connect to WhatsApp API'",
    whatsAppStep1: "1. Create Facebook APP",
    whatsAppStep2: "2. Facebook LogIn settings",
    whatsAppStep3: "3. Webhooks settings",
    whatsAppStep4: "4. WhatsApp settings",
    facebookAppSecret: "App Secret",
    facebookAppId: "App Id",
    shopifyInstructionsForConnectWithWhatsapp:
        "There is only one last step left for your account to be ready, you need to integrate with Whatsapp meta, for that press the button below that will take you to the integration panel with Whatsapp and other apps.",
    makeSureYouHaveVerifiedYourFacebookAccount: "Make sure you have verified your Facebook account's manager.",
    accessToken: "Access Token",
    wabaId: "WhatsApp Business Application Id",
    seeInstructions: "See instructions",
}

export default messages
