const messages = {
    fidelization: "Fidelización",
    fidelizationDesc: "Define la estrategia para fidelizar a tus clientes según su frecuencia de compra.",
    fidelizationTimeframe: "Rango de fidelización",
    condition: "Condición",
    reward: "Recompensa",
    couponCode: "Código de cupón",
    couponUrl: "URL de cupón",
    welcomeToFidelizationMessage: "Mensage de bienvenida a la fidelización, enviado tras primer paso",
    fidelizationDiscountMessage: "Mensaje de envío de recompensa",
    addFidelizationStep: "Agregar paso de fidelización",
    deleteFidelizationStep: "Eliminar paso de fidelización",
    fidelizationSale: "Compra",
    fidelizationVisit: "Visita",
    salesDataObtainedThroughShopify: "Datos de compra obtenidos de Shopify.",
    visitDataObtainedThroughMenuMessageSent: "Datos de visita obtenido de cartas envíadas por mensajería.",
    addReward: "Agregar recompensa",
    thisMessageNeedsToBeApprovedBeforeStartingFidelization:
        "Este mensaje necesita ser aprobado antes de activar esta estrategia de fidelización.",
    previewOfFidelizationStartingMessage: "Vista previa del mensaje de inicio de fidelización",
    previewOfFidelizationRewardMessage: "Vista previa del mensaje de recompensa de fidelización",
    restaurantView: "Vista Restaurante",
    fidelizationTemplateWaitingForApproval: "El mensaje de fidelización está esperando aprobación",
    youNeedToWait3MinutesBeforeSavingAgain: "Necesitas esperar 3 minutos antes de guardar de nuevo",
    fidelizationTemplateWasRejected: "El mensaje de fidelización fue rechazado",

    obtainingFidelizationData: "Obteniendo datos de fidelización...",
    fidelizationAnswersDesc:
        "Si tu ticket promedio por persona  es {average_ticket} Cheetrack te ayudó a generar una venta estimada de {total_ticket}",
    anErrorOcurredObtainingFidelizationData: "Ocurrió un error obteniendo los datos de fidelización.",
    couponsUsedByCustomers: "Cupones usados por clientes",
    couponsSent: "Cupones enviados",
    couponsUsed: "Cupones usados",
    estimatedSale: "Venta estimada",
    youNeedToSetUpFidelizationToSeeThisData: "Necesitas configurar la fidelización para ver estos datos.",
    goToFidelizationSetUp: "Ir a configuración de fidelización",
    dataCanTakeUpToADayToBeUpdated: "Los datos pueden tardar hasta un día en actualizarse",
    clientsWhoExceededMaximumProfitsInThePeriod: "Clientes que superaron los beneficios máximos en el período",
    totalBenefitsDeniedOnTheDay: "Total Beneficios denegados en el día",
    showFirstBenefit: "Mostrar el primer beneficio",
    totalBenefitsUsed: "Total beneficios usados",
    doYouWantToActivateTheSatisfactionBot: "¿Desea triggear bot de satisfacción?",
    benefitProgram: "Programa beneficios",
    clientList: "Listado de clientes",
    benefitsObtained: "Beneficios obtenidos",
    globalVisits: "Visita global",
    showingInformationFromTo: "Mostrando informacion desde {from} hasta {to}",
}

export default messages
