import dayjs from "dayjs"
import locales, { ES, EN } from "../locale"

let locale
const storedLoc = localStorage.getItem("locale"),
    browserLocale = navigator.language || navigator.userLanguage || null

if (storedLoc) locale = storedLoc
else if (browserLocale.startsWith("es")) locale = ES
else locale = EN

const intl = locales[locale]

export const processMonthlyFrequencyData = (data) => {
    const newData = []

    data.forEach((item) => {
        const index = dayjs(item.month, "YYYY-MM").diff(dayjs(), "month") * -1

        Object.keys(item.data).forEach((key, dataIndex) => {
            const value = item.data[key],
                percentage = Math.floor((value / item.data["Month0"]) * 1000) / 10,
                point = {
                    x: dataIndex,
                    y: index,
                    value: dataIndex === 0 ? value : percentage,
                    color: dataIndex === 0 ? "white" : undefined,
                    dataLabels: {
                        enabled: true,
                        style: { color: dataIndex === 0 || percentage < 30 ? "black" : "white" },
                        format: dataIndex === 0 ? "{point.value}" : value + " ({point.value}%)",
                    },
                    tooltip: dataIndex === 0 ? "{point.value}" : value + " ({point.value}%)",
                }
            newData.push(point)
        })
    })

    return newData
}

// Procesa los datos de la consulta de frecuencia de clientes semanal

export const processWeeklyFrequencyData = (data) => {
    const newData = []

    data.reverse().forEach((item, index) => {
        Object.keys(item.data).forEach((key, dataIndex) => {
            const value = item.data[key],
                percentage = Math.floor((value / item.data[item.week]) * 1000) / 10,
                point = {
                    x: dataIndex,
                    y: index,
                    value: dataIndex === 0 ? value : percentage,
                    color: dataIndex === 0 ? "white" : undefined,
                    week: key.split("-")[1],
                    originalWeek: item.week.split("-")[1],
                    dataLabels: {
                        enabled: true,
                        style: { color: dataIndex === 0 || percentage < 30 ? "black" : "white" },
                        format: dataIndex === 0 ? "{point.value}" : value + " ({point.value}%)",
                    },
                    tooltip:
                        dataIndex === 0
                            ? intl.formatMessage(
                                  { id: "xNewClientsOnWeekY" },
                                  {
                                      x: value,
                                      y: item.week.split("-")[1],
                                  }
                              )
                            : `<span>${intl.formatMessage(
                                  { id: "xClientsFromWeekYReturnedOnWeekZ" },
                                  {
                                      x: value,
                                      y: item.week.split("-")[1],
                                      z: key.split("-")[1],
                                  }
                              )}</span>`,
                }
            newData.push(point)
        })
    })

    return newData
}
