const messages = {
    chases: "Perseguições",
    chasesSubtitle: "Persiga seus clientes como um guepardo e não os deixe escapar.",
    chasesTrigger: "Gatilho",
    chasesTriggerDesc: "O evento que dá início à perseguição",
    chasesAppEvent: "Evento em outro aplicativo",
    chasesAppEventDesc: "A perseguição começa quando algo acontece em um aplicativo",
    chasesSchedule: "Agendar",
    chasesScheduleDesc: "Começa a perseguição a intervalos regulares",
    chasesTag: "Rótulo",
    chasesTagDesc: "A perseguição começa depois de adicionar uma etiqueta a uma conversa",
    chasesSelectTriggerEvent: "Selecione o evento gatilho",
    chasesConnectYourAccount: "Conecta tua conta",
    chasesTestTrigger: "Testar gatilho",
    chasesChangeAccount: "Trocar de conta",
    chasesTemplateSequence: "Sequência de mensagens",
    chasesTemplateSequenceDesc: "Será enviado um conjunto de mensagens até que o cliente responda",
    chasesSelectTemplate: "Selecione uma mensagem inicial para enviar",
    chasesWait: "Aguardar ",
    chasesBeforeSendingTemplate: " antes de enviar.",
    chasesSend: "Enviar ",
    chasesTriggerEventOcurred: " Após a ocorrência do evento gatilho.",
    chasesPreviousTemplateWasSent: " depois da mensagem anterior, se não houve resposta.",
    chasesSendCheetabotAfter: "Ativar o Cheetabot",
    chasesSendCheetabotAfterDesc: "Si a resposta for obtida, o Cheetabot será ativado para essa conversa",
    createChase: "Criar perseguição",
    chasesCancel: "Cancelar",
    chasesSelectTag: "Selecione uma etiqueta para agir como gatilho",
    chasesSelectApp: "Selecione um aplicativo ao qual conectar",
    chasesConnectAccount: "Conecte sua conta",
    chasesApiKey: "Ingrese a chave API necessária",
    chasesSelectAppEvent: "Selecione um evento do aplicativo",
    chasesSelectATimeFrequency: "Escolha uma frequência para ativar esta perseguição",
    chasesOnceAMonth: "Uma vez por mês",
    chasesSelectDayOfTheMonth: "Selecione um dia do mês",
    onceAWeek: "Uma vez por semana",
    selectDayOfTheWeek: "Selecione um dia da semana",
    everyDay: "Cada dia",
    selectTimeOfTheDay: "Selecione uma hora do dia",
    chasesMessageTemplate: "Mensagem inicial",
    chasesAddMessageTemplateToSequence: "Adicionar outra mensagem à sequência",
    chasesActivateBotToRespond: "Ativar o Cheetabot quando uma mensagem da sequência receber uma resposta.",
    publishChase: "Publicar esta perseguição",
    unpublishChase: "Despublicar esta perseguição",
    chaseName: "Dê um nome a esta perseguição",
    chasesSavingError: "Ocorreu um erro ao salvar esta perseguição. Por favor, tente novamente mais tarde",
    thereCanBeADifferenceInActualTimes:
        "O intervalo entre mensagens não pode ser inferior a 1 dia. Pode haver um atraso de 30 a 60 minutos ao enviar as mensagens.",
    chasesPreviousTagConversationsMsg:
        "A perseguição NÃO será aplicada às conversas em que o gatilho foi executado anteriormente à publicação da perseguição.",
    chasesNoteChaseEndedAndConversationsArchived: "Se o cliente não responder, a conversa será arquivada.",
    chasesPreviousTagConversationsMsg2:
        "Se deseja que as conversas com a etiqueta gatilho recebam esta perseguição, você precisa associar a etiqueta novamente a elas.",
    activateChaseInTaggedConversations:
        "Ativar esta perseguição em {taggedConversations} conversas que já tinham esta etiqueta.",
    chasesDeleteDialogTitle: "¿Você tem certeza de que deseja excluir a perseguição {{chase_name}}?",
    chasesChangeToPublishedDialogTitle: " Publicar perseguição",
    chasesChangeToDraftDialogTitle: " Cancelar perseguição e tornar rascunho",
    chasesDeleteDialogDescription:
        "Al eliminar esta perseguição, todas as mensagens e ações programadas serão interrompidas e perdidas. Isso afetará {{number_of_client_with_active_chase}} clientes que têm esta perseguição ativa.",
    chasesChangeToDraftDialogDescription:
        "Ao aceitar esta perseguição, ela será cancelada, eliminando todas as perseguições em andamento. Enquanto estiver neste estado, você poderá editar a perseguição. Deseja continuar?",
    chasesChangeToPublishedDialogDescription:
        "Ao aceitar esta perseguição, ela será publicada e as mensagens serão enviadas de acordo com as regras que você configurou. ¿Deseja continuar?",
    chasesDialogConfirmButton: "Confirmar",
    chasesUnavailableVariableWarning:
        "Se a mensagem inicial utilizar uma variável, será enviada apenas para clientes que a tenham definida (por exemplo, se utilizar {{customer_company_name}}, será enviada apenas para clientes que tenham a informação de Companhia).",
    youCanSendUpToChaseMessages: "Pode enviar até {number} mensagens de perseguição por dia.",
    chasesGraphExplanation:
        "No gráfico, você pode ver quantas mensagens suas perseguições enviarão nas próximas 2 semanas.",
    chasesCreationGraphExplanation:
        "No gráfico, você pode ver quantas mensagens sua nova perseguição enviará e quando.",
    chaseHardLimitCreationDesc:
        "No é possível criar esta perseguição porque excederia o limite de 600 mensagens de perseguição diárias!",
    thisChase: "Esta perseguição",
    overDailyLimitOn: "Sobre o limite diário em:",
    yourChasesCanOnlyTemplates: "Uma perseguição não pode ter mais de {templates} mensagens.",
    chaseDeletingError: "Ocorreu um erro ao excluir esta perseguição, tente novamente mais tarde.",
    chaseStatusDraft: "Rascunho",
    chaseStatusPublished: "Publicado",
    chaseMsgNoteHeaderChangeToDraftForEdit:
        "Você precisa colocar esta perseguição no estado de rascunho para poder editá-la",
    chaseTooltipSwitch: "Não é possível alterar o estado desta perseguição porque a informação está incompleta.",
    chaseNameIsRequired: "Sua perseguição precisa de um nome",
    chaseThatTriggerEventRequiresATemplateVariable:
        "Esse tipo de evento gatilho requer que a mensagem tenha a variável: {variable}.",
    chaseThatTriggerEventHasMaximunTemplates: "Esse tipo de evento gatilho não pode ter mais de {x} mensagens.",
    chasePleaseRemoveXTemplates: "Por favor, remover {x} mensagens.",
    chaseTimelimit: "Uma perseguição não pode durar mais de {limit} dias.",
    sendMessagesThrough: "Enviar as mensagens desta perseguição através de:",
    SMSSegments: " segmentos SMS",
    clientSegments: "Segmentos de clientes",
    chooseASegment: "Selecione um segmento de clientes",
    onceAWeekSendAMessageToClientsMeetingCriteria:
        "Uma vez por semana, envie mensagens a clientes que atendam a determinado critério.",
    equalTo: "igual a",
    equalOrGreaterThan: "igual ou maior que",
    equalOrLessThan: "igual ou menor que",
    greaterThan: "maior que",
    lessThan: "menor que",
    segmentCriteria: "Critério de segmentação",
    operator: "Operador",
    segmentCustomersWhoHavePurchasedTheProducts:
        "Segmente os clientes que compraram os produtos: {products} nos últimos {days} dias",
    segmentCustomersWhoHavePurchasedTheProductsBeetwen:
        "Segmente os clientes que compraram os produtos: {products} nos últimos {daysAgo} dias e antes de {days} dias.",
    value: "Valor",
    until: "até",
    lastOrderDate: "Data da última compra",
    averagePurchase: "Compra média",
    customerAddedDate: "Data de registro do cliente",
    numberOfOrders: "Número de pedidos",
    productsPurchased: "Produtos adquiridos",
    createSegment: "Criar novo segmento",
    editSegment: "Editar segmento",
    deleteSegment: "Excluir segmento",
    productName: "Comprou: [Nome do produto]",
    saveSegment: "Salvar segmento",
    addCriteria: "Adicionar outro critério de segmentação",
    segmentName: "Nome do segmento",
    otherCriteria: "Outros critérios",
    selectADayOfTheWeekToSendMessages: "Selecione um dia da semana para iniciar a perseguição",
    segmentChaseDescription1: "Cada ",
    segmentChaseDescription2: "Gatilhar perseguição para clientes do segmento ",
    youNeedToCreateASegmentFirst: "Você precisa criar um segmento antes de configurar esta perseguição.",
    segmentChaseCaption1:
        "Para evitar spam, a perseguição não será iniciada em clientes que já a tenham recebido há menos de 30 dias.",
    segmentChaseCaption2: "Devido às políticas do WhatsApp, será enviado para no máximo 500 clientes por dia.",
    tagChaseDescription: "Esta perseguição será iniciada quando uma etiqueta for atribuída a um cliente: ",
    appChaseDescription1: "Esta perseguição será iniciada quando na aplicação ",
    appChaseDescription2: " ocorrer o evento ",
    daysAgo: "dias atrás",

    thisMessageWillBeSentEach: "Esta mensagem será enviada a cada ",
    atTime: " as ",
    onTheChosenDayTheSegmentWillBeUpdated:
        "No dia selecionado, o segmento será atualizado antes de enviar as mensagens.",
    clientsWontReceiveThisMessageMoreThanOnce: "Os clientes que já receberam esta mensagem não a receberão novamente.",
    anErrorOcurredSavingYourSegment: "Ocorreu um erro ao tentar salvar seu segmento.",
    anErrorOcurredGettingSegments: "Ocorreu um erro ao tentar obter seus segmentos.",
    loadingSegments: "Carrregando segmentos",
    dueToWhatsAppLimitsWellOnlySendXMessages:
        "Devido aos limites da API do WhatsApp, enviaremos no máximo 500 mensagens por vez.",
    clientsSegment: "Segmento de clientes",
    inTheLast: "dentro dos últimos",
    beforeThan: "anterior a",
    period: "Período",
    thisChaseWillRunOnXDays: "Esta perseguição será iniciada em {x} dias.",
    thisChaseWillRunTomorrow: "Esta perseguição será iniciada amanhã.",
    giveThisChasePriority: "Dê prioridade a esta perseguição ",
    overOtherChases: " em relação a outras perseguições.",
    priorityExplanation:
        "Isto permitirá decidir quais mensagens enviar caso estejamos nos aproximando do limite diário imposto pelo WhatsApp.",
    priority: "Prioridade",

    runThisChaseOnce: "Enviar uma única vez",
    thisMessageWillBeSentAt: "Enviar esta mensagem no dia ",
    allClients: "Todos os clientes (sem segmento) ",

    chasesList: "Lista de perseguições",
    chaseLogs: "Histórico de perseguições",
    withYourCurrentLimitItWillBeSentOnXDays:
        "Com seu limite atual de {x} mensagens diárias, elas serão enviadas em {y} dias.",
    youNeedToKeepSendingMessageToUpYourLimit: "Você precisa continuar enviando mensagens para aumentar seu limite.",
    clickHereIfYouPreferNotToSendOverSeveralDays:
        "Clique aqui se preferir enviar tudo em um único dia. Isso enviará apenas os primeiros {x} mensagens.",
    sendThisChaseTo: "Enviar esta perseguição para ",
    yourCurrentDailyLimitIs: "Seu limite diário atual é de {x} mensagens.",
    thisWillTakeXDays: "Isto levará {x} dias.",

    youCanOnlyHaveOneChaseWithThisTrigger: "Você não pode ter mais de uma perseguição ativa com este evento gatilho.",
    confirmedPurchaseOrder: "Ordem de compra paga e confirmada",
    unpaidPurchaseOrder: "Ordem de compra não paga",
    sendStartMessage: "Enviar mensagem inicial",
    startCheetabot: "Iniciar o Cheetabot",
    chasesSelectCheetabot: "Selecione um Cheetabot para iniciar",
    chasesStart: "Iniciar",
    afterPreviousBotCloses: "após o fechamento do Cheetabot anterior.",
    addAnotherMessageOrCheetabot: "Adicionar outra mensagem ou Cheetabot",
    botNeedsToStartWithAStartMessageForWaitsOver24Hours:
        "O Cheetabot deve começar com uma Mensagem de Início para esperas superiores a 24 horas.",
    doesntStartWithAStartMessage: "Não inicia com uma Mensagem de Início.",
    botIsNotReady: "Este Cheetabot não está pronto. Por favor, aguarde alguns minutos e atualize a lista.",
    refreshBotList: "Atualizar lista de Cheetabots",

    youCannotHaveThatTriggerWithACheetabot: "Você não pode ter esse evento gatilho com um Cheetabot.",
    inUseByAnotherChase: "Em uso por outra perseguição",
    botDisabled: "Cheetabot não ativo",
    setUpATimeFrameForMessages: "Defina um intervalo de horário para as mensagens",
    youCanOnlyHaveUpToXChases: "Só é possível ter até {x} perseguições.",
    youWillNeedToDeleteSomeChasesToCreateANewOne: "Você precisa excluir algumas perseguições para criar uma nova.",
    abandonedCheckoutsOutsideThisTimeFrameWillNotTriggerThisChase:
        "Carrinhos abandonados fora deste intervalo de horário não acionarão esta perseguição.",
    orderUpdatedReadyForPickup: "Pedido pronto para retirada",
    orderUpdatedCanceled: "Pedido cancelado",
    orderUpdatedFulfilled: "Pedido retirado",
}

export default messages
