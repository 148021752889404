import { configureStore } from "@reduxjs/toolkit"
import chatSlice from "./chat-slice"
import cheetabotSlice from "./cheetabot-slice"
import conversationsSlice from "./conversations-slice"
import tagsSlice from "./tags-slice"
import userSlice from "./user-slice"
import websocketSlice from "./websocket-slice"

const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    reducer: {
        user: userSlice.reducer,
        websocket: websocketSlice.reducer,
        conversations: conversationsSlice.reducer,
        chat: chatSlice.reducer,
        tags: tagsSlice.reducer,
        cheetabot: cheetabotSlice.reducer,
    },
})

export default store
