//  import { TENANT_IDS } from "../constants"
// import isCheetrackTenant from "./is-cheetrack-tenant"

function hasBotStartMessages(tenant) {
    // return (
    //     isCheetrackTenant(tenant.id) ||
    //     tenant.id === TENANT_IDS.PAVAROTTI_RISTORANTE ||
    //     tenant.id === TENANT_IDS.TOWNHOUSE_IMMOBILIARIA ||
    //     tenant.id === TENANT_IDS.OSTERIA_MAIORI ||
    //     tenant.id === TENANT_IDS.STARBUCKS ||
    //     tenant.id === TENANT_IDS.MYHUEVOS ||
    //     tenant.id === TENANT_IDS.FIORELL ||
    //     tenant.id === TENANT_IDS.WILDLAMA ||
    //     tenant.id === TENANT_IDS.WILDFOODS ||
    //     tenant.id === TENANT_IDS.KAYAUNITE ||
    //     tenant.is_restaurant
    // )
    return true
}

export default hasBotStartMessages
