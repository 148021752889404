// Reduce la cantidad de datos de la entidad client para que ocupen menos memoria en front

export const reduceClient = (cl) => {
    const client = {
        PK: cl.PK,
        answer: cl.answer,
        phone: cl.phone,
        read: cl.read,
        user: (cl.user || "").replace("USER#", ""),
        status: cl.status,
        sort_date_message: cl.sort_date_message,
        metadata: {
            company: cl.metadata.company,
            firstname: cl.metadata.firstname,
            lastname: cl.metadata.lastname,
            email: cl.metadata.email,
            last_message: cl.metadata.last_message,
            has_chase: cl.metadata.has_chase,
            has_chase_active: cl.metadata.has_chase_active,
            has_bot_active: cl.metadata.has_bot_active,
            wa_id: cl.metadata.wa_id,
            shopify: cl.metadata.shopify,
            source: cl.metadata.source,
            ig_id: cl.metadata.ig_id,
            tag: cl.metadata.tag,
            blacklist: cl.metadata.blacklist,
            current_bot_id: cl.metadata.current_bot_id,
            visit_data: cl.metadata.visit_data,
            benefits: cl.metadata.benefits,
        },
    }

    if (client.metadata?.source === "facebook") client.isFacebookClient = true
    else if (client.metadata?.source === "instagram" || (client.metadata?.ig_id && client.metadata?.ig_id !== "N/A"))
        client.isInstagramClient = true
    else if (client.metadata?.source === "sms") client.isSMSClient = true
    else if (
        client.metadata?.source === "meta_whatsapp" ||
        client.metadata?.source === "whatsapp" ||
        (client.metadata?.wa_id && client.metadata?.wa_id !== "N/A")
    )
        client.isWhatsappClient = true

    return client
}
