import { createSlice } from "@reduxjs/toolkit"
import { getStoredChatInitialState, reduceMessageProps } from "../utils"
import initialState from "./chat-initial-state"

const storedState = getStoredChatInitialState()

const chatSlice = createSlice({
    name: "chat",
    initialState: {
        ...initialState,
        ...storedState,
    },
    reducers: {
        startMessageLoader(state, action) {
            state.loadingMessages[action.payload] = true
            state.messagesError.getMessages = false
        },
        setMessages(state, action) {
            const messages = { ...state.messages },
                { pageNo, clientId, total, refresh } = action.payload,
                clientMessages = action.payload.messages.filter(
                    (m) => m.metadata.source !== "twilio" || m.metadata.body !== "" || m.metadata.media?.length > 0
                )

            state.messagesPaging[clientId] = {
                current: refresh ? state.messagesPaging[clientId]?.current || 1 : pageNo,
                total: Math.ceil(total / 20),
            }

            //simplify client model to use less memory
            let simplifiedMessages = clientMessages.map((msg) => reduceMessageProps(msg))
            if (refresh) messages[clientId] = simplifiedMessages
            else {
                if (messages[clientId])
                    simplifiedMessages = simplifiedMessages.filter(
                        (msg) => !messages[clientId].some((m) => m.PK === msg.PK)
                    )
                messages[clientId] = [...(messages[clientId] || []), ...simplifiedMessages]
            }

            messages[clientId].sort((a, b) => a.sort_date - b.sort_date)
            console.log("Messages:", messages[clientId])

            state.messages = messages
            state.loadingMessages[clientId] = false
        },
        setMulticlientMessages(state, action) {
            state.messages.multiple = action.payload
        },
        getMessagesError(state, action) {
            state.loadingMessages[action.payload] = false
            state.messagesError.getMessages = true
        },
        setMultiMessageInChat(state, action) {
            const messages = { ...state.messages },
                multiMessages = [...messages["multiple"]],
                { temporalMessageId, queryData } = action.payload,
                date = new Date().toISOString().substr(0, 19).replace("T", " ").replace(/-/g, "/")

            multiMessages.push({
                temporalMessageId: temporalMessageId + "b",
                message: queryData.receiver[0].body,
                user: "USER#" + queryData.sender.email,
                sort_date: new Date(date),
                sentByUser: true,
                metadata: {
                    ...queryData,
                    body: queryData.receiver[0].body,
                    created_at: date,
                },
            })
            multiMessages.push({
                temporalMessageId: temporalMessageId + "a",
                message: "Se ha enviado el mensaje a " + queryData.receiver.length + " clientes \n",
                isBot: true,
                SK_DATE: date,
                metadata: {
                    body: "Se ha enviado el mensaje a " + queryData.receiver.length + " clientes \n",
                    created_at: date,
                    updated_at: date,
                },
            })

            messages["multiple"] = multiMessages
            state.messages = messages
        },
        setCurrUserMessageInChat(state, action) {
            const { clientId, temporalMessageId, queryData } = action.payload,
                date = new Date().toISOString().substr(0, 19).replace("T", " ").replace(/-/g, "/"),
                messageBody = queryData.receiver[0].body,
                newMessage = {
                    user: "USER#" + queryData.sender.email,
                    sentByUser: true,
                    temporalMessageId: temporalMessageId,
                    thisSessionMsg: true,
                    sort_date: new Date(date),
                    metadata: {
                        ...queryData,
                        created_at: date,
                        is_client_message: false,
                        read: false,
                        body: messageBody,
                    },
                    original_receiver: queryData.receiver,
                }

            if (state.lastMessage === messageBody) state.repeatedMessageCounter++
            else state.lastMessage = messageBody

            if (newMessage.metadata.media && newMessage.metadata.media[0] && action.payload.mediaPreview)
                newMessage.metadata.media[0].preview = action.payload.mediaPreview

            state.messages[clientId] = [...(state.messages[clientId] || []), reduceMessageProps(newMessage)]
        },
        updateUserMessage(state, action) {
            const { clientId, temporalMessageId, data } = action.payload,
                clientMessages = [...(state.messages[clientId] || [])],
                messageToUpdateIndex = clientMessages.findIndex(
                    (msg) => msg.sentByUser && msg.temporalMessageId === temporalMessageId
                ),
                updateAttr = ["PK", "SK_DATE", "metadata"],
                updatedMessage = { ...clientMessages[messageToUpdateIndex] }

            updateAttr.forEach((attr) => {
                if (data[attr] !== undefined) updatedMessage[attr] = data[attr]
            })

            if (data.error && (!data.metadata || !data.metadata.error)) {
                if (!updatedMessage.metadata) updatedMessage.metadata = {}
                updatedMessage.metadata.error = { code: 1 }
                if (data.errorMessage) updatedMessage.metadata.errorMessage = data.errorMessage
            }

            clientMessages[messageToUpdateIndex] = reduceMessageProps(updatedMessage)
            state.messages[clientId] = clientMessages
        },
        setQuickTexts(state, action) {
            state.quickTexts = action.payload
            state.loadingQuickTexts = false
        },
        setQuickTextsError(state) {
            state.loadingQuickTexts = false
            state.quickTextsError = true
        },
        showQuickTextsLoader(state) {
            state.loadingQuickTexts = true
        },
        addQuickText(state, action) {
            state.quickTexts = [action.payload, ...state.quickTexts]
        },
        updateQuickText(state, action) {
            const index = state.quickTexts.findIndex(
                (qt) => qt.PK === action.payload.PK || (action.payload.id && qt.id === action.payload.id)
            )

            if (index !== -1) {
                if (action.payload.text) state.quickTexts[index].metadata.text = action.payload.text
                if (action.payload.user) state.quickTexts[index].user = "USER#" + action.payload.user
                if (action.payload.PK) state.quickTexts[index].PK = action.payload.PK
            }
        },
        deleteQuickText(state, action) {
            state.quickTexts = state.quickTexts.filter((qt) => qt.PK !== action.payload)
        },
        setTemplates(state, action) {
            state.templates = action.payload
            state.loadingTemplates = false
        },
        setTemplatesFlow(state, action) {
            state.templatesFlows = action.payload
            state.loadingTemplatesFlows = false
        },
        setTemplateserror(state) {
            state.loadingTemplates = false
            state.templatesError = true
        },
        showTemplatesLoader(state) {
            state.loadingTemplates = true
        },
        addTemplate(state, action) {
            state.templates = [action.payload, ...state.templates]
            state.templateCreationError = false
            state.savingTemplate = true
        },
        updateTemplate(state, action) {
            const index = state.templates.findIndex((qa) => qa.PK === action.payload.PK || qa.id === action.payload.id)
            state.templates[index] = { ...state.templates[index], ...action.payload }

            state.savingTemplate = false
        },
        deleteTemplate(state, action) {
            state.templates = state.templates.filter((qa) => qa.PK !== action.payload && qa.id !== action.payload)
        },
        setDismissedAlerts(state, action) {
            state.dismissedAlerts = action.payload
        },
        setClientAssignedToOtherConfirmation(state, action) {
            state.clientAssignedToOtherConfirmation = action.payload.showDialog
            state.messageOnHold = action.payload.messageData || {}
        },
        resetRepeatedMessageCounter(state) {
            state.repeatedMessageCounter = 0
        },
        setTemplateCreationError(state, action) {
            state.templateCreationError = action.payload
        },
        receiveMessages(state, action) {
            const { newMessages, selectedClientId } = action.payload

            newMessages.forEach((msg) => {
                const clientId = msg.client.replace("CLIENT#", ""),
                    incomingMessage = { ...msg }

                if (
                    !state.messages[clientId] ||
                    !state.messages[clientId].some(
                        (m) => (m.PK && m.PK === msg.PK) || (m.PK && "MESSAGE#" + m.PK === msg.PK)
                    )
                ) {
                    state.messages[clientId] = [
                        ...(state.messages[clientId] || []),
                        reduceMessageProps(incomingMessage),
                    ]
                }
            })
        },
        updateMessages(state, action) {
            const updateMessages = action.payload

            updateMessages.forEach((msg) => {
                const incomingMessage = { ...msg },
                    clientId = incomingMessage.client.replace("CLIENT#", ""),
                    clientMessages = [...(state.messages[clientId] || [])],
                    messageToUpdateIndex = clientMessages.findIndex(
                        (m) => m.PK && (m.PK === incomingMessage.PK || "MESSAGE#" + m.PK === incomingMessage.PK)
                    ),
                    updateAttr = ["PK", "SK_DATE", "metadata", "status"]

                if (messageToUpdateIndex !== -1) {
                    const updatedMessage = { ...state.messages[messageToUpdateIndex] }

                    updateAttr.forEach((attr) => {
                        if (incomingMessage[attr] !== undefined) updatedMessage[attr] = incomingMessage[attr]
                    })

                    if (incomingMessage.error && !incomingMessage.metadata.error)
                        updatedMessage.metadata.error = { code: 1 }
                    clientMessages[messageToUpdateIndex] = reduceMessageProps(updatedMessage)
                    state.messages[clientId] = clientMessages
                }
            })
        },
        sendMessages(state, action) {
            const newMessages = action.payload

            newMessages.forEach((msg) => {
                const incomingMessage = { ...msg },
                    clientId = incomingMessage.client.replace("CLIENT#", "")

                state.messages[clientId] = [...(state.messages[clientId] || []), reduceMessageProps(incomingMessage)]
            })
        },
    },
})

export const chatActions = chatSlice.actions
export default chatSlice
