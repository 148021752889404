export function hasClientChanged(incomingClient, comparingClient) {
    const baseProps = ["SK_DATE", "active", "answer", "read", "scheduled_date", "status", "user"],
        metaProps = [
            "answer",
            "company",
            "email",
            "firstname",
            "has_bot_active",
            "has_chase_active",
            "lastname",
            "phone",
            "read",
            "status",
            "user",
        ],
        metaObjProps = [
            {
                name: "last_message",
                value: "body",
            },
            {
                name: "tag",
                value: "id",
            },
            {
                name: "tag",
                value: "assigned_to_chase_date",
            },
            {
                name: "tag",
                value: "color",
            },
            {
                name: "tag",
                value: "name",
            },
            {
                name: "tag",
                value: "has_chase",
            },
        ]

    return (
        baseProps.some((prop) => incomingClient[prop] !== comparingClient[prop]) ||
        (comparingClient.metadata &&
            incomingClient.metadata &&
            (metaProps.some((prop) => incomingClient.metadata[prop] !== comparingClient.metadata[prop]) ||
                metaObjProps.some(
                    (prop) =>
                        incomingClient.metadata[prop.name] &&
                        comparingClient.metadata[prop.name] &&
                        incomingClient.metadata[prop.name][prop.value] !==
                            comparingClient.metadata[prop.name][prop.value]
                )))
    )
}
