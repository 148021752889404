const messages = {
  "campaigns": "Campanhas",
  "campaignsSubtitle": "Gerencie suas campanhas do WhatsApp",
  "youCanOnlyHaveUpToXCampaigns": "Você só pode ter até {x} campanhas",
  "youWillNeedToDeleteSomeCampaignsToCreateANewOne": "Você precisará excluir algumas campanhas para criar uma nova",
  "createCampaign": "Criar Campanha",
  "editCampaign": "Editar Campanha",
  "campaignsDeleteDialogDescription": "Tem certeza de que deseja excluir esta campanha?",
  "campaignsDialogConfirmButton": "Confirmar",
  "campaignsDeleteDialogTitle": "Excluir campanha {campaign_name}",
  "campaignDeletingError": "Ocorreu um erro ao excluir a campanha",
  "name": "Nome",
  "dateAndTime": "Data e Hora",
  "totalMessages": "Total de Mensagens",
  "template": "Modelo",
  "status": "Status",
  "actions": "Ações",
  "campaignName": "Nome da Campanha",
  "selectTemplate": "Selecionar Modelo",
  "skipQualityWarning": "Ignorar Aviso de Qualidade",
  "antiSpam": "Anti-Spam",
  "updateCampaign": "Atualizar Campanha",
  "cancel": "Cancelar",
  "csvFileUpload": "Carregar arquivo CSV",
  "csvFileError": "O arquivo CSV não contém as colunas necessárias",
  "campaignCreatedSuccess": "Campanha criada com sucesso",
  "campaignUpdatedSuccess": "Campanha atualizada com sucesso",
  "scheduled": "Agendada",
  "draft": "Rascunho",
  "completed": "Concluída",
  "in_progress": "Em Andamento",
  "adminPhone": "Telefone do Administrador",
  "adminName": "Nome do Administrador",
  "timezone": "Fuso Horário",
  "csvFile": "Arquivo CSV",
  "next": "Próximo",
  "back": "Voltar",
  "Campaign Details": "Detalhes da Campanha",
  "File Upload and Template": "Carregar Arquivo e Modelo",
  "Additional Settings": "Configurações Adicionais",
  "messageWarningCsv":"Faça upload de um arquivo CSV com as colunas: firstname, lastname, phone",
  "selectTemplateForYourCampaign":"Selecione a mensagem de abertura da sua campanha",
  "contentOfTheSelectedTemplate": "Conteúdo do modelo selecionado",
  "downloadSampleCSVFile": "Baixe o arquivo CSV de amostra",
  "CSVFileExample":"Exemplo de arquivo CSV",
  "close": "Fechar",
  "NoCSVFileSelected":"Nenhum arquivo CSV selecionado",
  "pleaseSelectACSVFileToContinue":"Selecione um arquivo CSV para continuar",
  "theStartMessageIsRequired": "A mensagem de inicialização é obrigatória",
  "enterTheTotalNumberOfMessagesForThisCampaign":"Insira o número total de mensagens para esta campanha",
  "labelAntiSpam":"Não será enviado para clientes que receberam esta mesma mensagem de inicialização nos últimos N dias atrás",
  "enterAdminPhone":"Digite o número de telefone do administrador",
  "enterAdminName":"Digite o nome do administrador",
  "SkipQualityWarning":"Ignorar aviso de qualidade",
  "campaignConfirm":"Confirmação da campanha",
  "adminName": "O nome do administrador é obrigatório",
  "adminPhone": "O telefone do administrador é obrigatório",
  "totalMessages": "O total de mensagens é obrigatório",
  "errorAdminName": "Erro: O nome do administrador é obrigatório",
  "dialogAdminName": "Por favor, insira o nome do administrador para continuar",
  "errorAdminPhone": "Erro: O telefone do administrador é obrigatório",
  "dialogAdminPhone": "Por favor, insira o telefone do administrador para continuar",
  "errorTotalMessages": "Erro: O total de mensagens é obrigatório",
  "dialogTotalMessages": "Por favor, insira o total de mensagens para continuar"
}
export default messages