import { createSlice } from "@reduxjs/toolkit"
import initialState from "./conversations-initial-state"
import { getTimeDiffInDays, isClientAssignedToUser, reduceClient } from "../utils"

const clientsPerPage = 100,
    selectAllAmountOfClients = 40

const storedFilterState = {
        selectedAgent: localStorage.getItem("selectedAgent") || "all",
        filterPending: JSON.parse(localStorage.getItem("filterPending")) || false,
        selectedStatus: localStorage.getItem("selectedStatus") || "open",
        selectedSources: localStorage.getItem("selectedSourceFilter"),
        selectedReadStatus: localStorage.getItem("selectedReadFilter"),
        selectedTags: localStorage.getItem("selectedTagFilter"),
        currClientsParams: {
            user: localStorage.getItem("selectedAgent") !== "all" ? localStorage.getItem("selectedAgent") : undefined,
            answer: JSON.parse(localStorage.getItem("filterPending")) === true ? false : undefined,
            tag_id: localStorage.getItem("selectedTagFilter")
                ? localStorage.getItem("selectedTagFilter").replace("TAG#", "")
                : undefined,
            source: localStorage.getItem("selectedSourceFilter")?.toLowerCase(),
            read: localStorage.getItem("selectedReadFilter") === "unread" ? false : undefined,
        },
    },
    initialStatusFilter = localStorage.getItem("selectedStatus") || "open"

if (initialStatusFilter !== "all") {
    if (initialStatusFilter === "hasChaseActive") storedFilterState.currClientsParams.has_chase_active = true
    else if (initialStatusFilter === "hasBotActive") storedFilterState.currClientsParams.has_bot_active = true
    else if (initialStatusFilter === "archived") storedFilterState.currClientsParams.status = initialStatusFilter
    else {
        storedFilterState.currClientsParams.status = initialStatusFilter
        storedFilterState.currClientsParams.has_chase_active = false
    }
}

const conversationsSlice = createSlice({
    name: "conversations",
    initialState: {
        ...initialState,
        ...storedFilterState,
    },
    reducers: {
        resetClients(state) {
            state.clients = state.clients.filter((cl) => state.multiselectClients[cl.PK])
            state.clientsPage = 1
            state.clientsError = {}
        },
        setClients(state, action) {
            state.clients = action.payload
        },
        setClientParamsAndStartLoader(state, action) {
            state.currClientsParams = action.payload
            state.loadingClients = true
            state.clientListDone = true
        },
        gotClients(state, action) {
            let clients = [],
                clientListDone = false,
                { data, reset, email, refresh } = action.payload

            if (state.clients.length === 0 || reset || refresh) {
                const selectedClients = state.clients.filter((cl) => state.multiselectClients[cl.PK]),
                    newClients = data.filter((cl) => !state.multiselectClients[cl.PK])
                clients = [...selectedClients, ...newClients]
            } else {
                const clientPKS = state.clients.map((cl) => cl.PK),
                    newClients = data.filter((cl) => !clientPKS.includes(cl.PK))
                clients = [...state.clients, ...newClients]
            }

            //simplify client model to use less memory
            clients = clients.map((cl) => reduceClient(cl))

            clients.forEach((cl) => {
                if (!cl.metadata?.lastname) cl.metadata.lastname = ""
                if (cl.metadata.source === "facebook") {
                    state.facebookClients[cl.PK] = true
                } else if (cl.metadata.source === "instagram" || (cl.metadata?.ig_id && cl.metadata?.ig_id !== "N/A")) {
                    state.instagramClients[cl.PK] = true
                } else if (cl.metadata.source === "sms") {
                    state.smsClients[cl.PK] = true
                } else if (
                    cl.metadata.source === "whatsapp" ||
                    cl.metadata.source === "meta_whatsapp" ||
                    (cl.metadata?.wa_id && cl.metadata?.wa_id !== "N/A")
                ) {
                    state.whatsappClients[cl.PK] = true
                }
            })

            if (data.length < clientsPerPage) clientListDone = true
            if (state.clients.length === 0) state.multiselectClients = {}
            if (state.checkSelectedAll && state.clients.length > 0)
                clients.forEach((cl, i) => {
                    if (i >= 0 && i < selectAllAmountOfClients) state.multiselectClients[cl.pk] = true
                })

            const unread = clients.filter(
                (client) => !client.read && !client.metadata?.has_bot_active && isClientAssignedToUser(client, email)
            )
            if (unread && unread.length) document.title = "(" + unread.length + ") Cheetrack"
            else document.title = "Cheetrack"

            console.log("Clients:", clients)
            state.unreadClients = unread.length
            state.clients = clients
            state.clientListDone = clientListDone
            if (!refresh) state.clientsPage = reset ? 2 : state.clientsPage + 1
            state.loadingClients = false
            state.clientsError.getClients = false
        },
        getClientsError(state) {
            state.loadingClients = false
            state.clientsError.getClients = true
        },
        addOrUpdateClient(state, action) {
            const clients = [...state.clients],
                newClient = { ...action.payload.client },
                clientIndex = clients.findIndex((cl) => cl.PK === newClient.PK)

            if (newClient.metadata?.source === "facebook") state.facebookClients[newClient.PK] = true
            else if (
                newClient.metadata?.source === "instagram" ||
                (newClient.metadata?.ig_id && newClient.metadata?.ig_id !== "N/A")
            )
                state.instagramClients[newClient.PK] = true
            else if (newClient.metadata?.source === "sms") state.smsClients[newClient.PK] = true
            else if (
                newClient.metadata?.source === "meta_whatsapp" ||
                newClient.metadata?.source === "whatsapp" ||
                (newClient.metadata?.wa_id && newClient.metadata?.wa_id !== "N/A")
            )
                state.whatsappClients[newClient.PK] = true

            const unreadClients =
                clients.filter(
                    (client) =>
                        !client.read &&
                        !client.metadata?.has_bot_active &&
                        isClientAssignedToUser(client, action.payload.email)
                ).length + (!newClient.read ? 1 : 0)

            if (unreadClients > 0) document.title = "(" + unreadClients + ") Cheetrack"
            else document.title = "Cheetrack"

            state.unreadClients = unreadClients

            if (clientIndex !== -1) {
                clients[clientIndex] = reduceClient({
                    ...clients[clientIndex],
                    ...newClient,
                    metadata: {
                        ...clients[clientIndex].metadata,
                        ...newClient.metadata,
                    },
                })
            } else if (
                action.payload.created_by_user ||
                (newClient.metadata?.last_message?.created_at &&
                    getTimeDiffInDays(newClient.metadata?.last_message?.created_at, new Date()) < 2)
            ) {
                clients.push(reduceClient(newClient))
            }

            clients.sort((clientA, clientB) => {
                const dateOne = new Date(clientA.sort_date_message).getTime(),
                    dateTwo = new Date(clientB.sort_date_message).getTime()
                return dateTwo - dateOne
            })

            state.clients = clients
            state.savingClient = action.payload.created_by_user ? true : state.savingClient
        },
        updateLastMessage(state, action) {
            const clients = [...state.clients],
                message = action.payload,
                clientIndex = clients.findIndex((cl) => cl.PK === message.client),
                clientToUpdate = { ...clients[clientIndex] }

            let msgDate = message.sort_date

            clientToUpdate.sort_date_message = msgDate
            if (!clientToUpdate.metadata) clientToUpdate.metadata = {}
            clientToUpdate.metadata.last_message = message.metadata

            if (clientIndex !== -1) clients[clientIndex] = { ...clientToUpdate }

            state.clients = clients
        },
        deleteclient(state, action) {
            state.clients = state.clients.filter((c) => c.PK !== action.payload)
        },
        showClientLoader(state) {
            state.savingClient = true
            state.clientsError.createClient = false
            state.clientsError.updateClient = false
        },
        endClientLoader(state) {
            state.savingClient = false
        },
        createClientError(state) {
            state.savingClient = false
            state.clientsError.createClient = true
        },
        updateClientError(state) {
            state.savingClient = false
            state.clientsError.updateClient = true
        },
        toggleCheckClient(state, action) {
            const { checked, id } = action.payload
            state.multiselectClients[id] = checked

            const selectedClients = state.clients.filter((cl) => state.multiselectClients[cl.PK]).length
            state.checkSelectedAll = selectedClients >= selectAllAmountOfClients
        },
        uncheckSelectedAll(state) {
            state.checkSelectedAll = false
        },
        selectClients(state, action) {
            state.checkSelectedAll = true
            action.payload.forEach((clientPK) => {
                state.multiselectClients[clientPK] = true
            })
        },
        deselectAllClients(state) {
            state.checkSelectedAll = false
            state.multiselectClients = {}
        },
        updateMultipleClients(state, action) {
            const clients = [...state.clients]
            let { clientsIds, prop, data } = action.payload
            if (prop === "agent") prop = "user"
            clients.forEach((cl) => {
                const id = cl.PK.split("CLIENT#")[1]
                if (clientsIds.includes(id)) {
                    if (cl.metadata[prop] !== undefined) cl.metadata[prop] = data
                    else cl[prop] = data
                }
            })

            state.clients = clients
            state.savingClient = false
        },
        tenantHasActiveBot(state) {
            state.tenant_has_active_bot = true
        },
        setFilter(state, action) {
            state[action.payload.filterName] = action.payload.filterState
        },
        setSearchQuery(state, action) {
            state.searchQuery = action.payload
        },
        fixClientsReadStatus(state, action) {
            const clientsReadStatusFixed = { ...state.clientsReadStatusFixed, ...action.payload }
            state.clientsReadStatusFixed = clientsReadStatusFixed
        },
    },
})

export const conversationsActions = conversationsSlice.actions
export default conversationsSlice
