// Retorna si se está en horario laboral
export function isOnWorkingHours(workingHours) {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
        d = new Date(),
        n = d.getDay(),
        h = d.getHours(),
        today = days[n],
        thisWeekdayWH = workingHours.days[today]

    if (thisWeekdayWH && parseInt(thisWeekdayWH[0].start) <= h && parseInt(thisWeekdayWH[0].stop) >= h) {
        return true
    }

    return false
}

//Retorna las horas laborales restantes
export function workingHoursLeft(workingHours) {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
        d = new Date(),
        n = d.getDay(),
        h = d.getHours(),
        today = days[n],
        thisWeekdayWH = workingHours.days[today]

    if (thisWeekdayWH) return parseInt(thisWeekdayWH[0].stop) - h

    return 0
}
