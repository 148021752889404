const messages = {
    choosePlan: "Escolha um plano",
    changePlan: "Mudar plano",
    youHaveCompletedTheSetUp: "Você concluiu sua configuração e demonstração 🥳!",
    youNeedToWaitForWA: "Agora precisamos aguardar a aprovação do WhatsApp para sua conta 😓.",
    wellLetYouKnowWhenIstReady: "Iremos avisar quando estiver pronto 😊!",
    confirmYourSubscription: "Confirme sua assinatura",
    selectYourSalesRange: "Selecione sua faixa de vendas",
    betweenXAndYSales: "Entre {x} e {y} USD por mês",
    fromXSales: "Mais de {x} USD por mês",
    toXSales: "Menos de {x} USD por mês",
    forUpToXUsers: "Para até {user_amount} usuários. Cada usuário adicional tem um custo de {user_price} USD/mês",
    confirmXPlan: "Confirmar assinatura do plano {x} 😁",
    youWillBeRedirectToShopify: "Você será redirecionado para o Shopify, por favor, aguarde 😉.",
    anErrorOcurredWithTeRedirection: "Ops! Algo deu errado. Por favor, tente novamente 😅.",

    connectToAPlatform: "Conectar às plataformas de chat",
    welcomeToCheetrack: "Obrigado por instalar o Cheetrack 🥳!",
    welcomeToCheetrackSubtitle:
        "Só precisamos conectar algumas coisas 🔌 para começar com sua primeira campanha de Marketing no WhatsApp.",
    welcomeToCheetrackDesc1: "Nossos clientes multiplicam seu investimento no Cheetrack de 10 a 37 vezes!",
    welcomeToCheetrackDesc2:
        "Para começar, precisamos que você confirme sua assinatura e nos conecte às suas redes sociais.",
    whatsAppConnectionOff:
        "Você poderá conversar com seus clientes no WhatsApp, enviar e receber mensagens e arquivos, além de configurar perseguições e respostas automáticas.",
    facebookConnectionOff:
        "Permite que você responda a mensagens do Facebook e Instagram, bem como responda a comentários e menções em histórias.",
    smsConnectionOff:
        "Você poderá enviar e receber mensagens SMS, e usar esse canal para perseguir clientes e fazer campanhas.",
    whatsAppConnectionPending: "Estamos aguardando a aprovação do WhatsApp para a sua conta 😊.",
    smsConnectionPending: "Seu número de SMS está sendo criado e estamos aguardando a aprovação 😅.",
    facebookConnectionOn: "Você está conectado ao Facebook e Instagram 😁!",
    whatsAppConnectionOn: "Seu WhatsApp foi aprovado e está pronto para ser usado no Cheetrack 🤩!",
    smsConnectionOn: "Você pode enviar SMS no Cheetrack 😀!",
    clickHereForHelp: "Você precisa de ajuda? Clique aqui para falar conosco! 🤓",
    clickHereToScheduleAMeeting: "Ou clique aqui se precisar agendar uma reunião 🕐.",
    theFirst14DaysAreFree: "Os primeiros 14 dias são gratuitos, e você pode cancelar a qualquer momento.",

    basicPlan: "Plano Básico",
    advancedPlan: "Plano Avançado",
    enterprisePlan: "Plano Empresarial",
    premiumPlan: "Plano Premium",
    thanksForConfirmingYourPlan: "Obrigado por confirmar sua assinatura 😁!",
    freeUsers: "usuários incluídos.",
    extraUsersForXCost: "usuários por {x} adicionais .",
    yourPlan: "Seu plano",

    youAreReadyToUseCheetrack: "Você está pronto para usar o Cheetrack 🎉!",
    goToTheMainScreen: "Vá para a tela principal",
    plusXUsdForAutomatizedChaseMessage: "+ {x} USD para mensagem automatizada dentro de uma perseguição.",
    onlyAnAdminUserCanMakeChanges: "Apenas um usuário administrador pode fazer alterações!",
    clickAPlatformToConnect: "Clique em uma plataforma para conectá-la ao Cheetrack:",

    youAreCloserToYourFirstCampaign: "Você está mais perto da sua primeira campanha! Como você gostaria de continuar?",
    relaxAndChill: "Relaxe e aproveite",
    relaxAndChillDesc: "Defina uma reunião com um de nossos especialistas 🤓 e deixe-nos ajudar você com tudo.",
    missionImpossible: "Missão Impossível",
    missionImpossibleDesc: "Faça por conta própria usando nossos tutoriais 📚.",
    youMustConfirmYourPlan: "Você precisa confirmar seu plano antes de continuar.",
}

export default messages
