const messages = {
    templateSubHeader: "O WhatsApp limita a sua empresa a enviar apenas mensagens aprovadas por eles.",
    templateSubHeader2: "Aqui você pode criar mensagens iniciais e solicitar aprovação ao WhatsApp.",
    templateSubHeader3:
        "Assim que sua mensagem inicial for aprovada pelo WhatsApp, você poderá enviá-la aos seus clientes, e se obtiver uma resposta, será aberta uma janela de 24 horas que permite que você os contate livremente.",
    templateSubHeader4:
        "Após o fechamento dessa janela de 24 horas, você voltará a ser limitado a enviar apenas mensagens de início aprovadas.",
    templateHeader: "Mensagens de início",
    templateInitButton: "Entendido",
    templateInitDesc1:
        "Só é possível iniciar conversas com clientes usando mensagens de início. Aqui você pode criar suas próprias mensagens de início, ",
    templateInitDesc2: "que serão revisadas ⌛ por",
    templateInitDesc3:
        "Fornecemos um conjunto de mensagens iniciais para que você possa começar a fazer acompanhamento com seus clientes.",
    createdBy: "Criado por",
    status: "Estado",
    shortName: "Nome",
    createNewDialogDesc: "Este nome ajudará você a reconhecer a mensagem inicial mais rapidamente.",
    templateMessage: "Mensagens de início",
    createNewDialogDesc1: "Pode incluir algumas das seguintes variáveis em sua mensagem: ",
    createNewDialogDesc2:
        "Também pode criar suas próprias variáveis, usando chaves duplas {{nome}} e atribuindo-lhes um nome, por exemplo: ",
    createNewDialogDesc3: ". Será solicitado que você as substitua ao enviar a mensagem.",
    saveDraft: "Salvar rascunho",
    sendApproval: "Enviar para aprovação",
    confirmTempDialogTitle: "Mensagem de início enviada para aprovação",
    confirmTempDialogDesc:
        "Enviamos sua mensagem de início ao WhatsApp para aprovação. Isso pode levar algum tempo ⏳, avisaremos quando estiver aprovado.",
    getIt: "Eu entendo",
    createMsgTemplate: "Criar mensagem de início",
    editMsgTemplate: "Editar mensagem de início",
    deleteConfirmMsg1: "Esta ação eliminará a mensagem de início",
    deleteConfirmMsg2:
        "Esta mensagem não estará mais disponível para iniciar uma conversa com seus clientes, e caso queira usá-la novamente, você deverá criá-la e enviá-la para validação no WhatsApp.",
    deleteConfirmMsg3: "¿Tem certeza de que deseja excluir esta mensagem de início?",
    deleteConfrimBtn: "Sim, excluir mensagem de início",
    updateDraft: "Atualizar rascunho",
    updateTemplate: "Atualizar mensagem de início",
    characters: "Caracteres",
    templateShortName: "Nome",
    templateTooltipShortName: "Este nome ajudará você a reconhecer a mensagem de início mais rapidamente.",
    templateTemplateMessage: "Mensagem de início",
    templateTooltipTemplateMessage: `Com esta mensagem, você iniciará uma conversa com seus clientes. \n \nPode inserir variáveis com dupla chave `,
    templateCreatedBy: "Criado por",
    templateStatus: "Estado",
    templateTooltipStatus:
        "Os mensagens iniciais precisam ser aprovados pelo WhatsApp. Aqui estão mostrados os status da aprovação.",
    templateFilteredCharacterMessage:
        "Apenas letras minúsculas (a-z), números (0-9) e sublinhado (_) são permitidos no nome da mensagem.",
    templateNameDupe: "Não é possível repetir nomes de mensagens iniciais",
    searchTemplate: "Procurar mensagem de início",
    hideRejectedTemplates: "Ocultar mensagens rejeitadas",
    creationDate: "Data de criação",
    tipsForWhatsAppApproval: "Conselhos para aprovação no WhatsApp:",
    checkOurTips: "Confira nossas dicas para obter aprovação do WhatsApp para suas mensagens iniciais! >",
    templateTip1: "Tente incluir pelo menos 8 palavras.",
    templateTip2: "Não deve ser uma pesquisa.",
    templateTip3: "Escreva claramente o objetivo para o qual você está enviando a mensagem.",
    templateTip4: "Se for enviar uma mensagem do tipo saudação, deve ter um objetivo além de apenas cumprimentar.",
    templateTip5: "Evite conteúdo de assinatura.",
    templateTip6: "Se você está vendendo produtos ou promovendo uma campanha, deve cumprir com a ",
    whatsappCommercePolicy: "Política Comercial do WhatsApp",
    replaceModalTitle: "O mensagem contém variáveis personalizadas que você precisa substituir",
    replaceModalPreview: "Pré-visualização da mensagem:",
    replaceModalInstruction: "Substitua as seguintes variáveis:",
    templateContent: "Conteúdo da mensagem",
    templateWordLenghtWarning: "Sua mensagem inicial deve conter pelo menos 7 palavras",
    templateCharLenghtWarning: "Sua mensagem inicial deve conter pelo menos 50 caracteres",
    templateSellsWarning: "Se a sua mensagem inicial contém produtos, promoções ou campanhas, deve cumprir com a ",
    templatePollWarning: "Sua mensagem inicial pode ser rejeitada se contiver pesquisas",
    templateSubscriptionsWarning: "Sua mensagem inicial pode ser rejeitada se tiver conteúdo de assinatura",
    templateForbiddenProductWarning:
        "Sua mensagem inicial será rejeitada se oferecer produtos ou serviços relacionados a ",
    illegalProducts: "produtos ilegais",
    drugs: "drogas",
    tabacco: "tabaco",
    alcohol: "álcool",
    unsafeSuplements: "suplementos inseguros",
    gunsAmmosOrExplosives: "armas, munições ou explosivos",
    animals: "gado, espécies protegidas ou partes de animais",
    adultProductsOrServices: "produtos ou serviços para adultos",
    bodyPartsOrFluids: "partes ou fluidos corporais",
    healthProducts: "produtos médicos ou de saúde",
    productsWithSexualConnotation: "produtos com conotação sexual",
    realMoneyBet: "apostas com dinheiro real",
    dateServices: "serviços de namoro",
    unauthorizedAccesToDigitalMedia: "acesso não autorizado a meios digitais",
    offensiveFraudulentDeceivingOrExploitativeCommercialModels:
        "modelos comerciais ofensivos, fraudulentos, enganosos ou exploradores",
    realVirtualOrFakeMoney: "moeda real, virtual ou falsa",
    forgeryOrPiracy: "pirataria ou falsificação",
    templateCloseVariablesWarning:
        "Sua mensagem inicial contém variáveis muito próximas uma da outra, considere uni-las ou utilizá-las de outra maneira",
    templatesCannotBeEditedWarning: "Os mensagens iniciais não podem ser editados uma vez enviados para o WhatsApp.",
    templatesOpenVariableWarning: "Parece que você tem uma variável que está faltando uma chave. O formato correto é: ",
    templatesSingleBracketsVariableWarning:
        "Parece que você tem uma variável com chaves simples em vez de chaves duplas. O formato correto é: ",
    templatesVarTypoWarning:
        "Você escreveu {customVariable}, você quis dizer usar a variável padrão: {defaultVariable}?",
    approvalCanTake2Days: "O processo de revisão do WhatsApp pode levar até 2 dias úteis.",
    templateType: "Tipo",
    previewMessage: "Ver pré-visualização da mensagem",
    templateContainsHtmlWarning: "Sua mensagem inicial não pode conter código HTML.",
    emptyVariablesWarning: "Parece que você tem uma variável vazia, por favor, dê a ela um nome ou número.",
    urlVariablesWarning:
        "As variáveis não podem conter URLs, atribua um nome à variável e, em seguida, você pode substituí-la por sua URL ao enviar a mensagem para um cliente.",
    variablesWithSpacesWarning: "As variáveis não podem conter espaços, use - ou _ em seu lugar.",
    anonymousCheetah: "Chita Anónimo",
    templateCannotStartWithVariableWarning: "Sua mensagem inicial não pode começar com uma variável.",
    templateCannotEndWithVariableWarning: "Sua mensagem inicial não pode terminar com uma variável.",
    templateThreatsWarning:
        "Sua mensagem inicial pode ser rejeitada se contiver ameaças de ação legal ou humilhação pública.",
    templateAsksForIDWarning:
        "Sua mensagem inicial pode ser rejeitada se solicitar documentos de identificação de um cliente.",
    templateHasTooManyLineSkips: "Sua mensagem inicial pode ser rejeitada se contiver muitas quebras de linha.",
    templateHasTooManyVariables:
        "Sua mensagem inicial parece ter muitas variáveis para seu conteúdo, pode ser rejeitada se seu propósito não for claro.",
    templateNoType: "Regular",
    templateAgendaProType: "Agenda Pro - Agendado",
    templateDiscountCouponType: "Shopify – Cupom de desconto",
    templateAbandonedCheckoutType: "Shopify - Carrinho Abandonado",
    templateSegments: "Segmentos",
    templateTypeNeedsVariables: "Esse tipo de mensagem inicial requer as variáveis: ",
    templateSmsMessagesWillInclude: "Os mensagens SMS incluirão automaticamente: ",
    smsStopMessage: "Responda STOP para não receber mensagens.",
    smsLengthRecommendation: "Recomendamos que os SMS tenham menos de {x} caracteres.",
    templateSource: "Canal",
    templateCostToSend: "Custo de enviar esta mensagem: {x}",
    templateSuggestionStopMessage:
        "Sugerimos incluir a frase: Responda 'Não mais mensagens' para não receber mais mensagens. Cheetrack detectará essa resposta e não enviará mais mensagens automáticas para esses clientes.",
    includeTemplateFile: "Incluir arquivo",
    templateFileFormat: "JPG, PNG, MP4 o PDF.",

    addTemplateButton: "Adicionar botão",
    templateButton: "Botão",
    templateButtonType: "Tipo",
    templateButtonText: "Texto do botão",
    templateButtonQuickReply: "Resposta rápida",
    templateButtonUrl: "URL do botão",
    templateButtonName: "Nome do botão",
    templateFlowsTitle: "Adicionar Flows para facilitar a interação com os clientes",
    templateButtonPhoneNumber: "Número de telefone",
    templateButtons: "Botões",
    templateButtonsDescription:
        "Adicione botões à sua mensagem inicial para facilitar as respostas dos seus clientes ou um chamado para ação a uma URL do seu e-commerce.",
    cantUseVariablesInButtonText: "Você não pode usar variáveis no texto de um botão.",
    noTemplateButtons: "Sem botões",
    dynamicUrl: "URL dinâmica",
    staticURL: "URL estática",
    templateButtonCallToAction: "Chamada para a ação",
    dynamicUrlDescription: "A URL dinâmica inclui uma variável no final da URL, por exemplo: ",

    welcomeMessageTemplateContent:
        "Olá {{client_first_name}}! Você agora faz parte da família {{my_company}}🌟 \nEsperamos que você goste dos nossos produtos e para continuarmos nos conhecendo melhor. \nSe tiver alguma dúvida, pode nos escrever por aqui.",
    welcomeMessageTemplateName: "Mensagem de boas-vindas",
    abandonedCheckoutTemplateContent:
        "Olá {{client_first_name}}, escrevemos para você de {{my_company}}🌟 \nPercebemos que deixou seus produtos favoritos esquecidos! Se fosse você, iria agora antes que seja tarde!",
    abandonedCheckoutTemplateName: "Carrinho abandonado",
    newProductLaunchTemplateName: "Lançamento de novo produto",
    newProductLaunchTemplateContent:
        "Olá {{client_first_name}}, escrevemos para você de {{my_company}}🌟 \nQueremos contar um segredo 🤫 \nEstamos lançando um novo produto com estoque reduzido, e queremos contar a você antes dos outros 😁 \n👀 Deixamos o link para nosso catálogo: ",
    discountCouponTemplateContent:
        "Olá {{client_first_name}}, da {{my_company}} queremos te dar um presentinho 🎁 para que você nos visite novamente. \nReceba um desconto de {{discount_amount}}% em sua próxima compra usando o código {{discount_code}}. \nEsperamos te ver em breve!",
    discountCouponTemplateName: "Código de desconto",
    reengageCustomerTemplateContent:
        "🤗 {{client_first_name}}, na {{my_company}} estamos com saudades 🌟 \nJá faz dias que não te vemos em nossa loja e esperamos que não tenha se esquecido de nós. Dê uma olhada em nosso catálogo para conferir todas as ofertas que preparamos para você!",
    reengageCustomerTemplateName: "Reencontro com o cliente",
    abandonedCheckoutNoBtnsTemplateContent:
        "Olá {{client_first_name}}, escrevemos para você de {{my_company}}🌟 \nPercebemos que deixou seus produtos favoritos esquecidos! \n\nClique no link para ir recuperá-los: {{abandoned_checkout_link}}\n\nSe fosse você, iria agora antes que seja tarde",

    seeYourAbandonedCheckout: "Ir para meu carrinho abandonado",
    seeOurCatalog: "Veja nosso catálogo",
    templateTemplate: "Modelo",
    noTemplate: "Sem modelo",
    anErrorOcurredCreatingThisTemplate: "Ocorreu um erro ao criar esta mensagem de início.",
    templateCategory: "Categoria",
    rejectReasonINCORRECT_CATEGORY: "A categoria da mensagem de início está incorreta, por favor, selecione outra.",
    rejectReasonSCAM:
        "A mensagem de início foi considerada uma possível fraude, por favor, revise o conteúdo e tente novamente.",
    rejectReasonINVALID_FORMAT:
        "A mensagem de início tem problemas de formato, por favor, revise o conteúdo e tente novamente.",
    rejectReasonABUSIVE_CONTENT:
        "A mensagem de início foi marcada como possível conteúdo abusivo, por favor, revise o conteúdo e tente novamente.",

    restaurantMenuTemplateName: "Cardápio do restaurante",
    restaurantMenuTemplateContent: "Bem-vindo ao {{my_company}}! \nVocê pode ver nosso cardápio clicando no botão: ",
    seeTheMenu: "Ver cardápio",
    youCanOnlyHaveUpTo80Templates: "Só é possível ter até 80 mensagens de início.",
    pleaseDeleteSomeTemplates: "Por favor, exclua algumas mensagens de início para poder criar novas.",
}

export default messages
