const messages = {
    didYouKnow: "Did you know...?",
    cheetahFact1: "A cheetah can run up to 120 km/h",
    cheetahFact2: "Cheetahs chase during the day",
    cheetahFact3: "Only 9000-12000 cheetahs remain in Africa",
    cheetahFact4: "Cheetah usually give birth to 2-8 cubs at a time",
    cheetahFact5: "A cheetah can take care of itself at 16-24 months old",
    cheetahFact6: "Cheetahs usually chase rabbits, warthogs, springboks, gazelles and birds",
    cheetahFact7: "The cheetah is the fastest land animal",
    cheetahFact8: "Cheetahs don't retract their claws when running",
    cheetahFact9: "When running, cheetahs use their tail to steer",
    cheetahFact10: "The cheetah's black tear lines keep the sun out of their eyes when chasing",
    cheetahFact11: "A cheetah can run 100 meters in 6 seconds",
    cheetahFact12: "A cheetah reaches 100 km/h in just three seconds",
    cheetahFact13: "After a chase, a cheetah needs 30 minutes to catch its breath before eating",
    cheetahFact14: "Cheetahs can't roar, they meow and purr",
    cheetahFact15: "Cheetahs only drink water every three or four days",
    cheetahFact16: "A cheetah runs about 50% faster than Usain Bolt",
    cheetahFact17: "At full speed, cheetahs cover about 21 feet with each stride",
    cheetahFact18: "A cheetah can see up to 3 miles away",
    cheetahFact19: "A cheetah has between 2000 and 3000 spots",
    cheetahFact20: "Cheetahs were kept as pets and used for chasing in Ancient Egypt",
    cheetahFact21: "Cheetah comes from the Hindi language and means adorned or painted",
    cheetahFact22: "You can write bold letters on chat using * like this: ",
    cheetahFact23: "You can write italic letters on chat using _ like this: ",
    cheetahFact24: "Connecting Cheetrack to Shopify allows you to segment clients and send them personalized messages",
    cheetahFact25: "A person needs to see your product 5 times before buying it",
    cheetahFact26: "Connecting Cheetrack to Shopify allows you to recover abandoned carts through WhatsApp",
    cheetahFact27: "You can use emojis in your Cheetrack messages",
    cheetahFact28: "You can create sales boards using tags",
    cheetahFact29: "You can connect Cheetrack to your Facebook and Instagram accounts",
    cheetahFact30: "You can use your own custom variables in your messages",
    cheetahFact31: "Cheetrack can assign incoming messages to your agents automatically",
    cheetahFact32: "You can limit your agents to only see conversations assigned to them",
    cheetahFact33: "WhatsApp imposes a daily limit on your messages, the more messages you send, the higher the limit",
}

export default messages
