import uuid from "react-uuid"

// Props que se consideran para las preguntas
const questionProps = [
    "prompt",
    "optionSelected",
    "name",
    "required",
    "type",
    "typeInput",
    "id",
    "template_pk",
    "button_answer",
    "text",
    "tag_id",
    "user_id",
    "group_tag",
    "end_message",
    "template_text",
    "last_question_sub_menu",
    "bot_subtype",
    "feedback_message",
]

// Da formato al objeto de cheetabot para utilizarle en el front

function storeQuestionInfoOnObj(q, obj, parentId) {
    obj[q.id] = {}

    questionProps.forEach((prop) => {
        if (q[prop]) obj[q.id][prop] = JSON.parse(JSON.stringify(q[prop]))
    })

    if (parentId) obj[q.id].parentId = parentId

    if (q.childOptions) {
        const childOptionsArr = []
        q.childOptions.forEach((o) => {
            const id = o.id || uuid()
            childOptionsArr.push(id)
            storeQuestionInfoOnObj({ ...o, id }, obj, q.id)
        })
        obj[q.id].childOptions = childOptionsArr
    }

    if (q.optionSelected && q.optionSelected.childOptions) {
        const optionsSelectedArr = []
        q.optionSelected.childOptions.forEach((o) => {
            const id = o.id || uuid()
            optionsSelectedArr.push(id)
            storeQuestionInfoOnObj({ ...o, id }, obj, q.id)
        })
        obj[q.id].optionSelected.childOptions = optionsSelectedArr
    }

    if (q.buttons) {
        const buttonsArr = []
        q.buttons.forEach((b) => {
            const id = b.id || uuid()
            buttonsArr.push(id)
            storeQuestionInfoOnObj({ ...b, id }, obj, q.id)
        })
        obj[q.id].buttons = buttonsArr
    }

    if (q.actions) {
        const actionsArr = []
        q.actions.forEach((a) => {
            const id = a.id || uuid()
            actionsArr.push(id)
            storeQuestionInfoOnObj({ ...a, id }, obj, q.id)
        })
        obj[q.id].actions = actionsArr
    }
}

export function formatCheetabotToStoreQuestionContent(questions) {
    const storeObj = {}

    if (questions && questions.length) {
        questions.forEach((q) => {
            storeQuestionInfoOnObj(q, storeObj)
        })
    }

    return storeObj
}
