//Predice los headers de un archivo excel importado para crear múltiples clientes
function predictHeadersMatching(arr) {
    const prediction = {}
    arr.forEach((val) => {
        const str = val.replace(/\s/g, "").replace(/\n/g, "").toLowerCase()
        if (
            str.includes("phone") ||
            str.includes("teléfono") ||
            str.includes("telefono") ||
            str.includes("telefone") ||
            str.includes("whatsapp") ||
            str.includes("fono")
        )
            prediction.phone = val
        if (str.includes("firstname") || str.includes("nombre") || str === "name") prediction.firstname = val
        if (str.includes("lastname") || str.includes("surname") || str.includes("apellido")) prediction.lastname = val
        if (str.includes("email") || str.includes("correo") || str.includes("mail")) prediction.email = val
        if (
            str.includes("company") ||
            str.includes("business") ||
            str.includes("enterprise") ||
            str.includes("compañía") ||
            str.includes("compañia") ||
            str.includes("empresa") ||
            str.includes("razon social") ||
            str.includes("razón social") ||
            str.includes("negocio")
        )
            prediction.company = val
    })
    return prediction
}

export default predictHeadersMatching
