const messages = {
    welcomeToChiTrack: "Bienvenido a Cheetrack",
    email: "Dirección de e-mail",
    password: "Contraseña",
    login: "Iniciar sesión",
    loginWithGoogle: "Entrar con Google",
    createAccount: "Crear una cuenta",
}

export default messages
