//Agrega la extensión de celular a un número de teléfono
// En especial para el caso de méxico cambia el +52 por +521

function addMobilePhoneExtension(phoneNumber) {
    let mobilePhoneNumber = phoneNumber + ""

    if (!mobilePhoneNumber.startsWith("+")) mobilePhoneNumber = "+" + mobilePhoneNumber

    //Mexico
    if (mobilePhoneNumber.startsWith("+52") && !mobilePhoneNumber.startsWith("+521"))
        mobilePhoneNumber = mobilePhoneNumber.replace("+52", "+521")

    return mobilePhoneNumber
}

export default addMobilePhoneExtension
