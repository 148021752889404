const messages = {
    addClients: "Add clients",
    customerAgenda: "Clients list",
    addNewClient: "Add new client",
    filters: "Filters",
    search: "Search",
    newCustomerInformation: "New client information",
    AddTheDetailsOfTheNewCustomerCheckTheNumbersThatWeSuggestFromYourConversationsWithUnsecuredPhones:
        "Add the details of the new client. Check the numbers that we suggest from your conversations with unsecured phones.",
    actions: "Actions",
    thisActionWillRemoveTheClient: "This action will archive the conversation with client ",
    andAllTheRecordsAssociatedWithIt: " and all the associated information.",
    areYouSureYouWantToDeleteThisClient: "Are you sure you want to archive this client?",
    yesDeleteClient: "Yes, archive client",
    editInformationFor: "Edit information for",
    editAndUpdateTheInformationOfThisClient: "Edit and update the information of this client",
    firstname: "First name",
    lastname: "Last name",
    validationPassword: "The password must contain a special character !#@$%+=._-, letters Aa-zZ and numbers 0-9",
    addClientDescription1: "Your clients are the center of your business",
    addClientDescription2:
        "Add a client, saving their contact and information so that you can maintain personalized communication with each one.",
    strengthenTheRelationshipWithYourCustomers: "Strengthen the relationship with your customers",
    youAreJustFewClicksAwayTakeYourSalesOnWhatsAppToAnotherLevel:
        "You are just a few clicks away, take your sales on WhatsApp to another level",
    deleteClient: "Delete client",
    addFirstClient: "Add my first client",
    addAnotherClient: "Add this client and another",
    seeArchived: "See Archived",
    assignedTo: "Assigned to",
    alertAfterCreated:
        "It will take a few minutes for the client to appear or update in this list and in the chat view.",
    accept: "Accept",
    created_at: "Creation date",
    autoAssignNewClients: "Assign new clients to executives automatically.",
    autoAssignDesc:
        "Activating this will automatically assign clients that start a conversation to one of your executives.",
    autoAssignDesc2:
        "The assignment will follow a tandem logic, meaning, executives will be assigned a client one at a time until every executive has being assigned a client before starting over.",
    clientExists: "You already have that phone number in your client's list",
    anErrorOcurredCreatingClient:
        "An error ocurred creating that client, please check the information or try again later",
    anErrorOcurredUpdatingClient:
        "An error ocurred updating that client, please check the information or try again later",
    clientsNotListed: "clients not listed.",
    clientLimitDesc:
        "There is a limit to the amount of clients we are able to show, you can find more clients by searching for them.",
    ifYouNeedToCreateMoreThan50Clients: "If you need to create more than 50 clients let us know through support chat.",
    importClientList: "Import a client list",
    createClientsOneAtATime: "Create clients one at a time",
    importFile: "Select file",
    importFileFormat: "XLSX, XLS or CSV formats accepted",
    importFileError: "There is an issue with the imported file, please try another.",
    reviewTheImportedData: "Review the imported data.",
    assignATagToClients: "Assign a tag to all {n} clients",
    pairColumnHeadersAndProps: "Pair your file's column headers with your clients information.",
    useThisRowAsHeader: "Use the following row as header",
    clientInformation: "Client information",
    columHeader: "Column header on file",
    continueToPreviewData: "Continue to data preview",
    saveClientList: "Save {n} listed clients",
    selectTag: "Select tag",
    youHaveXClientsWithIssues:
        "You have {n} clients with invalid phone numbers, if you save them as they are you may have trouble sending messages to them.",
    weCouldntSaveXClientsWithIssues: "We couldn't save {n} clients, you may review them and send them again.",
    weFoundXDuplicatedClients:
        "We didn't save {n} clients whose phone numbers were duplicated or already in your agenda.",
    downloadTheseXClientsAsAnCSV: "Download this {n} clients as CSV",
    reviewDuplicatedClients: "Review the {n} duplicated clients",
    weCorrectlySavedXClientes: "We successfully saved {n} clients.",
    hereYouCanUploadAFileWithClients: "Here you can upload a file containing your clients' information.",
    youWillBeAbleToPairTheInfo:
        "You will be then able to match a column in your file with each of: first name, last name, company, phone number and email.",
    theOnlyRequisiteIsAPhoneNumber:
        "The only requisite is for at least one of the file's columns to be your client's phone number.",
    ourBotCanCorrectXNumbers: "Our Cheetabot can automatically fix {n} of those phone numbers",
    clickHereToAllowTheBotToCorrectThem: "Click here to allow Cheetabot to fix them",
    allTheNumbersHaveIssues: "All of your client's phone numbers have issues, did you choose the right column?",
    backToPairingStep: "Go back to the pairing step",
    clientsAddedThisWayWillShowUpOnRelaod: "Clients added this way may take a few minutes to show up",
    clientsAddedMayNeedToBeFound:
        "👀 In case you have many conversations during the day, new clients may not show up at the top of the list. You may need to use the search bar to find them.",
    clientSource: "Source",
    myClients: "My clients",
    exportToCSV: "Export to CSV",
    firstVisit: "First visit",
    daysWithoutComingToToday: "Days without coming today",
    historicalVisits: "Historical visits",
    visitsDuringThePeriod: "Visits during the period",
    validatedCoupons: "Validated coupons",
    goodExperiences: "Good experiences",
    badExperiences: "Bad experiences",
    numberOfVisits: "Number of visits",
    experiences: "Experiences",
    relativeTime: "Relative time",
    visitsFrom: "Visits from",
    visitsUntil: "Visits until",
    searchClients: "Search clients",
    daysAgoRelative: "{days} days ago",
    cantVisits: "Número de visitas",
    validatedCoupons: "Validated coupons",
    totalsClientsFiltered: "{number} filtered clients",
    numberClientsWithOneVisit: "{number} with 1 visit",
    numberClientsWithTwoVisit: "{number} with 2 visits",
    numberClientsWithThreeVisit: "{number} with +3 visits",
    numberCouponsValidates: "{number} with 3 visits",
    numberValidatedCoupons: "{number} Validated coupons",
    numberGoodExperiences: "{number} Good experiences",
    numberBadExperiences: "{number} Bad experiences",
    numberTotalVisitsInRange: "{number} clients filtered",
    descriptionNotFound: "We have not found results for the filters applied, try another selection.",
    applyFilters: "Apply",
    positive: "Good",
    negative: "Bad",
    preparingCsv: "Preparing CSV",
}

export default messages
