// Constanes para segmentos

export const segmentationCriteria = [
    {
        name: "lastOrderDate",
        value: "last_order_date",
        type: "date",
        min: 0,
        max: 90,
        origin: "shopify",
        unit: "daysAgo",
        unitValue: "_days_ago",
    },
    {
        name: "averagePurchase",
        value: "average_purchase",
        type: "numeric",
        subtype: "float",
        min: 0,
        origin: "custom",
        // unit: "money",
        custom: true,
    },
    {
        name: "numberOfOrders",
        value: "number_of_orders",
        type: "numeric",
        subtype: "integer",
        min: 0,
        max: 90,
        origin: "shopify",
    },
    {
        name: "productsPurchased",
        value: "products_purchased",
        type: "numeric",
        subtype: "integer",
        min: 1,
        max: 90,
        origin: "shopify",
        unit: "daysAgo",
        unitValue: "_days_ago",
    },
]

export const segmentationOperators = [
    {
        name: "greaterThan",
        value: ">",
    },
    {
        name: "equalOrGreaterThan",
        value: ">=",
    },
    {
        name: "equalTo",
        value: "=",
    },
    {
        name: "equalOrLessThan",
        value: "<=",
    },
    {
        name: "lessThan",
        value: "<",
    },
]

export const dateSegmentationOperators = [
    {
        name: "inTheLast",
        value: ">=",
    },
    {
        name: "beforeThan",
        value: "<",
    },
]

export const dateSegmentationOperatorsForProducts = [
    {
        name: "beforeThan",
        value: "<",
    },
    {
        name: "beetwen",
        value: "beetwen",
    },
]
