// Agrega los hijos al prompt del padre
const addChildToPrompts = (q, arr, questionContent) => {
    arr.forEach((c, i) => {
        if (questionContent[c]) {
            if (!q.stringPromptWithOutFormat) q.stringPromptWithOutFormat = ""
            if (!q.stringPrompt) q.stringPrompt = ""

            q.stringPrompt += "\n" + (i + 1) + ". " + (questionContent[c].prompt || "")
            q.stringPromptWithOutFormat += " " + i + 1 + ". " + (questionContent[c].prompt || "")
        }
    })
}

// Actualiza los prompts de las preguntas con opciones
const updatePrompts = (q, arr, questionContent) => {
    q.stringPrompt = q.prompt
    q.stringPromptWithOutFormat = q.prompt

    addChildToPrompts(q, arr, questionContent)
}

// Construye los hijos de una pregunta
const buildChildren = (parent, arr, questionContent) => {
    const children = []

    if (parent.type === "questionWithOptions" || parent.optionSelected?.option === "ShowSubMenu")
        updatePrompts(parent, arr, questionContent)

    arr.forEach((c) => {
        const content = questionContent[c]
        if (content) {
            buildParentage(content, questionContent)
            if (content.optionSelected?.option === "go_back" && !content.last_question_sub_menu)
                content.last_question_sub_menu = "question_" + parent.parentId
            content.name = "question_" + content.id
            children.push(content)
        }
    })
    // console.log("Built children", parent.prompt, children)
    return children
}

// Arma el árbol de hijos de una pregunta
const buildParentage = (parent, questionContent) => {
    if (parent.optionSelected && parent.optionSelected.childOptions && parent.optionSelected.childOptions.length > 0) {
        // console.log("Building parentage through optionSelected", parent)
        parent.optionSelected.childOptions = buildChildren(parent, parent.optionSelected.childOptions, questionContent)
        parent.childOptions = parent.optionSelected.childOptions
    } else if (parent.childOptions && parent.childOptions.length > 0) {
        // console.log("Building parentage through childOptions", parent)
        parent.childOptions = buildChildren(parent, parent.childOptions, questionContent)
    } else if (parent.buttons && parent.buttons.length > 0) {
        parent.buttons = buildChildren(parent, parent.buttons, questionContent)
    } else if (parent.actions && parent.actions.length > 0) {
        parent.actions = buildChildren(parent, parent.actions, questionContent)
    }
}

// Construye el Cheetabot a enviar a la API
// Toma la secuencia base e itera sobre cada pregunta para agregarle sus hijos
// Además agrega los question name a todas las preguntas y opciones
export const buildCheetabotToSubmit = (sequence, questionContent) => {
    console.log("Building Cheetabot", sequence, questionContent)

    const updatedSequence = [],
        baseSequence = JSON.parse(JSON.stringify(sequence)),
        baseQuestionContent = JSON.parse(JSON.stringify(questionContent))

    baseSequence.forEach((s) => {
        const content = baseQuestionContent[s.id]
        if (content) {
            // console.log("Question content", content)
            buildParentage(content, baseQuestionContent)

            content.name = "question_" + content.id
            updatedSequence.push({ ...s, ...content })
        } else updatedSequence.push(s)
    })

    console.log("Cheetabot to submit", updatedSequence)

    return updatedSequence
}
