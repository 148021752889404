const messages = {
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    password: "Password",
    phone: "Phone",
    register: "Sign up",
    Iaccept: "I accept the ",
    TermsOfService: "Terms of service",
    privacyPolicy: " and Privacy Policy",
    alreadyAUser: "Already an user? Log In Here!",
    logIn: "Log in",
    signUp: "Sign up",
    cancel: "Cancel",
    logout: "Log Out",
    passwordStrength: "Password strength",
    weak: "Weak",
    acceptable: "Acceptable",
    strong: "Strong",
    nameRequired: "Please write your first name",
    lastNameRequired: "Please write your last name",
    emailRequired: "Please write an email address",
    phoneRequired: "Please write your phone number",
    emailInvalid: "Email address not valid",
    showPassword: "Show password",
    hidePassword: "Hide password",
    yourPasswordMustBeAcceptable:
        "Your password is too weak, consider mixing symbols, numbers and both uppercase and lowercase letters",
    passwordMustBeAtLeast6Chars: "Your password must be at least 6 characters long",
    anErrorOcurredOnYourRegister: "An error ocurred with your registry, please check your information and try again",
    youMustAgreeToTerms: "You must agree to our terms of service and privacy policy",
    companyNameRequired: "Please write your company's name",
    passwordRecommendation:
        "We recommend your password contains at least 8 characters, upper and lowercase characters, and symbols.",
    shopifyParamsMissingError:
        "Required shopify data unavailable. You may need to reinstall the Cheetrack app on your Shopify account.",
    emailInUseError: "That email is already in use, click here to Log In.",
    tenantAlreadyExists: "That company is already registered, please try another name.",
    createNewTenant: "Create new company",
    selectTenant: "Select company",
    continueWithTenant: "Continue with {tenant}",
    companyName: "Company name",
    anErrorOcurredSelectingThisTenant: "An error ocurred selecting this company, please try again later.",
    anErrorOcurredCreatingTenant: "An error ocurred creating a new company, please try again later.",
    notAnUserYet: "Not an user yet? Sign Up here!",
    youAreEnteringAsStore: "You are entering with the Shopify Store: {name}",

    selectBranch: "Select branch",
}

export default messages
