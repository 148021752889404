import { appEvents, defaultMessageVariables } from "../constants"
import getWordDifference from "./get-word-difference"
import { isADefaultMessageVariable } from "./replace_default_message_variables"

// Funciones que chequean si un template es válido o no

function checkIfVariablesAreClose(text) {
    return /}}.{0,1}{{/.test(text)
}

function checkForForbiddenProducts(text) {
    const forbiddenProducts = []

    if (
        text.toLowerCase().includes("illegal") ||
        text.toLowerCase().includes("ilegal") ||
        text.toLowerCase().includes("unregulated") ||
        text.toLowerCase().includes("no regulado") ||
        text.toLowerCase().includes("fuera de la ley") ||
        text.toLowerCase().includes("outlaw")
    )
        forbiddenProducts.push("illegalProducts")

    if (
        text.toLowerCase().includes("drug") ||
        text.toLowerCase().includes("drugs") ||
        text.toLowerCase().includes("droga") ||
        text.toLowerCase().includes("drogas") ||
        text.toLowerCase().includes("marihuana") ||
        text.toLowerCase().includes("cocaína") ||
        text.toLowerCase().includes("ganja") ||
        text.toLowerCase().includes(" dope ") ||
        text.toLowerCase().includes("cocaine") ||
        text.toLowerCase().includes("pastabase") ||
        text.toLowerCase().includes(" crack ") ||
        text.toLowerCase().includes("cannabis") ||
        text.toLowerCase().includes(" coca ") ||
        text.toLowerCase().includes("anfetamina") ||
        text.toLowerCase().includes("anphetamine") ||
        text.toLowerCase().includes("extásis") ||
        text.toLowerCase().includes("extasis") ||
        text.toLowerCase().includes("cocaina") ||
        text.toLowerCase().includes("papelillo") ||
        text.toLowerCase().includes("pipa de agua")
    )
        forbiddenProducts.push("drugs")

    if (
        text.toLowerCase().includes(" pipa ") ||
        text.toLowerCase().includes("cigarro") ||
        text.toLowerCase().includes("cigarrillo") ||
        text.toLowerCase().includes(" cigar ") ||
        text.toLowerCase().includes("cigarette") ||
        text.toLowerCase().includes("tabacco") ||
        text.toLowerCase().includes("tobacco") ||
        text.toLowerCase().includes("tábaco") ||
        text.toLowerCase().includes("tabaco") ||
        text.toLowerCase().includes("nicotina") ||
        text.toLowerCase().includes("nicotine") ||
        text.toLowerCase().includes("narguil") ||
        text.toLowerCase().includes("narguiles") ||
        text.toLowerCase().includes(" vape ") ||
        text.toLowerCase().includes(" vaper ") ||
        text.toLowerCase().includes("fumar ") ||
        text.toLowerCase().includes(" fumar") ||
        text.toLowerCase().includes(" smoke") ||
        text.toLowerCase().includes("smoke ") ||
        text.toLowerCase().includes("smoking") ||
        text.toLowerCase().includes("vaping")
    )
        forbiddenProducts.push("tabacco")

    if (
        text.toLowerCase().includes("alcohol") ||
        text.toLowerCase().includes("alcohólic") ||
        text.toLowerCase().includes("alcoholic") ||
        text.toLowerCase().includes("drinking") ||
        text.toLowerCase().includes(" beber ") ||
        text.toLowerCase().includes("cerveza") ||
        text.toLowerCase().includes(" beer ") ||
        text.toLowerCase().includes(" wine ") ||
        text.toLowerCase().includes(" vino ") ||
        text.toLowerCase().includes("espumante") ||
        text.toLowerCase().includes("champagne") ||
        text.toLowerCase().includes("champaña") ||
        text.toLowerCase().includes("bourbon") ||
        text.toLowerCase().includes("whisky") ||
        text.toLowerCase().includes("wisky") ||
        text.toLowerCase().includes(" ron ") ||
        text.toLowerCase().includes(" rum ") ||
        text.toLowerCase().includes(" birra ") ||
        text.toLowerCase().includes("cavernet") ||
        text.toLowerCase().includes("sauvignon") ||
        text.toLowerCase().includes("carmenere") ||
        text.toLowerCase().includes(" bar ") ||
        text.toLowerCase().includes(" pub ") ||
        text.toLowerCase().includes("oktoberfest") ||
        text.toLowerCase().includes(" gyn ") ||
        text.toLowerCase().includes(" gin ") ||
        text.toLowerCase().includes("licor") ||
        text.toLowerCase().includes("schnaps") ||
        text.toLowerCase().includes("liquor") ||
        text.toLowerCase().includes("moonshine") ||
        text.toLowerCase().includes("ron blanco") ||
        text.toLowerCase().includes("vino tinto") ||
        text.toLowerCase().includes("vino blanco") ||
        text.toLowerCase().includes("red wine") ||
        text.toLowerCase().includes("vodka") ||
        text.toLowerCase().includes("absolut") ||
        text.toLowerCase().includes("heineken") ||
        text.toLowerCase().includes("ajenjo") ||
        text.toLowerCase().includes("jagermeister") ||
        text.toLowerCase().includes("absinthium") ||
        text.toLowerCase().includes("absenta") ||
        text.toLowerCase().includes("royal guard") ||
        text.toLowerCase().includes("kuntsmann") ||
        text.toLowerCase().includes("dolbek") ||
        text.toLowerCase().includes("carlsberg") ||
        text.toLowerCase().includes("yanjing") ||
        text.toLowerCase().includes("brewery") ||
        text.toLowerCase().includes("botilleria") ||
        text.toLowerCase().includes("botellón") ||
        text.toLowerCase().includes("budweiser") ||
        text.toLowerCase().includes("coors")
    )
        forbiddenProducts.push("alcohol")

    if (
        text.toLowerCase().includes("steroid") ||
        text.toLowerCase().includes("esteroide") ||
        text.toLowerCase().includes("chitosan") ||
        text.toLowerCase().includes("consuelda") ||
        text.toLowerCase().includes("dehidroepiandrosterona") ||
        text.toLowerCase().includes("efedra") ||
        text.toLowerCase().includes("suplementos") ||
        text.toLowerCase().includes("supplements") ||
        text.toLowerCase().includes("hormones") ||
        text.toLowerCase().includes("hormonas")
    )
        forbiddenProducts.push("unsafeSuplements")

    if (
        text.toLowerCase().includes(" gun") ||
        text.toLowerCase().includes("gun ") ||
        text.toLowerCase().includes(" ammo") ||
        text.toLowerCase().includes("ammunition") ||
        text.toLowerCase().includes("explosive") ||
        text.toLowerCase().includes(" arma") ||
        text.toLowerCase().includes("arma ") ||
        text.toLowerCase().includes("revólver") ||
        text.toLowerCase().includes("escopeta") ||
        text.toLowerCase().includes("rifle") ||
        text.toLowerCase().includes("shotgun") ||
        text.toLowerCase().includes("pistol") ||
        text.toLowerCase().includes("munición") ||
        text.toLowerCase().includes("weapon") ||
        text.toLowerCase().includes("arsenal") ||
        text.toLowerCase().includes("armeria") ||
        text.toLowerCase().includes("paintball") ||
        text.toLowerCase().includes("aire comprimido") ||
        text.toLowerCase().includes("fireworks") ||
        text.toLowerCase().includes("fuego artificial") ||
        text.toLowerCase().includes("fuegos artificiales") ||
        text.toLowerCase().includes("petardos") ||
        text.toLowerCase().includes("pepperspray") ||
        text.toLowerCase().includes("pepper spray") ||
        text.toLowerCase().includes(" stun ") ||
        text.toLowerCase().includes("paralizante") ||
        text.toLowerCase().includes("shooting") ||
        text.toLowerCase().includes("campo de tiro") ||
        text.toLowerCase().includes("campos de tiro") ||
        text.toLowerCase().includes("disparo") ||
        text.toLowerCase().includes("disparar")
    )
        forbiddenProducts.push("gunsAmmosOrExplosives")

    if (
        text.toLowerCase().includes("gallina") ||
        text.toLowerCase().includes("chicken") ||
        text.toLowerCase().includes("livestock") ||
        text.toLowerCase().includes("ganado") ||
        text.toLowerCase().includes("taxidermy") ||
        text.toLowerCase().includes("taxidermia") ||
        text.toLowerCase().includes("cows") ||
        text.toLowerCase().includes(" vacas") ||
        text.toLowerCase().includes("vacuno") ||
        text.toLowerCase().includes("panda") ||
        text.toLowerCase().includes("pingüino") ||
        text.toLowerCase().includes("penguin") ||
        text.toLowerCase().includes("exotic pet") ||
        text.toLowerCase().includes("mascota exótica") ||
        text.toLowerCase().includes("animal exótico") ||
        text.toLowerCase().includes("animales exóticos") ||
        text.toLowerCase().includes(" vaca ") ||
        text.toLowerCase().includes("axolotl")
    )
        forbiddenProducts.push("animals")

    if (
        text.toLowerCase().includes("sex toy") ||
        text.toLowerCase().includes("dildo") ||
        text.toLowerCase().includes("sexual toy") ||
        text.toLowerCase().includes("juguete sexual") ||
        text.toLowerCase().includes("juguetes sexuales") ||
        text.toLowerCase().includes("porno ") ||
        text.toLowerCase().includes("pornografía") ||
        text.toLowerCase().includes(" porno") ||
        text.toLowerCase().includes(" porn ") ||
        text.toLowerCase().includes("pornograph") ||
        text.toLowerCase().includes("live sex") ||
        text.toLowerCase().includes("sexo en vivo") ||
        text.toLowerCase().includes(" sex ") ||
        text.toLowerCase().includes(" sexo ") ||
        text.toLowerCase().includes("sexual performance") ||
        text.toLowerCase().includes("rendimiento sexual") ||
        text.toLowerCase().includes(" sexual ") ||
        text.toLowerCase().includes("blowjob") ||
        text.toLowerCase().includes("mamada") ||
        text.toLowerCase().includes("dama de compañía") ||
        text.toLowerCase().includes("hooker") ||
        text.toLowerCase().includes("prostitut") ||
        text.toLowerCase().includes("prostituc") ||
        text.toLowerCase().includes("escort") ||
        text.toLowerCase().includes("onlyfans") ||
        text.toLowerCase().includes("only fans") ||
        text.toLowerCase().includes("chaturbate") ||
        text.toLowerCase().includes("pornhub") ||
        text.toLowerCase().includes("xvideos") ||
        text.toLowerCase().includes("sextape") ||
        text.toLowerCase().includes("para adultos") ||
        text.toLowerCase().includes("for adults") ||
        text.toLowerCase().includes(" slut ") ||
        text.toLowerCase().includes(" puta ") ||
        text.toLowerCase().includes(" puta,") ||
        text.toLowerCase().includes(" putas ") ||
        text.toLowerCase().includes(" unlok ") ||
        text.toLowerCase().includes("unlok.me") ||
        text.toLowerCase().includes("escort")
    )
        forbiddenProducts.push("adultProductsOrServices")

    if (
        text.toLowerCase().includes("sangre") ||
        text.toLowerCase().includes("blood") ||
        text.toLowerCase().includes("wigs") ||
        text.toLowerCase().includes("peluca") ||
        text.toLowerCase().includes("orina") ||
        text.toLowerCase().includes("urine") ||
        text.toLowerCase().includes(" pee ") ||
        text.toLowerCase().includes(" poop ") ||
        text.toLowerCase().includes(" caca ") ||
        text.toLowerCase().includes(" caca,") ||
        text.toLowerCase().includes(" pipi ") ||
        text.toLowerCase().includes("bodyparts") ||
        text.toLowerCase().includes("body parts") ||
        text.toLowerCase().includes("partes del cuerpo") ||
        text.toLowerCase().includes("organs") ||
        text.toLowerCase().includes("órganos") ||
        text.toLowerCase().includes(" liver ") ||
        text.toLowerCase().includes("hígado") ||
        text.toLowerCase().includes("kidney") ||
        text.toLowerCase().includes("riñón") ||
        text.toLowerCase().includes("tejido humano")
    )
        forbiddenProducts.push("bodyPartsOrFluids")

    if (
        text.toLowerCase().includes("contact lenses") ||
        text.toLowerCase().includes("lentes de contacto") ||
        text.toLowerCase().includes("lesiones") ||
        text.toLowerCase().includes("injuries") ||
        text.toLowerCase().includes("termómetro") ||
        text.toLowerCase().includes("thermometer") ||
        text.toLowerCase().includes("succionadores de leche") ||
        text.toLowerCase().includes("succionador de leche") ||
        text.toLowerCase().includes("primeros auxilios") ||
        text.toLowerCase().includes("first aid") ||
        text.toLowerCase().includes("nicotina") ||
        text.toLowerCase().includes("nicotine")
    )
        forbiddenProducts.push("healthProducts")

    if (
        text.toLowerCase().includes("desnudo") ||
        text.toLowerCase().includes("desnuda") ||
        text.toLowerCase().includes("nudes") ||
        text.toLowerCase().includes(" nude") ||
        text.toLowerCase().includes("nude ") ||
        text.toLowerCase().includes("sexual") ||
        text.toLowerCase().includes("vágina") ||
        text.toLowerCase().includes("vagina") ||
        text.toLowerCase().includes("dick") ||
        text.toLowerCase().includes(" pene ") ||
        text.toLowerCase().includes(" falo ") ||
        text.toLowerCase().includes("fálico") ||
        text.toLowerCase().includes("clitoris") ||
        text.toLowerCase().includes("testículos") ||
        text.toLowerCase().includes("testicles") ||
        text.toLowerCase().includes("prepucio") ||
        text.toLowerCase().includes("teabagging") ||
        text.toLowerCase().includes("escroto") ||
        text.toLowerCase().includes("scrotum") ||
        text.toLowerCase().includes("ballsack") ||
        text.toLowerCase().includes("boudoir") ||
        text.toLowerCase().includes("erotic") ||
        text.toLowerCase().includes("erótic") ||
        text.toLowerCase().includes("braga ") ||
        text.toLowerCase().includes(" braga")
    )
        forbiddenProducts.push("productsWithSexualConnotation")

    if (
        text.toLowerCase().includes(" bets ") ||
        text.toLowerCase().includes(" bet ") ||
        text.toLowerCase().includes("apuesta") ||
        text.toLowerCase().includes("casino") ||
        text.toLowerCase().includes("blackjack") ||
        text.toLowerCase().includes("slot machine") ||
        text.toLowerCase().includes("slotmachine") ||
        text.toLowerCase().includes("roulette") ||
        text.toLowerCase().includes("ruleta") ||
        text.toLowerCase().includes("poker") ||
        text.toLowerCase().includes("apostar") ||
        text.toLowerCase().includes("gamble") ||
        text.toLowerCase().includes("gambling") ||
        text.toLowerCase().includes("kino") ||
        text.toLowerCase().includes("lotto") ||
        text.toLowerCase().includes("betting") ||
        text.toLowerCase().includes("betson") ||
        text.toLowerCase().includes("bets on") ||
        text.toLowerCase().includes("cassino")
    )
        forbiddenProducts.push("realMoneyBet")

    if (
        text.toLowerCase().includes("dating") ||
        text.toLowerCase().includes("citas") ||
        text.toLowerCase().includes("relaciones") ||
        text.toLowerCase().includes("relantionship") ||
        text.toLowerCase().includes("novio") ||
        text.toLowerCase().includes("novia") ||
        text.toLowerCase().includes("girlfriend") ||
        text.toLowerCase().includes("boyfriend") ||
        text.toLowerCase().includes("pololo") ||
        text.toLowerCase().includes("polola") ||
        text.toLowerCase().includes("pololear") ||
        text.toLowerCase().includes("pareja") ||
        text.toLowerCase().includes("soltero") ||
        text.toLowerCase().includes("soltera")
    )
        forbiddenProducts.push("dateServices")

    if (
        text.toLowerCase().includes("intervenir") ||
        text.toLowerCase().includes("intervene") ||
        text.toLowerCase().includes("interviene") ||
        text.toLowerCase().includes("liberado") ||
        text.toLowerCase().includes("preinstalado") ||
        text.toLowerCase().includes("preinstalled") ||
        text.toLowerCase().includes("decodificador") ||
        text.toLowerCase().includes("decoder") ||
        text.toLowerCase().includes("unauthorized") ||
        text.toLowerCase().includes("no autorizado") ||
        text.toLowerCase().includes("netflix") ||
        text.toLowerCase().includes("direct tv") ||
        text.toLowerCase().includes("amazon prime") ||
        text.toLowerCase().includes("disney +") ||
        text.toLowerCase().includes("hbo max") ||
        text.toLowerCase().includes("satelital") ||
        text.toLowerCase().includes("sátelital") ||
        text.toLowerCase().includes("desbloquear") ||
        text.toLowerCase().includes("desbloqueo") ||
        text.toLowerCase().includes("jailbreak") ||
        text.toLowerCase().includes("jail break") ||
        text.toLowerCase().includes("unlock")
    )
        forbiddenProducts.push("unauthorizedAccesToDigitalMedia")

    if (
        text.toLowerCase().includes("multilevel") ||
        text.toLowerCase().includes("multinivel") ||
        text.toLowerCase().includes("subasta") ||
        text.toLowerCase().includes("auction") ||
        text.toLowerCase().includes("criptomoneda") ||
        text.toLowerCase().includes("cryptocurrency") ||
        text.toLowerCase().includes("crypto") ||
        text.toLowerCase().includes("opciones binarias") ||
        text.toLowerCase().includes("microcréditos") ||
        text.toLowerCase().includes("microcredit") ||
        text.toLowerCase().includes("pérdida de peso") ||
        text.toLowerCase().includes("dietético") ||
        text.toLowerCase().includes("weight loss") ||
        text.toLowerCase().includes("ethereum") ||
        text.toLowerCase().includes("bitcoin") ||
        text.toLowerCase().includes("naturlife") ||
        text.toLowerCase().includes("payday") ||
        text.toLowerCase().includes("loans") ||
        text.toLowerCase().includes("anticipo de sueldo") ||
        text.toLowerCase().includes("anticipos de sueldo") ||
        text.toLowerCase().includes("préstamo") ||
        text.toLowerCase().includes("deudas") ||
        text.toLowerCase().includes("fianza") ||
        text.toLowerCase().includes("tariff") ||
        text.toLowerCase().includes("herbalife") ||
        text.toLowerCase().includes("herba life")
    )
        forbiddenProducts.push("offensiveFraudulentDeceivingOrExploitativeCommercialModels")

    if (
        text.toLowerCase().includes("money") ||
        text.toLowerCase().includes("cash ") ||
        text.toLowerCase().includes(" cash") ||
        text.toLowerCase().includes("moneda") ||
        text.toLowerCase().includes("dinero") ||
        text.toLowerCase().includes("crypto") ||
        text.toLowerCase().includes("crédito") ||
        text.toLowerCase().includes("credit") ||
        text.toLowerCase().includes("débito") ||
        text.toLowerCase().includes("cupones") ||
        text.toLowerCase().includes("coupons") ||
        text.toLowerCase().includes("dinero falso") ||
        text.toLowerCase().includes(" pesos") ||
        text.toLowerCase().includes("pesos ") ||
        text.toLowerCase().includes("prepago") ||
        text.toLowerCase().includes("prepagad") ||
        text.toLowerCase().includes("cheque") ||
        text.toLowerCase().includes("talonario") ||
        text.toLowerCase().includes("falsifica") ||
        text.toLowerCase().includes("dólares") ||
        text.toLowerCase().includes("dollars") ||
        text.toLowerCase().includes(" NFT ") ||
        text.toLowerCase().includes("bitcoin") ||
        text.toLowerCase().includes(" ether ") ||
        text.toLowerCase().includes("eterium") ||
        text.toLowerCase().includes("doggecoin")
    )
        forbiddenProducts.push("realVirtualOrFakeMoney")

    if (
        text.toLowerCase().includes("pirata") ||
        text.toLowerCase().includes("pirater") ||
        text.toLowerCase().includes("piracy") ||
        text.toLowerCase().includes("falsifica") ||
        text.toLowerCase().includes("forgery") ||
        text.toLowerCase().includes(" cd ") ||
        text.toLowerCase().includes(" cds ") ||
        text.toLowerCase().includes(" dvd ") ||
        text.toLowerCase().includes(" dvds ") ||
        text.toLowerCase().includes("copias") ||
        text.toLowerCase().includes(" copy ")
    )
        forbiddenProducts.push("forgeryOrPiracy")

    return forbiddenProducts
}

function checkForThreats(text) {
    return (
        text.toLowerCase().includes("amenaza") ||
        text.toLowerCase().includes("demanda") ||
        text.toLowerCase().includes(" suing") ||
        text.toLowerCase().includes("warned") ||
        text.toLowerCase().includes(" sue ") ||
        text.toLowerCase().includes("cease and desist") ||
        text.toLowerCase().includes("litigación") ||
        text.toLowerCase().includes("cese y desist") ||
        text.toLowerCase().includes("legal action") ||
        text.toLowerCase().includes("cancelación") ||
        text.toLowerCase().includes("payback") ||
        text.toLowerCase().includes("cancelling") ||
        text.toLowerCase().includes("venganza") ||
        text.toLowerCase().includes("humillación") ||
        text.toLowerCase().includes("funado") ||
        text.toLowerCase().includes(" funa ") ||
        text.toLowerCase().includes("humiliate") ||
        text.toLowerCase().includes("beat you") ||
        text.toLowerCase().includes("golpiza") ||
        text.toLowerCase().includes("paliza") ||
        text.toLowerCase().includes("acción legal") ||
        text.toLowerCase().includes("payback") ||
        text.toLowerCase().includes("ransom")
    )
}

function checkForIdDocuments(text) {
    return (
        text.toLowerCase().includes(" RUT ") ||
        text.toLowerCase().includes(" DNI ") ||
        text.toLowerCase().includes("passport") ||
        text.toLowerCase().includes("pasaporte") ||
        text.toLowerCase().includes("social security number") ||
        text.toLowerCase().includes("número de seguridad social") ||
        text.toLowerCase().includes("documento de identidad") ||
        text.toLowerCase().includes("documento de identificación") ||
        text.toLowerCase().includes("id document") ||
        text.toLowerCase().includes("credit card") ||
        text.toLowerCase().includes("debit card") ||
        text.toLowerCase().includes("tarjeta de crédito") ||
        text.toLowerCase().includes("tarjeta de débito") ||
        text.toLowerCase().includes("tarjeta de credito") ||
        text.toLowerCase().includes("tarjeta de debito") ||
        text.toLowerCase().includes("health insurance number") ||
        text.toLowerCase().includes("tarjeta de salud") ||
        text.toLowerCase().includes("health card") ||
        text.toLowerCase().includes("licencia de conduc") ||
        text.toLowerCase().includes("licensia de conduc") ||
        text.toLowerCase().includes("lisencia de conduc") ||
        text.toLowerCase().includes("driving license") ||
        text.toLowerCase().includes("drive license") ||
        (text.toLowerCase().includes("driver") && text.toLowerCase().includes("license")) ||
        text.toLowerCase().includes("tarjeta europea") ||
        text.toLowerCase().includes("european card") ||
        text.toLowerCase().includes("id number") ||
        text.toLowerCase().includes("número de id")
    )
}

function checkIfTemplateHasRequiredVariables(text, type, buttons) {
    const requiredVariables = [],
        appEvent = appEvents.find((app) => app.value === type),
        typeVariables = appEvent?.templateRules.requiredVariables || [],
        requiredBtn = appEvent?.templateRules.requiredButton

    if (
        requiredBtn &&
        buttons &&
        !buttons.some((b) => b.type.toLowerCase() === requiredBtn.type.toLowerCase() && b.url === requiredBtn.url)
    ) {
        typeVariables.push(requiredBtn.variableAlternative)
    }

    if (typeVariables)
        typeVariables.forEach((v) => {
            if (!text.toLowerCase().includes(v)) requiredVariables.push(v)
        })

    return requiredVariables
}

function checkTemplateMessage(text, type, buttons) {
    const warnings = {}

    if (text.length > 0) {
        warnings.closeVariables = checkIfVariablesAreClose(text)
        warnings.forbiddenProducts = checkForForbiddenProducts(text)
        warnings.containsHtml = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/g.test(text)
        warnings.threats = checkForThreats(text)
        warnings.idDocuments = checkForIdDocuments(text)
        warnings.tooManyLineBreaks = text.match(/[^\n]*\n[^\n]*/gi)?.length > 4
    }

    if (type !== "normal") {
        warnings.requiresTypeVariables = checkIfTemplateHasRequiredVariables(text, type, buttons)
    }

    const variables = text.match(/\{{(.+?)\}}/g)
    if (variables) {
        warnings.tooManyVariables = text.replace(/\{{(.+?)\}}/g, "").length / variables.length < 15

        warnings.defaultVariableSimilarity = {}
        variables.forEach((v) => {
            if (!isADefaultMessageVariable(v)) {
                defaultMessageVariables.forEach((dV) => {
                    if (!warnings.defaultVariableSimilarity[dV.name] && getWordDifference(dV.bracket_name, v) < 6)
                        warnings.defaultVariableSimilarity[dV.name] = v
                })
            }
        })

        warnings.variablesWithUrls = variables.some((v) => {
            const varText = v.replace("{{", "").replace("}}", ""),
                test = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/.test(varText)
            return test
        })
        warnings.variablesWithEmptySpaces = variables.some((v) => v.includes(" "))
    }

    return warnings
}

export default checkTemplateMessage
