const messages = {
  "campaigns": "Campaigns",
  "campaignsSubtitle": "Manage your WhatsApp campaigns",
  "youCanOnlyHaveUpToXCampaigns": "You can only have up to {x} campaigns",
  "youWillNeedToDeleteSomeCampaignsToCreateANewOne": "You will need to delete some campaigns to create a new one",
  "createCampaign": "Create Campaign",
  "editCampaign": "Edit Campaign",
  "campaignsDeleteDialogDescription": "Are you sure you want to delete this campaign?",
  "campaignsDialogConfirmButton": "Confirm",
  "campaignsDeleteDialogTitle": "Delete campaign {campaign_name}",
  "campaignDeletingError": "An error occurred while deleting the campaign",
  "name": "Name",
  "dateAndTime": "Date and Time",
  "totalMessages": "Total Messages",
  "template": "Template",
  "status": "Status",
  "actions": "Actions",
  "campaignName": "Campaign Name",
  "selectTemplate": "Select Template",
  "skipQualityWarning": "Skip Quality Warning",
  "antiSpam": "Anti-Spam",
  "updateCampaign": "Update Campaign",
  "cancel": "Cancel",
  "csvFileUpload": "Upload CSV File",
  "csvFileError": "The CSV file does not contain the required columns",
  "campaignCreatedSuccess": "Campaign created successfully",
  "campaignUpdatedSuccess": "Campaign updated successfully",
  "scheduled": "Scheduled",
  "draft": "Draft",
  "completed": "Completed",
  "in_progress": "In Progress",
  "adminPhone": "Admin Phone",
  "adminName": "Admin Name",
  "timezone": "Timezone",
  "csvFile": "CSV File",
  "next": "Next",
  "back": "Back",
  "Campaign Details": "Campaign Details",
  "File Upload and Template": "File Upload and Template",
  "Additional Settings": "Additional Settings",
  "messageWarningCsv":"Please upload a CSV file with the columns: firstname, lastname, phone",
  "selectTemplateForYourCampaign":"Select the Start Message for your campaign",
  "contentOfTheSelectedTemplate": "Content of the selected template",
  "downloadSampleCSVFile": "Download Sample CSV File",
  "CSVFileExample":"CSV File Example",
  "close": "Close",
  "NoCSVFileSelected":"No CSV file selected",
  "pleaseSelectACSVFileToContinue":"Please select a CSV file to continue",
  "theStartMessageIsRequired": "The start message is required",
  "enterTheTotalNumberOfMessagesForThisCampaign":"Enter the total number of messages for this campaign",
  "labelAntiSpam":"It will not be sent to customers who received this same start message in the last N days ago",
  "enterAdminPhone":"Enter the administrator's phone number",
  "enterAdminName":"Enter the administrator name",
  "SkipQualityWarning":"Skip quality warning",
  "campaignConfirm":"Confirmation of campaign",
  "adminName": "The administrator's name is required",
  "adminPhone": "The administrator's phone is required",
  "totalMessages": "The total number of messages is required",
  "errorAdminName": "Error: The administrator's name is required",
  "dialogAdminName": "Please enter the administrator's name to proceed",
  "errorAdminPhone": "Error: The administrator's phone is required",
  "dialogAdminPhone": "Please enter the administrator's phone to proceed",
  "errorTotalMessages": "Error: The total number of messages is required",
  "dialogTotalMessages": "Please enter the total number of messages to proceed"
}
export default messages