import { createSlice } from "@reduxjs/toolkit"
import uuid from "react-uuid"
import {
    formatCheetabotToStoreQuestionContent,
    getDefaultCheetabotSequence,
    getDefaultCheetabotSequenceFlows,
} from "../utils"
import initialState from "./cheetabot-initial-state"

const cheetabotSlice = createSlice({
    name: "cheetabot",
    initialState,
    reducers: {
        setActiveStatus(state, action) {
            state.selectedBot.is_active = action.payload
        },
        setSequence(state, action) {
            state.selectedBot.sequence = action.payload
        },
        addQuestionChild(state, action) {
            state.botUpdate.questionContent[action.payload.id] = action.payload
        },
        addQuestion(state, action) {
            const { type, includeStartMessage } = action.payload,
                defaultQuestions = getDefaultCheetabotSequence(includeStartMessage, false),
                newQuestion = defaultQuestions.find((q) => q.type === type)

            if (!state.selectedBot.sequence) state.selectedBot.sequence = []
            state.botUpdate.questionContent[newQuestion.id] = newQuestion

            const newIndex = state.selectedBot.sequence.some((q) => q.type === "message")
                ? state.selectedBot.sequence.length - 1
                : state.selectedBot.sequence.length

            state.selectedBot.sequence.splice(newIndex, 0, newQuestion)

            if (type === "questionWithOptions") {
                newQuestion.childOptions.forEach((child) => {
                    state.botUpdate.questionContent[child.id] = child
                })
            }
        },
        addQuestionFlow(state, action) {
            const { type } = action.payload,
                defaultQuestions = getDefaultCheetabotSequenceFlows(false, true),
                newQuestion = defaultQuestions.find((q) => q.type === type)

            if (!state.selectedBot.sequence) state.selectedBot.sequence = []
            state.botUpdate.questionContent[newQuestion.id] = newQuestion

            const newIndex = state.selectedBot.sequence.some((q) => q.type === "message")
                ? state.selectedBot.sequence.length - 1
                : state.selectedBot.sequence.length
            state.selectedBot.sequence = defaultQuestions
        },
        deleteQuestion(state, action) {
            state.selectedBot.sequence = state.selectedBot.sequence.filter((q) => q.id !== action.payload)
        },
        updateQuestion(state, action) {
            state.botUpdate.questionContent[action.payload.id] = action.payload
        },
        copyQuestion(state, action) {
            const originalIndex = state.selectedBot.sequence.findIndex((q) => q.id === action.payload),
                newQuestion = JSON.parse(JSON.stringify(state.selectedBot.sequence[originalIndex]))

            newQuestion.id = uuid()
            newQuestion.name = "question_" + newQuestion.id

            function checkArrs(content) {
                if (content.childOptions && content.childOptions.length > 0) {
                    content.childOptions = setNewIdsForChilds(content.childOptions, content.id)
                }
                if (content.selectedOption?.childOptions && content.selectedOption?.childOptions.length > 0) {
                    content.selectedOption.childOptions = setNewIdsForChilds(
                        content.selectedOption.childOptions,
                        content.id
                    )
                }
            }

            function setNewIdsForChilds(arr, parentId) {
                return arr.map((child) => {
                    const newId = uuid(),
                        content = JSON.parse(JSON.stringify(child))

                    content.id = newId
                    content.name = "question_" + newId
                    content.parentId = parentId
                    checkArrs(content)

                    state.botUpdate.questionContent[newId] = content
                    return content
                })
            }

            checkArrs(newQuestion)

            console.log("newQuestion", newQuestion)

            state.botUpdate.questionContent[newQuestion.id] = newQuestion
            state.selectedBot.sequence.splice(originalIndex + 1, 0, newQuestion)
        },
        deleteQuestionChild(state, action) {
            const { id, parentId } = action.payload,
                parent = state.botUpdate.questionContent[parentId]

            if (parent.childOptions) parent.childOptions = parent.childOptions.filter((o) => o.id !== id)
            if (parent.optionSelected.childOptions)
                parent.optionSelected.childOptions = parent.optionSelected.childOptions.filter((o) => o.id !== id)
        },
        changeQuestionOrder(state, action) {
            const { removedIndex, addedIndex } = action.payload,
                removedElm = state.selectedBot.sequence[removedIndex],
                addedElm = state.selectedBot.sequence[addedIndex]

            state.selectedBot.sequence[removedIndex] = addedElm
            state.selectedBot.sequence[addedIndex] = removedElm
        },
        resetBot(state) {
            state.selectedBot = {
                id: null,
                name: "",
                sequence: getDefaultCheetabotSequence(),
                utterances: [],
                type: state.bots.length <= 1 ? "welcome" : "keyword",
            }
        },
        deleteBot(state, action) {
            state.bots = state.bots.filter((b) => b.id !== action.payload)
            const bot = state.bots.find((b) => b.is_main_bot) || state.bots[0]
            if (bot) state.selectedBot = bot
            else {
                state.selectedBot = {
                    id: null,
                    name: "",
                    sequence: getDefaultCheetabotSequence(),
                    utterances: [],
                    type: state.bots.length <= 1 ? "welcome" : "keyword",
                }
            }

            state.errors["deleteBot"] = false
            state.loaders["deleteBot"] = false
        },
        setError(state, action) {
            state.loaders[action.payload.action] = false
            state.errors[action.payload.action] = action.payload.status
        },
        setLoader(state, action) {
            state.errors[action.payload.action] = false
            state.loaders[action.payload.action] = action.payload.status
        },
        gotBot(state, action) {
            const { bots } = action.payload
            bots.forEach((b) => {
                b.id = b.PK.replace("INTENT#", "")
                b.name = b.metadata.body.intents[0].name
                b.is_active = b.metadata.body.intents[0].is_active
                b.sequence = b.metadata.body.intents[0].slots
                b.utterances = b.metadata.body.intents[0].utterances.filter((u) => u !== b.name)
                b.amazonData = b.metadata.body.intents[0].bot
                b.updated_at = new Date(
                    new Date(b.metadata.updated_at).getTime() - new Date().getTimezoneOffset() * 60 * 1000
                )

                b.type = b.metadata.body.intents[0].type
                b.bot_subtype = b.metadata.body.intents[0].bot_subtype
                b.is_main_bot = b.metadata.body.intents[0].is_main_bot || !b.type

                if (!b.type) {
                    if (b.is_main_bot) b.type = "welcome"
                    else if (b.utterances.length > 0) b.type = "keyword"
                    else b.type = "chase"
                }
                if (b.metadata.body.intents[0].bot_subtype) b.bot_subtype = b.metadata.body.intents[0].bot_subtype
            })

            const bot = bots.find((b) => b.is_main_bot) || bots[0]
            console.log("Bots", bots)
            state.bots = bots
            state.selectedBot = bot

            if (bot && bot.sequence) {
                state.botUpdate = {
                    questionContent: formatCheetabotToStoreQuestionContent(bot.sequence),
                }
            }

            state.tenant_has_active_bot = bots.some((b) => b.is_active)
            state.errors.getBot = false
            state.loaders.getBot = false
        },
        selectBot(state, action) {
            const bot = state.bots.find((b) => b.id === action.payload)
            if (bot) {
                state.selectedBot = bot

                state.botUpdate = {
                    questionContent: formatCheetabotToStoreQuestionContent(
                        bot.sequence || bot.metadata.body.intents[0].slots
                    ),
                }
            }
        },
        setNewBot(state) {
            state.selectedBot = {
                id: null,
                name: "",
                sequence: getDefaultCheetabotSequence(),
                utterances: [],
                is_active: false,
                type: "keyword",
            }
        },
        setActiveBotConversations(state, action) {
            state.activeBotConversations = action.payload
            state.loaders.getActiveConversations = false
        },
        createdBot(state, action) {
            const bot = {
                ...action.payload,
                updated_at: new Date().toISOString(),
                utterances: action.payload.utterances.filter(
                    (u) => u.toLowerCase() !== action.payload.name.toLowerCase()
                ),
            }
            state.loaders.createBot = false
            state.errors.createBot = false

            state.selectedBot = bot
            state.bots.push(bot)
        },
        updatedBot(state, action) {
            const { id, name, status, sequence, is_main_bot, utterances, type } = action.payload

            const updateBot = state.bots.find((b) => b.id === id)
            updateBot.metadata.body.intents[0].is_active = status
            updateBot.metadata.body.intents[0].slots = sequence
            updateBot.is_active = status
            updateBot.sequence = sequence
            updateBot.name = name
            updateBot.updated_at = new Date().toISOString()
            updateBot.is_main_bot = is_main_bot
            if (utterances) updateBot.utterances = utterances
            updateBot.type = type

            if (state.selectedBot.id === id) state.selectedBotName = updateBot

            state.loaders.updateBot = false
            state.errors.updateBot = false
        },
        setBotName(state, action) {
            state.selectedBot.name = action.payload
        },
        setBotUtterances(state, action) {
            state.selectedBot.utterances = action.payload
        },
        setBotType(state, action) {
            state.selectedBot.type = action.payload
        },
    },
})

export const cheetabotActions = cheetabotSlice.actions
export default cheetabotSlice
