const messages = {
    visits: "Visitas",
    visitsDescription: "Descrição de visitas",
    newClients: "Novos clientes",
    existingClients: "Clientes existentes",
    thisPeriodClients: "Clientes durante este período",
    previousPeriodClients: "Clientes durante o período anterior",
    dailyClientsFromTo: "Ações diárias de {from} a {to}",
    newClientsFromTo: "Novos clientes únicos de {from} a {to}",
    recurringClientsLast12Months: "Clientes recorrentes nos últimos {x} meses",
    recurringClientsLast12Weeks: "Clientes recorrentes nas últimas {x} semanas",
    interactions: "Interações",
    menusSent: "Menus enviados",
    menusSentThisPeriod: "Menus enviados neste período",
    menusOpensThisPeriod: "Menus abertos neste período",
    menusSentPreviousPeriod: "Menus enviados no período anterior",
    menusOpensPreviousPeriod: "Menus abertos do período anterior",
    menusSentFromTo: "Menus enviados de {from} a {to}",
    menusOpensFromTo: "Cartas abertas de {from} até {to}",
    menus: "Menus",
    answerRate: "Taxa de resposta",
    dataUpdatesEveryMondayMorning: "Os dados são atualizados toda segunda-feira de manhã.",
    onlyWeeksWithDataAvailableAreShown: "Apenas as semanas com dados disponíveis são exibidas.",
    xClientsFromWeekYReturnedOnWeekZ: "{x} clientes da semana {y} retornaram na semana {z}",
    xNewClientsOnWeekY: "{x} novos clientes na semana {y}",
    weeklyRecurrence: "Recorrência semanal",

    anErrorOcurredObtainingData:
        "Ocorreu um erro ao obter alguns dos dados, por favor, altere o intervalo de datas ou tente novamente mais tarde.",
    dataForMenusBeforeThe13OfJulyIsNotAvailable:
        "Os dados para os menus enviados antes de 13 de julho de 2023 não estão disponíveis.",
}

export default messages
