const messages = {
    connectToInstagram: "Conectar con Facebook e Instagram",
    yourInstagramIsConnected: "Tus cuentas de Facebook e Instagram están conectadas!",
    yourWhatsAppIsConnected: "Tu cuenta de WhatsApp está conectada.",
    yourWhatsAppIsNotReady: "Tu cuenta de WhatsApp está en proceso, te contactaremos cuando esté lista para su uso.",
    yourInstagramHasIssues:
        "Tenemos problemas con tu cuenta de Facebook o Instagram, puede fallar el envío o recepción de mensajes.",
    yourWhatsAppHasIssues:
        "Tenemos problemas con tu cuenta de WhatsApp, puedes fallar el envío o recepción de mensajes.",
    instagramInstructionsTitle: "Conectar Cheetrack con Facebook e Instagram",
    instagramInstructions1: "Para conectar tu cuenta de Instagram necesitas:",
    instagramInstructions2: "1) Tener una cuenta Business/Comercial en Instagram.",
    instagramInstructions3: "2) Tener una cuenta de Facebook Business ligada a esa cuenta de Instagram.",
    instagramInstructions4: "3) Presionar el botón abajo y otorgar a Cheetrack los permisos solicitados.",
    youAlreadyHaveAnInstagramAccount: "Ya tienes una cuenta de Facebook conectada a esta cuenta de Cheetrack.",
    youAlreadyHaveAnInstagramAccount2:
        "Si presionas el botón abajo puedes reemplazar la cuenta de Facebook conectada por otra.",
    connectNewInstagram: "Conectar otra cuenta de Facebook",
    integrations: "Integraciones",

    connectWithShopify: "Conectar con Shopify",
    yourShopifyAccountIsConnected: "Tu cuenta de Shopify está conectada!",
    shopifyInstrucitionsTitle: "Conectar Cheetrack con Shopify",
    shopifyInstruction1: "Para conectar tu cuenta de Shopify debes:",
    shopifyInstruction2: "1) Ir a 'Aplicaciones' dentro de tu cuenta de Shopify",
    shopifyInstruction3: "2) Haz click en 'Desarrollar Aplicaciones'",
    shopifyInstruction4: "3) Haz click en 'Permitir desarrollo de Aplicaciones personalizadas'",
    shopifyInstruction5: "4) Haz click en 'Crear una Aplicación'",
    shopifyInstruction6:
        "5) Escribe un nombre para tu Aplicación (puede ser Cheetrack), selecciona un usuario como desarrollador (puedes ser tú) y haz click en 'Crear Aplicación'",
    shopifyInstruction7: "6) Haz click en 'Configurar alcances de la API del panel de control'",
    shopifyInstruction8:
        "7) Entre las opciones disponibles, anda a Órdenes/Orders y habilita los siguientes: 'read_orders', 'read_customers', 'write_discounts', y 'read_discounts'. Luego haz click en 'Guardar'",
    shopifyInstruction9:
        "8) Cuando termine de guardar, haz click en 'Instalar Aplicación', si se abre una ventana de confirmación, haz click en 'Instalar'",
    shopifyInstruction10:
        "9) Después de que la instalación esté completa, tu 'Token de acceso de la API del panel de control' aparecerá, haz click en 'Revelar token una vez' para verlo",
    shopifyInstruction11:
        "10) Copia tu Token de acceso de la API del panel de control y pégale en el cuadro de texto aquí abajo",
    shopifyInstruction12:
        "11) Luego, escribe el nombre de tu tienda de Shopify en el otro cuadro de texto, es la dirección de url del panel tu cuenta de Shopify y debiese ser algo como: 'cheetrack.myshopify.com'",
    shopifyInstruction13: "12) Finalmente, haz click en 'Conectar con Shopify'",
    adminApiAccessToken: "Token de acceso de la API del panel de control",
    youAlreadyHaveAnShopifyAccount: "Ya tienes una cuenta de Shopify conectada a esta cuenta de Cheetrack.",
    youAlreadyHaveAnShopifyAccount2:
        "Puedes cambiar el Access Token y Shop URL debajo y luego presionar el botón para reemplazar tu conexión a Shopify por una nueva.",
    connectNewShopify: "Reemplazar conexión con Shopify",
    hereYouCanConnectCheetrackToOtherApps: "Aquí puedes conectar Cheetrack a otras Apps.",
    clickingOnAnAppIconWillTellYouHowToConnectOrShowSettings:
        "Clickear en un ícono de App te mostrará como conectarte o desplegará información sobre esa App.",
    shopifyDescription1: "Al conectar Shopify podrás:",
    shopifyDescription2: "Importar y conectar clientes e historial de pedidos.",
    shopifyDescription3: "Importar y conectar catálogo de productos.",
    shopifyDescription4: "Rescatar carros perdidos",
    instagramDescription1: "Al conectar Facebook e Instagram podrás:",
    instagramDescription2: "Enviar y recibir mensajes de Facebook e Instagram.",
    lostCart: "Carro abandonado",
    customerPhoneNumberRegistration: "Registro del número de teléfono del cliente",
    integrationsDescription:
        "Integrar otras Apps te permitirá recibir mensajes, activar persecuciones o enlazar una variedad de funcionalidades con tu cuenta de Cheetrack.",
    integrationsDescription2:
        "Cada App tiene su propia forma de integrarse a Cheetrack que tendrás que revisar para cada una por separado.",

    connectNewAgendaPro: "Conectar nueva cuenta de Agenda Pro",
    connectWithAgendaPro: "Conectar con Agenda Pro",
    agendaProInstrucitionsTitle: "Conectar Cheetrack a Agenda Pro",
    yourAgendaProAccountIsConnected: "Tu cuenta de Agenda Pro está conectada",
    agendaProDescription1: "Al conectar tu cuenta de Agenda Pro podrás:",
    agendaProDescription2: "Enviar mensajes con recordatorios a tus clientes.",
    agendaProInstruction1: "Para conectar tu cuenta de Agenda Pro deberás:",
    agendaProInstruction2:
        "1) Ir a Integraciones / API Pública en tu cuenta de Agenda Pro, o haz click el siguiente link: ",
    agendaProInstruction3: "2) Ir a API Pública y haz click en Crear Webhook",
    agendaProInstruction4:
        "3) Pegar el siguiente texto en el cuadro de texto: api.cheetrack.com/api/v1/agenda-pro/webhook",
    agendaProInstruction5: "4) Hacer click en Activo y luego Guardar",
    agendaProInstruction6:
        "5) Copiar la información de User and Password de tu API pública en Agenda Pro y pegarla en los cuadros de texto debajo",
    agendaProInstruction7: "6) Hacer click en el botón debajo que dice 'Conectar con Agenda Pro'",
    agendaProError: "Ocurrió un error al integrar con Agenda Pro, por favor vuelve a intentarlo más tarde.",
    youAlreadyHaveAnAgendaProAccount: "Ya tienes una cuenta de Agenda Pro conectada a esta cuenta de Cheetrack.",
    youAlreadyHaveAnAgendaProAccount2:
        "Puedes cambiar la cuenta a la que estás conectado escribiendo nuevos datos en API User and API Password y luego haciendo click en el botón debajo.",
    agendaProInstructions1: "1. Visita la página de ",
    agendaProInstructions1b: " en las configuraciones de tu cuenta AgendaPro.",
    agendaProInstructions2:
        "2. En la sección <b>API pública</b>, copia los valores de User y Password, y pégales en los campos de abajo de este formulario.",
    agendaProInstructions3: "3. En AgendaPro, presiona ** + Crear Webhook** y copia la siguiente URL: ",
    agendaProInstructions3b: " Asegúrate que sea https y quede Activo",
    agendaProInstructions4: "4. En Cheetrack, presiona Conectar con AgendaPro",
    agendaProInstructions5: "Integraciones / Api Pública",
    instagramError:
        "Un error ocurrió al intentar integrar con Facebook o Instagram, por favor vuelve a intentarlo más tarde.",
    shopifyError: "Un error ocurrió al intentar integrar con Shopify, por favor vuelve a intentarlo más tarde.",
    connectingToShopify: "Connectando con Shopify",
    toConnectWithShopifyYouMustLogIn: "Para conectarse con Shopify debes primero ingresar a Cheetrack!",
    finishingYouShopifyConnection: "Dando los pasos finales para tu conexión a Shopify 😉",
    whatsAppInstructionsTitle: "Conectar a WhatsApp API",

    whatsAppDescription: "Beneficios de conectar a WhatsApp API",
    whatsAppDescription1: "Enviar y recibir mensajes de WhatsApp.",
    whatsAppDescription2: "Enviar y recibir archivos, imágenes y grabaciones de audio.",
    whatsAppDescription3: "Recibir localizaciones y mostrarlas en un mapa.",
    whatsAppDescription4: "Programar a Cheetabot para responder a tus clientes.",
    whatsAppDescription5: "Configurar persecuciones para hacer seguimiento y recordatorios.",
    youAlreadyHaveAWhatsAppAccount: "Ya tienes una cuenta de WhatsApp API conectada a esta cuenta de Cheetrack.",
    youAlreadyHaveAWhatsAppAccount2:
        "Al presionar el botón abajo, puedes reemplazar la cuenta de WhatsApp API por otra.",
    connectNewWhatsApp: "Conectar una nueva cuenta de WhatsApp API",
    whatsAppError: "Un error ocurrió intentando conectar con WhatsApp API, por favor vuelve a intentarlo más tarde.",
    connectToWhatsApp: "Conectar con WhatsApp API",
    whatsAppInstructions1: "Instrucciones para conectar con WhatsApp API:",
    whatsAppInstructions2: "Ir a {link} ➜ Si no tienes perfil de desarrollador: 'Comenzar' ➜ Creas tu Perfil.",
    whatsAppInstructions3:
        "'Mis Apps' ➜ 'Crear APP' ➜ 'Negocios' ➜ Le agregas un nombre a la APP ➜ Seleccionas cuenta comercial de tu negocio ➜ 'Crear'",
    whatsAppInstructions4: "Entras al panel de la APP ➜ 'Agregar productos' ➜ 'Inicio de sesión con Facebook'",
    whatsAppInstructions5: "Luego en el menú de la izquierda 'Inicio de sesión con Facebook' ➜ 'Configuración'",
    whatsAppInstructions51: "Iniciar sesión con el SDK para JavaScript: Sí",
    whatsAppInstructions52: "URI de redireccionamiento de OAuth válidos: https://app.cheetrack.com/integrations",
    whatsAppInstructions53:
        "Dominios permitidos para el SDK para JavaScript: https://app.cheetrack.com ➜ 'Guardar Cambios'",
    whatsAppInstructions6: "En el panel izquierdo ➜ 'Webhooks' ➜ 'Configurar'",
    whatsAppInstructions61: "En el menú desplegable 'User' seleccionamos 'WhatsApp Business Account' ➜ 'Suscribir'",
    whatsAppInstructions62: "URL de devolución de llamada: https://api.cheetrack.com/v1/whatsapp/webhook/receive",
    whatsAppInstructions63: "Token de verificación: meatyhamhock",
    whatsAppInstructions64: "Luego haz click en suscribir para todas las opciones.",
    whatsAppInstructions7: "Vamos arriba al panel izquierdo ➜ 'WhatsApp' ➜ 'Configurar'",
    whatsAppInstructions71:
        "En este paso debes tener tu cuenta de WhatsApp eliminada y tener el chip del número en un teléfono para recibir un SMS.",
    whatsAppInstructions72: "'Primeros pasos'",
    whatsAppInstructions73: "Vamos al paso 5 agregar número de teléfono.",
    whatsAppInstructions74: "Rellenamos con la información solicitada.",
    whatsAppInstructions741:
        "Si tu rubro es “belleza, spa” te recomendamos no seleccionar esta categoría para evitar cualquier tipo de rechazo por parte de WhatsApp.",
    whatsAppInstructions75: "Agregamos el número de teléfono, e ingresamos el código.",
    whatsAppInstructions8:
        "Ya fue enviado a verificar tu número de teléfono 😉, te va a llegar un correo electrónico 📩 diciendo 'tu cuenta de WhatsApp ya fue aprobada'. Continua al siguiente paso ➡️ una vez hayas recibido ese correo.",
    whatsAppInstructions9: "En caso de que tardes en recibir el correo electrónico, revisa los siguientes videos:",
    whatsAppInstructions10: "7) Una vez el número está conectado, debes integrar tu cuenta de WhatsApp a Cheetrack.",
    whatsAppInstructions11: "¡Listo! Ya quedó conectado, ahora podrás enviar y recibir mensajes sin problemas.",
    whatsAppInstructions101: "Ir a {link} y selecciona tu negocio",
    whatsAppInstructions102: "Hacer click en 'Configuración' y luego ir a 'Configuración del negocio'",
    whatsAppInstructions103: "En el menú de la izquierda bajo 'Usuarios' ➜ 'Usuarios del Sistema'",
    whatsAppInstructions104: "Hacer click en 'Añadir' ➜ Agregar tu nombre ➜ Seleccionar 'Rol de administrador'",
    whatsAppInstructions105:
        "Luego a la derecha, click en 'Añadir Activos' ➜ A la izquierda 'Aplicaciones' ➜ Seleccionamos Cheetrack ➜ Luego le damos todos los permisos a la app ➜ Guardar cambios",
    whatsAppInstructions106: "Click en 'Generar un identificador nuevo'",
    whatsAppInstructions107:
        "Seleccionamos la APP ➜ Debemos permitir dos permisos ➜ 'whatsapp_business_messaging' y 'whatsapp_business_management'",
    whatsAppInstructions108:
        "Copiamos el 'Identificador del acceso' ➜ Ir a Cheetrack ➜ Pegar el ID en el campo 'Token de acceso'",
    whatsAppInstructions109: "Volvemos al administrador comercial ➜ A la izquierda 'Cuentas' ➜ 'Cuentas de Whatsapp'",
    whatsAppInstructions110:
        "Seleccionamos la cuenta ➜ copiamos el número identificador ➜ Ir a Cheetrack ➜ Pegar el valor en 'Identificador de Whatsapp Business APP'",
    whatsAppInstructions111: "Ahora click en 'Conectar una nueva cuenta de Whatsapp API'",
    whatsAppStep1: "1. Crear Facebook APP",
    whatsAppStep2: "2. Configurar Facebook LogIn",
    whatsAppStep3: "3. Configurar Webhooks",
    whatsAppStep4: "4. Configurar WhatsApp",
    facebookAppId: "Identificador de la App",
    facebookAppSecret: "Clave secreta de la App",
    shopifyInstructionsForConnectWithWhatsapp:
        "Solo falta un último paso para que tu cuenta esté lista, necesitas hacer la integración con WhatsApp meta, para eso presiona en el botón de abajo que te llevara al panel de integración con WhatsApp y otras apps.",
    makeSureYouHaveVerifiedYourFacebookAccount: "Asegurate de haber verificado tu cuenta de administrador de Facebook.",
    accessToken: "Token de acceso",
    wabaId: "Identificador de WhatsApp Business App",
    seeInstructions: "Ver instrucciones",
}

export default messages
