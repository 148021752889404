import { getTimeDiffInDays } from "."

const today = new Date()

// Retorna el estado inicial de alertas del chat guardado en el localStorage
function getStoredChatInitialState() {
    const storedState = {
        dismissedAlerts: {
            unassignedClient: JSON.parse(localStorage.getItem("unassignedClientAlertDismissed")) || {},
            sendMessageToClientAssignedToOther:
                JSON.parse(localStorage.getItem("sendMessageToClientAssignedToOtherDismissed")) || {},
            activeChase: JSON.parse(localStorage.getItem("activeChaseAlertDismissed")) || {},
        },
    }

    for (let prop in storedState.dismissedAlerts) {
        if (storedState.dismissedAlerts[prop] && Object.keys(storedState.dismissedAlerts[prop]).length > 0) {
            const today = new Date()
            for (let client in storedState.dismissedAlerts[prop]) {
                const daysDiff = getTimeDiffInDays(storedState.dismissedAlerts[prop][client], today)
                if (daysDiff > 4) delete storedState.dismissedAlerts[prop][client]
            }
        }
    }

    if (localStorage.getItem("repeatedMessageSent")) {
        storedState.dismissedAlerts.repeatedMessageSent = JSON.parse(
            localStorage.getItem("repeatedMessageSentDismissed")
        )
        const daysDiff = getTimeDiffInDays(storedState.dismissedAlerts.repeatedMessageSent, today)
        if (daysDiff > 4) delete storedState.dismissedAlerts.repeatedMessageSent
    }

    return storedState
}

export default getStoredChatInitialState
