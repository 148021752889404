const messages = {
    welcomeToChiTrack: "Welcome to Cheetrack",
    email: "Email address",
    password: "Password",
    login: "Log in",
    loginWithGoogle: "Login with google",
    createAccount: "Create an account",
}

export default messages
