const messages = {
    firstName: "Primeiro nome",
    lastName: "Sobrenome",
    email: "Email",
    password: "Senha",
    phone: "Telefone",
    register: "Cadastrar-se",
    Iaccept: "Eu aceito o ",
    TermsOfService: "Termos de serviço",
    privacyPolicy: " e Política de Privacidade",
    alreadyAUser: "Já é usuário? Faça login aqui!",
    logIn: "Entrar",
    signUp: "Cadastrar-se",
    cancel: "Cancelar",
    logout: "Sair",
    passwordStrength: "Força da senha",
    weak: "Fraca",
    acceptable: "Aceitável",
    strong: "Forte",
    nameRequired: "Por favor, escreva seu primeiro nome",
    lastNameRequired: "Por favor, escreva seu sobrenome",
    emailRequired: "Por favor, escreva um endereço de e-mail",
    phoneRequired: "Por favor, escreva seu número de telefone",
    emailInvalid: "Endereço de e-mail inválido",
    showPassword: "Mostrar senha",
    hidePassword: "Ocultar senha",
    yourPasswordMustBeAcceptable:
        "Sua senha está muito fraca. Considere misturar símbolos, números e letras maiúsculas e minúsculas",
    passwordMustBeAtLeast6Chars: "Sua senha deve ter pelo menos 6 caracteres de comprimento",
    anErrorOcurredOnYourRegister:
        "Ocorreu um erro com o seu registro. Por favor, verifique suas informações e tente novamente",
    youMustAgreeToTerms: "Você deve concordar com nossos termos de serviço e política de privacidade",
    companyNameRequired: "Por favor, escreva o nome da sua empresa",
    passwordRecommendation:
        "Recomendamos que sua senha contenha pelo menos 8 caracteres, caracteres maiúsculos e minúsculos, e símbolos.",
    shopifyParamsMissingError:
        "Os dados necessários do Shopify não estão disponíveis. Talvez você precise reinstalar o aplicativo Cheetrack em sua conta do Shopify.",
    emailInUseError: "Esse e-mail já está em uso. Clique aqui para fazer login.",
    tenantAlreadyExists: "Essa empresa já está registrada. Por favor, tente outro nome.",
    createNewTenant: "Criar nova empresa",
    selectTenant: "Selecionar empresa",
    continueWithTenant: "Continuar com {tenant}",
    companyName: "Nome da empresa",
    anErrorOcurredSelectingThisTenant:
        "Ocorreu um erro ao selecionar esta empresa. Por favor, tente novamente mais tarde.",
    anErrorOcurredCreatingTenant: "Ocorreu um erro ao criar uma nova empresa. Por favor, tente novamente mais tarde.",
    notAnUserYet: "Ainda não é um usuário? Cadastre-se aqui!",
    youAreEnteringAsStore: "Você está acessando com a loja Shopify: {name}",

    selectBranch: "Selecionar filial",
}

export default messages
