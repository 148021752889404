const initialState = {
    messages: {
        multiple: [],
    },
    messagesPaging: {},
    loadingMessages: {},
    messagesError: {},
    quickTexts: [],
    loadingQuickTexts: false,
    templates: [],
    loadingTemplates: false,
    quickTextsError: false,
    templatesError: false,
    dismissedAlerts: {
        unassignedClient: {},
        sendMessageToClientAssignedToOther: {},
        repeatedMessageSent: null,
        activeChase: {},
    },
    clientAssignedToOtherConfirmation: false,
    messageOnHold: {},
    lastMessage: "",
    repeatedMessageCounter: 0,
    lastMessagesLoaded: [],
    templateCreationError: false,
    savingTemplate: false,
    templatesFlows: [],
}

export default initialState
