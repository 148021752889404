const messages = {
    messageLoadingData: "Estamos consultando os dados, aguarde um momento, por favor.",
    messageLoadingTemplates: "Estamos carregando as mensagens de início, por favor, aguarde um momento.",
    anErrorOcurredObtainingFlowsData: "Ocorreu um erro ao obter dados dos fluxos, tente novamente.",
    noFlowFound: "Não foram encontrados dados de fluxos para este intervalo de datas.",
    withoutResponding: "Sem resposta",
    emptyTemplates: "Não foram encontradas mensagens de início",
    labelButtons: "Botões",
    labelSelectFlows: "Selecione o seu fluxo",
}
export default messages
