const messages = {
    messageLoadingData: "Estamos consultando por la data, espera un momento por favor.",
    messageLoadingTemplates: "Estamos cargando los mensajes de inicio, por favor espera un momento.",
    anErrorOcurredObtainingFlowsData: "Se produjo un error al obtener datos de flujos, inténtelo nuevamente.",
    noFlowFound: "No se encontraron datos de flujos para este rango de fechas.",
    withoutResponding: "Sin responder",
    emptyTemplates: "No se encontraron mensajes de inicio",
    labelButtons: "Botones",
    labelSelectFlows: "Selecciona tu flow",
}
export default messages
