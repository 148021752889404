// Retorna las iniciales de un nombre
function getInitials(names) {
    let Initials = ""
    names.forEach((name) => {
        if (name) Initials = Initials + name.toUpperCase()[0]
    })
    return Initials
}

export default getInitials
