import ScreenMessages from "./screen-messages"
import loadingMessages from "./loading-messages"
import verticals from "./whatsapp-verticals"
import metaErrorMessages from "./meta-error-messages"
import countryMessages from "./countries.messages"
import countingMessages from "./counting.messages"

const messages = {
    ...ScreenMessages,
    ...loadingMessages,
    ...verticals,
    ...metaErrorMessages,
    ...countryMessages,
    ...countingMessages,
    confirmPassword: "Confirm Password",
    rememberPassword: "Remember password",
    didYouforgetPassword: "Did you forget your password?",
    forgotPasswordMessage: "Enter your email and we will send you the instructions to reset your password",
    createAccount: "Create an account",
    recoveryPassword: "Recover password",
    costumerService: "Customer chat",
    clientsList: "My Clients",
    clients: "Clients",
    noClients: "No clients",
    whatsappConfig: "Setting",
    billing: "Billing",
    name: "Name",
    phone: "Phone",
    assignedExecutive: "Assigned executive",
    inquiries: "Inquiries",
    company: "Company",
    search: "Search",
    open: "Open",
    scheduled: "Scheduled",
    archived: "Archived",
    pending: "Pending",
    pendingPlural: "Pending",
    unread: "Unread",
    filters: "Filters",
    hasBeenSavedSuccessfully: "Has been saved successfully",
    initialSetup: "Initial SetUp",
    this: "this",
    postpone: "Postpone",
    logout: "Logout",
    myProfile: "My Profile",
    users: "Users",
    cold: "Cold",
    warm: "Warm",
    hot: "Hot",
    note: "Note",
    notes: "Notes",
    template: "Start message",
    help: "Help",
    tour: "Tour",
    saveChanges: "Save changes",
    discardChanges: "Discard changes",
    resetFilters: "Reset filters",
    selected: "Selected",
    tag: "Tag",
    cancel: "Cancel",
    close: "Close",
    preview: "Preview",
    basic: "Basic",
    standard: "Standard",
    pro: "Pro",
    included: "Included",
    max: "Maximum",
    download: "Download",
    approved: "Approved",
    rejected: "Rejected",
    role: "Role",
    retry: "Retry",
    send: "Send",
    item: "Item",
    date: "Date",
    detail: "Detail",
    price: "Price",
    save: "Save",
    to: "to",
    openInGoogleMaps: "Open in Google Maps",
    archivedSingular: "Archived",
    yesterday: "Yesterday",
    today: "Today",
    multiSelect: "Multi select",
    selectedAll: "Select visible conversations",
    unselectAll: "Unselect all conversations",
    none: "None",
    connectionError: "Oops! You seem to have lost connection",
    reloadPage: "Reload page",
    status: "Status",
    month: "Month",
    week: "Week",
    months: "Months",
    weeks: "Weeks",
    hours: "Hours",
    hour: "Hour",
    tomorrow: "Tomorrow",
    report: "Report",
    sequenceMessage: "Cheetabot",
    templateMessageToInitiateConversations: "Start message to initiate conversations",
    messagesSetup: "Messages SetUp",
    delete: "Delete",
    duplicate: "Duplicate",
    message: "Message",
    activeBot: "Enable Bot",
    inactiveBot: "Disabled Bot",
    lockToSegment: "Skip this conversation for segment chases.",
    unlocktoSegment: "Enable this conversation for segment chases.",
    goBack: "Go back",
    minutes: "Minutes",
    days: "Days",
    seeMore: "See more",
    minimize: "Minimize",
    here: "here",
    seeTable: "See table",
    seeChart: "See chart",
    and: "and",
    uploadFile: "Upload File",
    uploadFiles: "Upload Files",
    uploadImage: "Upload Image",
    uploadImages: "Upload Images",
    dropHere: "Drop here 😉",
    fileLimitIs: "File limit is {limit}",
    fileMustBeAnImage: "File must be an image",
    yourImage: "Your image",
    continue: "Continue",
    darkMode: "Dark Mode",
    lightMode: "Light Mode",
    yourDocument: "Your document",
    yourVideo: "Your video",
    messages: "Messages",
    morning: "Morning",
    afternoon: "Afternoon",
    evening: "Evening",
    dashboard: "Dashboard",
    others: "Others",
    from: "From",
    until: "to",
    andXMore: "and {x} more",
    in: "in",
    add: "Add",
}

export default messages
