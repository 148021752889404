// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
import React from "react"
import * as ReactDOM from "react-dom/client"
import App from "./App"
import { AppProviders } from "./context"
import "./index.css"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <AppProviders>
        <App />
    </AppProviders>
)

reportWebVitals()
