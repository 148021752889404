import { CHASE_DURATION_LIMIT } from "./constants"
import { timezones } from "./timezones"

// Constantes para los chases

const initialTimeZone =
    timezones.find((t) =>
        Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions()
            ? t.zoneName === Intl.DateTimeFormat().resolvedOptions().timeZone
            : t.gmtOffset / -60 === new Date().getTimezoneOffset()
    ) || timezones[0]

export const initialChase = {
    name: "",
    trigger: {},
    template_sequence: [
        {
            time: 30,
            time_unit: "minutes",
            type: "template",
        },
        {
            time: 1,
            time_unit: "days",
            type: "template",
        },
    ],
    assign_to_all: false,
    status: "Draft",
    defaultChase: true,
    source: "whatsapp",
    // activate_cheetabot: true,
}

export const appsList = [
    {
        name: "Shopify",
        id: 1,
        value: "shopify",
    },
    {
        name: "Agenda Pro",
        id: 2,
        value: "agendapro",
    },
]

export const appEvents = [
    {
        name: "lostCart",
        id: 0,
        value: "abandoned_checkout",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            max: 3,
            requiredButton: {
                type: "URL",
                url: "https://link.cheetrack.com/{{1}}",
                variableAlternative: "{{abandoned_checkout_link}}",
            },
            requiredVariablesOnlyOnTemplate: 0,
            defaultSequence: [
                {
                    time: 30,
                    time_unit: "minutes",
                },
            ],
            defaultWorkingHours: {
                name: "working_hours_for_abandoned_checkout",
                to: 9,
                from: 19,
                timezone: initialTimeZone.zoneName,
            },
        },
        tenantRequirement: {
            propParent: "shopify",
            prop: "has_abandoned_checkout_set",
        },
    },
    {
        name: "customerPhoneNumberRegistration",
        id: 1,
        value: "customer_phone_number_registration",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            max: 1,
            defaultSequence: [
                {
                    time: 0,
                    time_unit: "minutes",
                },
            ],
            disableTimeChange: true,
        },
        tenantRequirement: null,
        availableForTenants: ["ce7ce47282b34f2c8f9e17d9d958ffb4"],
    },
    {
        name: "confirmedPurchaseOrder",
        id: 4,
        value: "paid_order",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            max: 1,
            defaultSequence: [
                {
                    time: 0,
                    time_unit: "minutes",
                },
            ],
            disableTimeChange: true,
        },
    },
    {
        name: "unpaidPurchaseOrder",
        id: 5,
        value: "created_order",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            max: 1,
            defaultSequence: [
                {
                    time: 0,
                    time_unit: "minutes",
                },
            ],
            disableTimeChange: true,
        },
    },
    {
        name: "orderUpdatedReadyForPickup",
        id: 5,
        value: "ready_for_pickup",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            requiredVariables: ["{{shopify_firstname}}", "{{shopify_order_name}}", "{{shopify_localization}}"],
            max: 2,
            defaultSequence: [
                {
                    time: 5,
                    time_unit: "days",
                },
            ],
            disableTimeChange: false,
        },
        availableForTenants: ["302e7b77a8674dfa806c9ba2f21f36d4", "cefb10b0b4ad4a7f980c36cfe7fef3d2"],
    },
    {
        name: "orderUpdatedCanceled",
        id: 5,
        value: "order_cancelled",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            max: 1,
            requiredVariables: ["{{shopify_firstname}}", "{{shopify_order_name}}"],
            defaultSequence: [
                {
                    time: 0,
                    time_unit: "minutes",
                },
            ],
            disableTimeChange: true,
        },
        availableForTenants: ["302e7b77a8674dfa806c9ba2f21f36d4", "cefb10b0b4ad4a7f980c36cfe7fef3d2"],
    },
    {
        name: "orderUpdatedFulfilled",
        id: 5,
        value: "order_fulfilled",
        app: "shopify",
        chaseLimit: 1,
        templateRules: {
            requiredVariables: ["{{shopify_firstname}}", "{{shopify_order_name}}"],
            max: 1,
            defaultSequence: [
                {
                    time: 0,
                    time_unit: "minutes",
                },
            ],
            disableTimeChange: true,
        },
        availableForTenants: ["302e7b77a8674dfa806c9ba2f21f36d4", "cefb10b0b4ad4a7f980c36cfe7fef3d2"],
    },
]

export const templateWaitTimeFormats = ["days", "hours", "minutes"]

export const templateTimeLimits = {
    minutes: {
        min: 30,
        step: 15,
        max: 60 * 23,
    },
    hours: {
        min: 1,
        step: 1,
        max: 72,
    },
    days: {
        min: 1,
        step: 1,
        max: CHASE_DURATION_LIMIT - 1,
    },
}
