// Listado de tenant ids para feature flags

export const TENANT_IDS = {
    QA_PRODUCCION: "857f4141295c4f489b6576e92bb8be7a",
    QA_STAGING: "a1996fd1ec304fee9f2f4d72af5fbd93",
    QA_STAGING2: "6b9fcce6a7314f4a8b715008e51ba4d3",
    AMPLIFICA: "30d7aa4079b443d4b914864636ed6913",
    COMERCIAL: "bc4971f8f76f40a197f1d10b5e29c183",
    ESTUDIO_OFTALMOLOGICO_CHILLAN: "6e07cf9577e24d2fa4e9389f72b083b1",
    IHOME_COLOMBIA: "ad718f7d10ff4d0b8c14938df9b38c6b",
    HEALTH_SOLUTIONS: "93cc0b22da534e818339992ce1b87188",
    ORP_SEGUROS: "3713b5c21e194db4a40cecf33756c55b",
    QA_STAGING3: "054e0ebafadb4f0ab83937d15ebffe1d",
    VEZCO: "8bd7451bf712496f965b3b5ed15c8774",
    PRESTAQUI: "7b03f672b0fd4ab99ea04e636aa00ec1",
    QA_STAGING4: "e54d47d77b3548948eef1ff94a7d7efc",
    ARROZ_FEDERAL: "3023d5bd41c24c6788e0ea4ad40d6e5b",
    BUYDEPA: "9d234199e0694ffd9a6dd764af4fa79c",
    BROCANTE: "26e7976787f84de1a254a24f2abd56bb",
    URSET: "1111b6db211042a2b7025e19c65c1e49",
    FATIGA: "9459f464c75246a9a6b60cc4e4c8655a",
    INDUMEX: "691fa3fbb80646f893c807e058a7fa37",
    SURETI: "b57382ce82e344468c6857d5b5222a5b",
    SHERPAMOVERS: "99ce7f62afc54cc0ab229ad665334dd7",
    FREEMET: "34s8b40441bc49fea5c59e55c31fd78g",
    BENDITA: "eebf96e03ccc4acdb0d2d5d322cf9708",
    MONADAS: "d40a79a71e7b40a09a7570ead4ece89d",
    EQUIPAMIENTO_CRECER: "7f9f75e4519a46d78b4a33f43715a16d",
    BLACKMOBY: "f7e9baa6b6ed4668bb2557b4b1617ec2",
    PSK_JOYAS: "285af5bfb04d456aa0330c682fbb0082",
    SPA_ANTONELLA_ROUSS: "30fd6ac2df934506b68965cd44fa0acf",
    BEROOTS: "b9903208151b40518794b95ef0716bfa",
    LHESH: "f388e247d8574c868d3167037bbb7827",
    VAGANDO_POR_MEXICO: "93913ab374a94fdc9e841503fe54968f",
    PASTELERITOS: "afabe1de58a7478f9f9a0e934d500f80",
    ESENCIA_KCAO: "58012e34133a42d499ebc84477641d69",
    TIENDA_UNIQUE: "df96f2b36cfa4b6ca39bed688ece53cc",
    LEGNA: "cf3d2f201a234eb9bc01fa2dee02453a",
    TUMECANICO: "a643aea784754465aedfe571a3844b76",
    HABITAR: "2dd99549fcf84bfab45c8e0cbe40f307",
    RITA_LIRA: "aa8dc0176de54e7cbef7a4a8a62593ec",
    OLIVO_Y_NUEZ: "29cd128258f74d718a7b25cf1ceaadd5",
    SAFEFOOT: "6f840ce3f5f54d78b524f80435a1716c",
    MEGARED: "8623c34f603843b7a91c5490fd808086",
    DER_EDICIONES: "7c10d235f7e84dc49089997d131e0ade",
    RESMAS_EXPRESS: "8b605059105c4bdabb28f08561508a40",
    MUEBLEO: "9409dd3363b74db99a8d7c253552590f",
    OMCLEANER: "7cfb28f98d29414abf21fb72efc8bca6",
    MERIDIAN: "d671d99ac72c43ee8cb359a5d8673bf2",
    LOOPLY: "28017d3dccdf44a1ba34a796b490c445",
    LANCO_PUERTO_RICO: "73cddd5186fb4a28b444b5844e985972",
    DEV_META_WHATSAPP: "1618a6b070d0474595397203be191a34",
    STAGING_META_WHATSAPP: "079ddb1d83e64e9a84862b14edea64e5",
    PROD_WHATSAPP_META: "cefb10b0b4ad4a7f980c36cfe7fef3d2",
    LEONORA_STUDIO: "553afb4978c6407595ff855de906f512",
    QA_STAGING_SMS: "f59b0e60a45c4195879b8862f238371a",
    PROD_SMS: "88168245d20b44b390968da205c480e3",
    DERMEDICA: "2f114bda4f5b49b8a37587ae6429c9d5",
    RED_NUTRICION: "3f0b1a6378954290a8061fe0b3133450",
    MY_COCOS: "2a16c5dd95d4476a871fad416be8bbdf",
    CORAZON_DE_PAPEL: "6b3c555b62dc4e5f965af055ec0e58fd",
    PAPAYA_BRAGAS: "38497d5d9dd24f10a8296c49e71a99e4",
    MODISTA: "d54250525c954798a2ba4d1a320c4f77",
    FESTINA: "fc99b43dc015416395150391a308966b",
    FEELS_VERY_NICE: "c9f8d7f5534c483689a8f93d2b769cd1",
    PICHINTUN: "763bfa8e36b74affb2d7932f994ed0e3",
    BESTIAS: "7d7fb61e21be4da590ea9aae15cc5562",
    GREENGLAS: "aefc50f8f40343ebb0077d255d2600cf",
    URBANO: "ba87e4afa2fc4191879ee28014bc96fc",
    LA_OTRA_ONLINE: "0b41906787ba4beb899568941f232fb7",
    TRAVIESO: "8782ff8f22b0418f9aa149d02d604f83",
    DANIEL_MOSHKA: "b4bda7d36dbd428784e3b93a3fd43145",
    YAMBO: "4d328ee64b2e42a6bdbf3a1ae682daa9",
    ZAZU: "957970ca56744fa290590eb252209a56",
    TRAUCO_ESTILO: "69df8e60f2c8422ea302b6c15441f99c",
    DUEESCARPE: "6fbb8e0218464835afe1f40d922de810",
    GRANJA_MAGDALENA: "b329945052424619aac147623323c507",
    LANCO_CL: "f57d848993d34f02b0708f3ce8588a9b",
    PRODUCTION_WA2: "28bb02456aeb4b39b391f09e5688ccc0",
    PAVAROTTI_RISTORANTE: "7935cb4c750e466fbba999a3e67b5db0",
    TOWNHOUSE_IMMOBILIARIA: "56d53294d8f64408a3de2de909a267e3",
    OSTERIA_MAIORI: "aaf03175afb34efc896b33a0716e1ab2",
    STARBUCKS: "f48ad557e0ee4255b88a7b0e07d30be0",
    MYHUEVOS: "d7dfa94b48fd46ad97d4139f870395cc",
    FIORELL: "06c66645cd1b46f3bbdfea818c7c485f",
    WILDLAMA: "011544d0cf04461bb6f211502a7ea781",
    WILDFOODS: "fdf31c8b992840a2b80df93c4d297091",
    KAYAUNITE: "d55a63fb65f842b0b590bfbf4f0c4358",
    TRATTORIA: "d21384b0bd3f46cca6cda9b183042313",
    LASTARRIA: "cf44c2efb4934ef8b2f5a4d51a4605ba",
    HONESTO_VITACURA: "720b060b3ea4418a8d0b43f9aebbceca",
    WILDFOODS_MEXICO: "31216781d0344c5cb70dc79bc93a0cbe",
    HOSTAL_MORENO: "acd65d20cc224b88a55ca2278d330a0c",
}
