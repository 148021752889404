import { ordinals } from "../constants"
import locales, { ES, EN } from "../locale"

let locale
const storedLoc = localStorage.getItem("locale"),
    browserLocale = navigator.language || navigator.userLanguage || null

if (storedLoc) locale = storedLoc
else if (browserLocale.startsWith("es")) locale = ES
else locale = EN

const intl = locales[locale]

// Reemplaza las variables de fidelización para el preview

export const replaceFidelizationVars = (text = "", rewards = [], period = 30) => {
    let replacedText = text

    replacedText = replacedText.replace(/{{period}}/g, period)
    replacedText = replacedText.replace(/{{code}}/g, rewards[0]?.code || "{{code}}")
    replacedText = replacedText.replace(/{{name}}/g, rewards[0]?.name || "{{name}}")
    replacedText = replacedText.replace(/{{end_period_date}}/g, new Date().toLocaleDateString())

    const replaceNames = new RegExp(/{{name_(\d+)}}/g),
        nameMatches = text.match(replaceNames)

    if (nameMatches) {
        nameMatches.forEach((m) => {
            const index = m.match(/\d+/g)[0]
            replacedText = replacedText.replace(m, rewards[index - 1]?.name)
        })
    }

    const replaceCodes = new RegExp(/{{code_(\d+)}}/g),
        codeMatches = text.match(replaceCodes)

    if (codeMatches) {
        codeMatches.forEach((m) => {
            const index = m.match(/\d+/g)[0]
            replacedText = replacedText.replace(m, rewards[index - 1]?.code || "{{code}}")
        })
    }

    return replacedText
}

// Genera el mensaje por defecto para fidelización
export const parseDefaultText = (text, rewards, tenantName, isRestaurant, showFirstBenefit, useDotsForVars) => {
    let newText = text
    newText = newText.replace(/{{tenant}}/g, tenantName)

    if (newText.includes("{{conditions_list}}")) {
        const list = rewards
            .map((reward, index) => {
                if (!showFirstBenefit) {
                    const number = isRestaurant ? index + 2 : index + 1

                    let rewardText

                    if (rewards.length > 1) {
                        rewardText = `${intl.formatMessage({
                            id: "she" + ordinals[index + 1].charAt(0).toUpperCase() + ordinals[index + 1].slice(1),
                        })} ${intl.formatMessage({
                            id: isRestaurant ? "fidelizationVisit" : "fidelizationSale",
                        })}: {{name_${number}}}`
                    } else rewardText = `{{name_${number}}}`

                    return "\n\n" + rewardText
                } else {
                    if (index + 1 < rewards.length) {
                        const number = isRestaurant ? index + 2 : index + 1

                        let rewardText

                        if (rewards.length > 1) {
                            rewardText = `*${intl.formatMessage({
                                id: "she" + ordinals[index + 1].charAt(0).toUpperCase() + ordinals[index + 1].slice(1),
                            })} ${intl.formatMessage({
                                id: isRestaurant ? "fidelizationVisit" : "fidelizationSale",
                            })}:* {{name_${number}}}`
                        } else rewardText = `{{name_${number}}}`

                        return "\n\n" + rewardText
                    }
                }
            })
            .join("")
        newText = newText.replace("{{conditions_list}}", list)
    }

    if (useDotsForVars) newText = newText.replace(/{{/g, "...").replace(/}}/g, "...")

    return newText
}
