import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import useStyles from "./fullpage-spinner.styles"
import { useIntl } from "react-intl"
import { Typography, Box } from "@mui/material"

const factNumber = Math.floor(Math.random() * 33) + 1

// Componente de loader inicial de Cheetrack

const FullPageSpinner = () => {
    const classes = useStyles(),
        { formatMessage } = useIntl(),
        textStyle = { marginBottom: 12 }

    return (
        <Box className={classes.root}>
            <img className={classes.logo} justify="center" src="/assets/img/logo_cheetrack.svg" alt="Cheetrack logo" />
            <CircularProgress />
            <Box className={classes.textContainer}>
                <Typography color="primary" variant="h3" style={textStyle}>
                    {formatMessage({ id: "didYouKnow" })}
                </Typography>
                <Typography variant="h4">
                    {formatMessage({ id: "cheetahFact" + factNumber })}
                    {factNumber === 22 && <strong>*Cheetrack*</strong>}
                    {factNumber === 23 && <em>_Cheetrack_</em>}
                </Typography>
            </Box>
        </Box>
    )
}

export default FullPageSpinner
