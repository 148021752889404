const messages = {
    addClients: "Adicionar clientes",
    customerAgenda: "Lista de clientes",
    addNewClient: "Adicionar novo cliente",
    filters: "Filtros",
    search: "Buscar",
    newCustomerInformation: "Informações do novo cliente",
    AddTheDetailsOfTheNewCustomerCheckTheNumbersThatWeSuggestFromYourConversationsWithUnsecuredPhones:
        "Adicione os detalhes do novo cliente. Verifique os números que sugerimos a partir das suas conversas com telefones não seguros.",
    actions: "Ações",
    thisActionWillRemoveTheClient: "Esta ação arquivará a conversa com o cliente ",
    andAllTheRecordsAssociatedWithIt: " e todas as informações associadas.",
    areYouSureYouWantToDeleteThisClient: "Você tem certeza de que deseja arquivar este cliente?",
    yesDeleteClient: "Sim, arquivar cliente",
    editInformationFor: "Editar informações para",
    editAndUpdateTheInformationOfThisClient: "Editar e atualizar as informações deste cliente",
    firstname: "Primeiro nome",
    lastname: "Sobrenome",
    validationPassword: "A senha deve conter um caractere especial !#@$%+=._-, letras Aa-zZ e números 0-9",
    addClientDescription1: "Seus clientes são o centro do seu negócio",
    addClientDescription2:
        "Adicione um cliente, salvando seu contato e informações para que você possa manter uma comunicação personalizada com cada um.",
    strengthenTheRelationshipWithYourCustomers: "Fortaleça o relacionamento com seus clientes",
    youAreJustFewClicksAwayTakeYourSalesOnWhatsAppToAnotherLevel:
        "Você está a apenas alguns cliques de distância, leve suas vendas no WhatsApp para outro nível",
    deleteClient: "Excluir cliente",
    addFirstClient: "Adicionar meu primeiro cliente",
    addAnotherClient: "Adicionar este cliente e outro",
    seeArchived: "Ver arquivados",
    assignedTo: "Atribuído a",
    alertAfterCreated:
        "Levará alguns minutos para que o cliente apareça ou seja atualizado nesta lista e na visualização do chat.",
    accept: "Aceitar",
    created_at: "Data de criação",
    autoAssignNewClients: "Atribuir novos clientes a executivos automaticamente.",
    autoAssignDesc:
        "Ativar isso atribuirá automaticamente os clientes que iniciarem uma conversa a um dos seus executivos.",
    autoAssignDesc2:
        "A atribuição seguirá uma lógica de tandem, ou seja, os executivos serão atribuídos a um cliente de cada vez até que cada executivo tenha sido atribuído a um cliente antes de começar novamente.",
    clientExists: "Você já tem esse número de telefone na lista de clientes",
    anErrorOcurredCreatingClient:
        "Ocorreu um erro ao criar esse cliente, por favor, verifique as informações ou tente novamente mais tarde",
    anErrorOcurredUpdatingClient:
        "Ocorreu um erro ao atualizar esse cliente, por favor, verifique as informações ou tente novamente mais tarde",
    clientsNotListed: "Clientes não listados.",
    clientLimitDesc:
        "Há um limite para a quantidade de clientes que podemos mostrar, você pode encontrar mais clientes pesquisando por eles.",
    ifYouNeedToCreateMoreThan50Clients:
        "Se você precisar criar mais de 50 clientes, nos avise através do chat de suporte.",
    importClientList: "Importar uma lista de clientes",
    createClientsOneAtATime: "Criar clientes um de cada vez",
    importFile: "Selecionar arquivo",
    importFileFormat: "Formatos XLSX, XLS ou CSV são aceitos",
    importFileError: "Há um problema com o arquivo importado, por favor, tente outro.",
    reviewTheImportedData: "Revisar os dados importados.",
    assignATagToClients: "Atribuir uma etiqueta a todos os {n} clientes",
    pairColumnHeadersAndProps: "Associe os cabeçalhos de coluna do seu arquivo com as informações dos seus clientes.",
    useThisRowAsHeader: "Use a seguinte linha como cabeçalho",
    clientInformation: "Informações do cliente",
    columHeader: "Cabeçalho da coluna no arquivo",
    continueToPreviewData: "Continuar para a visualização dos dados",
    saveClientList: "Salvar {n} clientes listados",
    selectTag: "Selecionar etiqueta",
    youHaveXClientsWithIssues:
        "Você tem {n} clientes com números de telefone inválidos. Se você salvá-los como estão, pode ter dificuldades para enviar mensagens a eles.",
    weCouldntSaveXClientsWithIssues: "Não conseguimos salvar {n} clientes. Você pode revisá-los e enviá-los novamente.",
    weFoundXDuplicatedClients:
        "Não salvamos {n} clientes cujos números de telefone estavam duplicados ou já estavam em sua agenda.",
    downloadTheseXClientsAsAnCSV: "Baixar esses {n} clientes como CSV",
    reviewDuplicatedClients: "Revisar os {n} clientes duplicados",
    weCorrectlySavedXClientes: "Salvamos com sucesso {n} clientes.",
    hereYouCanUploadAFileWithClients:
        "Aqui você pode fazer upload de um arquivo contendo as informações dos seus clientes.",
    youWillBeAbleToPairTheInfo:
        "Você poderá então associar uma coluna em seu arquivo com cada um dos seguintes: nome, sobrenome, empresa, número de telefone e e-mail.",
    theOnlyRequisiteIsAPhoneNumber:
        "O único requisito é que pelo menos uma das colunas do arquivo seja o número de telefone do seu cliente.",
    ourBotCanCorrectXNumbers: "Nosso Cheetabot pode corrigir automaticamente {n} desses números de telefone",
    clickHereToAllowTheBotToCorrectThem: "Clique aqui para permitir que o Cheetabot os corrija",
    allTheNumbersHaveIssues:
        "Todos os números de telefone dos seus clientes têm problemas. Você escolheu a coluna correta?",
    backToPairingStep: "Volte para a etapa de associação",
    clientsAddedThisWayWillShowUpOnRelaod:
        "Os clientes adicionados desta forma podem demorar alguns minutos para aparecer",
    clientsAddedMayNeedToBeFound:
        "👀 Caso você tenha muitas conversas durante o dia, os novos clientes podem não aparecer no topo da lista. Você pode precisar usar a barra de pesquisa para encontrá-los.",
    clientSource: "Origem",
    myClients: "Meus clientes",
    exportToCSV: "Exportar para CSV",
    firstVisit: "Primeira visita",
    daysWithoutComingToToday: "Dias sem vir até hoje",
    historicalVisits: "Visitas históricas",
    visitsDuringThePeriod: "Visitas durante o período",
    validatedCoupons: "Cupons validados",
    goodExperiences: "Boas experiências",
    badExperiences: "Experiências ruins",
    numberOfVisits: "Número de visitas",
    experiences: "Experiências",
    relativeTime: "Tempo relativo",
    visitsFrom: "Visitas a partir de",
    visitsUntil: "Visitas até",
    searchClients: "Procure clientes",
    daysAgoRelative: "Últimos {days} dias",
    cantVisits: "Número de visitas",
    validatedCoupons: "Cupons validados",
    totalsClientsFiltered: "{number} clientes filtrados",
    numberClientsWithOneVisit: "{number} com 1 visita",
    numberClientsWithTwoVisit: "{number} com 2 visitas",
    numberClientsWithThreeVisit: "{number} com +3 visitas",
    numberCouponsValidates: "{number} com 3 visitas",
    numberValidatedCoupons: "{number} Cupons validados",
    numberGoodExperiences: "{number} Boas experiências",
    numberBadExperiences: "{number} Experiências ruins",
    numberTotalVisitsInRange: "{number}  clientes filtrados",
    descriptionNotFound: "Não encontramos resultados para os filtros aplicados, tente outra seleção.",
    applyFilters: "Aplicar",
    positive: "Boa",
    negative: "Má",
    preparingCsv: "Preparando CSV",
}
export default messages
