const messages = {
    visits: "Visitas",
    visitsDescription: "Visits description",
    newClients: "Clientes nuevos",
    existingClients: "Clientes existentes",
    thisPeriodClients: "Clientes de este periodo",
    previousPeriodClients: "Clientes del periodo anterior",
    dailyClientsFromTo: "Interacciones diarias desde {from} hasta {to}",
    newClientsFromTo: "Clientes únicos nuevos desde {from} hasta {to}",
    recurringClientsLast12Months: "Clientes recurrentes en los últimos {x} meses",
    recurringClientsLast12Weeks: "Clientes recurrentes en las últimas {x} semanas",
    interactions: "Interacciones",
    menusSent: "Cartas enviadas",
    menusSentThisPeriod: "Cartas enviadas este periodo",
    menusOpensThisPeriod: "Cartas abiertas este periodo",
    menusSentPreviousPeriod: "Cartas enviadas periodo anterior",
    menusOpensPreviousPeriod: "Cartas abiertas periodo anterior",
    menusSentFromTo: "Cartas enviadas desde {from} hasta {to}",
    menusOpensFromTo: "Cartas abiertas desde {from} hasta {to}",
    menus: "Cartas",
    answerRate: "Tasa de respuesta",
    dataUpdatesEveryMondayMorning: "Los datos se actualizan cada lunes por la mañana.",
    onlyWeeksWithDataAvailableAreShown: "Sólo se muestran las semanas con datos disponibles.",
    xClientsFromWeekYReturnedOnWeekZ: "{x} clientes de la semana {y} volvieron en la semana {z}",
    xNewClientsOnWeekY: "{x} clientes nuevos en la semana {y}",
    weeklyRecurrence: "Recurrencia semanal",

    anErrorOcurredObtainingData:
        "Ocurrió un error obteniendo algunos de los datos, por favor cambie el rango de fechas o intente nuevamente más tarde.",
    dataForMenusBeforeThe13OfJulyIsNotAvailable:
        "Los datos de cartas envíadas anteriores al 13 de Julio de 2023 no están disponibles.",
}

export default messages
