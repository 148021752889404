const messages = {
    telephone: "Telephone",
    roleInCheetrack: "Role in Cheetrack",
    activeConversation: "Active conversations",
    workTeam: "Work team",
    invite: "Invite",
    userDescription:
        "The <account_plan_name> plan includes <included_seats_plan> seats, expandable up to <max_seats_plan> seats.",
    active: "Active",
    inactive: "Inactive",
    manager: "Manager",
    executive: "Executive",
    activeDialogTitle: "Activate users",
    activeDialogDesc1: "Your plan includes <included_seats_plan> seats in the monthly payment.",
    activeDialogDesc2:
        "By activating this user we will load <amount_current_month> for the remainder of this month and an additional <amount_others_month> for the following months in which you have more than <included_seats_plan> active users.",
    activeDialogDesc3:
        "We remind you that your plan is <account_plan_name> and allows <total_seats_plan> seats in total. To improve your plan and see billing details visit the Billing module",
    activeDialogButton: "I understand, activate user",
    inviteUserDialogTitle: "Invite Users",
    editUserDialogTitle: "Edit users",
    inviteUserDialogDesc: "Write the email and select the role of the users you will invite to Cheetrack.",
    inviteuserDialogButton: "Send Invitation",
    selectRole: "Select a role",
    planLimitDialogTitle: "Your plan reached the maximum seats",
    planLimitDialogDesc1: "Your plan allows a maximum of <total_seats_plan> seats.",
    planLimitDialogDesc2: "To activate this user you must improve your plan.",
    planLimitDialogButton: "Improve plan",
    deleteUserConfirmMsg1: "This action will remove the user ",
    deleteUserConfirmMsg2: " and all records associated with this account.",
    deleteUserConfirmMsg3: "Are you sure you want to delete this user?",
    buttonUserDelete: "Yes, delete user",
    buttonUpdateUser: "Update user",
    inviteMyTeam: "Invite my team",
    blankTipDescription1: '"Talent wins games but teamwork wins championship"',
    blankTipDescription2: "Invite your team to Cheetrack to boost your sales.",
    inviteUser: "Invite user",
    improvePlan: "Improve plan",
    usedSeats: "Occupied seats",
    maxUsersReached: "You've reached your maximum amount of users, if you need more update your plan!",
    inviteOtherUser: "Invite another user",
    userGroup: "Group",
    userGroupHelp:
        "You can assign this user to a group that associates them to other users. For example: sales or marketing.",
    recommendedGroups: "Recommended groups",
    createGroup: "Create group",
    allowExecutivesToSeeAllConversations: "Executives can see all conversations.",
    executivesWillOnlySeeTheirConversations: "Executives only see their conversations.",
    anErrorOcurredObtainingUserData: "An error ocurred obtaining users data",
    thatEmailIsAlreadyInUse: "That email is already in use",
    deactivateUser: "Deactivate user",
    deactivateUserDesc: "Deactivating this user will prevent them from logging into the platform.",
    errorSavingUser: "Error saving user",
    eachAdditionalUserCosts19USD: "Each additional user costs 19 USD a month.",

    yourPlanIncludesXSeats: "Your plan includes {x} seats.",
    ifYouNeedMoreUsersClickHere: "If you need more users, click here.",
    notifications: "Notifications",
    feedbackNotifications: "Feedback notifications",
    userXAlreadyHasAnAccount: "User {x} already has an account, please try with another email.",
}

export default messages
