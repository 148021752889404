const messages = {
    recommendedForYou: "Recomendado para ti",
    welcomeToCheetrack: "Bienvenido a Cheetrack!",
    welcomeToCheetrackSubtitle: "Estamos felices de tenerte aquí",
    chooseACategoryToStart: "Elige una categoría para comenzar",
    shoppingCart: "Resumen de tu orden",
    glutenFree: "Sin gluten",
    vegan: "Vegano",
    spicy: "Picante",

    catalogSetUp: "Configuración de catálogo",
    catalogSetUpSubtitle: "Configura tu catálogo para comenzar a vender",
    createProduct: "Crear producto",
    createCategory: "Crear/editar categorías",
    createSubcategory: "Crear subcategoría",
    productCategories: "Categorías del producto",
    productSubCategories: "Subcategorías del producto",
    productImage: "Imagen del producto",
    productName: "Nombre del producto",
    productDescription: "Descripción del producto",
    catalog: "Catálogo",
    editProduct: "Editar producto",
    editCategory: "Editar categoría",
    addCategories: "Agregar categorías",
    productPrice: "Precio del producto",
    productDetails: "Detalles del producto",
    addCategory: "Agregar categoría",
    subcategories: "Subcategorías",
    addSubcategory: "Agregar subcategoría",
    thisCategoryNameAlreadyExists: "Este nombre de categoría ya existe",
    categoryName: "Nombre de la categoría",
    selectACategoryToEdit: "Selecciona una categoría para editar",
    categoryDetails: "Detalles de la categoría",
    saveNewCategory: "Guardar nueva categoría",
}

export default messages
