import { createTheme, darken, lighten } from "@mui/material/styles"

export const defaultFontSize = 12
export const defaultSpacing = 4

export const primaryColor = "#655DC6",
    secondaryColor = "#FF3C70",
    white = "#FFF",
    neutralGrey = "#718090",
    lightGrey = darken(neutralGrey, 0.75),
    red = "#FF4E4E",
    orange = "#FF910C",
    green = "#20C96B",
    yellow = "#FFD240",
    blue = "#009FE3"

function DarkThemeProvider() {
    return createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: primaryColor,
                light: lighten(primaryColor, 0.3),
                dark: darken(primaryColor, 0.3),
                contrastText: white,
                lighter: lighten(primaryColor, 0.5),
            },
            secondary: {
                main: secondaryColor,
                light: lighten(secondaryColor, 0.3),
                dark: darken(secondaryColor, 0.3),
            },
            action: {
                disabled: {
                    backgroundColor: lighten(neutralGrey) + " !important",
                    color: lighten(neutralGrey, 0.4),
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    textAlign: "center",
                },
            },
            text: { primary: lighten(neutralGrey, 0.95), main: lighten(neutralGrey, 0.95) },
            background: { default: darken(neutralGrey, 0.9) }, //#f7f8f9
            divider: darken(neutralGrey, 0.6),
            neutralGray: {
                light: {
                    A10: darken(neutralGrey, 0.2), //#8f9bb3
                    A20: darken(neutralGrey, 0.4), //#aeb6c7
                    A30: darken(neutralGrey, 0.5), //#CCD1DC
                    A40: darken(neutralGrey, 0.8), //#e2e5e8
                    A42: darken(neutralGrey, 0.85),
                    A45: darken(neutralGrey, 0.9), //#f2f3f5
                    A50: darken(neutralGrey, 0.95), //#f7f8f9
                },
                base: neutralGrey,
                main: neutralGrey,
                dark: lighten(neutralGrey, 0.8),
            },
            lightGray: {
                main: darken(neutralGrey, 0.8),
                dark: darken(neutralGrey, 0.4),
                light: darken(neutralGrey, 0.95),
            },
            white: {
                main: darken(neutralGrey, 0.7),
            },
            link: blue,
            tags: {
                cold: blue,
                warm: orange,
                hot: red,
            },
            notes: {
                background: darken(primaryColor, 0.3),
                border: darken(primaryColor, 0.3),
            },
            calendar: {
                text: darken(neutralGrey, 0.2),
                outOfMonthBg: darken(neutralGrey, 0.6),
                outOfMonthText: darken(neutralGrey, 0.6),
            },
            error: {
                main: red,
                light: lighten(red, 0.3),
                dark: darken(red, 0.3),
            },
            success: {
                main: green,
                light: lighten(green, 0.3),
                dark: darken(green, 0.3),
            },
            warning: {
                main: yellow,
                light: lighten(yellow, 0.2),
                dark: darken(yellow, 0.1),
            },
        },
        spacing: (factor) => `${0.25 * factor}rem`,
        typography: {
            fontFamily: ["'Open Sans'"].join(","),
            h1: {
                fontSize: "1.75rem",
                color: secondaryColor,
            },
            h2: {
                fontSize: "1.5rem",
                fontWeight: "normal",
                marginTop: 0,
                color: secondaryColor,
            },
            h3: {
                fontSize: "1.25rem",
            },
            h4: {
                fontSize: "1rem",
            },
            body1: { fontSize: "0.9rem" },
            body2: { fontSize: "0.825rem" },
            caption: { fontSize: "0.7rem" },
            h5: { fontSize: "1.25rem" },
        },
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: "none",
                        "& .MuiDataGrid-row": {
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                            "&:nth-of-type(odd)": {
                                backgroundColor: darken(neutralGrey, 0.85),
                                "& > div > p::after": {
                                    background: darken(neutralGrey, 0.85),
                                },
                            },
                        },
                        "& .MuiDataGrid-colCell": {
                            padding: "0 1rem",
                            "&:not(:nth-of-type(1))": {
                                textAlign: "center",
                                "& div": {
                                    margin: "auto",
                                },
                            },
                        },
                        "& .MuiDataGrid-cell": {
                            padding: "0 1rem",
                            "&:not(:nth-of-type(1))": {
                                textAlign: "center",
                                "& div": {
                                    margin: "auto",
                                },
                            },
                        },
                        "& .MuiDataGrid-colCellTitle": {
                            fontWeight: "bold",
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        fontSize: "0.875rem",
                        lineHeight: 1.5,
                        textTransform: "none",
                        "&$disabled": {
                            backgroundColor: lighten(neutralGrey, 0.6) + " !important",
                            color: white,
                            fontWeight: "bold",
                            lineHeight: "1.5",
                            textAlign: "center",
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        fontWeight: "normal !important",
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-disabled": {
                            backgroundColor: "transparent !important",
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: blue,
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        "&.Mui-disabled": {
                            backgroundColor: "transparent !important",
                        },
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    button: {
                        "&:hover": {
                            backgroundColor: lighten(neutralGrey, 0.6),
                        },
                    },
                },
            },
            MuiMenuPaper: {
                styleOverrides: {
                    MuiMenuPaper: {
                        background: white,
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontSize: "0.8rem",
                        color: lighten(neutralGrey, 0.6),
                        fontWeight: "normal",
                        lineHeight: "1.5",
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    actions: {
                        "& .MuiButtonBase-root": {
                            padding: "0.2rem",
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        fontSize: "0.825rem",
                        "&.MuiFocused": {
                            backgroundColor: "transparent",
                        },
                    },
                    select: {
                        "&:focus": {
                            background: "transparent",
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    "&.Mui-checked": {
                        color: primaryColor,
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: primaryColor,
                        fontSize: "0.75rem",
                    },
                },
            },
        },
    })
}

export { DarkThemeProvider }
