import { appEvents } from "../constants"

// Verifica si una persecución no es publicable
const checkIfChaseIsNotPublishable = (chase) => {
    const { name, template_sequence, trigger } = chase,
        rules =
            trigger.type === "app" || trigger.type === "shopify"
                ? appEvents.find((ev) => ev.value === trigger.trigger_event || ev.value === trigger.sub_type)
                      ?.templateRules
                : null

    return (
        !name ||
        name.length === 0 ||
        template_sequence.some(
            (t) => (!t.template_id || t.template_id === "") && (!t.cheetabot_id || t.cheetabot_id === "")
        ) ||
        !trigger.type ||
        (trigger.type === "shopify" && !(trigger.sub_type || trigger.trigger_event)) ||
        (trigger.type === "tags" && !trigger.sub_type) ||
        (trigger.type === "schedule" &&
            ((trigger.sub_type === "week" && !trigger.scheduleDay) ||
                (trigger.sub_type === "month" && !trigger.scheduleDay) ||
                !trigger.scheduleHour)) ||
        (rules && rules.max && template_sequence.length > rules.max)
    )
}

export default checkIfChaseIsNotPublishable
