const messages = {
    dailyClients: "Interacciones diarias",
    dailyClientsDesc: "Ve cuántos de tus interacciones han pasado por el flujo de Cheetrack en los últimos días.",
    clientsInTheLastXDaysCompared: "Interacciones desde {from} hasta {to} comparado con los {days} días anteriores",
    thisPeriod: "Este periodo",
    previousPeriod: "Periodo anterior",
    last2Weeks: "Últimas 2 semanas",
    last4Weeks: "Últimas 4 semanas",
    seeCompiledData: "Ver datos compilados",
    seeClientsReachedByTemplate: "Ver interacciones diarias por mensaje de inicio",
    allDailyClients: "Todos las interacciones diarias",

    filterClients: "Filtrar interacciones",
}
export default messages
