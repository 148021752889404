// Define data para el popover de filtros

function createFiltersPopoverData(data, labelSelector, startIconCreator) {
    return data.map((item) => ({
        id: item.PK,
        label: labelSelector(item),
        // data: item,
        startIcon: startIconCreator ? startIconCreator(item) : undefined,
    }))
}

export default createFiltersPopoverData
