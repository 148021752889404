const messages = {
    fidelization: "Fidelização",
    fidelizationDesc:
        "Defina uma estratégia para aumentar a fidelidade do cliente de acordo com a frequência de compras.",
    fidelizationTimeframe: "Tempo de fidelização",
    condition: "Condição",
    reward: "Recompensa",
    couponCode: "Código do cupom",
    couponUrl: "URL do cupom",
    welcomeToFidelizationMessage: "Mensagem de boas-vindas à fidelização, enviada no primeiro passo",
    fidelizationDiscountMessage: "Mensagem de recompensa de fidelização",
    addFidelizationStep: "Adicionar etapa de fidelização",
    deleteFidelizationStep: "Excluir etapa de fidelização",
    fidelizationSale: "Venda",
    fidelizationVisit: "Visita",
    salesDataObtainedThroughShopify: "Dados de vendas obtidos através do Shopify.",
    visitDataObtainedThroughMenuMessageSent: "Dados de visitas obtidos através da mensagem do menu enviada.",
    addReward: "Adicionar recompensa",
    thisMessageNeedsToBeApprovedBeforeStartingFidelization:
        "Esta mensagem precisa ser aprovada antes de ativar esta estratégia de fidelização.",
    previewOfFidelizationStartingMessage: "Prévia da mensagem de início da fidelização",
    previewOfFidelizationRewardMessage: "Prévia da mensagem de recompensa de fidelização",
    restaurantView: "Visualização do Restaurante",
    fidelizationTemplateWaitingForApproval: "O modelo de fidelização está aguardando aprovação",
    youNeedToWait3MinutesBeforeSavingAgain: "Você precisa esperar 3 minutos antes de salvar novamente",
    fidelizationTemplateWasRejected: "O modelo de fidelização foi rejeitado",

    obtainingFidelizationData: "Obtendo dados de fidelização...",
    fidelizationAnswersDesc:
        "Se o seu ticket médio por pessoa {average_ticket}, o Cheetrack ajudou você a gerar uma venda estimada de {total_ticket}",
    anErrorOcurredObtainingFidelizationData: "Ocorreu um erro ao obter dados de fidelização.",
    couponsUsedByCustomers: "Cupons usados pelos clientes",
    couponsSent: "Cupons enviados",
    couponsUsed: "Cupons utilizados",
    estimatedSale: "Venda estimada",
    youNeedToSetUpFidelizationToSeeThisData: "Você precisa configurar a fidelização para ver esses dados.",
    goToFidelizationSetUp: "Ir para a configuração de fidelização",
    dataCanTakeUpToADayToBeUpdated: "Os dados podem levar até um dia para serem atualizados",
    clientsWhoExceededMaximumProfitsInThePeriod: "Clientes que ultrapassaram o lucro máximo no período",
    totalBenefitsDeniedOnTheDay: "Total de benefícios negados no dia",
    showFirstBenefit: "Mostrar o primeiro benefício",
    totalBenefitsUsed: "Total de benefícios utilizados",
    doYouWantToActivateTheSatisfactionBot: "Você quer acionar o bot de satisfação?",
    benefitProgram: "Programa de benefícios",
    clientList: "Lista de clientes",
    benefitsObtained: "Benefícios obtidos",
    globalVisits: "Visitas globais",
    showingInformationFromTo: "Mostrando informações de {from} a {to}",
}

export default messages
