const messages = {
    boardName: "Board Name",
    boards: "Boards",
    boardsDesc: "Create boards to organize your clients.",
    boardsDesc2: "Boards use tags to order conversations with your clients into columns.",
    createNewBoard: "SetUp a new board",
    addTagToBoard: "Add tag to board",
    boardsSelectTag: "Select tag",
    selectTagsForYourBoard: "Select tags to add to this board",
    saveBoardChanges: "Save board",
    deleteBoard: "Delete board",
    thisTagBelongsToABoard: "This tag belongs to a board.",
    seeBoard: "Go to board",
    advanceToNextTagInBoard: "Advance to next tag in the board",
    backToPreviousTagInBoard: "Move back to previous tag in the board",
    goToConversation: "Go to this conversation",
    goToConversationsWithTag: "See conversations with this tag",
    boardSuccessPercentage: "Board success rate",
    seeBoardsList: "See boards list",
    nameYourBoard: "Name your board",
    gettingConversationsWithThisTag: "Getting clients with this tag",
    boardStepSucess: "success",
    boardStepSucessDesc: "% of clients advanced from previous steps to this step or beyond",
    finalBoardStep: "Final step",
    savingTag: "Saving tag",
    editBoard: "Edit board",
    thisTagIsPartOfABoard: "This tag is part of a board:",
    thisTagActivatesAChase: "This tag activates a chase:",
    thisTagIsStepOnBoard: "Step {step} on {board}.",
    thisConversationIsOnABoard: "This conversation shows up in the following boards:",
    clickOnOneOfTheStepToChangeTag:
        "Click on one of the steps to move this conversation inside the board and change it's tag accordingly",
    youDontHaveAnyBoardsYet: "You don't have any boards yet",
    createYourFirstBoardClickingTheTopBtn: "Create your first board clicking the button on top",
    youDontHaveThePermissionsToCreateBoards:
        "You don't have the necessary permissions to create or edit boards. Ask an admin if you need one to be created or changed.",
    boardTags: "Board tags",
    filterBoardClients: "Filter board clients",
    aDayAgo: "A day ago",
    threeDaysAgo: "Three days ago",
    aWeekAgo: "A week ago",
    aMonthAgo: "A month ago",
    aYearAgo: "A year ago",
    clientCreationDate: "Start date",
    successPercentageInfo:
        "The success rate by step indicates the proportion of clients that have gone through that step, including both the ones currently there and the ones in steps beyond.",

    xTotalClientsForThisBoard: "{totalClients} total clients for this board.",
    xTotalClientsForThisBoardWithFilters: "{totalClients} total clients for this board with {filters} filters.",
    youCanOnlyHaveUpToXTags: "You can only have up to {x} tags.",
    youWillNeedToDeleteSomeTagsToCreateANewOne: "Delete one to create a new one.",
    youCanOnlyHaveUpToXBoards: "You can only have up to {x} boards.",
    youWillNeedToDeleteSomeBoardsToCreateANewOne: "You will need to delete some boards to create a new one.",
}
export default messages
