// Precios de mensajes de sms de Twilio

export const smsPrices = [
    {
        country: "Chile",
        countryCode: "CL",
        id: 1,
        priceToSend: 0.058,
        priceToReceive: 0.0075,
        MMS: false,
    },
    {
        country: "España",
        countryCode: "ES",
        id: 2,
        priceToSend: 0.0833,
        priceToReceive: 0.0075,
        MMS: false,
    },
    {
        country: "Colombia",
        countryCode: "CO",
        id: 3,
        priceToSend: 0.0525,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Perú",
        countryCode: "PE",
        id: 4,
        priceToSend: 0.0525,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Estados Unidos",
        countryCode: "US",
        id: 11,
        priceToSend: 0.01,
        priceToReceive: 0.01,
        MMS: true,
        priceToSendMMS: 0.02,
        priceToReceiveMMS: 0.02,
    },
    {
        country: "México",
        countryCode: "MX",
        id: 9,
        priceToSend: 0.0515,
        priceToReceive: 0.02,
        MMS: false,
    },
    {
        country: "Canadá",
        countryCode: "CA",
        id: 12,
        priceToSend: 0.0079,
        priceToReceive: 0.0075,
        MMS: true,
        priceToSendMMS: 0.02,
        priceToReceiveMMS: 0.02,
    },
    {
        country: "Argentina",
        countryCode: "AR",
        id: 5,
        priceToSend: 0.0743,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Ecuador",
        countryCode: "EC",
        id: 6,
        priceToSend: 0.1253,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Venezuela",
        countryCode: "VE",
        id: 7,
        priceToSend: 0.069,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Bolivia",
        countryCode: "BO",
        id: 8,
        priceToSend: 0.1,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Panamá",
        countryCode: "PA",
        id: 10,
        priceToSend: 0.0791,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Brasil",
        countryCode: "BR",
        id: 13,
        priceToSend: 0.057,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Uruguay",
        countryCode: "UR",
        id: 14,
        priceToSend: 0.0852,
        priceToReceive: false,
        MMS: false,
    },
    {
        country: "Paraguay",
        countryCode: "PY",
        id: 15,
        priceToSend: 0.0205,
        priceToReceive: false,
        MMS: false,
    },
]
