// Limites de caracteres para cada tipo de mensaje

export const message_limits = {
    meta_whatsapp: {
        text: 4096,
    },
    sms: {
        text: 320,
    },
    instagram: {
        size: 1000,
    },
    facebook: {
        size: 1000,
    },
    twilio: {
        text: 1600,
    },
}
