import { segmentationCriteria } from "../constants"

// Decodifica la query de segmentación proveniente de Shopify

export const decipherSegmentationQuery = (query) => {
    if (!query) return [{}]

    const criteria = []

    if (query.custom_values) {
        Object.keys(query.custom_values).forEach((key) => {
            criteria.push({
                name: key,
                val: query.custom_values[key].val,
                operator: query.custom_values[key].operator,
            })
        })
    }

    if (query.definition) {
        const queries = query.definition.split("AND")
        queries.forEach((q) => {
            const splitStr = q.split(" "),
                name = splitStr[0],
                operator = splitStr[1],
                selectedCriteria = segmentationCriteria.find((c) => c.value === name),
                val =
                    selectedCriteria?.type === "numeric" || selectedCriteria?.type === "date"
                        ? selectedCriteria?.subtype === "float"
                            ? parseFloat(splitStr[2])
                            : parseInt(splitStr[2])
                        : splitStr[2]

            criteria.push({ name, operator, val })
        })
    }
    return criteria
}
const findIdsByTitles = (productsArray, titlesArray) => {
    const idMap = new Map()
    for (const product of productsArray) {
        idMap.set(product.title, product.id)
    }

    const resultIds = titlesArray.map((p) => idMap.get(p.title)).filter((id) => id !== undefined)
    return resultIds.join(",")
}
export const parseSegmentationQuery = (criteria, products) => {
    const query = {}
    criteria.forEach((c) => {
        const selectedCriteria = segmentationCriteria.find((sc) => sc.value === c.name)

        if (selectedCriteria && selectedCriteria.name === "productsPurchased") {
            const products_ids = findIdsByTitles(products, c.products_purchased)
            if (!query.definition) query.definition = ""
            else query.definition = `products_purchased(id: (${products_ids}), since: ${c.val}_day_ago) = true`
            if (c.operator === "beetwen") {
                const value = parseInt(c.val) < 0 ? c.val * -1 : c.val,
                    toValue = parseInt(c.toValue) < 0 ? c.toValue * -1 : c.toValue,
                    str = `products_purchased(id: (${products_ids}), since: ${value}_day_ago, until: ${toValue}_day_ago) = true`
                query.definition += str
            } else {
                const value = parseInt(c.val) < 0 ? c.val * -1 : c.val,
                    str = `products_purchased(id: (${products_ids}), since: ${value}_day_ago) = true`
                query.definition += str
            }
        } else {
            if (selectedCriteria) {
                if (selectedCriteria.custom) {
                    if (!query.custom_values) query.custom_values = {}
                    query.custom_values[selectedCriteria.value] = {
                        name: selectedCriteria.value,
                        val: c.val,
                        operator: c.operator,
                    }
                } else {
                    if (!query.definition) query.definition = ""
                    else query.definition += " AND "
                    const value = selectedCriteria.unit === "daysAgo" ? c.val * -1 + "d" : c.val,
                        str = `${selectedCriteria.value} ${c.operator} ${value}`
                    query.definition += str
                }
            }
        }
    })

    return query
}
