const messages = {
    messageLoadingData: "We are consulting for the data, please wait a moment.",
    messageLoadingTemplates: "We are loading startup messages, please wait a moment.",
    anErrorOcurredObtainingFlowsData: "An error occurred obtaining flows data, please try again.",
    noFlowFound: "No flows data found for this date range.",
    withoutResponding: "Without responding",
    emptyTemplates: "No startup messages found",
    labelButtons: "Buttons",
    labelSelectFlows: "Select your flow",
}
export default messages
