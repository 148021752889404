const messages = {
    recommendedForYou: "Recommended for you",
    welcomeToCheetrack: "Welcome to Cheetrack!",
    welcomeToCheetrackSubtitle: "We are happy to have you here",
    chooseACategoryToStart: "Choose a category to start",
    shoppingCart: "Order summary",
    glutenFree: "Gluten free",
    vegan: "Vegan",
    spicy: "Spicy",

    catalogSetUp: "Catalog set up",
    catalogSetUpSubtitle: "Set up your catalog to start selling",
    createProduct: "Create product",
    createCategory: "Create/edit categories",
    createSubcategory: "Create subcategory",
    productCategories: "Product categories",
    productSubCategories: "Product subcategories",
    productImage: "Product image",
    productName: "Product name",
    productDescription: "Product description",
    catalog: "Catalog",
    editProduct: "Edit product",
    editCategory: "Edit category",
    addCategories: "Add categories",
    productPrice: "Product price",
    productDetails: "Product details",
    addCategory: "Add category",
    subcategories: "Subcategories",
    addSubcategory: "Add subcategory",
    thisCategoryNameAlreadyExists: "This category name already exists",
    categoryName: "Category name",
    selectACategoryToEdit: "Select a category to edit",
    categoryDetails: "Category details",
    saveNewCategory: "Save new category",
}

export default messages
