const messages = {
    monthlySales: "Ventas mensuales",
    eCommerceSales: "Ventas E-Commerce",
    monthlyGoal: "Meta para este mes",
    cheetrackSales: "Ventas totales asistidas por Cheetrack",
    nonCheetrackSales: "Ventas no asistidas por Cheetrack",
    dailyGoal: "Meta diaria",
    increaseSales: "Incrementar ventas",
    dailyAverage: "Promedio diario",
    toShowYourSalesHereFirstIntegrate: "Para mostrar tus ventas aquí, primero debes integrar Cheetrack con Shopify!",
    dailySales: "Ventas diarias",
    lastMonthDailyAvg: "Promedio meses anteriores",
    youAreXFromYourGoal: "Estás a {x} de tu meta para este mes 💪",
    toAchieveYourGoalYouNeedToSellXDaily: "Para cumplir tu meta debes vender {x} diarios",
    youExceededYourGoal: "Estás {x} por sobre tu meta de este mes! 🥳",
    youHaveSelledAnAvgOf: "Has vendido un promedio de {x} diario!",
    youSoldXThisMonth: "Vendiste {x} durante {month}",
    cheetrackHelpedYouXThatMonth: "Cheetrack te ayudo en {x} de esas ventas",
    loadingData: "Cargando datos...",
    noDataToDisplay: "No hay datos para mostrar",
    youHaveNotYetConnectedToECommerce: "No estás conectado con Shopify!",
    clickToConnectToSeeYourDataHere: "Haz click en el ícono de Shopify para conectarte y ver tus datos aquí!",
    goal: "Meta",
    anErrorOcurredGettingYourData: "Ocurrió un error obteniendo tus datos ☹️!",
    anErrorOcurredWithRangeDatetime: "Por favor elige una fecha más reciente a 60 días atrás ☹️!",
    pleaseTryAgainLater: "Por favor vuelve a intentarlo más tarde",
    thereIsNoData: "No hay datos para mostrar!",
    youEitherNeedToConnectOrHaveNoData: "No te has conectado a Shopify o no tienes datos disponibles para este período",
    obtainingYourData: "Obteniendo tus datos de Shopify",
    cartsRecoveredByCheetrack: "Carros recuperados por Cheetrack este mes",
    obtainingLostData: "Obteniendo tus carros pérdidos",
    anErrorOcurredGettingYourLostCartData: "No pudimos obtener tus datos de carros pérdidos 😖",
    cheetrackHasRecoveredXCarts: "Cheetrack ha recuperado {x} carros pérdidos 🛒 este mes!",
    thisAmountsToXCartsADay: "Esto es un promedio de {x} por carro recuperado!",
    dailyLostCarts: "Carros abandonados al día",
    cheetrackRecoverdCarts: "Carros recuperados por Cheetrack al día",
    cheetrackHasntRecoveredCartsYet: "Cheetrack no ha recuperado carros aún 😵‍💫!",
    youMayNeedToWaitALittle:
        "Puede que debas esperar un poco para que se recuperen carros o para que recopilemos la información",
    thereisNoLostCartsData: "No tenemos datos de tus carros pérdidos 😨!",
    checkYourShopifyConnectionOrPermssions: "Revisa que nos hayas entregado los permisos correctos en Shopify!",
    obtainingBuyersData: "Obteniendo información de compras de tus mejores clientes",
    anErrorOcurredWithYourBuyersData: "¡Ups! Ocurrió un error obteniendo los datos de compra de tus clientes",
    thereIsNoBuyersData: "No encontramos datos de compra de tus clientes",
    thereMayBeAnIssueWithYourShopifyPermissions: "Puede que haya un problema con los permisos de Shopify",
    saleNumbersDescription: "Los números de ventas están en bruto. Incluyen impuestos, costos de envío y descuentos.",
    cheetrackHasAssistedYouWithXSalesTheLastYDays: "Cheetrack te ha asistido con {x} en ventas estos {y} días 🤩!",
    top10SalesTemplates: "10 Mensajes de inicio con mayores ventas",
    totalSales: "Ventas totales en Shopify",
    clientsReached: "Clientes contactados",
    averageSale: "Ticket promedio",
    cheetrackAssistedSales: "Ventas asistidas Cheetrack",
    cheetrackAssistedSalesMD: "Ventas asist. Cheetrack",
    ordersCreated: "Órdenes creadas",
    openRate: "Open rate",
    stopMessages: "Mensajes de Stop",
    soon: "Próximamente",
    last7Days: "Últimos 7 días",
    last15Days: "Últimos 15 días",
    last30Days: "Últimos 30 días",
    thisAmountsToXOfYourTotalSales: "Esto es {x}% de tus ventas totales en Shopify para el mismo período.",
    comparedToTheXDaysBefore: "Comparado con los {x} días anteriores.",
    selectDataToShow: "Ver ventas compiladas:",
    showDataFrom: "Ver ventas por mensaje de inicio:",
    cheetrackAssistedSaleDesc:
        "Una venta asistida por Cheetrack es un cualquier venta hasta 5 días posterior a que el cliente haya recibido un mensaje de inicio desde Cheetrack.",
    dailySalesForXTemplates: "Ventas diarias para el mensaje de inicio {x}",
    dailySalesForXYTemplates: "Ventas diarias para los mensajes de inicio {x}",
    dailySalesForXYZTemplates: "Ventas diarias para el mensaje de inicio {x} y {y} otros",
    dailySalesForTop10Templates: "Ventas diarias para tus 10 mensajes de inicio con mayores ventas",
    dailySalesAssistedByCheetrack: "Ventas diarias asistidas por Cheetrack",
    averageSaleDesc: "Ventas asistidas por cheetrack / órdenes",
    salesDataUpdatesDaily: "Los datos de venta se actualizan cada 24 hrs.",
    salesWarningModalTitle: "Informacion de reporte",
    saleswarningModalBody:
        "Estás solicitando el detalle de las ventas asistidas por Cheetrack. Se demorará unos minutos en llegar al e-mail de los usuarios administradores registrados en nuestra plataforma.",
    salesGetReport: "Enviar reporte por email",
    salesStartDate: "Fecha de inicio",
    salesEndDate: "Fecha fin",
    salesButtonConfirmation: "Entendido",
    salesDatePickerAnswerTitle: "¿Quieres descargar un respaldo de las ventas asistidas?",
}

export default messages
