// Listado de emojis disponibles

export const emojis = [
    [
        "😀",
        "😃",
        "😄",
        "😁",
        "😆",
        "😅",
        "😂",
        "🤣",
        "🥲",
        "☺️",
        "😊",
        "😇",
        "🙂",
        "🙃",
        "😉",
        "😌",
        "😍",
        "🥰",
        "😘",
        "😗",
        "😙",
        "😚",
        "😋",
        "😛",
        "😝",
        "😜",
        "🤪",
        "🤨",
        "🧐",
        "🤓",
        "😎",
        "🥸",
        "🤩",
        "🥳",
        "😏",
        "😒",
        "😞",
        "😔",
        "😟",
        "😕",
        "🙁",
        "☹️",
        "😣",
        "😖",
        "😫",
        "😩",
        "🥺",
        "😢",
        "😭",
        "😤",
        "😠",
        "😡",
        "🤬",
        "🤯",
        "😳",
        "🥵",
        "🥶",
        "😶‍🌫️",
        "😱",
        "😨",
        "😰",
        "😥",
        "😓",
        "🤗",
        "🤔",
        "🫣",
        "🤭",
        "🤫",
        "🫡",
        "🤫",
        "🫠",
        "🤥",
        "😶",
        "🫥",
        "😐",
        "🫤",
        "😑",
        "😬",
        "🙄",
        "😯",
        "😦",
        "😧",
        "😮",
        "😲",
        "🥱",
        "😴",
        "🤤",
        "😪",
        "😮‍💨",
        "😵",
        "😵‍💫",
        "🤐",
        "🥴",
        "🤢",
        "🤮",
        "🤧",
        "😷",
        "🤒",
        "🤕",
        "🤑",
        "🤠",
        "😈",
        "👿",
        "👹",
        "👺",
        "🤡",
        "💩",
        "👻",
        "💀",
        "☠️",
        "👽",
        "👾",
        "🤖",
        "🎃",
        "😺",
        "😸",
        "😹",
        "😻",
        "😼",
        "😽",
        "🙀",
        "😿",
        "😾",
    ],
    [
        "👋",
        "🤚",
        "🖐",
        "✋",
        "🖖",
        "👌",
        "🤌",
        "🤏",
        "✌️",
        "🤞",
        "🤟",
        "🤘",
        "🤙",
        "👈",
        "👉",
        "👆",
        "🖕",
        "👇",
        "🫵",
        "☝️",
        "👍",
        "👎",
        "✊",
        "👊",
        "🤛",
        "🤜",
        "👏",
        "🙌",
        "👐",
        "🤲",
        "🤝",
        "🫶",
        "🫰",
        "🫳",
        "🫴",
        "🫲",
        "🫱",
        "🙏",
        "✍️",
        "💅",
        "🤳",
        "💪",
        "🦾",
        "🦵",
        "🦿",
        "🦶",
        "👣",
        "👂",
        "🦻",
        "👃",
        "🫀",
        "🫁",
        "🧠",
        "🦷",
        "🦴",
        "👀",
        "👁",
        "👅",
        "👄",
        "💋",
        "🩸",
    ],
    [
        "👶",
        "👧",
        "🧒",
        "👦",
        "👩",
        "🧑",
        "👨",
        "👩‍🦱",
        "🧑‍🦱",
        "👨‍🦱",
        "👩‍🦰",
        "🧑‍🦰",
        "👨‍🦰",
        "👱‍♀️",
        "👱",
        "👱‍♂️",
        "👩‍🦳",
        "🧑‍🦳",
        "👨‍🦳",
        "👩‍🦲",
        "🧑‍🦲",
        "👨‍🦲",
        "🧔‍♀️",
        "🧔",
        "🧔‍♂️",
        "👵",
        "🧓",
        "👴",
        "👲",
        "👳‍♀️",
        "👳",
        "👳‍♂️",
        "🧕",
        "👮‍♀️",
        "👮",
        "👮‍♂️",
        "👷‍♀️",
        "👷",
        "👷‍♂️",
        "💂‍♀️",
        "💂",
        "💂‍♂️",
        "🕵️‍♀️",
        "🕵️",
        "🕵️‍♂️",
        "👩‍⚕️",
        "🧑‍⚕️",
        "👨‍⚕️",
        "👩‍🌾",
        "🧑‍🌾",
        "👨‍🌾",
        "👩‍🍳",
        "🧑‍🍳",
        "👨‍🍳",
        "👩‍🎓",
        "🧑‍🎓",
        "👨‍🎓",
        "👩‍🎤",
        "🧑‍🎤",
        "👨‍🎤",
        "👩‍🏫",
        "🧑‍🏫",
        "👨‍🏫",
        "👩‍🏭",
        "🧑‍🏭",
        "👨‍🏭",
        "👩‍💻",
        "🧑‍💻",
        "👨‍💻",
        "👩‍💼",
        "🧑‍💼",
        "👨‍💼",
        "👩‍🔧",
        "🧑‍🔧",
        "👨‍🔧",
        "👩‍🔬",
        "🧑‍🔬",
        "👨‍🔬",
        "👩‍🎨",
        "🧑‍🎨",
        "👨‍🎨",
        "👩‍🚒",
        "🧑‍🚒",
        "👨‍🚒",
        "👩‍✈️",
        "🧑‍✈️",
        "👨‍✈️",
        "👩‍🚀",
        "🧑‍🚀",
        "👨‍🚀",
        "👩‍⚖️",
        "🧑‍⚖️",
        "👨‍⚖️",
        "👰‍♀️",
        "👰",
        "👰‍♂️",
        "🤵‍♀️",
        "🤵",
        "🤵‍♂️",
        "👸",
        "🤴",
        "🫅",
        "🥷",
        "🦸‍♀️",
        "🦸",
        "🦸‍♂️",
        "🦹‍♀️",
        "🦹",
        "🦹‍♂️",
        "🤶",
        "🧑‍🎄",
        "🎅",
        "🧙‍♀️",
        "🧙",
        "🧙‍♂️",
        "🧝‍♀️",
        "🧝",
        "🧝‍♂️",
        "🧛‍♀️",
        "🧛",
        "🧛‍♂️",
        "🧟‍♀️",
        "🧟",
        "🧟‍♂️",
        "🧌",
        "🧞‍♀️",
        "🧞",
        "🧞‍♂️",
        "🧜‍♀️",
        "🧜",
        "🧜‍♂️",
        "🧚‍♀️",
        "🧚",
        "🧚‍♂️",
        "👼",
        "🤰",
        "🫄",
        "🫃",
        "🤱",
        "👩‍🍼",
        "🧑‍🍼",
        "👨‍🍼",
        "🙇‍♀️",
        "🙇",
        "🙇‍♂️",
        "💁‍♀️",
        "💁",
        "💁‍♂️",
        "🙅‍♀️",
        "🙅",
        "🙅‍♂️",
        "🙆‍♀️",
        "🙆",
        "🙆‍♂️",
        "🙋‍♀️",
        "🙋",
        "🙋‍♂️",
        "🧏‍♀️",
        "🧏",
        "🧏‍♂️",
        "🤦‍♀️",
        "🤦",
        "🤦‍♂️",
        "🤷‍♀️",
        "🤷",
        "🤷‍♂️",
        "🙎‍♀️",
        "🙎",
        "🙎‍♂️",
        "🙍‍♀️",
        "🙍",
        "🙍‍♂️",
        "💇‍♀️",
        "💇",
        "💇‍♂️",
        "💆‍♀️",
        "💆",
        "💆‍♂️",
        "🧖‍♀️",
        "🧖",
        "🧖‍♂️",
        "💅",
        "🤳",
        "💃",
        "🕺",
        "👯‍♀️",
        "👯",
        "👯‍♂️",
        "🕴",
        "👩‍🦽",
        "🧑‍🦽",
        "👨‍🦽",
        "👩‍🦼",
        "🧑‍🦼",
        "👨‍🦼",
        "🚶‍♀️",
        "🚶",
        "🚶‍♂️",
        "👩‍🦯",
        "🧑‍🦯",
        "👨‍🦯",
        "🧎‍♀️",
        "🧎",
        "🧎‍♂️",
        "🏃‍♀️",
        "🏃",
        "🏃‍♂️",
        "🧍‍♀️",
        "🧍",
        "🧍‍♂️",
        "👭",
        "🧑‍🤝‍🧑",
        "👬",
        "👫",
        "👩‍❤️‍👩",
        "💑",
        "👨‍❤️‍👨",
        "👩‍❤️‍👨",
        "👩‍❤️‍💋‍👩",
        "💏",
        "👨‍❤️‍💋‍👨",
        "👩‍❤️‍💋‍👨",
        "👪",
        "👨‍👩‍👦",
        "👨‍👩‍👧",
        "👨‍👩‍👧‍👦",
        "👨‍👩‍👦‍👦",
        "👨‍👩‍👧‍👧",
        "👨‍👨‍👦",
        "👨‍👨‍👧",
        "👨‍👨‍👧‍👦",
        "👨‍👨‍👦‍👦",
        "👨‍👨‍👧‍👧",
        "👩‍👩‍👦",
        "👩‍👩‍👧",
        "👩‍👩‍👧‍👦",
        "👩‍👩‍👦‍👦",
        "👩‍👩‍👧‍👧",
        "👨‍👦",
        "👨‍👦‍👦",
        "👨‍👧",
        "👨‍👧‍👦",
        "👨‍👧‍👧",
        "👩‍👦",
        "👩‍👦‍👦",
        "👩‍👧",
        "👩‍👧‍👦",
        "👩‍👧‍👧",
        "🗣",
        "👤",
        "👥",
        "🫂",
    ],
    [
        "🧳",
        "🌂",
        "☂️",
        "🧵",
        "🪡",
        "🪢",
        "🧶",
        "👓",
        "🕶",
        "🥽",
        "🥼",
        "🦺",
        "👔",
        "👕",
        "👖",
        "🧣",
        "🧤",
        "🧥",
        "🧦",
        "👗",
        "👘",
        "🥻",
        "🩴",
        "🩱",
        "🩲",
        "🩳",
        "👙",
        "👚",
        "👛",
        "👜",
        "👝",
        "🎒",
        "👞",
        "👟",
        "🥾",
        "🥿",
        "👠",
        "👡",
        "🩰",
        "👢",
        "👑",
        "👒",
        "🎩",
        "🎓",
        "🧢",
        "⛑",
        "🪖",
        "💄",
        "💍",
        "💼",
    ],
    [
        "🐶",
        "🐱",
        "🐭",
        "🐹",
        "🐰",
        "🦊",
        "🐻",
        "🐼",
        "🐻‍❄️",
        "🐨",
        "🐯",
        "🦁",
        "🐮",
        "🐷",
        "🐽",
        "🐸",
        "🐵",
        "🙈",
        "🙉",
        "🙊",
        "🐒",
        "🐔",
        "🐧",
        "🐦",
        "🐤",
        "🐣",
        "🐥",
        "🦆",
        "🦅",
        "🦉",
        "🦇",
        "🐺",
        "🐗",
        "🐴",
        "🦄",
        "🐝",
        "🪱",
        "🐛",
        "🦋",
        "🐌",
        "🐞",
        "🐜",
        "🪰",
        "🪲",
        "🪳",
        "🦟",
        "🦗",
        "🕷",
        "🕸",
        "🦂",
        "🐢",
        "🐍",
        "🦎",
        "🦖",
        "🦕",
        "🐙",
        "🦑",
        "🦐",
        "🦞",
        "🦀",
        "🐡",
        "🐠",
        "🐟",
        "🐬",
        "🐳",
        "🐋",
        "🦈",
        "🐊",
        "🐅",
        "🐆",
        "🦓",
        "🦍",
        "🦧",
        "🦣",
        "🐘",
        "🦛",
        "🦏",
        "🐪",
        "🐫",
        "🦒",
        "🦘",
        "🦬",
        "🐃",
        "🐂",
        "🐄",
        "🐎",
        "🐖",
        "🐏",
        "🐑",
        "🦙",
        "🐐",
        "🦌",
        "🐕",
        "🐩",
        "🦮",
        "🐕‍🦺",
        "🐈",
        "🐈‍⬛",
        "🪶",
        "🐓",
        "🦃",
        "🦤",
        "🦚",
        "🦜",
        "🦢",
        "🦩",
        "🕊",
        "🐇",
        "🦝",
        "🦨",
        "🦡",
        "🦫",
        "🦦",
        "🦥",
        "🐁",
        "🐀",
        "🐿",
        "🦔",
        "🐾",
        "🐉",
        "🐲",
        "🌵",
        "🎄",
        "🌲",
        "🌳",
        "🌴",
        "🪵",
        "🌱",
        "🌿",
        "☘️",
        "🍀",
        "🎍",
        "🪴",
        "🎋",
        "🍃",
        "🍂",
        "🍁",
        "🍄",
        "🐚",
        "🪨",
        "🌾",
        "💐",
        "🌷",
        "🌹",
        "🥀",
        "🌺",
        "🌸",
        "🌼",
        "🌻",
        "🌞",
        "🌝",
        "🌛",
        "🌜",
        "🌚",
        "🌕",
        "🌖",
        "🌗",
        "🌘",
        "🌑",
        "🌒",
        "🌓",
        "🌔",
        "🌙",
        "🌎",
        "🌍",
        "🌏",
        "🪐",
        "💫",
        "⭐️",
        "🌟",
        "✨",
        "⚡️",
        "☄️",
        "💥",
        "🔥",
        "🌪",
        "🌈",
        "☀️",
        "🌤",
        "⛅️",
        "🌥",
        "☁️",
        "🌦",
        "🌧",
        "⛈",
        "🌩",
        "🌨",
        "❄️",
        "☃️",
        "⛄️",
        "🌬",
        "💨",
        "💧",
        "💦",
        "☔️",
        "☂️",
        "🌊",
        "🌫",
    ],
    [
        "🍏",
        "🍎",
        "🍐",
        "🍊",
        "🍋",
        "🍌",
        "🍉",
        "🍇",
        "🍓",
        "🫐",
        "🍈",
        "🍒",
        "🍑",
        "🥭",
        "🍍",
        "🥥",
        "🥝",
        "🍅",
        "🍆",
        "🥑",
        "🥦",
        "🥬",
        "🥒",
        "🌶",
        "🫑",
        "🌽",
        "🥕",
        "🫒",
        "🧄",
        "🧅",
        "🥔",
        "🍠",
        "🥐",
        "🥯",
        "🍞",
        "🥖",
        "🥨",
        "🧀",
        "🥚",
        "🍳",
        "🧈",
        "🥞",
        "🧇",
        "🥓",
        "🥩",
        "🍗",
        "🍖",
        "🦴",
        "🌭",
        "🍔",
        "🍟",
        "🍕",
        "🫓",
        "🥪",
        "🥙",
        "🧆",
        "🌮",
        "🌯",
        "🫔",
        "🥗",
        "🥘",
        "🫕",
        "🥫",
        "🍝",
        "🍜",
        "🍲",
        "🍛",
        "🍣",
        "🍱",
        "🥟",
        "🦪",
        "🍤",
        "🍙",
        "🍚",
        "🍘",
        "🍥",
        "🥠",
        "🥮",
        "🍢",
        "🍡",
        "🍧",
        "🍨",
        "🍦",
        "🥧",
        "🧁",
        "🍰",
        "🎂",
        "🍮",
        "🍭",
        "🍬",
        "🍫",
        "🍿",
        "🍩",
        "🍪",
        "🌰",
        "🥜",
        "🍯",
        "🥛",
        "🍼",
        "🫖",
        "☕️",
        "🍵",
        "🧃",
        "🥤",
        "🧋",
        "🍶",
        "🍺",
        "🍻",
        "🥂",
        "🍷",
        "🥃",
        "🍸",
        "🍹",
        "🧉",
        "🍾",
        "🧊",
        "🥄",
        "🍴",
        "🍽",
        "🥣",
        "🥡",
        "🥢",
        "🧂",
    ],
    [
        "⚽️",
        "🏀",
        "🏈",
        "⚾️",
        "🥎",
        "🎾",
        "🏐",
        "🏉",
        "🥏",
        "🎱",
        "🪀",
        "🏓",
        "🏸",
        "🏒",
        "🏑",
        "🥍",
        "🏏",
        "🪃",
        "🥅",
        "⛳️",
        "🪁",
        "🏹",
        "🎣",
        "🤿",
        "🥊",
        "🥋",
        "🎽",
        "🛹",
        "🛼",
        "🛷",
        "⛸",
        "🥌",
        "🎿",
        "⛷",
        "🏂",
        "🪂",
        "🏋️‍♀️",
        "🏋️",
        "🏋️‍♂️",
        "🤼‍♀️",
        "🤼",
        "🤼‍♂️",
        "🤸‍♀️",
        "🤸",
        "🤸‍♂️",
        "⛹️‍♀️",
        "⛹️",
        "⛹️‍♂️",
        "🤺",
        "🤾‍♀️",
        "🤾",
        "🤾‍♂️",
        "🏌️‍♀️",
        "🏌️",
        "🏌️‍♂️",
        "🏇",
        "🧘‍♀️",
        "🧘",
        "🧘‍♂️",
        "🏄‍♀️",
        "🏄",
        "🏄‍♂️",
        "🏊‍♀️",
        "🏊",
        "🏊‍♂️",
        "🤽‍♀️",
        "🤽",
        "🤽‍♂️",
        "🚣‍♀️",
        "🚣",
        "🚣‍♂️",
        "🧗‍♀️",
        "🧗",
        "🧗‍♂️",
        "🚵‍♀️",
        "🚵",
        "🚵‍♂️",
        "🚴‍♀️",
        "🚴",
        "🚴‍♂️",
        "🏆",
        "🥇",
        "🥈",
        "🥉",
        "🏅",
        "🎖",
        "🏵",
        "🎗",
        "🎫",
        "🎟",
        "🎪",
        "🤹",
        "🤹‍♂️",
        "🤹‍♀️",
        "🎭",
        "🩰",
        "🎨",
        "🎬",
        "🎤",
        "🎧",
        "🎼",
        "🎹",
        "🥁",
        "🪘",
        "🎷",
        "🎺",
        "🪗",
        "🎸",
        "🪕",
        "🎻",
        "🎲",
        "♟",
        "🎯",
        "🎳",
        "🎮",
        "🎰",
        "🧩",
    ],
    [
        "🚗",
        "🚕",
        "🚙",
        "🚌",
        "🚎",
        "🏎",
        "🚓",
        "🚑",
        "🚒",
        "🚐",
        "🛻",
        "🚚",
        "🚛",
        "🚜",
        "🦯",
        "🦽",
        "🦼",
        "🛴",
        "🚲",
        "🛵",
        "🏍",
        "🛺",
        "🚨",
        "🚔",
        "🚍",
        "🚘",
        "🚖",
        "🚡",
        "🚠",
        "🚟",
        "🚃",
        "🚋",
        "🚞",
        "🚝",
        "🚄",
        "🚅",
        "🚈",
        "🚂",
        "🚆",
        "🚇",
        "🚊",
        "🚉",
        "✈️",
        "🛫",
        "🛬",
        "🛩",
        "💺",
        "🛰",
        "🚀",
        "🛸",
        "🚁",
        "🛶",
        "⛵️",
        "🚤",
        "🛥",
        "🛳",
        "⛴",
        "🚢",
        "⚓️",
        "🪝",
        "⛽️",
        "🚧",
        "🚦",
        "🚥",
        "🚏",
        "🗺",
        "🗿",
        "🗽",
        "🗼",
        "🏰",
        "🏯",
        "🏟",
        "🎡",
        "🎢",
        "🎠",
        "⛲️",
        "⛱",
        "🏖",
        "🏝",
        "🏜",
        "🌋",
        "⛰",
        "🏔",
        "🗻",
        "🏕",
        "⛺️",
        "🛖",
        "🏠",
        "🏡",
        "🏘",
        "🏚",
        "🏗",
        "🏭",
        "🏢",
        "🏬",
        "🏣",
        "🏤",
        "🏥",
        "🏦",
        "🏨",
        "🏪",
        "🏫",
        "🏩",
        "💒",
        "🏛",
        "⛪️",
        "🕌",
        "🕍",
        "🛕",
        "🕋",
        "⛩",
        "🛤",
        "🛣",
        "🗾",
        "🎑",
        "🏞",
        "🌅",
        "🌄",
        "🌠",
        "🎇",
        "🎆",
        "🌇",
        "🌆",
        "🏙",
        "🌃",
        "🌌",
        "🌉",
        "🌁",
    ],
    [
        "⌚️",
        "📱",
        "📲",
        "💻",
        "⌨️",
        "🖥",
        "🖨",
        "🖱",
        "🖲",
        "🕹",
        "🗜",
        "💽",
        "💾",
        "💿",
        "📀",
        "📼",
        "📷",
        "📸",
        "📹",
        "🎥",
        "📽",
        "🎞",
        "📞",
        "☎️",
        "📟",
        "📠",
        "📺",
        "📻",
        "🎙",
        "🎚",
        "🎛",
        "🧭",
        "⏱",
        "⏲",
        "⏰",
        "🕰",
        "⌛️",
        "⏳",
        "📡",
        "🔋",
        "🔌",
        "💡",
        "🔦",
        "🕯",
        "🪔",
        "🧯",
        "🛢",
        "💸",
        "💵",
        "💴",
        "💶",
        "💷",
        "🪙",
        "💰",
        "💳",
        "💎",
        "⚖️",
        "🪜",
        "🧰",
        "🪛",
        "🔧",
        "🔨",
        "⚒",
        "🛠",
        "⛏",
        "🪚",
        "🔩",
        "⚙️",
        "🪤",
        "🧱",
        "⛓",
        "🧲",
        "🔫",
        "💣",
        "🧨",
        "🪓",
        "🔪",
        "🗡",
        "⚔️",
        "🛡",
        "🚬",
        "⚰️",
        "🪦",
        "⚱️",
        "🏺",
        "🔮",
        "📿",
        "🧿",
        "💈",
        "⚗️",
        "🔭",
        "🔬",
        "🕳",
        "🩹",
        "🩺",
        "💊",
        "💉",
        "🩸",
        "🧬",
        "🦠",
        "🧫",
        "🧪",
        "🌡",
        "🧹",
        "🪠",
        "🧺",
        "🧻",
        "🚽",
        "🚰",
        "🚿",
        "🛁",
        "🛀",
        "🧼",
        "🪥",
        "🪒",
        "🧽",
        "🪣",
        "🧴",
        "🛎",
        "🔑",
        "🗝",
        "🚪",
        "🪑",
        "🛋",
        "🛏",
        "🛌",
        "🧸",
        "🪆",
        "🖼",
        "🪞",
        "🪟",
        "🛍",
        "🛒",
        "🎁",
        "🎈",
        "🎏",
        "🎀",
        "🪄",
        "🪅",
        "🎊",
        "🎉",
        "🎎",
        "🏮",
        "🎐",
        "🧧",
        "✉️",
        "📩",
        "📨",
        "📧",
        "💌",
        "📥",
        "📤",
        "📦",
        "🏷",
        "🪧",
        "📪",
        "📫",
        "📬",
        "📭",
        "📮",
        "📯",
        "📜",
        "📃",
        "📄",
        "📑",
        "🧾",
        "📊",
        "📈",
        "📉",
        "🗒",
        "🗓",
        "📆",
        "📅",
        "🗑",
        "📇",
        "🗃",
        "🗳",
        "🗄",
        "📋",
        "📁",
        "📂",
        "🗂",
        "🗞",
        "📰",
        "📓",
        "📔",
        "📒",
        "📕",
        "📗",
        "📘",
        "📙",
        "📚",
        "📖",
        "🔖",
        "🧷",
        "🔗",
        "📎",
        "🖇",
        "📐",
        "📏",
        "🧮",
        "📌",
        "📍",
        "✂️",
        "🖊",
        "🖋",
        "✒️",
        "🖌",
        "🖍",
        "📝",
        "✏️",
        "🔍",
        "🔎",
        "🔏",
        "🔐",
        "🔒",
        "🔓",
    ],
    [
        "❤️",
        "🧡",
        "💛",
        "💚",
        "💙",
        "💜",
        "🖤",
        "🤍",
        "🤎",
        "💔",
        "❣️",
        "💕",
        "💞",
        "💓",
        "💗",
        "💖",
        "💘",
        "💝",
        "💟",
        "☮️",
        "✝️",
        "☪️",
        "🕉",
        "☸️",
        "✡️",
        "🔯",
        "🕎",
        "☯️",
        "☦️",
        "🛐",
        "⛎",
        "♈️",
        "♉️",
        "♊️",
        "♋️",
        "♌️",
        "♍️",
        "♎️",
        "♏️",
        "♐️",
        "♑️",
        "♒️",
        "♓️",
        "🆔",
        "⚛️",
        "🉑",
        "☢️",
        "☣️",
        "📴",
        "📳",
        "🈶",
        "🈚️",
        "🈸",
        "🈺",
        "🈷️",
        "✴️",
        "🆚",
        "💮",
        "🉐",
        "㊙️",
        "㊗️",
        "🈴",
        "🈵",
        "🈹",
        "🈲",
        "🅰️",
        "🅱️",
        "🆎",
        "🆑",
        "🅾️",
        "🆘",
        "❌",
        "⭕️",
        "🛑",
        "⛔️",
        "📛",
        "🚫",
        "💯",
        "💢",
        "♨️",
        "🚷",
        "🚯",
        "🚳",
        "🚱",
        "🔞",
        "📵",
        "🚭",
        "❗️",
        "❕",
        "❓",
        "❔",
        "‼️",
        "⁉️",
        "🔅",
        "🔆",
        "〽️",
        "⚠️",
        "🚸",
        "🔱",
        "⚜️",
        "🔰",
        "♻️",
        "✅",
        "🈯️",
        "💹",
        "❇️",
        "✳️",
        "❎",
        "🌐",
        "💠",
        "Ⓜ️",
        "🌀",
        "💤",
        "🏧",
        "🚾",
        "♿️",
        "🅿️",
        "🛗",
        "🈳",
        "🈂️",
        "🛂",
        "🛃",
        "🛄",
        "🛅",
        "🚹",
        "🚺",
        "🚼",
        "⚧",
        "🚻",
        "🚮",
        "🎦",
        "📶",
        "🈁",
        "🔣",
        "ℹ️",
        "🔤",
        "🔡",
        "🔠",
        "🆖",
        "🆗",
        "🆙",
        "🆒",
        "🆕",
        "🆓",
        "0️⃣",
        "1️⃣",
        "2️⃣",
        "3️⃣",
        "4️⃣",
        "5️⃣",
        "6️⃣",
        "7️⃣",
        "8️⃣",
        "9️⃣",
        "🔟",
        "🔢",
        "#️⃣",
        "*️⃣",
        "⏏️",
        "▶️",
        "⏸",
        "⏯",
        "⏹",
        "⏺",
        "⏭",
        "⏮",
        "⏩",
        "⏪",
        "⏫",
        "⏬",
        "◀️",
        "🔼",
        "🔽",
        "➡️",
        "⬅️",
        "⬆️",
        "⬇️",
        "↗️",
        "↘️",
        "↙️",
        "↖️",
        "↕️",
        "↔️",
        "↪️",
        "↩️",
        "⤴️",
        "⤵️",
        "🔀",
        "🔁",
        "🔂",
        "🔄",
        "🔃",
        "🎵",
        "🎶",
        "➕",
        "➖",
        "➗",
        "✖️",
        "♾",
        "💲",
        "💱",
        "™️",
        "©️",
        "®️",
        "〰️",
        "➰",
        "➿",
        "🔚",
        "🔙",
        "🔛",
        "🔝",
        "🔜",
        "✔️",
        "☑️",
        "🔘",
        "🔴",
        "🟠",
        "🟡",
        "🟢",
        "🔵",
        "🟣",
        "⚫️",
        "⚪️",
        "🟤",
        "🔺",
        "🔻",
        "🔸",
        "🔹",
        "🔶",
        "🔷",
        "🔳",
        "🔲",
        "▪️",
        "▫️",
        "◾️",
        "◽️",
        "◼️",
        "◻️",
        "🟥",
        "🟧",
        "🟨",
        "🟩",
        "🟦",
        "🟪",
        "⬛️",
        "⬜️",
        "🟫",
        "🔈",
        "🔇",
        "🔉",
        "🔊",
        "🔔",
        "🔕",
        "📣",
        "📢",
        "👁‍🗨",
        "💬",
        "💭",
        "🗯",
        "♠️",
        "♣️",
        "♥️",
        "♦️",
        "🃏",
        "🎴",
        "🀄️",
        "🕐",
        "🕑",
        "🕒",
        "🕓",
        "🕔",
        "🕕",
        "🕖",
        "🕗",
        "🕘",
        "🕙",
        "🕚",
        "🕛",
        "🕜",
        "🕝",
        "🕞",
        "🕟",
        "🕠",
        "🕡",
        "🕢",
        "🕣",
        "🕤",
        "🕥",
        "🕦",
        "🕧",
    ],
    [
        "✢",
        "✣",
        "✤",
        "✥",
        "✦",
        "✧",
        "★",
        "☆",
        "✯",
        "✡︎",
        "✩",
        "✪",
        "✫",
        "✬",
        "✭",
        "✮",
        "✶",
        "✷",
        "✵",
        "✸",
        "✹",
        "→",
        "⇒",
        "⟹",
        "⇨",
        "⇾",
        "➾",
        "⇢",
        "☛",
        "☞",
        "➔",
        "➜",
        "➙",
        "➛",
        "➝",
        "➞",
        "♠︎",
        "♣︎",
        "♥︎",
        "♦︎",
        "♤",
        "♧",
        "♡",
        "♢",
        "♚",
        "♛",
        "♜",
        "♝",
        "♞",
        "♟",
        "♔",
        "♕",
        "♖",
        "♗",
        "♘",
        "♙",
        "⚀",
        "⚁",
        "⚂",
        "⚃",
        "⚄",
        "⚅",
        "🂠",
        "⚈",
        "⚉",
        "⚆",
        "⚇",
        "𓀀",
        "𓀁",
        "𓀂",
        "𓀃",
        "𓀄",
        "𓀅",
        "𓀆",
        "𓀇",
        "𓀈",
        "𓀉",
        "𓀊",
        "𓀋",
        "𓀌",
        "𓀍",
        "𓀎",
        "𓀏",
        "𓀐",
        "𓀑",
        "𓀒",
        "𓀓",
        "𓀔",
        "𓀕",
        "𓀖",
        "𓀗",
        "𓀘",
        "𓀙",
        "𓀚",
        "𓀛",
        "𓀜",
        "𓀝",
    ],
    [
        "🏳️",
        "🏴",
        "🏁",
        "🚩",
        "🏳️‍🌈",
        "🏳️‍⚧️",
        "🏴‍☠️",
        "🇦🇫",
        "🇦🇽",
        "🇦🇱",
        "🇩🇿",
        "🇦🇸",
        "🇦🇩",
        "🇦🇴",
        "🇦🇮",
        "🇦🇶",
        "🇦🇬",
        "🇦🇷",
        "🇦🇲",
        "🇦🇼",
        "🇦🇺",
        "🇦🇹",
        "🇦🇿",
        "🇧🇸",
        "🇧🇭",
        "🇧🇩",
        "🇧🇧",
        "🇧🇾",
        "🇧🇪",
        "🇧🇿",
        "🇧🇯",
        "🇧🇲",
        "🇧🇹",
        "🇧🇴",
        "🇧🇦",
        "🇧🇼",
        "🇧🇷",
        "🇮🇴",
        "🇻🇬",
        "🇧🇳",
        "🇧🇬",
        "🇧🇫",
        "🇧🇮",
        "🇰🇭",
        "🇨🇲",
        "🇨🇦",
        "🇮🇨",
        "🇨🇻",
        "🇧🇶",
        "🇰🇾",
        "🇨🇫",
        "🇹🇩",
        "🇨🇱",
        "🇨🇳",
        "🇨🇽",
        "🇨🇨",
        "🇨🇴",
        "🇰🇲",
        "🇨🇬",
        "🇨🇩",
        "🇨🇰",
        "🇨🇷",
        "🇨🇮",
        "🇭🇷",
        "🇨🇺",
        "🇨🇼",
        "🇨🇾",
        "🇨🇿",
        "🇩🇰",
        "🇩🇯",
        "🇩🇲",
        "🇩🇴",
        "🇪🇨",
        "🇪🇬",
        "🇸🇻",
        "🇬🇶",
        "🇪🇷",
        "🇪🇪",
        "🇪🇹",
        "🇪🇺",
        "🇫🇰",
        "🇫🇴",
        "🇫🇯",
        "🇫🇮",
        "🇫🇷",
        "🇬🇫",
        "🇵🇫",
        "🇹🇫",
        "🇬🇦",
        "🇬🇲",
        "🇬🇪",
        "🇩🇪",
        "🇬🇭",
        "🇬🇮",
        "🇬🇷",
        "🇬🇱",
        "🇬🇩",
        "🇬🇵",
        "🇬🇺",
        "🇬🇹",
        "🇬🇬",
        "🇬🇳",
        "🇬🇼",
        "🇬🇾",
        "🇭🇹",
        "🇭🇳",
        "🇭🇰",
        "🇭🇺",
        "🇮🇸",
        "🇮🇳",
        "🇮🇩",
        "🇮🇷",
        "🇮🇶",
        "🇮🇪",
        "🇮🇲",
        "🇮🇱",
        "🇮🇹",
        "🇯🇲",
        "🇯🇵",
        "🎌",
        "🇯🇪",
        "🇯🇴",
        "🇰🇿",
        "🇰🇪",
        "🇰🇮",
        "🇽🇰",
        "🇰🇼",
        "🇰🇬",
        "🇱🇦",
        "🇱🇻",
        "🇱🇧",
        "🇱🇸",
        "🇱🇷",
        "🇱🇾",
        "🇱🇮",
        "🇱🇹",
        "🇱🇺",
        "🇲🇴",
        "🇲🇰",
        "🇲🇬",
        "🇲🇼",
        "🇲🇾",
        "🇲🇻",
        "🇲🇱",
        "🇲🇹",
        "🇲🇭",
        "🇲🇶",
        "🇲🇷",
        "🇲🇺",
        "🇾🇹",
        "🇲🇽",
        "🇫🇲",
        "🇲🇩",
        "🇲🇨",
        "🇲🇳",
        "🇲🇪",
        "🇲🇸",
        "🇲🇦",
        "🇲🇿",
        "🇲🇲",
        "🇳🇦",
        "🇳🇷",
        "🇳🇵",
        "🇳🇱",
        "🇳🇨",
        "🇳🇿",
        "🇳🇮",
        "🇳🇪",
        "🇳🇬",
        "🇳🇺",
        "🇳🇫",
        "🇰🇵",
        "🇲🇵",
        "🇳🇴",
        "🇴🇲",
        "🇵🇰",
        "🇵🇼",
        "🇵🇸",
        "🇵🇦",
        "🇵🇬",
        "🇵🇾",
        "🇵🇪",
        "🇵🇭",
        "🇵🇳",
        "🇵🇱",
        "🇵🇹",
        "🇵🇷",
        "🇶🇦",
        "🇷🇪",
        "🇷🇴",
        "🇷🇺",
        "🇷🇼",
        "🇼🇸",
        "🇸🇲",
        "🇸🇦",
        "🇸🇳",
        "🇷🇸",
        "🇸🇨",
        "🇸🇱",
        "🇸🇬",
        "🇸🇽",
        "🇸🇰",
        "🇸🇮",
        "🇬🇸",
        "🇸🇧",
        "🇸🇴",
        "🇿🇦",
        "🇰🇷",
        "🇸🇸",
        "🇪🇸",
        "🇱🇰",
        "🇧🇱",
        "🇸🇭",
        "🇰🇳",
        "🇱🇨",
        "🇵🇲",
        "🇻🇨",
        "🇸🇩",
        "🇸🇷",
        "🇸🇿",
        "🇸🇪",
        "🇨🇭",
        "🇸🇾",
        "🇹🇼",
        "🇹🇯",
        "🇹🇿",
        "🇹🇭",
        "🇹🇱",
        "🇹🇬",
        "🇹🇰",
        "🇹🇴",
        "🇹🇹",
        "🇹🇳",
        "🇹🇷",
        "🇹🇲",
        "🇹🇨",
        "🇹🇻",
        "🇻🇮",
        "🇺🇬",
        "🇺🇦",
        "🇦🇪",
        "🇬🇧",
        "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
        "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
        "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
        "🇺🇳",
        "🇺🇸",
        "🇺🇾",
        "🇺🇿",
        "🇻🇺",
        "🇻🇦",
        "🇻🇪",
        "🇻🇳",
        "🇼🇫",
        "🇪🇭",
        "🇾🇪",
        "🇿🇲",
        "🇿🇼",
    ],
]
