const messages = {
    satisfaction: "Satisfação",
    goodExperience: "Boa experiência",
    badExperience: "Má experiência",
    doesntKnowDoesntAnswer: "Sem alternativas",
    satisfactionDesc:
        "Aqui você pode ver as respostas dos seus clientes para as {x} pesquisas de satisfação enviadas de {from} a {to}.",
    youDontHaveAnySatisfactionSurveys: "Você não possui nenhuma pesquisa de satisfação",
    noDataFoundForThisDateRange: "Nenhum dado encontrado para este intervalo de datas",
    clientAnswers: "Respostas do cliente",
    answersSummary: "Resumo das respostas",
    badExperiencesBreakdown: "Detalhamento das más experiências",
    answersByDay: "Respostas por dia",
    obtainingSatisfactionData: "A obter dados de satisfação...",
    feedback: "Feedback",
    customerFeedback: "Comentários dos clientes",
    anErrorOcurredObtainingSatisfactionData:
        "Um erro ocorreu ao obter os dados de satisfação, por favor, tente novamente.",
    anErrorOcurredObtainingFeedbackData: "Um erro ocorreu ao obter os dados de feedback, por favor, tente novamente.",
    noFeedbackFound: "Você não possui nenhum feedback para este intervalo de datas.",
    dataUpdatedevery3Hours: "Os dados são atualizados a cada 3 horas.",
    commentsBeforeThe25OfSeptember2023AreNotAvailable:
        "Comentários anteriores a 26 de setembro de 2023 não estão disponíveis.",
    xClientsDidNotSendFeedbackOrSentIncompleteFeedback:
        "{x} Clientes não enviaram feedback ou enviaram feedback com menos de 2 caracteres.",
}

export default messages
