import ScreenMessages from "./screen-messages"
import loadingMessages from "./loading-messages"
import verticals from "./whatsapp-verticals"
import metaErrorMessages from "./meta-error-messages"
import countryMessages from "./countries.messages"
import countingMessages from "./counting.messages"

const messages = {
    ...ScreenMessages,
    ...loadingMessages,
    ...verticals,
    ...metaErrorMessages,
    ...countryMessages,
    ...countingMessages,
    confirmPassword: "Confirme sua senha",
    rememberPassword: "Lembrar sua senha",
    didYouforgetPassword: "Você esqueceu sua senha?",
    forgotPasswordMessage: "Digite seu e-mail e enviaremos as instruções para redefinir sua senha",
    createAccount: "Crie a sua conta aqui",
    recoveryPassword: "Recuperar senha",
    costumerService: "Chat com o Cliente",
    clientsList: "Meus clientes",
    clients: "Clientes",
    noClients: "Não clientes",
    whatsappConfig: "Configurando",
    billing: "Cobrança",
    name: "Nome",
    phone: "Telefone",
    assignedExecutive: "Executivo designado",
    inquiries: "Consultas",
    company: "Empresa",
    search: "Procurar",
    open: "Abrir",
    scheduled: "Agendado",
    archived: "Arquivado",
    pending: "Pendente",
    pendingPlural: "Pendentes",
    unread: "Não lida",
    filters: "Filtros",
    hasBeenSavedSuccessfully: "Foi salvo com sucesso",
    initialSetup: "Configuração inicial",
    this: "Esses",
    postpone: "Adiar",
    logout: "Sair",
    myProfile: "Meu perfil",
    users: "Usuários",
    cold: "Frio",
    warm: "Esquentar",
    hot: "Quente",
    note: "Nota",
    notes: "Notas",
    template: "Iniciar mensagem",
    help: "Ajuda",
    tour: "Tour",
    saveChanges: "Salvar alterações",
    discardChanges: "Descartar mudanças",
    resetFilters: "Limpar filtros",
    selected: "Selecionado",
    tag: "Etiqueta",
    cancel: "Cancelar",
    close: "Fechar",
    preview: "Visualização",
    basic: "Básico",
    standard: "Padrão",
    pro: "Pró",
    included: "Incluído",
    max: "Máximo",
    download: "Descarregar",
    approved: "Aprovado",
    rejected: "Rejeitado",
    role: "Papel",
    retry: "Tentar novamente",
    send: "Enviar",
    item: "Item",
    date: "Data",
    detail: "Detalhe",
    price: "Preço",
    save: "Salvar",
    to: "Para",
    openInGoogleMaps: "Abrir no Google Maps",
    archivedSingular: "Arquivado",
    yesterday: "Ontem",
    today: "Hoje",
    multiSelect: "Seleção múltipla",
    selectedAll: "Selecione conversas visíveis",
    unselectAll: "Desmarque todas as conversas",
    none: "Nenhum",
    connectionError: "Ops! Você parece ter perdido a conexão",
    reloadPage: "Recarregar a página",
    status: "Estado",
    month: "Mês",
    week: "Semana",
    months: "Meses",
    weeks: "Semanas",
    hours: "Horas",
    hour: "Hora",
    tomorrow: "Amanhã",
    report: "Relatório",
    sequenceMessage: "Cheetabot",
    templateMessageToInitiateConversations: "Enviar mensagem para iniciar conversa",
    messagesSetup: "Configuração de mensagens",
    delete: "Excluir",
    duplicate: "Duplicado",
    message: "Mensagem",
    activeBot: "Habilitar Bot",
    inactiveBot: "Desativar Bot",
    lockToSegment: "Pule esta conversa para segmentos de clientes.",
    unlocktoSegment: "Ativar esta conversa para segmento de clientes.",
    goBack: "Voltar",
    minutes: "Minutos",
    days: "Dias",
    seeMore: "Ver mais",
    minimize: "Minimizar",
    here: "aqui",
    seeTable: "Veja tabela",
    seeChart: "Veja o gráfico",
    and: "e",
    uploadFile: "Subir arquivo",
    uploadFiles: "Subir arquivos",
    uploadImage: "Subir imagem",
    uploadImages: "Subir imagens",
    dropHere: "Solta aqui 😉",
    fileLimitIs: "O limite de arquivos é {limit}",
    fileMustBeAnImage: "O arquivo deve ser uma imagem",
    yourImage: "Sua imagem",
    continue: "Continuar",
    darkMode: "Modo escuro",
    lightMode: "Modo claro",
    yourDocument: "Seu documento",
    yourVideo: "Seu vídeo",
    messages: "Mensagens",
    morning: "Manhã",
    afternoon: "Tarde",
    evening: "Noite",
    dashboard: "Painel",
    others: "Outros",
    from: "De",
    until: "Para",
    andXMore: "e {x} mais",
    in: "em",
    add: "Adicionar",
}

export default messages
